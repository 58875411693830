import React, { useState, useEffect } from 'react';
import Sidebar from '../../component/Sidebar';
import Topbar from '../../component/Topbar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useTranslation } from 'react-i18next';
import Pagination from '@mui/material/Pagination';
import star from '../../images/star.svg';
import deleteIcon from '../../images/delete.svg';
import closeModal from '../../images/close.svg';
import edit from '../../images/edit.svg';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import plus from '../../images/plus.svg';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import check from '../../images/check.svg'
import { ToastContainer, toast } from 'react-toastify';
import delete_icon from '../../images/delete_icon.svg';
import 'react-toastify/dist/ReactToastify.css';
// <!-- Google Fonts -->
import '../../css/material-icon.css';

// <!-- Bootstrap Core Css -->
import '../../plugins/bootstrap/css/bootstrap.css';

// <!-- Waves Effect Css -->
import '../../plugins/node-waves/waves.css';

// <!-- Animation Css -->
import '../../plugins/animate-css/animate.css';

// <!-- Bootstrap Select Css -->
import '../../plugins/bootstrap-select/css/bootstrap-select.css';


// <!-- JQuery DataTable Css -->
import '../../plugins/jquery-datatable/skin/bootstrap/css/dataTables.bootstrap.css';
// <!-- Bootstrap Material Datetime Picker Css -->
import '../../plugins/bootstrap-material-datetimepicker/css/bootstrap-material-datetimepicker.css';

// <!-- Bootstrap DatePicker Css -->
import '../../plugins/bootstrap-datepicker/css/bootstrap-datepicker.css';

// <!-- Wait Me Css -->
import '../../plugins/waitme/waitMe.css';

// <!-- Morris Chart Css-->
import '../../plugins/morrisjs/morris.css';

// <!-- Custom Css -->
import '../../css/style.css';
import '../../css/custom.css';
import '../../css/materialize.css';
import '../../css/uicons-bold-rounded.css';
import { useDispatch, useSelector } from "react-redux";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import { advertisementImageListApi, advertisementImageListVerticalApi, approveTestimonialApi, blogFlagDataApi, blogGetByIdApi, blogListApi, deleteAdvertisementImageApi, deleteBlogApi, deleteTestimonialApi, howItWorksCounterGetByIdApi, howItWorksCounterUpdateApi, logoutApi, testimonialsListApi, } from '../../redux/ApiActionCreator';
import config from '../../redux/config';
import axios from 'axios';
import moment from 'moment-timezone';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CMS() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [value, setValue] = useState('one');
  const [loading, setLoading] = useState(false)
  const [blogDeletePopup, setBlogDeletePopup] = useState(false)
  const [blogDeleteSelectedId, setBlogDeleteSelectedId] = useState('')
  const [testinomialsDeletePoppup, setTestinomialsDeletePoppup] = useState(false)
  const [testinomialsDeleteSelectedId, setTestinomialsDeleteSelectedId] = useState('')
  const [totalProperty, setTotalProperty] = useState('')
  const [happyClient, sethappyClient] = useState('')
  const [orderCount, setorderCount] = useState('')
  const [totalPropertyError, settotalPropertyError] = useState('')
  const [happyClientError, sethappyClientError] = useState('')
  const [orderCountError, setorderCountError] = useState('')
  const [blogPage, setBlogPage] = useState(1)
  const [testiMonialsPage, setTestiMonialsPage] = useState(1)
  const token = useSelector((state) => state?.apiReducer?.loginData?.Data?.accessToken)
  const testimonialsListData = useSelector((state) => state?.apiReducer?.testimonialsListData)
  const blogListData = useSelector((state) => state?.apiReducer?.blogListData)
  const advertisementImageListData = useSelector((state) => state?.apiReducer?.advertisementImageListData)
  const advertisementImageListVerticalData = useSelector((state) => state?.apiReducer?.advertisementImageListVerticalData)
  const handleKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);

    // Allow digits, decimal point, and backspace
    if (!/^\d$/.test(keyValue) && keyValue !== '.' && keyCode !== 8) {
      event.preventDefault();
    }

    // Prevent typing a minus sign
    if (keyCode === 45) {
      event.preventDefault();
    }
  };


  useEffect(() => {
    settotalPropertyError('');
    sethappyClientError('');
    setorderCountError('')
  }, [value])

  //TESTIMONIALS API CALL
  useEffect(() => {
    const params = {
      "pageNumber": testiMonialsPage,
    }
    dispatch(testimonialsListApi(token, params))
  }, [testiMonialsPage, value])

  //BLOGLIST API CALL
  useEffect(() => {
    const params = {
      "pageNumber": blogPage,
    }
    dispatch(blogListApi(token, params))
  }, [blogPage, value])

  //ADVERTISEMENT IMAGE LIST API CALL
  useEffect(() => {
    dispatch(advertisementImageListApi(token))
    dispatch(advertisementImageListVerticalApi(token))
  }, [value])


  //HOW IT WORK API CALL
  useEffect(() => {
    getByHowItWorkApi()
  }, [value])



  const getByHowItWorkApi = async () => {
    setLoading(true)

    try {
      const response = await axios.get(`${config.baseURL}/HowItsWorks/GetById?Id=1`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status == 200) {

        if (response.data.Status == 'OK') {
          setLoading(false)
          const masterData = response?.data?.Data
          setTotalProperty(masterData?.Total_Properties)
          sethappyClient(masterData?.Happy_Clients)
          setorderCount(masterData?.Sucessfull_Orders)
        } else if (response.data.Status == 'ERROR') {
          setLoading(false)
        }

      } else {

      }
    } catch (error) {
      if (error.response && error.response.status === 401) {

        dispatch(logoutApi())
        navigate('/')

      }

      setLoading(false)


    }
  };


  const handleChanges = (event, newValue) => {
    setValue(newValue);
  };

  //DELETE TESTIMONIALS
  const clickDeleteTestinomials = () => {
    dispatch(deleteTestimonialApi(token, testinomialsDeleteSelectedId))
      .then((response) => {
        const params = {
          "pageNumber": 1,
        }
        dispatch(testimonialsListApi(token, params))
        setTestinomialsDeletePoppup(false);
        setTestiMonialsPage(1)
      })
      .catch((error) => {


      });
  }

  //ONCLICK APPROVE TESTIMONIALS
  const onClickApproved = (item) => {
    setLoading(true)
    const params = {
      "Id": item?.Id,
      "IsApproved": true,

    }
    dispatch(approveTestimonialApi(token, params))
      .then((response) => {

        const params = {
          "pageNumber": 1,
        }
        dispatch(testimonialsListApi(token, params))
          .then((response) => {
            setLoading(false)
            setTestiMonialsPage(1)
          })
      })
      .catch((error) => {
        setLoading(false)

      });
  }


  //DELETE BLOG API CALL
  const onClickdeleteBlogApi = () => {
    setLoading(true)
    dispatch(deleteBlogApi(token, blogDeleteSelectedId))
      .then((response) => {
        setLoading(false)
        const params = {
          "pageNumber": 1,
        }
        dispatch(blogListApi(token, params))
        setBlogPage(1)
        setBlogDeletePopup(false);
      })
      .catch((error) => {
        setLoading(false)
        setBlogDeletePopup(false);

      });
  }

  //ONCLICK CREATE BLOG NAVIGATE
  const onClickBlogCreate = () => {
    dispatch(blogFlagDataApi('create', ''))
    navigate('/AddBlog')
  }

  //ONCLICK EDIT BLOG NAVIGATE
  const onClickBlogEdit = (id) => {
    dispatch(blogFlagDataApi('edit', id))
    dispatch(blogGetByIdApi(token, id))
      .then((response) => {
        setLoading(false)
        navigate('/AddBlog')
      })
      .catch((error) => {
        setLoading(false)


      });

  }


  const handleClickOpen = (id) => {
    setBlogDeleteSelectedId(id)
    setBlogDeletePopup(true);
  };

  const handleClose = () => {
    setBlogDeletePopup(false);
  };


  const handleClickTestinomialsOpen = (id) => {
    setTestinomialsDeleteSelectedId(id)
    setTestinomialsDeletePoppup(true);
  };

  const handleTestinomialsClose = () => {
    setTestinomialsDeletePoppup(false);
  };

  const validatePropertyCount = (value) => {
    if (value.length === 0) {
      settotalPropertyError('Please Enter Properties count');
    } else {
      settotalPropertyError('');
    }
  };

  const validateHappyClientCount = (value) => {
    if (value.length === 0) {
      sethappyClientError('Please Enter Happy Client Count');
    } else {
      sethappyClientError('');
    }
  };

  const validateOrderCount = (value) => {
    if (value.length === 0) {
      setorderCountError('Please Enter Successful Orders Count');
    } else {
      setorderCountError('');
    }
  };


  //ADD HOW ITS WORK API CALL
  const onClickHowItsWorksAPi = () => {
    let validate = false
    if (totalProperty == '') {
      validate = true
      settotalPropertyError('Please Enter Properties count');
    } else {
      settotalPropertyError('');
    }
    if (happyClient == '') {
      validate = true
      sethappyClientError('Please Enter Happy Client Count');
    } else {
      sethappyClientError('');
    }
    if (orderCount == '') {
      validate = true
      setorderCountError('Please Enter Successful Orders Count');
    } else {
      setorderCountError('');
    }

    if (validate == false) {
      setLoading(true)
      const params = {
        "Id": 1,
        "Sucessfull_Orders": orderCount == '' ? null : orderCount,
        "Total_Properties": totalProperty == '' ? null : totalProperty,
        "Happy_Clients": happyClient == '' ? null : happyClient,
      }

      dispatch(howItWorksCounterUpdateApi(token, params))
        .then((response) => {
          toast.success("Record Saved Successfully", {
            position: 'top-right',
            autoClose: 1000, // Adjust the duration as needed
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          setLoading(false)
          setValue('one')
          getByHowItWorkApi()
        })
        .catch((error) => {
          setLoading(false)


        });

    }
  }

  //BANNER IMAGE UPLOAD
  const handleMediaFileChange = async (e) => {
    const file = e.target.files[0];
    const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png'];

    if (!allowedTypes.includes(file?.type)) {
      alert('Please upload a JPG, JPEG, or PNG file.');
      e.target.value = null;
      // Trigger an additional event to force recognition of the change
      e.target.dispatchEvent(new Event('input'));
      return; // Exit the function if file type is not allowed
    }

    try {
      // Load the image to check its dimensions
      const img = new Image();
      img.src = URL.createObjectURL(file);

      img.onload = async () => {
        if (img.width !== 560 || img.height !== 270) {
          alert('Please upload an image with dimensions of 560x270.');
          e.target.value = null;
          // Trigger an additional event to force recognition of the change
          e.target.dispatchEvent(new Event('input'));
          return;
        }

        // Create a FormData object
        const formData = new FormData();

        // Add form fields to the FormData object
        formData.append('file', file);
        formData.append('FieldId', 2);

        try {
          // Make an API call to upload the image
          const response = await axios.post(
            `${config.baseURL}/BannerImages/UploadBannerImage`,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data', // Set the content type to FormData
                Authorization: `Bearer ${token}`,
                // Add any other headers if needed
              },
            }
          );

          // Handle the response here (e.g., update state or perform other actions)
          if (response.data.Status == 'OK') {
            e.target.value = null;
            e.target.dispatchEvent(new Event('input'));
            dispatch(advertisementImageListApi(token)).then(() => {
              toast.success('Files Uploaded Successfully', {
                position: 'top-right',
                autoClose: 700,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
              setTimeout(() => {
                setLoading(false);
              }, 1000);
            });
          } else {
            // Handle error response
          }
        } catch (error) {
          // Handle API call error
          console.error('Error:', error);
        }
      };
    } catch (error) {
      // Handle any errors here
      console.error('Error:', error);
    }
  };

  //VERTICAL IMAGE UPLOAD
  const handleVerticalMediaFileChange = async (e) => {
    const file = e.target.files[0];
    const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png'];

    if (!allowedTypes.includes(file?.type)) {
      alert('Please upload a JPG, JPEG, or PNG file.');
      e.target.value = null;
      // Trigger an additional event to force recognition of the change
      e.target.dispatchEvent(new Event('input'));
      return; // Exit the function if file type is not allowed
    }

    try {
      // Load the image to check its dimensions
      const img = new Image();
      img.src = URL.createObjectURL(file);

      img.onload = async () => {
        if (img.width !== 360 || img.height !== 650) {
          alert('Please upload an image with dimensions of 360x650.');
          e.target.value = null;
          // Trigger an additional event to force recognition of the change
          e.target.dispatchEvent(new Event('input'));
          return;
        }

        // Create a FormData object
        const formData = new FormData();

        // Add form fields to the FormData object
        formData.append('file', file);
        formData.append('FieldId', 1);

        try {
          // Make an API call to upload the image
          const response = await axios.post(
            `${config.baseURL}/BannerImages/UploadBannerImage`,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data', // Set the content type to FormData
                Authorization: `Bearer ${token}`,
                // Add any other headers if needed
              },
            }
          );

          // Handle the response here (e.g., update state or perform other actions)
          if (response.data.Status == 'OK') {
            e.target.value = null;
            e.target.dispatchEvent(new Event('input'));
            dispatch(advertisementImageListVerticalApi(token)).then(() => {
              toast.success('Files Uploaded Successfully', {
                position: 'top-right',
                autoClose: 700,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
              setTimeout(() => {
                setLoading(false);
              }, 1000);
            });
          } else {
            // Handle error response
          }
        } catch (error) {
          // Handle API call error
          console.error('Error:', error);
        }
      };
    } catch (error) {
      // Handle any errors here
      console.error('Error:', error);
    }
  };


  //DELETE BANNER ADVERTISEMENT IMAGE
  const onClickAdvertizingImageDelete = (imageId) => {
    setLoading(true)
    dispatch(deleteAdvertisementImageApi(token, imageId))
      .then((response) => {
        toast.success("Deleted Image Successfully", {
          position: 'top-right',
          autoClose: 500, // Adjust the duration as needed
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        dispatch(advertisementImageListApi(token))
        setTimeout(() => {
          setLoading(false)
        }, 1000);
      })
      .catch((error) => {
        setLoading(false)


      });
  }

  //DELETE VERTICAL IMAGE
  const onClickVerticalAdvertizingImageDelete = (imageId) => {
    setLoading(true)
    dispatch(deleteAdvertisementImageApi(token, imageId))
      .then((response) => {
        toast.success("Deleted Image Successfully", {
          position: 'top-right',
          autoClose: 500, // Adjust the duration as needed
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        dispatch(advertisementImageListVerticalApi(token))
        setTimeout(() => {
          setLoading(false)
        }, 1000);
      })
      .catch((error) => {
        setLoading(false)


      });
  }

  const pageHandleChange = (event, value) => {
    setBlogPage(value);
  };

  const pageHandleTestiMonialChange = (event, value) => {
    setTestiMonialsPage(value);
  };
  return (
    <div className='App'>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}

      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        open={blogDeletePopup}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        className='dialog_box_popup'
      >

        <DialogContent class="dialog_box_block">
          <DialogContentText id="alert-dialog-slide-description" class="dialog_box_title">
            <h6>Delete</h6>
            <Button onClick={handleClose}><img src={closeModal} /></Button>
          </DialogContentText>
          <DialogContentText id="alert-dialog-slide-description" class="dialog_box_detail">
            Are you sure you want to delete this Blog ?
          </DialogContentText>
        </DialogContent>
        <DialogActions class="dialog_box_button">
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={() => onClickdeleteBlogApi()}>Delete</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={testinomialsDeletePoppup}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleTestinomialsClose}
        aria-describedby="alert-dialog-slide-description"
        className='dialog_box_popup'
      >

        <DialogContent class="dialog_box_block">
          <DialogContentText id="alert-dialog-slide-description" class="dialog_box_title">
            <h6>Delete</h6>
            <Button onClick={handleTestinomialsClose}><img src={closeModal} /></Button>
          </DialogContentText>
          <DialogContentText id="alert-dialog-slide-description" class="dialog_box_detail">
            Are you sure you want to delete this testinomials ?
          </DialogContentText>
        </DialogContent>
        <DialogActions class="dialog_box_button">
          <Button onClick={handleTestinomialsClose}>Cancel</Button>
          <Button onClick={() => clickDeleteTestinomials()}>Delete</Button>
        </DialogActions>
      </Dialog>
      <Topbar />
      <Sidebar message={'CMS'} />

      <section class="content">
        <div class="block-header">
          <h2>{t('cms')}</h2>
        </div>
        <div class="row clearfix">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div class="tab_block">
              <Tabs
                value={value}

                onChange={handleChanges}
                className='tab-nav-right'
                aria-label="wrapped label tabs example"
              >
                <Tab className='tabs_title' value="one" label={t('blog')} />
                <Tab className='tabs_title' value="two" label={t('adsImage')} wrapped />
                <Tab className='tabs_title' value="three" label={t('testinomials')} />
                <Tab className='tabs_title' value="four" label={t('howItsWorksNumber')} />
              </Tabs>
            </div>

            <div class="tab_content_block">

              {
                value == 'one' ?
                  <>
                    <div >

                      <div class="page_heading">
                        <h3 class="main_title">Blog</h3>
                        <a onClick={() => onClickBlogCreate()}><img src={plus} /></a>
                      </div>

                    </div>
                    <div>
                      <div class="form_block">
                        <div class="row clearfix">
                          <div class="main_class">
                            <div class="table_block">
                              <div class="card">
                                <div class="table-responsive">

                                  {
                                    blogListData?.Data?.length != 0 ?
                                      <table class="table table-bordered table-striped table-hover dataTable">
                                        <thead>
                                          <tr>
                                            <th></th>
                                            <th>
                                              <div class="table_detail text-center">
                                                <h5>{t('id')}</h5>
                                              </div>
                                            </th>
                                            <th>
                                              <div class="table_detail text-center">
                                                <h5>{t('blogTitle')}</h5>

                                              </div>
                                            </th>

                                          </tr>
                                        </thead>
                                        {
                                          blogListData?.Data?.map((item) => {
                                            return (
                                              <tbody>
                                                <tr>
                                                  <td style={{ width: '10%' }}>
                                                    <div class="table_detail" style={{ display: 'flex' }}>

                                                      <a class="link green"><img src={edit} onClick={() => onClickBlogEdit(item?.Id)} /></a>
                                                      <a href="#" class="link red" onClick={() => handleClickOpen(item?.Id)}><img src={deleteIcon} /></a>
                                                    </div>
                                                  </td>
                                                  <td style={{ width: '10%' }}>
                                                    <div class="table_detail text-center">
                                                      <h6>{item?.Id}</h6>
                                                    </div>
                                                  </td>
                                                  <td style={{ width: '80%' }}>
                                                    <div class="table_detail text-center">
                                                      <h6 class="blog-title">{item?.Title}</h6>
                                                    </div>
                                                  </td>

                                                </tr>
                                              </tbody>
                                            )
                                          })
                                        }

                                      </table> : <h1 class="text-center">No Data Found</h1>

                                  }

                                  <div class="text-right">
                                    <Pagination className="pagination" count={blogListData?.TotalPages} page={blogPage} onChange={pageHandleChange} />
                                  </div>

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </> : null
              }
              {
                value == 'two' ?
                  <>
                    <div class="main_class">
                      <div>
                        <p class="note" style={{ color: '#EC1A25' }}><span class="notes" style={{ color: '#EC1A25' }}>Note: </span>{t('adsImageTop')}.</p>

                        <div class="property_image_block">
                          <div class="row clearfix">
                            <div class="col-md-3 col-sm-4 col-xs-6">
                              <div class="property_image">
                                <div class="file-drop-area images">
                                  <figure><img src={require('../../images/upload_image.jpg')} /></figure>
                                  <input class="file-input" type="file" onChange={handleMediaFileChange} />
                                </div>
                              </div>
                            </div>
                            {
                              advertisementImageListData?.Data?.map((item) => {
                                return (
                                  <div class="col-md-3 col-sm-4 col-xs-6">
                                    <div class="property_image default">
                                      <img src={`${config.baseURL}${item?.Url}`} alt={item?.Id} />
                                      {


                                        <div class="default_block">
                                          <a class="delete_image" onClick={() => onClickAdvertizingImageDelete(item?.BannerImageId)}>
                                            <img src={delete_icon}></img>
                                          </a>
                                        </div>

                                      }
                                    </div>

                                  </div>
                                )
                              })
                            }

                          </div>
                        </div>

                      </div>
                      <div style={{ margin: '20px 0 0 0' }}>
                        <p class="note" style={{ color: '#EC1A25' }}><span class="notes" style={{ color: '#EC1A25' }}>Note: </span>{t('adsImageBottom')}.</p>

                        <div class="property_image_block">
                          <div class="row clearfix">
                            <div class="col-md-3 col-sm-4 col-xs-6">
                              <div class="property_image">
                                <div class="file-drop-area images">
                                  <figure><img src={require('../../images/upload_image.jpg')} /></figure>
                                  <input class="file-input" type="file" onChange={handleVerticalMediaFileChange} />
                                </div>
                              </div>
                            </div>
                            {
                              advertisementImageListVerticalData?.Data?.map((item) => {
                                return (
                                  <div class="col-md-3 col-sm-4 col-xs-6">
                                    <div class="property_image default">
                                      <img src={`${config.baseURL}${item?.Url}`} alt={item?.Id} />
                                      {


                                        <div class="default_block">
                                          <a class="delete_image" onClick={() => onClickVerticalAdvertizingImageDelete(item?.BannerImageId)}>
                                            <img src={delete_icon}></img>
                                          </a>
                                        </div>

                                      }
                                    </div>
                                  </div>
                                )
                              })
                            }
                          </div>
                        </div>

                      </div>

                    </div>
                  </>
                  : null
              }
              {
                value == 'three' ?
                  <>
                    <div class="main_class">
                      <div class="table_block">
                        <div class="table-responsive">
                          {
                            testimonialsListData?.Data?.length != 0 ?
                              <table class="table table-bordered table-striped table-hover dataTable custom">
                                <thead>
                                  <tr>
                                    <th></th>
                                    <th>{t('id')}</th>
                                    <th>{t('name')}</th>
                                    <th>{t('date')}</th>
                                    <th>{t('email')}</th>
                                    <th>{t('message')}</th>
                                    <th>{t('rating')}</th>

                                  </tr>
                                </thead>
                                {
                                  testimonialsListData?.Data?.map((item) => {
                                    return (
                                      <tbody>

                                        <tr>
                                          <td>
                                            <div class="table_detail" style={{ flexDirection: 'column' }}>
                                              {
                                                item?.IsApproved == false ?
                                                  <a class="link green" onClick={() => onClickApproved(item)}><img src={check} /></a>
                                                  :
                                                  null
                                              }

                                              <a href="#" class="link red" onClick={() => handleClickTestinomialsOpen(item?.Id)}><img src={deleteIcon} /></a>
                                            </div>
                                          </td>
                                          <td>
                                            <p class="word_break">{item?.Id}</p>
                                          </td>
                                          <td>
                                            <p class="word_break">{item?.Name}</p>
                                          </td>
                                          <td>
                                            <p class="word_break">{moment.utc(item?.CreatedDate).local().format('DD-MM-YYYY')}</p>
                                          </td>
                                          <td>
                                            <p class="word_break">{item?.Email}</p>
                                          </td>
                                          <td>
                                            <p class="testimonial-msg">{item?.Message}</p>
                                          </td>
                                          <td>
                                            <p class="word_break">{item?.Rating} <img src={star} /></p>
                                          </td>

                                        </tr>
                                      </tbody>
                                    )
                                  })
                                }

                              </table> :
                              <h1 class="text-center">No Data Found</h1>
                          }

                        </div>
                        <div class="text-right">
                          <Pagination className="pagination" count={testimonialsListData?.TotalPages} page={testiMonialsPage} onChange={pageHandleTestiMonialChange} />
                        </div>
                      </div>

                    </div>
                  </> : null
              }
              {
                value == 'four' ?
                  <>
                    <div class="main_class">
                      <h3 class="main_title">{t('howItsWorksNumber')}</h3>
                      <div class="form_block">
                        <div class="row clearfix">
                          <div class="col-sm-6">
                            <div class="form-group">
                              <div class="form-line">
                                <input type="text" class="form-control" title={t('totalProperties')} maxLength={8} onKeyPress={handleKeyPress} placeholder={t('totalProperties')} value={totalProperty} onChange={(e) => { setTotalProperty(e.target.value); validatePropertyCount(e.target.value) }} />
                                {
                                  totalPropertyError && (
                                    <span class="validation_notes">{totalPropertyError}</span>
                                  )
                                }
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="form-group">
                              <div class="form-line">
                                <input type="text" class="form-control" title={t('happyClients')} maxLength={8} onKeyPress={handleKeyPress} placeholder={t('happyClients')} value={happyClient} onChange={(e) => { sethappyClient(e.target.value); validateHappyClientCount(e.target.value) }} />
                                {
                                  happyClientError && (
                                    <span class="validation_notes">{happyClientError}</span>
                                  )
                                }
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group">
                              <div class="form-line">
                                <input type="text" title={t('successfulOrders')} class="form-control" maxLength={8} onKeyPress={handleKeyPress} placeholder={t('successfulOrders')} value={orderCount} onChange={(e) => { setorderCount(e.target.value); validateOrderCount(e.target.value) }} />
                                {
                                  orderCountError && (
                                    <span class="validation_notes">{orderCountError}</span>
                                  )
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="button_block">

                        <a href="#" class="links" onClick={() => onClickHowItsWorksAPi()}>Save</a>
                      </div>
                    </div>
                  </> : null
              }


            </div>


          </div>
        </div>
      </section>
      <ToastContainer />
    </div>
  )
}

