import React, { useEffect } from 'react';
import Navigation from "./navigation";
import { Provider } from 'react-redux';
import { store, persistor } from './redux/Store';
import { PersistGate } from 'redux-persist/integration/react';

function App() {

    useEffect(() => {
        if (window.location.protocol !== 'https:') {
            window.location.href = 'https://' + window.location.hostname + window.location.pathname + window.location.search;
        }
    }, []);

    return (

        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <Navigation />
            </PersistGate>
        </Provider>

    );
}

export default App;
