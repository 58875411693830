import React, { useState } from "react";
import arrow from '../../images/arrow.svg';
import emailIcon from '../../images/email.svg';
import roundArrow from '../../images/round-arrow.svg';
import signInOne from '../../images/sign_in_1.svg';
import signInTwo from '../../images/sign_in_2.svg';
import signInThree from '../../images/sign_in_3.svg';
import signInFour from '../../images/sign_in_4.svg';
import signInFive from '../../images/sign_in_5.svg';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

// <!-- Bootstrap Core Css -->
import '../../plugins/bootstrap/css/bootstrap.css';
import config from "../../redux/config";
import axios from "axios";


const ForgotPassword = () => {

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false)

    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState('')



    const validateEmail = (value) => {
        if (value.length === 0) {
            setEmailError('Email is Required');
        } else if (!(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value))) {
            setEmailError('Please Enter Valid Email');
        } else {
            setEmailError('');
        }
    };


    const handleEmailChange = (e) => {
        const value = e.target.value;
        setEmail(value);
        validateEmail(value);
    };



    const forgotPasswordApi = async () => {
        let validate = false;

        if (email === '') {
            validate = true;
            setEmailError('Email is Required');
        } else if (!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) {
            validate = true;
            setEmailError('Enter valid email');
        } else {
            setEmailError('');
        }

        if (!validate) {
            setLoading(true)
            const params = {
                Email: email
            }
            try {
                const response = await axios.post(`${config.baseURL}/Account/ForgotPassword`, params, {});

                if (response.status == 200) {

                    if (response.data.Status == 'OK') {
                        toast.success("After verifying your email, if an account with the provided email ID exists, Reset Password link will be sent to that email address.", {
                            position: 'top-right',
                            autoClose: 1500, // Adjust the duration as needed
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });

                        setTimeout(() => {
                            setLoading(false)
                            navigate('/Login')
                        }, 1500);

                    } else if (response.data.Status == 'ERROR') {
                        // setLoading(false)
                    }

                } else {

                }
            } catch (error) {
                // setLoading(false)
            }
        }
    };




    return (
        <div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div class="login_block">
                <div class="row clearfix no-gutter">
                    <div class="col-md-6">
                        <div class="back_block">
                            <div class="arrow_block">
                                <a onClick={() => navigate('/')} class="back_arrow">
                                    <img src={arrow} />
                                </a>

                            </div>
                            <div className="login_title_block">
                                <figure><img style={{ height: 85, marginLeft: -10 }} src={require("../../images/logo.png")} /></figure>
                                <h1>Forgot Password</h1>
                                <p>Secure Your Communictions with MakaanPro</p>
                                <div class="form_block">

                                    <div class="input-group">
                                        <span class="input-group-addon"><img src={emailIcon} /></span>
                                        <input type="email" class="form-control" disabled={loading} placeholder="Enter Your Email" value={email} onChange={handleEmailChange} />
                                        {
                                            emailError && (
                                                <span class="validation_notes">{emailError}</span>
                                            )
                                        }
                                    </div>


                                    <div className="login_button_block">
                                        <button type="button" class="btn btn-primary waves-effect" onClick={() => forgotPasswordApi()}>Continue<img src={roundArrow} /></button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div class="login_background">
                            <img src={require('../../images/sign-in-background.png')} />
                            <div class="sign_in_image">
                                <img src={signInOne} />
                            </div>
                            <div class="sign_in_image two">
                                <img src={signInTwo} />
                            </div>
                            <div class="sign_in_image three">
                                <img src={signInThree} />
                            </div>
                            <div class="sign_in_image four">
                                <img src={signInFour} />
                            </div>
                            <div class="your_data_block">
                                <div class="data_line_block">
                                    <div class="line white">
                                        <div class="line blue"></div>
                                    </div>
                                    <div class="line"></div>
                                    <div class="line grey"></div>
                                    <div class="line lightgrey"></div>
                                    <div class="line lightergrey"></div>
                                </div>
                                <div className="your_data">
                                    <img src={signInFive}></img>
                                    <h6>Your data, your rules</h6>
                                    <p>Your data belongs to you, and our encryption ensures that</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />

        </div>
    )
}
export default ForgotPassword