import React, { useEffect, useState } from "react";
import plus from '../../images/plus.svg';
import edit from '../../images/edit.svg';
import personInfo from '../../images/person_info.svg';
import deleteIcon from '../../images/delete.svg';
import closeModal from '../../images/close.svg';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Button } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import Topbar from '../../component/Topbar';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { agentApi, agentDetailDataApi, companyModeApi, companyNameListApi, deleteAgentApi, getAgentDetailsApi, logoutApi, mimicAPI } from "../../redux/ApiActionCreator";
import Sidebar from "../../component/Sidebar";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

// Function to get styles for the Select component
function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

// Transition component for the Dialog
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function AgentList() {
    const theme = useTheme();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = useSelector((state) => state?.apiReducer?.loginData?.Data?.accessToken);
    const agentListData = useSelector((state) => state?.apiReducer?.agentData);
    const agentLoad = useSelector((state) => state?.apiReducer?.agentLoad);
    const companyNameList = useSelector((state) => state?.apiReducer?.companyNameList);
    const [loading, setLoading] = useState(false);
    const [personName, setPersonName] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [page, setPage] = React.useState(1);
    const [id, setId] = useState(null);
    const [contact, setContact] = useState('');
    const [email, setEmail] = useState('');
    const [company, setCompany] = useState('');
    const [agent, setAgent] = useState('');
    const [agentSelectedId, setagentSelectedId] = useState('');



    // Handle key press for phone number input
    const handleKeyPressPhno = (event) => {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);

        // Allow digits and plus sign
        if (!/^\d$/.test(keyValue) && keyValue !== "+") {
            event.preventDefault();
        }
    };

    // Fetch company name list on component mount
    useEffect(() => {
        dispatch(companyNameListApi(token))
            .then((response) => {

            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {

                    dispatch(logoutApi())
                    navigate('/')

                }

            });

    }, []);

    // Handle page change for pagination
    const pageHandleChange = (event, value) => {
        setPage(value);
    };

    // Fetch agent list based on filter criteria
    useEffect(() => {
        const params = {
            Id: id === '' ? null : id,
            Email: email === '' ? null : email,
            Phone: contact === '' ? null : contact,
            Name: agent === '' ? null : agent,
            CompanyId: company === '' ? null : company,
            pagenumber: page
        };
        dispatch(agentApi(token, params));
    }, [page,]);

    // Delete agent
    const deleteAgent = () => {
        setLoading(true);
        dispatch(deleteAgentApi(token, agentSelectedId))
            .then(() => {
                const params = {
                    pagenumber: 1,
                    Id: id === '' ? null : id,
                    Email: email === '' ? null : email,
                    Phone: contact === '' ? null : contact,
                    Name: agent === '' ? null : agent,
                    CompanyId: company === '' ? null : company,
                };
                dispatch(agentApi(token, params))
                    .then(() => {
                        setOpen(false);
                        setLoading(false);
                        setPage(1);
                    })
                    .catch(error => {
                        setOpen(false);
                        setLoading(false);
                        setPage(1);
                    });
            })
            .catch(error => {
                // Handle error
            });
    };

    // Filter agents based on filter criteria
    const filterAPI = () => {
        setLoading(true);
        setPage(1);
        const params = {
            Id: id === '' ? null : id,
            Email: email === '' ? null : email,
            Phone: contact === '' ? null : contact,
            Name: agent === '' ? null : agent,
            CompanyId: company === '' ? null : company,
            pagenumber: 1,
        };
        dispatch(agentApi(token, params))
            .then(() => {
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
            });
    };

    // Reset filter criteria
    const resetFilter = () => {
        setLoading(true);
        setId('');
        setEmail('');
        setContact('');
        setAgent('');
        setCompany('');
        const params = { pagenumber: 1 };
        dispatch(agentApi(token, params))
            .then(() => {
                setPage(1);
                setLoading(false);
            })
            .catch(error => {
                setPage(1);
                setLoading(false);
            });
    };

    // Redirect to agent creation page
    const onClickAgentCreate = () => {
        dispatch(companyModeApi(0));
        dispatch(agentDetailDataApi('create', ''));
        navigate('/AgentDetail');
    };

    // Mimic API call
    const onClickMimicApi = (email) => {
        setLoading(true);
        dispatch(mimicAPI(token, email))
            .then(() => {
                setLoading(false);
                navigate('/Dashboard');
            })
            .catch(() => {
                setLoading(false);
            });
    };

    // Edit agent details
    const onClickAgentEdit = (agentId) => {
        dispatch(companyModeApi(1));
        setLoading(true);
        dispatch(agentDetailDataApi('edit', ''));
        dispatch(getAgentDetailsApi(token, agentId))
            .then(() => {
                setLoading(false);
                navigate('/AgentDetail');
            })
            .catch(() => {
                setLoading(false);
            });
    };

    // Open dialog box for agent deletion
    const handleClickOpen = (id) => {
        setagentSelectedId(id);
        setOpen(true);
    };

    // Close dialog box
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            {/* Backdrop for loading state */}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            {/* Backdrop for agent load state */}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={agentLoad}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            {/* Delete agent dialog */}
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                className='dialog_box_popup'
            >
                <DialogContent class="dialog_box_block">
                    <DialogContentText id="alert-dialog-slide-description" class="dialog_box_title">
                        <h6>Delete</h6>
                        <Button onClick={handleClose}><img src={closeModal} /></Button>
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-slide-description" class="dialog_box_detail">
                        Are you sure you want to delete this agent?
                    </DialogContentText>
                </DialogContent>
                <DialogActions class="dialog_box_button">
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={() => deleteAgent()}>Delete</Button>
                </DialogActions>
            </Dialog>

            {/* Topbar and Sidebar */}
            <Topbar />
            <Sidebar message={'AgentList'} />

            <section class="content">
                {/* Page header */}
                <div class="block-header">
                    <h2>{t('company')}</h2>
                </div>

                {/* Search form */}
                <div class="page_heading">
                    <h3>{t('companyList')}</h3>
                    <a onClick={() => onClickAgentCreate()}><img src={plus} /></a>
                </div>
                <div class="main_class">
                    <div class="form_block">
                        <div class="row clearfix">
                            {/* Filter inputs */}
                            {/* ID input */}
                            <div class="col-md-3 col-sm-4 col-xs-6">
                                <div class="form-group">
                                    <div class="form-line">
                                        <input type="text" class="form-control" title={t('id')} placeholder={t('id')} value={id} onChange={(e) => setId(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            {/* Contact input */}
                            <div class="col-md-3 col-sm-4 col-xs-6">
                                <div class="form-group">
                                    <div class="form-line">
                                        <input type='text' class="form-control" title={t('contact')} placeholder={t('contact')} value={contact} onKeyPress={handleKeyPressPhno} maxLength={15} onChange={(e) => setContact(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            {/* Email input */}
                            <div class="col-md-3 col-sm-4 col-xs-6">
                                <div class="form-group">
                                    <div class="form-line">
                                        <input type="text" class="form-control" title={t('email')} placeholder={t('email')} value={email} onChange={(e) => setEmail(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            {/* Company name select */}
                            <div class="col-md-3 col-sm-4 col-xs-6">
                                <div>
                                    <FormControl className='form-group' sx={{}}>
                                        <Select
                                            className='form-control border_temp'
                                            sx={{ borderColor: 'transparent' }}
                                            title={t('companyName')}
                                            displayEmpty
                                            value={company}
                                            onChange={(e) => setCompany(e.target.value)}
                                            input={<OutlinedInput />}
                                            MenuProps={{
                                                PaperProps: {
                                                    style: {
                                                        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                        width: 250,
                                                    },
                                                },
                                                disableScrollLock: true,
                                            }}
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem className='drop_menus' value="">
                                                <em className='select_text'>{t('companyName')}</em>
                                            </MenuItem>
                                            {companyNameList?.map((item) => (
                                                <MenuItem
                                                    className='drop_menus'
                                                    key={item?.Id}
                                                    value={item?.Id}
                                                    style={getStyles(item?.Id, personName, theme)}
                                                >
                                                    {item?.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            {/* Agent name input */}
                            <div class="col-md-3 col-sm-4 col-xs-6">
                                <div class="form-group">
                                    <div class="form-line">
                                        <input type="text" title={t('agentName')} class="form-control" placeholder={t('agentName')} value={agent} onChange={(e) => setAgent(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Filter buttons */}
                        <div class="button_block" >
                            <a href="#" class="links" onClick={() => resetFilter()}>Reset</a>
                            <a href="#" class="links" onClick={() => filterAPI()}>{t('search')}</a>
                        </div>
                    </div>
                </div>

                {/* Agent list table */}
                <div class="main_class">
                    <div class="table_block">
                        <div class="card">
                            <div class="table-responsive">
                                {/* Display agent list */}
                                {agentListData?.Data?.length !== 0 ?
                                    <table class="table table-bordered table-striped table-hover dataTable">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>
                                                    <div class="table_heading text-center">
                                                        <h5>{t('Company Id')}</h5>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div class="table_heading text-center">
                                                        <h5>{t('companyName')}</h5>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div class="table_heading text-center">
                                                        <h5>{t('agentName')}</h5>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div class="table_heading text-center">
                                                        <h5>{t('email')}</h5>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div class="table_heading text-center">
                                                        <h5>{t('contact')}</h5>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody class="text-center">
                                            {agentListData?.Data?.map((item) => {
                                                return (
                                                    <tr>
                                                        {/* Action buttons */}
                                                        <td>
                                                            <div class="table_detail text-center">
                                                                <a class="link blue" onClick={() => onClickMimicApi(item?.Email)}><img src={personInfo} /></a>
                                                                <a onClick={() => onClickAgentEdit(item.Id)} class="link green"><img src={edit} /></a>
                                                                <a onClick={() => handleClickOpen(item.Id)} href="#" class="link red" ><img src={deleteIcon} /></a>
                                                            </div>
                                                        </td>
                                                        {/* Agent details */}
                                                        <td>
                                                            <div class="table_detail text-center">
                                                                <h6>{item?.Id}</h6>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="table_detail text-center">
                                                                <h6>{item?.CompanyName}</h6>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="form-group">
                                                                <div class="table_detail text-center">
                                                                    <h6>{item?.Name}</h6>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="table_detail text-center">
                                                                <h6>{item?.Email}</h6>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="table_detail text-center">
                                                                <h6>{item?.Phone}</h6>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                    :
                                    // No data found
                                    <h1 class="text-center">No Data Found</h1>
                                }
                            </div>
                            {/* Pagination */}
                            <div class="text-right">
                                <Pagination className="pagination" count={agentListData?.TotalPages} page={page} onChange={pageHandleChange} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
