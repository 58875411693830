import React, { useEffect, useRef, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import pdf_icon from '../../images/pdf_icon.png'
import closeModal from '../../images/close.svg';
import folder from '../../images/folder.svg';
import area_icon from '../../images/area_icon.svg';
import info from '../../images/info.svg';
import dateRange from '../../images/date_range.svg';
import deleteIcon from '../../images/delete.svg';
import Carousel from '../../component/Carousel';
import dollar from '../../images/dollar.svg';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import bed from '../../images/bed.svg';
import sofa from '../../images/sofa.svg';
import bicycle from '../../images/bicycle.svg';
import bathtub from '../../images/bathtub.svg';
import edit from '../../images/edit.svg';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Modal, Button, Checkbox, Tooltip } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import Switch from '@mui/material/Switch';




// <!-- Google Fonts -->
import '../../css/material-icon.css';

// <!-- Bootstrap Core Css -->
import '../../plugins/bootstrap/css/bootstrap.css';

// <!-- Waves Effect Css -->
import '../../plugins/node-waves/waves.css';

// <!-- Animation Css -->
import '../../plugins/animate-css/animate.css';

// <!-- Bootstrap Select Css -->
import '../../plugins/bootstrap-select/css/bootstrap-select.css';


// <!-- JQuery DataTable Css -->
import '../../plugins/jquery-datatable/skin/bootstrap/css/dataTables.bootstrap.css';
// <!-- Bootstrap Material Datetime Picker Css -->
import '../../plugins/bootstrap-material-datetimepicker/css/bootstrap-material-datetimepicker.css';

// <!-- Bootstrap DatePicker Css -->
import '../../plugins/bootstrap-datepicker/css/bootstrap-datepicker.css';

// <!-- Wait Me Css -->
import '../../plugins/waitme/waitMe.css';
import { Backdrop, CircularProgress, } from '@mui/material';
// <!-- Morris Chart Css-->
import '../../plugins/morrisjs/morris.css';

// <!-- Custom Css -->
import '../../css/style.css';
import '../../css/custom.css';
import '../../css/materialize.css';

import '../../css/uicons-bold-rounded.css';




// <!-- Style -->
// <!--<link rel="stylesheet" href="css/style.css">-->

// <!-- AdminBSB Themes. You can choose a theme from css/themes instead of get all themes -->
import '../../css/themes/all-themes.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Topbar from '../../component/Topbar';
import Sidebar from '../../component/Sidebar';
import { addNotesOwnerApi, checkPublishCreditsApi, countryApi, createOwnerApi, deleteOwnerDocumentsApi, deleteOwnerPropertyApi, editOwnerApi, getByIdOwnerApi, getPropertyDetailApi, logoutApi, notesListOwnerApi, ownerDocumentsListApi, ownerDropdownApi, ownerModeApi, ownerPropertiesApi, ownerPropertyModeApi, propertyDetailDataApi, propertyModeApi } from '../../redux/ApiActionCreator';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import config from '../../redux/config';
import { scroller } from 'react-scroll';
import PhoneInput from 'react-phone-input-2';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;



function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
export default function OwnerDetail() {
    const label = { inputProps: { 'aria-label': 'Switch demo' } };
    const field1Ref = useRef()
    const field2Ref = useRef()
    const field3Ref = useRef()
    const field4Ref = useRef()


    const dispatch = useDispatch()
    const { t } = useTranslation();
    const theme = useTheme();
    const location = useLocation();
    const ownerNav = location.state;
    const navigate = useNavigate()
    const [value, setValue] = useState('one');
    const [personName, setPersonName] = useState([]);
    const [propertyListDetailShow, setPropertyListDetailShow] = useState(false)
    const [searchShow, setSearchShow] = useState(true)
    const [open, setOpen] = useState(false);
    const [starValue, setStarValue] = useState(2);
    const [page, setPage] = React.useState(1);
    const [pageOwner, setPageOwner] = React.useState(1);
    const [ownerAccordingList, setOwnerAccordingList] = useState(true)
    const [contactAccordingList, setContactAccordingList] = useState(true)
    const [addressAccordingList, setAddressAccordingList] = useState(true)
    const [familyAccordingList, setFamilyAccordingList] = useState(true)
    const [legalAccordingList, setLegalAccordingList] = useState(true)
    const [ownerDocPopup, setownerDocPopup] = useState(false)
    const [ownerDocSelectId, setownerDocSelectId] = useState('')
    const [loading, setLoading] = useState(false)
    const mimicFlag = useSelector((state) => state?.apiReducer?.mimicFlag)

    //State variables for owner information section
    const [title, setTitle] = useState("")
    const [firstName, setFirstName] = useState("")
    const [middleName, setMiddleName] = useState("")
    const [lastName, setLastName] = useState("")
    const [dateOfBirth, setDateOfBirth] = useState("")
    const [idNumber, setIdNumber] = useState("")
    const [passportId, setPassportId] = useState("")
    const [passportExpiryDate, setPassportExpiryDate] = useState("")
    const [titleError, setTitleError] = useState('');
    const [firstNameError, setFirstNameError] = useState("")
    const [middleNameError, setMiddleNameError] = useState("")
    const [lastNameError, setLastNameError] = useState("")
    const [dateOfBirthError, setDateOfBirthError] = useState("")
    const [idNumberError, setIdNumberError] = useState("")
    const [passportIdError, setPassportIdError] = useState("")
    const [passportExpiryDateError, setPassportExpiryDateError] = useState("")
    const [inputTypeBirthdate, setInputTypeBirthdate] = useState('text');
    const [inputTypeExpiredate, setInputTypeExpiredate] = useState('text');
    const [collapsedRows, setCollapsedRows] = useState({});
    const [upgradePlanOpen, setUpgradePlanOpen] = React.useState(false);
    const [publishOpen, setPublishOpen] = React.useState(false);
    const [mainSubscriptionTypeSelected, setMainSubscriptionTypeSelected] = useState("")
    const [subscriptionIdSelectedFeatured, setSubscriptionIdSelectedFeatured] = useState(2)
    const [subscriptionIdSelectedPremium, setSubscriptionIdSelectedPremium] = useState(4)
    const [propertyCurrentSubscriptionPlanId, setPropertyCurrentSubscriptionPlanId] = useState("")
    const [ownerPopupModal, setownerPopupModal] = useState(false)
    const [ownerSelectedId, setownerSelectedId] = useState('')
    const [lastDateStr, setlastDateStr] = useState('')
    const [createdDateStr, setcreatedDateStr] = useState('')
    const today = new Date().toISOString().split('T')[0];
    const [anchorEl, setAnchorEl] = React.useState(null);
    const personOpen = Boolean(anchorEl);
    const [propertyIdToPublish, setPropertyIdToPublish] = useState("")
    const userRole = useSelector((state) => state?.apiReducer?.loginData?.Data?.userRole[0])
    const [unPublishModal, setUnPublishModal] = useState(false)
    const [unPublishPropertyId, setUnPublishPropertyId] = useState('')
    const [featuredAutoRenewCheckBox, setFeaturedAutoRenewCheckBox] = useState(true)
    const [premiumAutoRenewCheckBox, setPremiumAutoRenewCheckBox] = useState(true)

    //State variables for Contact information section
    const [email, setEmail] = useState("")
    const [contactNo, setContactNo] = useState("")
    const [emailError, setEmailError] = useState('');
    const [contactNoError, setContactNoError] = useState("")

    //State variables for Address of residency Section
    const [nationality, setNationality] = useState("")
    const [countryOfResidence, setCountryOfResidence] = useState("")
    const [address, setAddress] = useState("")
    const [nationalityError, setNationalityError] = useState("")
    const [countryOfResidenceError, setCountryOfResidenceError] = useState("")
    const [addressError, setAddressError] = useState("")

    //State variables for Legal Representative Section
    const [legalRepresentativeFirstName, setLegalRepresentativeFirstName] = useState("")
    const [legalRepresentativeLastName, setLegalRepresentativeLastName] = useState("")
    const [legalRepresentativeContactNo, setLegalRepresentativeContactNo] = useState("")
    const [legalRepresentativeEmail, setLegalRepresentativeEmail] = useState("")
    const [legalRepresentativeFirstNameError, setLegalRepresentativeFirstNameError] = useState("")
    const [legalRepresentativeLastNameError, setLegalRepresentativeLastNameError] = useState("")
    const [legalRepresentativeContactNoError, setLegalRepresentativeContactNoError] = useState("")
    const [legalRepresentativeEmailError, setLegalRepresentativeEmailError] = useState("")

    //State variables for Notes
    const [notes, setNotes] = useState("")
    const [notesError, setNotesError] = useState("")

    //State variables for Family Information
    const [spouseName, setSpouseName] = useState("")
    const [noOfChildren, setNoOfChildren] = useState("")
    const [maritalStatus, setMaritalStatus] = useState("")
    const [spouseNameError, setSpouseNameError] = useState("")
    const [noOfChildrenError, setNoOfChildrenError] = useState("")
    const [maritalStatusError, setMaritalStatusError] = useState("")
    const [documentUploadError, setDocumentUploadError] = useState("")
    const [references, setReferences] = useState("")

    const token = useSelector((state) => state?.apiReducer?.loginData?.Data?.accessToken)
    const ownerDropdownData = useSelector((state) => state?.apiReducer?.ownerDropdownData)
    const ownerOfflineData = useSelector((state) => state?.apiReducer?.ownerOfflineData)
    const getByIdOwnerData = useSelector((state) => state?.apiReducer?.getByIdOwnerData)
    const createOwnerData = useSelector((state) => state?.apiReducer?.createOwnerData)
    const notesListOwnerData = useSelector((state) => state?.apiReducer?.notesListOwnerData)
    const documentsListOwnerData = useSelector((state) => state?.apiReducer?.ownerDocumentsData)
    const userId = useSelector((state) => state?.apiReducer?.loginData?.Data?.userId)
    const ownerPropertiesData = useSelector((state) => state?.apiReducer?.ownerPropertiesData)
    const checkPublishCreditsData = useSelector((state) => state?.apiReducer?.checkPublishCreditsData)
    const ownerMode = useSelector((state) => state?.apiReducer?.ownerMode)




    const handleFeaturedAutoRenewCheckBox = (event) => {

        setFeaturedAutoRenewCheckBox(event.target.checked); // Update the state when checkbox is changed
    };
    const handlePremiumAutoRenewCheckBox = (event) => {


        setPremiumAutoRenewCheckBox(event.target.checked); // Update the state when checkbox is changed
    };
    const handleOpenUnpublishpopup = (id) => {

        setUnPublishPropertyId(id)
        setUnPublishModal(true)
    };

    const handleCloseUnpublishpopup = () => {
        setUnPublishModal(false)
    }

   

    const numbers = [
        {
            Id: 1,
            Name: "1"
        },
        {
            Id: 2,
            Name: "2"
        },
        {
            Id: 3,
            Name: "3"
        },
        {
            Id: 4,
            Name: "4"
        },
        {
            Id: 5,
            Name: "5"
        },
        {
            Id: 6,
            Name: "6"
        },
        {
            Id: 7,
            Name: "7"
        },
        {
            Id: 8,
            Name: "8"
        },
        {
            Id: 9,
            Name: "9"
        },
        {
            Id: 10,
            Name: "10"
        },
        {
            Id: 11,
            Name: "11"
        },
        {
            Id: 12,
            Name: "12"
        },
        {
            Id: 13,
            Name: "13"
        },
        {
            Id: 14,
            Name: "14"
        },
        {
            Id: 15,
            Name: "15"
        },
        {
            Id: 16,
            Name: "16"
        },
        {
            Id: 17,
            Name: "17"
        },
        {
            Id: 18,
            Name: "18"
        },
        {
            Id: 19,
            Name: "19"
        },
        {
            Id: 20,
            Name: "20"
        },
    ];



    function convertDateFormat(inputDate) {
        // Split the date string into parts
        const parts = inputDate.split('-');

        // Rearrange the parts to dd-mm-yyyy format
        const outputDate = `${parts[2]}-${parts[1]}-${parts[0]}`;

        return outputDate;
    }



    const handleOpenDocPoppup = (id) => {
        setownerDocSelectId(id)
        setownerDocPopup(true)

    }

    const handleOpenPropertyPoppup = (id) => {
        setownerSelectedId(id)
        setownerPopupModal(true)

    }
    const handleOwnerClosePoppup = () => {
        setownerDocPopup(false)
    }
    const handleOwnerPropertyClosePoppup = () => {
        setownerPopupModal(false)
    }

    useEffect(() => {
        if (ownerNav == 12) {
            setValue('two')
        }

    }, [])


    // DELETE DOCUMENT API CALL
    const onClickDeleteDocument = () => {
        dispatch(deleteOwnerDocumentsApi(token, ownerDocSelectId))
            .then((response) => {
                setPage(1)
                const params = {
                    pageNumber: 1,
                    OwnerId: ownerOfflineData?.ownerType == 'create' ? createOwnerData?.OwnerId : getByIdOwnerData?.Id

                }
                dispatch(ownerDocumentsListApi(token, params))
                    .then((response) => {
                        setownerDocPopup(false)
                    })
                    .catch((error) => {
                        setLoading(false)


                    });


            })
            .catch((error) => {
                setLoading(false)


            });
    }

    // DELETE PROPERTY FROM OWNER PROPERTIES LIST API CALL
    const onClickDeleteProperty = () => {
        dispatch(deleteOwnerPropertyApi(token, ownerSelectedId))
            .then((response) => {
                const params = {
                    pageNumber: 1,
                    OwnerId: ownerOfflineData?.ownerType == 'create' ? createOwnerData?.OwnerId : getByIdOwnerData?.Id

                }
                dispatch(ownerPropertiesApi(token, params))
                    .then((response) => {
                        setownerPopupModal(false)
                    })
                    .catch((error) => {
                        setLoading(false)


                    });


            })
            .catch((error) => {
                setLoading(false)


            });
    }
    const fieldDefaultRefTop = useRef();
    useEffect(() => {
        scroller.scrollTo(fieldDefaultRefTop.current.id, {
            smooth: false,
            offset: -100, // Adjust as needed
        });
    }, [])
    useEffect(() => {
        dispatch(countryApi(token))
            .then((response) => {

            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {

                    dispatch(logoutApi())
                    navigate('/')

                }


            });

    }, [])

    // GET OWNER MASTER DROPDOWN DATA API CALL
    useEffect(() => {
        dispatch(ownerDropdownApi(token))
    }, [])

    // GET OWNER DETAILS API CALL
    useEffect(() => {

        if (ownerOfflineData?.ownerType != 'create' || !ownerMode?.mode == 0) {
            dispatch(getByIdOwnerApi(token, ownerOfflineData?.ownerId))
        }

    }, [])

    // NOTES LIST API CALL
    useEffect(() => {
        dispatch(notesListOwnerApi(token, ownerOfflineData?.ownerType == 'create' ? createOwnerData?.OwnerId : getByIdOwnerData?.Id,))
    }, [value])

    useEffect(() => {

        if (ownerOfflineData?.ownerType != 'create' || !ownerMode?.mode == 0) {

            setTitle(getByIdOwnerData?.Title == null ? '' : getByIdOwnerData?.Title)
            setFirstName(getByIdOwnerData?.Firstname == null ? '' : getByIdOwnerData?.Firstname)
            setMiddleName(getByIdOwnerData?.middlename == null ? '' : getByIdOwnerData?.middlename)
            setLastName(getByIdOwnerData?.Lastname == null ? '' : getByIdOwnerData?.Lastname)
            setDateOfBirth(getByIdOwnerData?.DateofBirthStr == "" ? null : convertDateFormat(getByIdOwnerData?.DateofBirthStr))
            setIdNumber(getByIdOwnerData?.IDNumber == null ? '' : getByIdOwnerData?.IDNumber)
            setPassportId(getByIdOwnerData?.PassportID == null ? '' : getByIdOwnerData?.PassportID)
            setPassportExpiryDate(getByIdOwnerData?.PassportExpiryDateStr == "" ? null : convertDateFormat(getByIdOwnerData?.PassportExpiryDateStr))
            setEmail(getByIdOwnerData?.Email == null ? '' : getByIdOwnerData?.Email)
            setlastDateStr(getByIdOwnerData?.ModifiedDateStr == null ? '' : getByIdOwnerData?.ModifiedDateStr)
            setcreatedDateStr(getByIdOwnerData?.CreatedDateStr == null ? '' : getByIdOwnerData?.CreatedDateStr)
            setContactNo(getByIdOwnerData?.Phone == null ? '' : getByIdOwnerData?.Phone)
            setNationality(getByIdOwnerData?.NationalityId == null ? '' : getByIdOwnerData?.NationalityId)
            setCountryOfResidence(getByIdOwnerData?.CountryofResId == null ? '' : getByIdOwnerData?.CountryofResId)
            setAddress(getByIdOwnerData?.Address == null ? '' : getByIdOwnerData?.Address)
            setLegalRepresentativeFirstName(getByIdOwnerData?.LegalRFirstName == null ? '' : getByIdOwnerData?.LegalRFirstName)
            setLegalRepresentativeLastName(getByIdOwnerData?.LegalRLastName == null ? '' : getByIdOwnerData?.LegalRLastName)
            setLegalRepresentativeContactNo(getByIdOwnerData?.LegalRPhone == null ? '' : getByIdOwnerData?.LegalRPhone)
            setLegalRepresentativeEmail(getByIdOwnerData?.LegalREmail == null ? '' : getByIdOwnerData?.LegalREmail)
            setSpouseName(getByIdOwnerData?.SpouseName == null ? '' : getByIdOwnerData?.SpouseName)
            setNoOfChildren(getByIdOwnerData?.NumberOfChildrens == null ? '' : getByIdOwnerData?.NumberOfChildrens)
            setMaritalStatus(getByIdOwnerData?.MaritalStatus == null ? '' : getByIdOwnerData?.MaritalStatus)
            setReferences(getByIdOwnerData?.AssignedUserId == null ? '' : getByIdOwnerData?.AssignedUserId)

        }
        if (ownerOfflineData?.ownerType == 'create' || ownerMode?.mode == 0) {
            setReferences(userId)


        }

    }, [])

    // DOCUMENTS LIST API CALL
    useEffect(() => {
        const params = {
            pageNumber: page,
            OwnerId: ownerOfflineData?.ownerType == 'create' ? createOwnerData?.OwnerId : getByIdOwnerData?.Id

        }

        dispatch(ownerDocumentsListApi(token, params))
    }, [page, value])

    // OWNER PROPERTIES LIST API CALL
    useEffect(() => {
        const params = {
            pageNumber: pageOwner,
            OwnerId: ownerOfflineData?.ownerType == 'create' ? createOwnerData?.OwnerId : getByIdOwnerData?.Id

        }

        dispatch(ownerPropertiesApi(token, params))

    }, [pageOwner, value])



    function formatDate(input) {
        const date = new Date(input);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    }

    const validateAndFormatDate = (dateString) => {
        // Check if the date is in dd-mm-yyyy format
        const isDDMMYYYYFormat = /^\d{2}-\d{2}-\d{4}$/.test(dateString);

        // If it's in dd-mm-yyyy format, convert to yyyy-mm-dd
        if (isDDMMYYYYFormat) {
            const [day, month, year] = dateString.split('-');
            const convertedDate = `${year}-${month}-${day}`;
            return convertedDate;
        }

        // If it's not in dd-mm-yyyy format, return the original date
        return dateString;
    };
    const handleFocusDate = (e) => {
        setInputTypeBirthdate('date');
        e.target.blur()
    };

    const handleFocusExpireDate = (e) => {
        setInputTypeExpiredate('date');
        e.target.blur()
    };

    const pageHandleChange = (event, value) => {
        setPage(value);
    };

    const pageOwnerHandleChange = (event, value) => {
        setPageOwner(value);
    };
    const toggleRowCollapse = (itemId) => {
        setCollapsedRows((prevCollapsedRows) => ({
            ...prevCollapsedRows,
            [itemId]: !prevCollapsedRows[itemId],
        }));
    };
    const validateFirstName = (value) => {
        if (value.length === 0) {
            setFirstNameError(t('firstNameError_2'));
        } else {
            setFirstNameError('');
        }
    };



    const validateLastName = (value) => {
        if (value.length === 0) {
            setLastNameError(t('lastNameError_2'));
        } else {
            setLastNameError('');
        }
    };





    const validateEmail = (value) => {
        if (value.trim() !== '') {
            // Email is not empty after trimming whitespace, validate its format
            let emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailPattern.test(value)) {
                // Email format is invalid
                setEmailError("Enter a valid email");
            } else {
                // Email format is valid
                setEmailError('');
            }
        } else {
            // Email is empty or consists only of whitespace
            setEmailError('');
        }
    };

    const validateContactNo = (value) => {
        if (value.length <= 4) {
            setContactNoError(t('contactNoError_2'));
        } else {
            setContactNoError('');
        }
    };





    // CREATE/EDIT OWNER ON CLICK SAVE & NEXT API CALL
    const informationNextApi = () => {

        let validate = false


        if (firstName == '') {
            validate = true
            setFirstNameError(t('firstNameError_2'));
        } else {
            setFirstNameError('');
        }

        if (lastName == '') {
            validate = true
            setLastNameError(t('lastNameError_2'));
        } else {
            setLastNameError('');
        }





        if (contactNo == '') {
            validate = true
            setContactNoError(t('contactNoError_2'));
        } else {
            setContactNoError('');
        }



        if (email.trim() !== '') {
            // Email is not empty, validate its format
            let emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailPattern.test(email)) {
                // Email format is invalid
                validate = true;
                setEmailError("Enter a valid email");
            } else {
                // Email format is valid
                setEmailError('');
            }
        } else {
            // Email is empty, no need to validate
            setEmailError('');
        }


        if (validate == true) {
            // Find the topmost mandatory field that is not filled
            let topmostFieldRef;

            if (!field1Ref.current.value) {
                topmostFieldRef = field1Ref;
            }

            else if (!field2Ref.current.value) {
                topmostFieldRef = field2Ref;
            }


            // Add more conditions for other mandatory fields as needed

            // Scroll to the topmost mandatory field
            if (topmostFieldRef && topmostFieldRef.current) {
                scroller.scrollTo(topmostFieldRef.current.id, {
                    smooth: true,
                    offset: -100, // Adjust as needed
                });
            }

            if (topmostFieldRef == field4Ref) {
                scroller.scrollTo(topmostFieldRef.current.id, {
                    smooth: true,
                    offset: -100, // Adjust as needed
                });
            }

            return;
        }


        if (validate == false) {
            setLoading(true)
            if (ownerOfflineData?.ownerType == 'create' && ownerMode?.mode == 0) {
                const params =

                {
                    "Title": title == '' ? null : title,
                    "Firstname": firstName == '' ? null : firstName,
                    "middlename": middleName == '' ? null : middleName,
                    "Lastname": lastName == '' ? null : lastName,
                    "DateofBirth": dateOfBirth == '' ? null : validateAndFormatDate(dateOfBirth),
                    "IDNumber": idNumber == '' ? null : idNumber,
                    "PassportID": passportId == '' ? null : passportId,
                    "PassportExpiryDate": passportExpiryDate == '' ? null : validateAndFormatDate(passportExpiryDate),
                    "Email": email == '' ? null : email,
                    "Phone": contactNo === "" ? null : (contactNo.startsWith("+") ? contactNo : `+${contactNo}`),                    
                    "Address": address == '' ? null : address,
                    "NationalityId": nationality == '' ? null : nationality,
                    "CountryofResId": countryOfResidence == '' ? null : countryOfResidence,
                    "LegalRFirstName": legalRepresentativeFirstName == '' ? null : legalRepresentativeFirstName,
                    "LegalRLastName": legalRepresentativeLastName == '' ? null : legalRepresentativeLastName,
                    "LegalREmail": legalRepresentativeEmail == '' ? null : legalRepresentativeEmail,
                    "LegalRPhone": legalRepresentativeContactNo === "" ? null : (legalRepresentativeContactNo.startsWith("+") ? legalRepresentativeContactNo : `+${legalRepresentativeContactNo}`),                    
                    "SpouseName": spouseName == '' ? null : spouseName,
                    "NumberOfChildrens": noOfChildren == '' ? null : noOfChildren,
                    "MaritalStatus": maritalStatus == '' ? null : maritalStatus,
                    "AssignedUserId": references == '' ? null : references
                }



                dispatch(createOwnerApi(token, params))
                    .then((response) => {
                        dispatch(ownerModeApi(1))
                        const params = {
                            pageNumber: 1,
                            OwnerId: ownerOfflineData?.ownerType == 'create' ? createOwnerData?.OwnerId : getByIdOwnerData?.Id

                        }

                        dispatch(ownerPropertiesApi(token, params))
                            .then((response) => {
                                dispatch(notesListOwnerApi(token, ownerOfflineData?.ownerType == 'create' ? createOwnerData?.OwnerId : getByIdOwnerData?.Id,))

                                const params = {
                                    pageNumber: 1,
                                    OwnerId: ownerOfflineData?.ownerType == 'create' ? createOwnerData?.OwnerId : getByIdOwnerData?.Id

                                }
                                dispatch(ownerDocumentsListApi(token, params))
                                toast.success("Record Saved Successfully", {
                                    position: 'top-right',
                                    autoClose: 1000, // Adjust the duration as needed
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                });
                                setTimeout(() => {
                                    setLoading(false)
                                    setValue('two');

                                }, 1000);


                            })

                    })
                    .catch((error) => {
                        setLoading(false)


                    });
            } else {
                const params =

                {
                    "Id": ownerOfflineData?.ownerType == 'create' ? createOwnerData?.OwnerId : getByIdOwnerData?.Id,
                    "Title": title == '' ? null : title,
                    "Firstname": firstName == '' ? null : firstName,
                    "middlename": middleName == '' ? null : middleName,
                    "Lastname": lastName == '' ? null : lastName,
                    "DateofBirth": dateOfBirth == '' ? null : validateAndFormatDate(dateOfBirth),
                    "IDNumber": idNumber == '' ? null : idNumber,
                    "PassportID": passportId == '' ? null : passportId,
                    "PassportExpiryDate": passportExpiryDate == '' ? null : validateAndFormatDate(passportExpiryDate),
                    "Email": email == '' ? null : email,
                    "Phone": contactNo === "" ? null : (contactNo.startsWith("+") ? contactNo : `+${contactNo}`),                    
                    "Address": address == '' ? null : address,
                    "NationalityId": nationality == '' ? null : nationality,
                    "CountryofResId": countryOfResidence == '' ? null : countryOfResidence,
                    "LegalRFirstName": legalRepresentativeFirstName == '' ? null : legalRepresentativeFirstName,
                    "LegalRLastName": legalRepresentativeLastName == '' ? null : legalRepresentativeLastName,
                    "LegalREmail": legalRepresentativeEmail == '' ? null : legalRepresentativeEmail,
                    "LegalRPhone": legalRepresentativeContactNo === "" ? null : (legalRepresentativeContactNo.startsWith("+") ? legalRepresentativeContactNo : `+${legalRepresentativeContactNo}`),                    
                    "SpouseName": spouseName == '' ? null : spouseName,
                    "NumberOfChildrens": noOfChildren == '' ? null : noOfChildren,
                    "MaritalStatus": maritalStatus == '' ? null : maritalStatus,
                    "AssignedUserId": references == '' ? null : references
                }



                dispatch(editOwnerApi(token, params))
                    .then((response) => {
                        const params = {
                            pageNumber: 1,
                            OwnerId: ownerOfflineData?.ownerType == 'create' ? createOwnerData?.OwnerId : getByIdOwnerData?.Id

                        }

                        dispatch(ownerPropertiesApi(token, params))
                            .then((response) => {
                                dispatch(notesListOwnerApi(token, ownerOfflineData?.ownerType == 'create' ? createOwnerData?.OwnerId : getByIdOwnerData?.Id,))

                                const params = {
                                    pageNumber: 1,
                                    OwnerId: ownerOfflineData?.ownerType == 'create' ? createOwnerData?.OwnerId : getByIdOwnerData?.Id

                                }
                                dispatch(ownerDocumentsListApi(token, params))
                                toast.success("Record Saved Successfully", {
                                    position: 'top-right',
                                    autoClose: 1000, // Adjust the duration as needed
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                });
                                setTimeout(() => {
                                    setLoading(false)
                                    setValue('two');

                                }, 1000);


                            })

                    })
                    .catch((error) => {
                        setLoading(false)


                    });
            }

        }

    }

    // CREATE/EDIT OWNER ON CLICK SAVE API CALL
    const informationApi = () => {

        let validate = false


        if (firstName == '') {
            validate = true
            setFirstNameError(t('firstNameError_2'));
        } else {
            setFirstNameError('');
        }

        if (lastName == '') {
            validate = true
            setLastNameError(t('lastNameError_2'));
        } else {
            setLastNameError('');
        }


        if (email.trim() !== '') {
            // Email is not empty, validate its format
            let emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailPattern.test(email)) {
                // Email format is invalid
                validate = true;
                setEmailError("Enter a valid email");
            } else {
                // Email format is valid
                setEmailError('');
            }
        } else {
            // Email is empty, no need to validate
            setEmailError('');
        }



        if (contactNo == '') {
            validate = true
            setContactNoError(t('contactNoError_2'));
        } else {
            setContactNoError('');
        }


        if (validate == true) {
            // Find the topmost mandatory field that is not filled
            let topmostFieldRef;

            if (!field1Ref.current.value) {
                topmostFieldRef = field1Ref;
            }

            else if (!field2Ref.current.value) {
                topmostFieldRef = field2Ref;
            }


            // Add more conditions for other mandatory fields as needed

            // Scroll to the topmost mandatory field
            if (topmostFieldRef && topmostFieldRef.current) {
                scroller.scrollTo(topmostFieldRef.current.id, {
                    smooth: true,
                    offset: -100, // Adjust as needed
                });
            }

            if (topmostFieldRef == field4Ref) {
                scroller.scrollTo(topmostFieldRef.current.id, {
                    smooth: true,
                    offset: -100, // Adjust as needed
                });
            }

            return;
        }


        if (validate == false) {
            setLoading(true)
            if (ownerOfflineData?.ownerType == 'create' && ownerMode?.mode == 0) {
                const params =

                {
                    "Title": title == '' ? null : title,
                    "Firstname": firstName == '' ? null : firstName,
                    "middlename": middleName == '' ? null : middleName,
                    "Lastname": lastName == '' ? null : lastName,
                    "DateofBirth": dateOfBirth == '' ? null : validateAndFormatDate(dateOfBirth),
                    "IDNumber": idNumber == '' ? null : idNumber,
                    "PassportID": passportId == '' ? null : passportId,
                    "PassportExpiryDate": passportExpiryDate == '' ? null : validateAndFormatDate(passportExpiryDate),
                    "Email": email == '' ? null : email,
                    "Phone": contactNo === "" ? null : (contactNo.startsWith("+") ? contactNo : `+${contactNo}`),                    
                    "Address": address == '' ? null : address,
                    "NationalityId": nationality == '' ? null : nationality,
                    "CountryofResId": countryOfResidence == '' ? null : countryOfResidence,
                    "LegalRFirstName": legalRepresentativeFirstName == '' ? null : legalRepresentativeFirstName,
                    "LegalRLastName": legalRepresentativeLastName == '' ? null : legalRepresentativeLastName,
                    "LegalREmail": legalRepresentativeEmail == '' ? null : legalRepresentativeEmail,
                    "LegalRPhone": legalRepresentativeContactNo === "" ? null : (legalRepresentativeContactNo.startsWith("+") ? legalRepresentativeContactNo : `+${legalRepresentativeContactNo}`),                    
                    "SpouseName": spouseName == '' ? null : spouseName,
                    "NumberOfChildrens": noOfChildren == '' ? null : noOfChildren,
                    "MaritalStatus": maritalStatus == '' ? null : maritalStatus,
                    "AssignedUserId": references == '' ? null : references

                }



                dispatch(createOwnerApi(token, params))
                    .then((response) => {
                        toast.success("Record Saved Successfully", {
                            position: 'top-right',
                            autoClose: 1000, // Adjust the duration as needed
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                        setTimeout(() => {

                            dispatch(ownerModeApi(1))
                            navigate('/OwnerList')
                            setLoading(false)

                        }, 1000);


                    })
                    .catch((error) => {
                        setLoading(false)


                    });
            }
            else {
                const params =

                {
                    "Id": ownerOfflineData?.ownerType == 'create' ? createOwnerData?.OwnerId : getByIdOwnerData?.Id,
                    "Title": title == '' ? null : title,
                    "Firstname": firstName == '' ? null : firstName,
                    "middlename": middleName == '' ? null : middleName,
                    "Lastname": lastName == '' ? null : lastName,
                    "DateofBirth": dateOfBirth == '' ? null : validateAndFormatDate(dateOfBirth),
                    "IDNumber": idNumber == '' ? null : idNumber,
                    "PassportID": passportId == '' ? null : passportId,
                    "PassportExpiryDate": passportExpiryDate == '' ? null : validateAndFormatDate(passportExpiryDate),
                    "Email": email == '' ? null : email,
                    "Phone": contactNo === "" ? null : (contactNo.startsWith("+") ? contactNo : `+${contactNo}`),
                    "Address": address == '' ? null : address,
                    "NationalityId": nationality == '' ? null : nationality,
                    "CountryofResId": countryOfResidence == '' ? null : countryOfResidence,
                    "LegalRFirstName": legalRepresentativeFirstName == '' ? null : legalRepresentativeFirstName,
                    "LegalRLastName": legalRepresentativeLastName == '' ? null : legalRepresentativeLastName,
                    "LegalREmail": legalRepresentativeEmail == '' ? null : legalRepresentativeEmail,
                    "LegalRPhone": legalRepresentativeContactNo === "" ? null : (legalRepresentativeContactNo.startsWith("+") ? legalRepresentativeContactNo : `+${legalRepresentativeContactNo}`),                    
                    "SpouseName": spouseName == '' ? null : spouseName,
                    "NumberOfChildrens": noOfChildren == '' ? null : noOfChildren,
                    "MaritalStatus": maritalStatus == '' ? null : maritalStatus,
                    "AssignedUserId": references == '' ? null : references
                }



                dispatch(editOwnerApi(token, params))
                    .then((response) => {
                        toast.success("Record Saved Successfully", {
                            position: 'top-right',
                            autoClose: 1000, // Adjust the duration as needed
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                        setTimeout(() => {

                            dispatch(ownerModeApi(1))
                            navigate('/OwnerList')
                            setLoading(false)

                        }, 1000);

                    })
                    .catch((error) => {
                        setLoading(false)


                    });
            }

        }

    };




    const validateNotes = (value) => {
        if (value.length === 0) {
            setNotesError('Notes is required');
        } else {
            setNotesError('');
        }
    };


    //ADD NOTES API CALL
    const notesScreenApi = () => {

        let validate = false
        if (notes == '') {
            validate = true
            setNotesError(t('NotesRequired'));
        } else {
            setNotesError('');
        }


        if (validate == false) {
            setLoading(true)
            const params = {
                OwnerId: ownerOfflineData?.ownerType == 'create' ? createOwnerData?.OwnerId : getByIdOwnerData?.Id,
                Note: notes
            }

            dispatch(addNotesOwnerApi(token, params))
                .then((response) => {
                    setNotes('')

                    dispatch(notesListOwnerApi(token, ownerOfflineData?.ownerType == 'create' ? createOwnerData?.OwnerId : getByIdOwnerData?.Id,))
                        .then((response) => {
                            setLoading(false)
                            toast.success("Record Saved Successfully", {
                                position: 'top-right',
                                autoClose: 1000, // Adjust the duration as needed
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                            });

                        })




                })
                .catch((error) => {
                    setLoading(false)


                });
        }
        // Check if both username and password are entered

    };


    const validateDocumentsUpload = (value) => {
        if (value.length === 0) {
            setDocumentUploadError(t('uploadDocumentError_2'));
        } else {
            setDocumentUploadError('');
        }
    };

    const documentScreenApi = (e) => {
        const scrollPosition = window.scrollY;
        e.preventDefault();
        window.scrollTo(0, scrollPosition);

        const params = {
            pageNumber: 1,
            OwnerId: ownerOfflineData?.ownerType == 'create' ? createOwnerData?.OwnerId : getByIdOwnerData?.Id

        }

        dispatch(ownerPropertiesApi(token, params))
            .then((response) => {
                dispatch(notesListOwnerApi(token, ownerOfflineData?.ownerType == 'create' ? createOwnerData?.OwnerId : getByIdOwnerData?.Id,))

                const params = {
                    pageNumber: 1,
                    OwnerId: ownerOfflineData?.ownerType == 'create' ? createOwnerData?.OwnerId : getByIdOwnerData?.Id

                }
                dispatch(ownerDocumentsListApi(token, params))

                navigate('/OwnerList')
                setLoading(false)

            })
        // Check if both username and password are entered

    };




    const openCloseOwnerAccordingList = () => {
        setOwnerAccordingList(!ownerAccordingList)
    }


    const openCloseContactAccordingList = () => {
        setContactAccordingList(!contactAccordingList)
    }

    const openCloseAddressAccordingList = () => {
        setAddressAccordingList(!addressAccordingList)
    }

    const openCloseLegalAccordingList = () => {
        setLegalAccordingList(!legalAccordingList)
    }

    const openCloseFamilyAccordingList = () => {
        setFamilyAccordingList(!familyAccordingList)
    }

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const properDetailHideShow = () => {
        setPropertyListDetailShow(!propertyListDetailShow)
    }

    const searchHideShow = () => {
        setSearchShow(!searchShow)
    }



    const handleChanges = (event, newValue) => { setValue(newValue); };

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setPersonName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };


    // UPLOAD DOCUMENTS API CALL
    const handleFileChange = async (e) => {
        setLoading(true)
        const file = e.target.files[0];
        const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/plain'];

        if (!allowedTypes.includes(file?.type)) {
            setLoading(false)

            alert('Please upload a JPG, JPEG, PNG, DOC, PDF,TXT or XLSX file.')

            e.target.value = null;
            // Trigger an additional event to force recognition of the change

            e.target.dispatchEvent(new Event('input'));

            return; // Exit the function if file type is not allowed

        }
        try {
            // Create a FormData object
            const formData = new FormData();

            // Add form fields to the FormData object
            formData.append('file', file);
            formData.append('OwnerId', ownerOfflineData?.ownerType == 'create' ? createOwnerData?.OwnerId : getByIdOwnerData?.Id,);

            const response = await axios.post(
                `${config.baseURL}/OwnerDocument/UploadFile`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data', // Set the content type to FormData
                        Authorization: `Bearer ${token}`,
                        // Add any other headers if needed
                    },
                }
            );

            // Handle the response here (e.g., update state or perform other actions)
            if (response.data.Status == 'OK') {
                e.target.value = null;

                // Trigger an additional event to force recognition of the change
                e.target.dispatchEvent(new Event('input'));
                const params = {
                    pageNumber: 1,
                    OwnerId: ownerOfflineData?.ownerType == 'create' ? createOwnerData?.OwnerId : getByIdOwnerData?.Id

                }
                dispatch(ownerDocumentsListApi(token, params))
                    .then((response) => {
                        setLoading(false)

                        toast.success("File Uploaded Successfully", {
                            position: 'top-right',
                            autoClose: 1000, // Adjust the duration as needed
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });

                    })
                    .catch((response) => {
                        setLoading(false)

                    })
            } else {
                setLoading(false)
            }

        } catch (error) {

        }

    };

    const onClickNotesNext = () => {
        setLoading(true)
        const params = {
            pageNumber: 1,
            OwnerId: ownerOfflineData?.ownerType == 'create' ? createOwnerData?.OwnerId : getByIdOwnerData?.Id

        }
        dispatch(ownerDocumentsListApi(token, params))
            .then((response) => {

                const params = {
                    pageNumber: 1,
                    OwnerId: ownerOfflineData?.ownerType == 'create' ? createOwnerData?.OwnerId : getByIdOwnerData?.Id

                }

                dispatch(ownerPropertiesApi(token, params))
                    .then((response) => {


                        setValue('four')
                        setLoading(false)

                    })

            })
            .catch((response) => {
                setLoading(false)

            })

    }

    const onClickPropertyNext = () => {

        dispatch(notesListOwnerApi(token, ownerOfflineData?.ownerType == 'create' ? createOwnerData?.OwnerId : getByIdOwnerData?.Id,))
        const params = {
            pageNumber: 1,
            OwnerId: ownerOfflineData?.ownerType == 'create' ? createOwnerData?.OwnerId : getByIdOwnerData?.Id

        }

        dispatch(ownerPropertiesApi(token, params))
            .then((response) => {


                const params = {
                    pageNumber: 1,
                    OwnerId: ownerOfflineData?.ownerType == 'create' ? createOwnerData?.OwnerId : getByIdOwnerData?.Id

                }
                dispatch(ownerDocumentsListApi(token, params))

                setValue('three')
                setLoading(false)

            })




    }

    //DOWNLOAD FILE(DOCUMENT) API CALL
    const handleDownload = (item) => {
        // Specify the URL of the file you want to download
        const fileUrl = item?.FileUrl;

        // Specify the desired filename
        const fileName = item?.FileName;

        // Construct the full URL with the filename
        const urlWithFileName = `${config.baseURL}${fileUrl}?filename=${fileName}`;

        // Open a new tab and set its location to the constructed URL
        window.open(urlWithFileName, '_blank');

    };

    //ONCLICK PROPERTY PUBLISH OPTIONS OPEN FUNCTION
    const handlePublishOpen = (item) => {
        setLoading(true)
        setPropertyIdToPublish(item?.Id)
        dispatch(checkPublishCreditsApi(token, item?.Id))
            .then((response) => {
                setLoading(false)
                setPublishOpen(true);

            })
            .catch((error) => {
                setLoading(false)
                setPublishOpen(true);

            });
    };

    //ONCLICK PROPERTY PUBLISH OPTIONS CLOSE FUNCTION
    const handlePublishClose = () => {
        setPublishOpen(false);
    };

    //ONCLICK PROPERTY UPGRADE PUBLISH PLAN OPTIONS OPEN FUNCTION
    const handleUpgradePlanOpen = (item) => {
        setLoading(true)
        setPropertyIdToPublish(item?.Id)
        setMainSubscriptionTypeSelected("")
        setPropertyCurrentSubscriptionPlanId(item?.SubscriptionPlanId)
        dispatch(checkPublishCreditsApi(token, item?.Id))
            .then((response) => {
                setLoading(false)
                setFeaturedAutoRenewCheckBox(true)
                setPremiumAutoRenewCheckBox(true)
                setUpgradePlanOpen(true);

            })
            .catch((error) => {
                setLoading(false)
                setUpgradePlanOpen(true);

            });
    };

    //ONCLICK PROPERTY UPGRADE PUBLISH PLAN OPTIONS CLOSE FUNCTION
    const handleUpgradePlanClose = () => {

        setUpgradePlanOpen(false);
    };

    // PROPERTY UPGRADE PUBLISH  API CALL
    const PublishUpgradeApiCall = async (params) => {
        setLoading(true)
        try {
            const response = await axios.post(`${config.baseURL}/Property/PublishProperty`, params, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.status == 200) {

                if (response.data.Status == 'OK') {
                    const params = {
                        pageNumber: pageOwner,
                        OwnerId: ownerOfflineData?.ownerType == 'create' ? createOwnerData?.OwnerId : getByIdOwnerData?.Id

                    }

                    dispatch(ownerPropertiesApi(token, params))
                        .then(() => {
                            setLoading(false)
                            handleUpgradePlanClose()
                        })
                        .catch(() => {
                            setLoading(false)
                            handleUpgradePlanClose()
                        })
                } else if (response.data.Status == 'ERROR') {
                    setLoading(false)

                    handleUpgradePlanClose()
                }

            } else {

            }
        } catch (error) {
            setLoading(false)
            handleUpgradePlanClose()

        }
    };

    // PROPERTY  PUBLISH  API CALL
    const publishApiCall = async (params) => {
        setLoading(true)
        try {
            const response = await axios.post(`${config.baseURL}/Property/PublishProperty`, params, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.status == 200) {

                if (response.data.Status == 'OK') {
                    const params = {
                        pageNumber: pageOwner,
                        OwnerId: ownerOfflineData?.ownerType == 'create' ? createOwnerData?.OwnerId : getByIdOwnerData?.Id

                    }

                    dispatch(ownerPropertiesApi(token, params))
                        .then(() => {
                            setLoading(false)
                            handlePublishClose()
                        })
                        .catch(() => {
                            setLoading(false)
                            handlePublishClose()
                        })
                } else if (response.data.Status == 'ERROR') {
                    setLoading(false)

                    handlePublishClose()
                }

            } else {

            }
        } catch (error) {
            setLoading(false)
            handlePublishClose()

        }
    };

    // ON CLICK UPGRADE PUBLISH PROPERTY
    const onClickPublishUpgrade = () => {
        if (mainSubscriptionTypeSelected == 1) {
            if (subscriptionIdSelectedFeatured == 2) {
                if (checkPublishCreditsData?.IsSuffiecientCreditsForFeatured_15_Days == 1) {
                    const params = {
                        "PropertyId": propertyIdToPublish,
                        "SubscriptionPlanId": subscriptionIdSelectedFeatured,
                        "IsBoosterAutoRenew": featuredAutoRenewCheckBox
                    }
                    PublishUpgradeApiCall(params)
                }
                else {
                    alert("not sufficient")
                }
            }
            else if (subscriptionIdSelectedFeatured == 3) {
                if (checkPublishCreditsData?.IsSuffiecientCreditsForFeatured_1_Month == 1) {
                    const params = {
                        "PropertyId": propertyIdToPublish,
                        "SubscriptionPlanId": subscriptionIdSelectedFeatured,
                        "IsBoosterAutoRenew": featuredAutoRenewCheckBox
                    }
                    PublishUpgradeApiCall(params)
                }
                else {
                    alert("not sufficient")
                }

            }
        }
        else if (mainSubscriptionTypeSelected == 2) {
            if (subscriptionIdSelectedPremium == 4) {
                if (checkPublishCreditsData?.IsSuffiecientCreditsForPremium_15_Days == 1) {
                    const params = {
                        "PropertyId": propertyIdToPublish,
                        "SubscriptionPlanId": subscriptionIdSelectedPremium,
                        "IsBoosterAutoRenew": premiumAutoRenewCheckBox

                    }
                    PublishUpgradeApiCall(params)
                }
                else {
                    alert("not sufficient")
                }
            }
            else if (subscriptionIdSelectedPremium == 5) {
                if (checkPublishCreditsData?.IsSuffiecientCreditsPremium_1_Month == 1) {
                    const params = {
                        "PropertyId": propertyIdToPublish,
                        "SubscriptionPlanId": subscriptionIdSelectedPremium,
                        "IsBoosterAutoRenew": premiumAutoRenewCheckBox

                    }
                    PublishUpgradeApiCall(params)
                }
                else {
                    alert("not sufficient")
                }
            }
        }
    }

    // ON CLICK  PUBLISH PROPERTY
    const onClickPublish = () => {
        if (checkPublishCreditsData?.IsSuffiecientCreditsForStandard == 1) {
            const params = {
                "PropertyId": propertyIdToPublish,
                "SubscriptionPlanId": 1
            }
            publishApiCall(params)
        }
        else {
            alert("insufficient balance")
        }
    }

    const onClickPropertyEdit = (id) => {
        dispatch(propertyModeApi(1))
        dispatch(ownerPropertyModeApi(3))
        setLoading(true)
        dispatch(propertyDetailDataApi('edit', ''))
        dispatch(getPropertyDetailApi(token, id))
            .then((response) => {
                setLoading(false)
                navigate('/PropertyDetail')
            })
            .catch((error) => {
                setLoading(false)


            });

    }

    const handleChangeAutoRenew = async (itemId) => {
        setLoading(true)
        try {
            const response = await axios.get(`${config.baseURL}/Property/AutoRenew?PropertyId=${itemId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.status == 200) {
                const params = {
                    pageNumber: pageOwner,
                    OwnerId: ownerOfflineData?.ownerType == 'create' ? createOwnerData?.OwnerId : getByIdOwnerData?.Id

                }
                dispatch(ownerPropertiesApi(token, params))
                    .then((response) => {
                        setPageOwner(pageOwner)
                        setLoading(false)
                    })
                    .catch((error) => {
                        setLoading(false)


                    });


            } else {

            }
        } catch (error) {


        }
    };

    const unpublishApiCall = async () => {
        setLoading(true)
        try {
            const response = await axios.get(`${config.baseURL}/Property/Unpublish?PropertyId=${unPublishPropertyId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.status == 200) {

                if (response.data.Status == 'OK') {
                    const params = {
                        pageNumber: 1,
                        OwnerId: ownerOfflineData?.ownerType == 'create' ? createOwnerData?.OwnerId : getByIdOwnerData?.Id

                    }
                    dispatch(ownerPropertiesApi(token, params))
                        .then((response) => {
                            setLoading(false)
                            setUnPublishModal(false)
                        })
                        .catch((error) => {
                            setLoading(false)


                        });



                } else if (response.data.Status == 'ERROR') {


                }

            } else {

            }
        } catch (error) {


        }
    };

    // ONCLICK UNPUBLISH PROPERTY
    const onClickUnpunlishProperty = () => {

        unpublishApiCall()

    }
    const onClickPdfDownload = (propertyId) => {
        const url = `${config.baseURL}/Reports/PdfBrochure?id=${propertyId}`;
        window.open(url, '_blank');


    }
    return (
        <div className="App">
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}

            >
                <CircularProgress color="inherit" />
            </Backdrop>


            <Dialog
                open={ownerDocPopup}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleOwnerClosePoppup}
                aria-describedby="alert-dialog-slide-description"
                className='dialog_box_popup'
            >

                <DialogContent class="dialog_box_block">
                    <DialogContentText id="alert-dialog-slide-description" class="dialog_box_title">
                        <h6>Delete</h6>
                        <Button onClick={handleOwnerClosePoppup}><img src={closeModal} /></Button>
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-slide-description" class="dialog_box_detail">
                        Are you sure you want to delete this document ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions class="dialog_box_button">
                    <Button onClick={handleOwnerClosePoppup}>Cancel</Button>
                    <Button onClick={() => onClickDeleteDocument()}>Delete</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={ownerPopupModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleOwnerPropertyClosePoppup}
                aria-describedby="alert-dialog-slide-description"
                className='dialog_box_popup'
            >

                <DialogContent class="dialog_box_block">
                    <DialogContentText id="alert-dialog-slide-description" class="dialog_box_title">
                        <h6>Delete</h6>
                        <Button onClick={handleOwnerPropertyClosePoppup}><img src={closeModal} /></Button>
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-slide-description" class="dialog_box_detail">
                        Are you sure you want to delete this property ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions class="dialog_box_button">
                    <Button onClick={handleOwnerPropertyClosePoppup}>Cancel</Button>
                    <Button onClick={() => onClickDeleteProperty()}>Delete</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={unPublishModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseUnpublishpopup}
                aria-describedby="alert-dialog-slide-description"
                className='dialog_box_popup'
            >

                <DialogContent class="dialog_box_block">
                    <DialogContentText id="alert-dialog-slide-description" class="dialog_box_title">
                        <h6>Unpublish</h6>
                        <Button onClick={handleCloseUnpublishpopup}><img src={closeModal} /></Button>
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-slide-description" class="dialog_box_detail">
                        <p>Are you sure you want to unpublish this property ?</p>
                    </DialogContentText>
                </DialogContent>
                <DialogActions class="dialog_box_button">
                    <Button onClick={handleCloseUnpublishpopup}>Cancel</Button>
                    <Button onClick={() => onClickUnpunlishProperty()}>Unpublish</Button>
                </DialogActions>
            </Dialog>

            <div class="overlay"></div>

            <Topbar />

            <Sidebar message={'Owners'} />

            <section class="content">
                <div
                    id="fieldDefaultTop" ref={fieldDefaultRefTop} class="block-header">
                    <h2>{t('ownerDetails')}</h2>

                </div>

                <div class="row clearfix">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="tab_block">
                            <Tabs
                                value={value}
                                onChange={handleChanges}
                                className='tab-nav-right'
                                aria-label="wrapped label tabs example"
                            >
                                <Tab className='tabs_title' value="one" label={t('information')} wrapped />
                                <Tab className='tabs_title' value="two" label={t('ownersProperties')} disabled={ownerMode?.mode == 0 ? true : false} />
                                <Tab className='tabs_title' value="three" label={t('notes')} disabled={ownerMode?.mode == 0 ? true : false} />
                                <Tab className='tabs_title' value="four" label={t('documents')} disabled={ownerMode?.mode == 0 ? true : false} />
                            </Tabs>
                        </div>

                        <div class="tab_content_block">

                            {
                                value == 'one' ?
                                    <div class="row clearfix">
                                        <div class="col-sm-9">
                                            <div class="panel-group full-body" id="accordion_3" role="tablist" aria-multiselectable="true">
                                                <div class="panel">
                                                    <div class="panel-heading" role="tab" id="headingOne_3">
                                                        <h4 class="panel-title">
                                                            <a href="javascript:void(0)" role="button" onClick={() => openCloseOwnerAccordingList()} data-toggle="collapse" aria-expanded="true" aria-controls="collapseOne_3">
                                                                <span>{t('ownerInformation')}</span>
                                                                <i className={ownerAccordingList ? 'openIcon' : 'closeIcon'}></i>
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    {ownerAccordingList ?
                                                        <div id="collapseOne_3" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne_3">
                                                            <div class="panel-body">
                                                                <div class="card">
                                                                    <div className="form_block">
                                                                        <div class="row clearfix">
                                                                            <div class="col-sm-6">
                                                                                <div class="form-group">
                                                                                    <div class="form-line">
                                                                                        <input type="text" class="form-control" title={t('title')} placeholder={t('title')} value={title} onChange={(e) => { setTitle(e.target.value); }} />
                                                                                        {
                                                                                            titleError && (
                                                                                                <span class="validation_notes">{titleError}</span>
                                                                                            )
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-sm-6">
                                                                                <div class="form-group">
                                                                                    <div class="form-line">
                                                                                        <input type="text" class="form-control" title={t('firstNamePlaceHolder')} placeholder={t('firstNamePlaceHolder') + '*'} ref={field1Ref} id="field1" value={firstName} onChange={(e) => { setFirstName(e.target.value); validateFirstName(e.target.value) }} />
                                                                                        {
                                                                                            firstNameError && (
                                                                                                <span class="validation_notes">{firstNameError}</span>

                                                                                            )
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row clearfix">
                                                                            <div class="col-sm-6">
                                                                                <div class="form-group">
                                                                                    <div class="form-line">
                                                                                        <input type="text" class="form-control" title={t('middleNamePlaceHolder')} placeholder={t('middleNamePlaceHolder')} value={middleName} onChange={(e) => { setMiddleName(e.target.value); }} />
                                                                                        {
                                                                                            middleNameError && (
                                                                                                <span class="validation_notes">{middleNameError}</span>

                                                                                            )
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-sm-6">
                                                                                <div class="form-group">
                                                                                    <div class="form-line">
                                                                                        <input type="text" class="form-control" title={t('lastNamePlaceHolder')} placeholder={t('lastNamePlaceHolder') + '*'} ref={field2Ref} id="field2" value={lastName} onChange={(e) => { setLastName(e.target.value); validateLastName(e.target.value) }} />
                                                                                        {
                                                                                            lastNameError && (
                                                                                                <span class="validation_notes">{lastNameError}</span>

                                                                                            )
                                                                                        }                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row clearfix">
                                                                            <div class="col-sm-6">
                                                                                <div class="form-group">
                                                                                    <div class="form-line">
                                                                                        <input type={inputTypeBirthdate}
                                                                                            max={today}
                                                                                            onFocus={(e) => handleFocusDate(e)} class="form-control" title={t('dateOfBirthPlaceHolder')} placeholder={t('dateOfBirthPlaceHolder')} value={dateOfBirth}
                                                                                            onKeyDown={(e) => e.preventDefault()} // Prevent typing
                                                                                            onKeyPress={(e) => e.preventDefault()} // Prevent typing
                                                                                            onKeyUp={(e) => e.preventDefault()} // Prevent typing
                                                                                            onPaste={(e) => e.preventDefault()} // Prevent pasting
                                                                                            onChange={(e) => { setDateOfBirth(e.target.value); }} />
                                                                                        {
                                                                                            dateOfBirthError &&
                                                                                            (
                                                                                                <span class="validation_notes">{dateOfBirthError}</span>

                                                                                            )

                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-sm-6">
                                                                                <div class="form-group">
                                                                                    <div class="form-line">
                                                                                        <input type="text" class="form-control" value={idNumber} maxLength={20} title={t('idNumberPlaceHolder')} placeholder={t('idNumberPlaceHolder')} onChange={(e) => { setIdNumber(e.target.value); }} />
                                                                                        {
                                                                                            idNumberError && (
                                                                                                <span class="validation_notes">{idNumberError}</span>

                                                                                            )
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row clearfix">
                                                                            <div class="col-sm-6">
                                                                                <div class="form-group">
                                                                                    <div class="form-line">
                                                                                        <input type="text" class="form-control" value={passportId} maxLength={20} title={t('passportIdPlaceHolder')} placeholder={t('passportIdPlaceHolder')} onChange={(e) => { setPassportId(e.target.value); }} />
                                                                                        {
                                                                                            passportIdError && (
                                                                                                <span class="validation_notes">{passportIdError}</span>

                                                                                            )
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-sm-6">
                                                                                <div class="form-group">
                                                                                    <div class="form-line">
                                                                                        <input type={inputTypeExpiredate}

                                                                                            onFocus={(e) => handleFocusExpireDate(e)} class="form-control" value={passportExpiryDate} title={t('passportExpiryDatePlaceHolder')} placeholder={t('passportExpiryDatePlaceHolder')}
                                                                                            onKeyDown={(e) => e.preventDefault()} // Prevent typing
                                                                                            onKeyPress={(e) => e.preventDefault()} // Prevent typing
                                                                                            onKeyUp={(e) => e.preventDefault()} // Prevent typing
                                                                                            onPaste={(e) => e.preventDefault()} // Prevent pasting
                                                                                            onChange={(e) => { setPassportExpiryDate(e.target.value); }} />
                                                                                        {
                                                                                            passportExpiryDateError && (
                                                                                                <span class="validation_notes">{passportExpiryDateError}</span>

                                                                                            )
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> : null
                                                    }
                                                </div>
                                            </div>
                                            <div class="panel-group full-body" id="accordion_4" role="tablist" aria-multiselectable="true">
                                                <div class="panel">
                                                    <div class="panel-heading" role="tab" id="headingOne_4">
                                                        <h4 class="panel-title">
                                                            <a href="javascript:void(0)" role="button" onClick={() => openCloseContactAccordingList()} data-toggle="collapse" aria-expanded="true" aria-controls="collapseOne_4">
                                                                <span>{t('contactInformation')}</span>
                                                                <i className={contactAccordingList ? 'openIcon' : 'closeIcon'}></i>
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    {contactAccordingList ?
                                                        <div id="collapseOne_4" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne_4">
                                                            <div class="panel-body">
                                                                <div class="card">
                                                                    <div class="form_block">
                                                                        <div class="row clearfix">
                                                                            <div class="col-sm-6">
                                                                                <div class="form-group">
                                                                                    <div class="form-line">
                                                                                        <input type="text" class="form-control" title={t('emailPlaceHolder')} placeholder={t('emailPlaceHolder')} id='field4' ref={field4Ref} value={email} onChange={(e) => { setEmail(e.target.value); validateEmail(e.target.value) }} />
                                                                                        {
                                                                                            emailError && (
                                                                                                <span class="validation_notes">{emailError}</span>
                                                                                            )
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-sm-6">
                                                                                <div class="form-group phone-input">

                                                                                    <Tooltip title={t('contactPlaceHolder')} arrow className="tooltip_notes">
                                                                                        <PhoneInput
                                                                                            placeholder={t('contactPlaceHolder') + '*'}
                                                                                            country={"bh"}
                                                                                            enableSearch={true}
                                                                                            value={contactNo}
                                                                                            onChange={(e) => { setContactNo(e); validateContactNo(e) }}
                                                                                        />
                                                                                        {
                                                                                            contactNoError && (
                                                                                                <span class="validation_notes">{contactNoError}</span>
                                                                                            )
                                                                                        }
                                                                                    </Tooltip>
                                                                                </div>
                                                                             
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> : null
                                                    }
                                                </div>
                                            </div>
                                            <div class="panel-group full-body" id="accordion_5" role="tablist" aria-multiselectable="true">
                                                <div class="panel">
                                                    <div class="panel-heading" role="tab" id="headingOne_5">
                                                        <h4 class="panel-title">
                                                            <a href="javascript:void(0)" role="button" onClick={() => openCloseAddressAccordingList()} data-toggle="collapse" aria-expanded="true" aria-controls="collapseOne_5">
                                                                <span>{t('addressOfResidency')}</span>
                                                                <i className={addressAccordingList ? 'openIcon' : 'closeIcon'}></i>
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    {addressAccordingList ?
                                                        <div id="collapseOne_5" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne_5">
                                                            <div class="panel-body">
                                                                <div class="card">
                                                                    <div class="form_block">
                                                                        <div class="row clearfix">
                                                                            <div class="col-sm-6">
                                                                                <div>
                                                                                    <FormControl className='form-group' sx={{}}>
                                                                                        <Select
                                                                                            className='form-control border_temp'
                                                                                            sx={{ borderColor: 'transparent' }}
                                                                                            title={t('selectNationality')}
                                                                                            displayEmpty
                                                                                            value={nationality}
                                                                                            onChange={(e) => { setNationality(e.target.value); }}
                                                                                            input={<OutlinedInput />}

                                                                                            MenuProps={{
                                                                                                PaperProps: {
                                                                                                    style: {
                                                                                                        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                                                        width: 250,
                                                                                                    },
                                                                                                },
                                                                                                disableScrollLock: true,
                                                                                            }}
                                                                                            inputProps={{ 'aria-label': 'Without label' }}
                                                                                        >
                                                                                            <MenuItem className='drop_menus' value="">
                                                                                                <em className='select_text'>{t('selectNationality')}</em>
                                                                                            </MenuItem>
                                                                                            {ownerDropdownData?.Countries?.map((item) => (
                                                                                                <MenuItem
                                                                                                    className='drop_menus'
                                                                                                    key={item?.Id}
                                                                                                    value={item?.Id}
                                                                                                    style={getStyles(item?.Name, personName, theme)}
                                                                                                >
                                                                                                    {item?.Name}
                                                                                                </MenuItem>
                                                                                            ))}
                                                                                        </Select>
                                                                                        {
                                                                                            nationalityError && (
                                                                                                <span class="validation_notes">Please Select Your Nationality</span>
                                                                                            )
                                                                                        }
                                                                                    </FormControl>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-sm-6">
                                                                                <div>
                                                                                    <FormControl className='form-group' sx={{}}>
                                                                                        <Select
                                                                                            className='form-control border_temp'
                                                                                            sx={{ borderColor: 'transparent' }}
                                                                                            title={t('selectCountryOfResidence')}
                                                                                            displayEmpty
                                                                                            value={countryOfResidence}
                                                                                            onChange={(e) => { setCountryOfResidence(e.target.value); }}
                                                                                            input={<OutlinedInput />}

                                                                                            MenuProps={{
                                                                                                PaperProps: {
                                                                                                    style: {
                                                                                                        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                                                        width: 250,
                                                                                                    },
                                                                                                },
                                                                                                disableScrollLock: true,
                                                                                            }}
                                                                                            inputProps={{ 'aria-label': 'Without label' }}
                                                                                        >
                                                                                            <MenuItem className='drop_menus' value="">
                                                                                                <em className='select_text'>{t('selectCountryOfResidence')}</em>
                                                                                            </MenuItem>
                                                                                            {ownerDropdownData?.Countries?.map((item) => (
                                                                                                <MenuItem
                                                                                                    className='drop_menus'
                                                                                                    key={item?.Id}
                                                                                                    value={item?.Id}
                                                                                                    style={getStyles(item?.Name, personName, theme)}
                                                                                                >
                                                                                                    {item?.Name}
                                                                                                </MenuItem>
                                                                                            ))}
                                                                                        </Select>
                                                                                        {
                                                                                            countryOfResidenceError && (
                                                                                                <span class="validation_notes">{countryOfResidenceError}</span>
                                                                                            )
                                                                                        }
                                                                                    </FormControl>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row clearfix">
                                                                            <div class="col-sm-12">
                                                                                <div class="form-group">
                                                                                    <div class="form-line">
                                                                                        <textarea rows="4" class="form-control no-resize" title={t('addressPlaceHolder')} value={address} placeholder={t('addressPlaceHolder')} onChange={(e) => { setAddress(e.target.value); }}></textarea>
                                                                                        {
                                                                                            addressError && (
                                                                                                <span class="validation_notes">{addressError}</span>

                                                                                            )
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> : null
                                                    }
                                                </div>
                                            </div>
                                            <div class="panel-group full-body" id="accordion_7" role="tablist" aria-multiselectable="true">
                                                <div class="panel">
                                                    <div class="panel-heading" role="tab" id="headingOne_7">
                                                        <h4 class="panel-title">
                                                            <a href="javascript:void(0)" role="button" onClick={() => openCloseLegalAccordingList()} data-toggle="collapse" aria-expanded="true" aria-controls="collapseOne_7">
                                                                <span>{t('legalRepresentative')}</span>
                                                                <i className={legalAccordingList ? 'openIcon' : 'closeIcon'}></i>
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    {legalAccordingList ?
                                                        <>
                                                            <div id="collapseOne_7" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne_7">
                                                                <div class="panel-body">
                                                                    <div class="card">
                                                                        <div className='form_block'>
                                                                            <div class="row clearfix">
                                                                                <div class="col-sm-6">
                                                                                    <div class="form-group">
                                                                                        <div class="form-line">
                                                                                            <input type="text" class="form-control" value={legalRepresentativeFirstName} title={t('legalRepresentativeFirstNamePlaceHolder')} placeholder={t('legalRepresentativeFirstNamePlaceHolder')} onChange={(e) => { setLegalRepresentativeFirstName(e.target.value); }} />
                                                                                            {
                                                                                                legalRepresentativeFirstNameError && (
                                                                                                    <span class="validation_notes">{legalRepresentativeFirstNameError}</span>

                                                                                                )
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-6">
                                                                                    <div class="form-group">
                                                                                        <div class="form-line">
                                                                                            <input type="text" class="form-control" value={legalRepresentativeLastName} title={t('legalRepresentativeLastNamePlaceHolder')} placeholder={t('legalRepresentativeLastNamePlaceHolder')} onChange={(e) => { setLegalRepresentativeLastName(e.target.value); }} />
                                                                                            {legalRepresentativeLastNameError && (
                                                                                                <span class="validation_notes">{legalRepresentativeLastNameError}</span>

                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row clearfix">
                                                                                <div class="col-sm-6">
                                                                                    <div class="form-group phone-input">
                                                                                        <Tooltip title={t('legalRepresentativeContactPlaceHolder')} arrow className="tooltip_notes">

                                                                                            <PhoneInput
                                                                                                placeholder={t('legalRepresentativeContactPlaceHolder')}
                                                                                                country={"bh"}
                                                                                                enableSearch={true}
                                                                                                value={legalRepresentativeContactNo}
                                                                                                onChange={(e) => { setLegalRepresentativeContactNo(e) }}
                                                                                            />
                                                                                            {
                                                                                                legalRepresentativeContactNoError && (
                                                                                                    <span class="validation_notes">{legalRepresentativeContactNoError}</span>
                                                                                                )
                                                                                            }
                                                                                        </Tooltip>
                                                                                    </div>
                                                                                   
                                                                                </div>
                                                                                <div class="col-sm-6">
                                                                                    <div class="form-group">
                                                                                        <div class="form-line">
                                                                                            <input type="text" class="form-control" value={legalRepresentativeEmail} title={t('legalRepresentativeEmailPlaceHolder')} placeholder={t('legalRepresentativeEmailPlaceHolder')} onChange={(e) => { setLegalRepresentativeEmail(e.target.value); }} />
                                                                                            {
                                                                                                legalRepresentativeEmailError && (
                                                                                                    <span class="validation_notes">{legalRepresentativeEmailError}</span>
                                                                                                )
                                                                                            }                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </> : null
                                                    }
                                                </div>
                                            </div>
                                            <div class="panel-group full-body" id="accordion_6" role="tablist" aria-multiselectable="true">
                                                <div class="panel">
                                                    <div class="panel-heading" role="tab" id="headingOne_6">
                                                        <h4 class="panel-title">
                                                            <a href="javascript:void(0)" role="button" onClick={() => openCloseFamilyAccordingList()} data-toggle="collapse" aria-expanded="true" aria-controls="collapseOne_6">
                                                                <span>{t('familyInformation')}</span>
                                                                <i className={familyAccordingList ? 'openIcon' : 'closeIcon'}></i>
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    {familyAccordingList ?
                                                        <div id="collapseOne_6" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne_6">
                                                            <div class="panel-body">
                                                                <div class="card">
                                                                    <div class="form_block">
                                                                        <div class="row clearfix">
                                                                            <div class="col-sm-6">
                                                                                <div class="form-group">
                                                                                    <div class="form-line">
                                                                                        <input type="text" class="form-control" value={spouseName} title={t('spouseNamePlaceHolder')} placeholder={t('spouseNamePlaceHolder')} onChange={(e) => { setSpouseName(e.target.value); }} />
                                                                                        {
                                                                                            spouseNameError &&
                                                                                            (
                                                                                                <span class="validation_notes">{spouseNameError}</span>
                                                                                            )
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-sm-6">
                                                                                <div>
                                                                                    <FormControl className='form-group' sx={{}}>
                                                                                        <Select
                                                                                            className='form-control border_temp'
                                                                                            sx={{ borderColor: 'transparent' }}

                                                                                            displayEmpty
                                                                                            value={noOfChildren}
                                                                                            onChange={(e) => { setNoOfChildren(e.target.value); }}
                                                                                            input={<OutlinedInput />}
                                                                                            title={t('selectNoOfChildren')}
                                                                                            MenuProps={{
                                                                                                PaperProps: {
                                                                                                    style: {
                                                                                                        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                                                        width: 250,
                                                                                                    },
                                                                                                },
                                                                                                disableScrollLock: true,
                                                                                            }}
                                                                                            inputProps={{ 'aria-label': 'Without label' }}
                                                                                        >
                                                                                            <MenuItem className='drop_menus' value="">
                                                                                                <em className='select_text'>{t('selectNoOfChildren')}</em>
                                                                                            </MenuItem>
                                                                                            {numbers?.map((item) => (
                                                                                                <MenuItem
                                                                                                    className='drop_menus'
                                                                                                    key={item?.Id}
                                                                                                    value={item?.Id}
                                                                                                    style={getStyles(item?.Name, personName, theme)}
                                                                                                >
                                                                                                    {item?.Name}
                                                                                                </MenuItem>
                                                                                            ))}
                                                                                        </Select>
                                                                                        {
                                                                                            noOfChildrenError && (
                                                                                                <span class="validation_notes">{noOfChildrenError}</span>
                                                                                            )
                                                                                        }
                                                                                    </FormControl>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row clearfix">
                                                                            <div class="col-sm-12">
                                                                                <div>
                                                                                    <FormControl className='form-group' sx={{}}>
                                                                                        <Select
                                                                                            className='form-control border_temp'
                                                                                            sx={{ borderColor: 'transparent' }}
                                                                                            title={t('selectMaritalStatus')}
                                                                                            displayEmpty
                                                                                            value={maritalStatus}
                                                                                            onChange={(e) => { setMaritalStatus(e.target.value); }}
                                                                                            input={<OutlinedInput />}

                                                                                            MenuProps={{
                                                                                                PaperProps: {
                                                                                                    style: {
                                                                                                        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                                                        width: 250,
                                                                                                    },
                                                                                                },
                                                                                                disableScrollLock: true,
                                                                                            }}
                                                                                            inputProps={{ 'aria-label': 'Without label' }}
                                                                                        >
                                                                                            <MenuItem className='drop_menus' value="">
                                                                                                <em className='select_text'>{t('selectMaritalStatus')}</em>
                                                                                            </MenuItem>
                                                                                            {ownerDropdownData?.Maritialstatuses?.map((item) => (
                                                                                                <MenuItem
                                                                                                    className='drop_menus'
                                                                                                    key={item?.Id}
                                                                                                    value={item?.Id}
                                                                                                    style={getStyles(item?.Name, personName, theme)}
                                                                                                >
                                                                                                    {item?.Name}
                                                                                                </MenuItem>
                                                                                            ))}
                                                                                        </Select>
                                                                                        {
                                                                                            maritalStatusError && (
                                                                                                <span class="validation_notes">{maritalStatusError}</span>
                                                                                            )
                                                                                        }
                                                                                    </FormControl>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="button_block">
                                                                            <a href="#" class="links" onClick={() => informationApi()}>{t('save')}</a>
                                                                            <a href="#" class="links" onClick={() => informationNextApi()}>{t('saveAndNext')}</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-3">
                                            <div class="rightside_block">
                                                <h4>{t('draftProperty')}</h4>
                                                <div class="update_block">
                                                    <p><span>{t('lastUpdate')}: {lastDateStr}</span></p>
                                                    <p><span>{'Created'}: {createdDateStr}</span></p>
                                                </div>
                                                {

                                                    userRole == 'AgentAdmin' && (
                                                        <>
                                                            <h4>{t('management')}</h4>
                                                            <div>
                                                                <FormControl className='form-group' sx={{}}>
                                                                    <Select
                                                                        className='form-control border_temp'
                                                                        sx={{ borderColor: 'transparent' }}
                                                                        displayEmpty
                                                                        value={references}
                                                                        onChange={(e) => { { setReferences(e.target.value); }; }}
                                                                        input={<OutlinedInput />}
                                                                        title='Assign to'
                                                                        MenuProps={{
                                                                            PaperProps: {
                                                                                style: {
                                                                                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                                    width: 250,
                                                                                },
                                                                            },
                                                                            disableScrollLock: true,
                                                                        }}
                                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                                    >
                                                                        <MenuItem className='drop_menus' value="">
                                                                            <em className='select_text'>Assign to</em>
                                                                        </MenuItem>
                                                                        {ownerDropdownData?.Users?.map((item) => (
                                                                            <MenuItem
                                                                                className='drop_menus'
                                                                                key={item?.Id}
                                                                                value={item?.Id}
                                                                                style={getStyles(item?.Name, personName, theme)}
                                                                            >
                                                                                {item?.Name}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>

                                                                </FormControl>
                                                            </div>
                                                        </>
                                                    )
                                                }

                                            </div>
                                        </div>
                                    </div> : null
                            }
                            {
                                value == 'two' ?
                                    <>
                                        <div class="main_class">
                                            <div class="table_block">
                                                <div class="card">
                                                    <div class="table-responsive">
                                                        {
                                                            ownerPropertiesData?.Data?.length != 0 ?
                                                                <table class="table table-bordered table-striped table-hover dataTable">
                                                                    <thead>
                                                                        <tr>
                                                                            <th></th>
                                                                            <th>
                                                                                <div class="table_heading">
                                                                                    <h5>{t('refNo')}</h5>
                                                                                    <p>{t('user')}</p>
                                                                                </div>
                                                                            </th>
                                                                            <th>
                                                                                <div class="table_heading">
                                                                                    <h5>{t('title')}</h5>
                                                                                    <p>{t('location')}</p>
                                                                                </div>
                                                                            </th>
                                                                            <th>
                                                                                <div class="table_heading">
                                                                                    <h5>{t('layout')}</h5>
                                                                                    <p>{t('size')}</p>
                                                                                </div>
                                                                            </th>
                                                                            <th>
                                                                                <div class="table_heading">
                                                                                    <h5>{t('type')}</h5>
                                                                                    <p>{t('offering')}</p>
                                                                                </div>
                                                                            </th>
                                                                            <th>
                                                                                <div class="table_heading">
                                                                                    <h5>{t('price')}</h5>
                                                                                    <p>{t('category')}</p>
                                                                                </div>
                                                                            </th>
                                                                            <th>
                                                                                <div class="table_heading">
                                                                                    <h5>{t('updated')}</h5>
                                                                                    <p>{t('listed')}</p>
                                                                                </div>
                                                                            </th>

                                                                            <th>
                                                                                <div class="table_heading">
                                                                                    <h5>{t('listing')}</h5>
                                                                                </div>
                                                                            </th>
                                                                            <th>
                                                                                <div class="table_heading">
                                                                                    <h5>Auto-renew</h5>
                                                                                </div>
                                                                            </th>
                                                                            <th>
                                                                                <div class="table_heading">
                                                                                    <h5>{t('qlt')}.<br />{t('score')}</h5>
                                                                                </div>
                                                                            </th>
                                                                            <th>
                                                                                <div class="table_heading">
                                                                                    <h5>Pdf Brochure</h5>
                                                                                </div>
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>

                                                                        {
                                                                            ownerPropertiesData?.Data?.map((item) => {

                                                                                return (

                                                                                    <>
                                                                                        <tr>
                                                                                            <td>
                                                                                                <div class="table_detail">
                                                                                                    <a href="javascript:void(0)" class="link blue" onClick={() => toggleRowCollapse(item?.Id)}><img src={info} /></a>
                                                                                                    <a href="#" class="link green" onClick={() => onClickPropertyEdit(item?.Id)}><img src={edit} /></a>
                                                                                                    <a href="#" class="link red" onClick={() => handleOpenPropertyPoppup(item?.Id)}><img src={deleteIcon} /></a>
                                                                                                </div>
                                                                                            </td>
                                                                                            <td>
                                                                                                <div class="table_detail">
                                                                                                    <h6>{item?.Reference}</h6>
                                                                                                    <p>{item?.AssingnedName}</p>
                                                                                                </div>
                                                                                            </td>
                                                                                            <td>
                                                                                                <div class="table_detail name">
                                                                                                    <h6 style={{ textOverflow: 'ellipsis', maxWidth: '120px', whiteSpace: 'nowrap', overflow: "hidden" }}>{item?.Title}</h6>
                                                                                                    <p style={{ textOverflow: 'ellipsis', maxWidth: '120px', whiteSpace: 'nowrap', overflow: "hidden" }}>{item?.LocationName}</p>

                                                                                                </div>
                                                                                            </td>
                                                                                            <td>
                                                                                                <div class="form-group">
                                                                                                    <div class="table_detail">
                                                                                                        <h6>
                                                                                                            <div class="props">{item?.Noofbedrooms == -1 ? "Studio" : item?.Noofbedrooms}<img src={bed} /></div>
                                                                                                            <div class="props">{item?.Noofbathrooms}<img src={bathtub} /></div>
                                                                                                        </h6>
                                                                                                        <p><div class="props">{item?.Size} sqm<img src={area_icon} /></div></p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </td>
                                                                                            <td>
                                                                                                <div class="table_detail">
                                                                                                    <h6>{item?.PropertyTypeName}</h6>
                                                                                                    <p>{item?.OfferingTypeName}</p>
                                                                                                </div>
                                                                                            </td>
                                                                                            <td>
                                                                                                <div class="table_detail">
                                                                                                    <h6>BHD {item?.MonthlyPrice}{item?.OfferingTypeName == "Rent" ? "/M" : ""}</h6>
                                                                                                    <p>{item?.CategoryName}</p>
                                                                                                </div>
                                                                                            </td>
                                                                                            <td>
                                                                                                <div class="table_detail">
                                                                                                    <h6>{item?.UpdatedStr}</h6>
                                                                                                    <p>{item?.CreatedStr}</p>
                                                                                                </div>
                                                                                            </td>

                                                                                            <td>
                                                                                                <div class="table_detail">
                                                                                                    {
                                                                                                        item?.SubscriptionPlanId != null && (
                                                                                                            <h6>{item?.SubscriptionPlanId == 1 ? "Standard" : item?.SubscriptionPlanId == 2 ? "Featured" : item?.SubscriptionPlanId == 3 ? "Featured" : item?.SubscriptionPlanId == 4 ? "Premium" : "Premium"}</h6>
                                                                                                        )
                                                                                                    }
                                                                                                    {
                                                                                                        item?.SubscriptionPlanId != 4 && item?.SubscriptionPlanId != 5 && (
                                                                                                            <div style={{ display: 'block' }}>
                                                                                                                <a href='#' onClick={() => item?.SubscriptionPlanId == null ? handlePublishOpen(item) : handleUpgradePlanOpen(item)} style={{ display: 'inline-block' }} class={item?.SubscriptionPlanId == null ? "status green" : "status blue"}>{item?.SubscriptionPlanId == null ? "Publish" : "Upgrade"}</a>

                                                                                                            </div>

                                                                                                        )
                                                                                                    }

                                                                                                    {
                                                                                                        item?.SubscriptionPlanId != null && (
                                                                                                            <>
                                                                                                                <div style={{ display: 'block' }}>
                                                                                                                    <a onClick={() => handleOpenUnpublishpopup(item?.Id)} class={"status red"} style={{ marginTop: 10, display: 'inline-block' }}>{"Unpublish"}</a>

                                                                                                                </div>
                                                                                                            </>

                                                                                                        )
                                                                                                    }

                                                                                                </div>
                                                                                            </td>
                                                                                            <td>
                                                                                                <Switch {...label}
                                                                                                    checked={item?.SubscriptionPlanId == null ? false : item?.IsAutoRenew}
                                                                                                    disabled={item?.SubscriptionPlanId == null ? true : (item?.SubscriptionPlanId == 1 && mimicFlag == false) ? true : false}
                                                                                                    onChange={() => handleChangeAutoRenew(item?.Id)}
                                                                                                />
                                                                                            </td>
                                                                                            <td>
                                                                                                <div class="table_detail center">
                                                                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderColor: item?.QualityGrade == 'Emerald(E)' ? '#5EAC18' : '#EC1A25' }} class="progress_bar"><span style={{ lineHeight: '25px', marginBottom: '0', color: item?.QualityGrade == 'Emerald(E)' ? '#5EAC18' : '#EC1A25' }}>{item?.QualityGrade == 'Emerald(E)' ? 'E' : 'S'}</span></div>
                                                                                                    <span class="opac" style={{ marginLeft: 8 }}>{item?.QualityScore}%</span>
                                                                                                    <div class="quality_score_detail">
                                                                                                        <div class="quality_tag">
                                                                                                            <h6>{t('title')}:</h6>
                                                                                                            <p>{item?.TitleScore}%</p>
                                                                                                        </div>
                                                                                                        <div class="quality_tag">
                                                                                                            <h6>{t('description')}:</h6>
                                                                                                            <p>{item?.DescriptionScore}%</p>
                                                                                                        </div>
                                                                                                        <div class="quality_tag">
                                                                                                            <h6>{t('images')}:</h6>
                                                                                                            <p>{item?.ImageCountScore}%</p>
                                                                                                        </div>
                                                                                                        <div class="quality_tag">
                                                                                                            <h6>{t('duplicatePhotos')}:</h6>
                                                                                                            <p>{item?.DuplicateImageScore}%</p>
                                                                                                        </div>
                                                                                                        <div class="quality_tag">
                                                                                                            <h6>{t('photoSizes')}:</h6>
                                                                                                            <p>{item?.ImageSizeScore}%</p>
                                                                                                        </div>
                                                                                                        <div class="quality_tag">
                                                                                                            <h6>{t('ImageDiversity')}:</h6>
                                                                                                            <p>{item?.ImageDiversity1Score}%</p>
                                                                                                        </div>

                                                                                                        <div class="quality_tag">
                                                                                                            <h6>{t('additionalFields')}:</h6>
                                                                                                            <p>{item?.NonMandatoryFieldsScore}%</p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </td>
                                                                                            <td>
                                                                                                <div class="table_detail text-center">
                                                                                                    <a onClick={() => onClickPdfDownload(item?.Id)} ><img style={{ maxWidth: '30px' }} src={pdf_icon} /></a>

                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                        {
                                                                                            collapsedRows[item.Id] &&
                                                                                            <tr>
                                                                                                <td colSpan={10}>
                                                                                                    <table>
                                                                                                        <div class="props_detail">
                                                                                                            <div class="row clearfix d-flex">
                                                                                                                <div class="col-md-4">
                                                                                                                    {
                                                                                                                        item?.MediaUrlList.length != 0 ?

                                                                                                                            <div class="property_list_image">
                                                                                                                                <Carousel images={item?.MediaUrlList} />
                                                                                                                            </div>
                                                                                                                            :
                                                                                                                            <div class="property_list_image">
                                                                                                                                <img src={require("../../images/no_image.jpg")} />
                                                                                                                            </div>

                                                                                                                    }
                                                                                                                </div>
                                                                                                                <div class="col-md-8 align-self-center">
                                                                                                                    <div class="property_list_detail">
                                                                                                                        <h2>{item?.Title}</h2>
                                                                                                                        <div class="amenities_details">
                                                                                                                            <p class="status green">{item?.AvailabilitytypeName}</p>
                                                                                                                            {
                                                                                                                                item?.AvailableFrom != null && (
                                                                                                                                    <div class="amenities_block">
                                                                                                                                        <img src={dateRange}></img>

                                                                                                                                        <span>From {formatDate(item?.AvailableFrom)}</span>


                                                                                                                                    </div>
                                                                                                                                )
                                                                                                                            }
                                                                                                                            <div class="amenities_block">
                                                                                                                                <img src={sofa}></img>
                                                                                                                                <span>{item?.FurnishingTypes}</span>
                                                                                                                            </div>
                                                                                                                            <div class="amenities_block">
                                                                                                                                <img src={dollar}></img>
                                                                                                                                <span>{item?.Price}</span>
                                                                                                                            </div>
                                                                                                                            <div class="amenities_block">
                                                                                                                                <img src={bicycle}></img>
                                                                                                                                <span>{item?.Noofparking} Parking</span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div class="quality_score_block">
                                                                                                                            <div class="amenities_detail_block">
                                                                                                                                <div class="services_detail">


                                                                                                                                    <p>Owner:&nbsp;&nbsp;&nbsp;<span>{item?.OwnerName}</span></p>

                                                                                                                                </div>
                                                                                                                                <div>
                                                                                                                                    {
                                                                                                                                        item?.AmenitiesList?.map((item) => {
                                                                                                                                            return (
                                                                                                                                                <p class="status green">{item}</p>
                                                                                                                                            )
                                                                                                                                        })
                                                                                                                                    }
                                                                                                                                </div>


                                                                                                                            </div>
                                                                                                                            <div class="quality_block">
                                                                                                                                <h3>Quality Score</h3>
                                                                                                                                <p>Quality Score Of This Listing</p>
                                                                                                                                <div class="services_detail">
                                                                                                                                    <p>Title:&nbsp;&nbsp;&nbsp;<span>{item?.TitleScore}</span></p>
                                                                                                                                    <p>Description:&nbsp;&nbsp;&nbsp;<span>{item?.DescriptionScore}</span></p>
                                                                                                                                    <p>Images:&nbsp;&nbsp;&nbsp;<span>{item?.ImageCountScore}</span></p>
                                                                                                                                    <p>Duplicate Photos:&nbsp;&nbsp;&nbsp;<span>{item?.DuplicateImageScore}</span></p>
                                                                                                                                    <p>Photo Sizes:&nbsp;&nbsp;&nbsp;<span>{item?.ImageSizeScore}</span></p>
                                                                                                                                    <p>Image Diversity:&nbsp;&nbsp;&nbsp;<span>{item?.ImageDiversity1Score}</span></p>

                                                                                                                                    <p>Additional Fields:&nbsp;&nbsp;&nbsp;<span>{item?.NonMandatoryFieldsScore}</span></p>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </table>
                                                                                                </td>
                                                                                            </tr>

                                                                                        }</>
                                                                                )
                                                                            })
                                                                        }



                                                                    </tbody>
                                                                </table> :
                                                                <h1 class="text-center">No Data Found</h1>
                                                        }

                                                    </div>
                                                    <div class="text-right">
                                                        <Pagination className="pagination" count={ownerPropertiesData?.TotalPages} page={pageOwner} onChange={pageOwnerHandleChange} />                                                    </div>
                                                </div>
                                            </div>
                                            <div class="button_block">
                                                <a href="#" class="links" onClick={() => setValue('one')}>{t('back')}</a>
                                                <a href="#" class="links" onClick={() => navigate('/OwnerList')}>{t('save')}</a>
                                                <a href="#" class="links" onClick={() => onClickPropertyNext()}>{t('saveAndNext')}</a>
                                            </div>
                                        </div>
                                    </> : null
                            }
                            {
                                value == 'three' ?
                                    <>
                                        <div class="main_class">
                                            <div class="form_block">
                                                <div class="form-group notes">
                                                    <div class="form-line">
                                                        <textarea rows="4" class="form-control no-resize" value={notes} placeholder={t('WriteNewNote')} onChange={(e) => { setNotes(e.target.value); validateNotes(e.target.value) }} ></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="button_block">
                                                <a href="#" class="links" onClick={() => notesScreenApi()}>{t('Save')}</a>
                                            </div>
                                            {
                                                notesError && (
                                                    <span class="validation_notes">{notesError}</span>

                                                )
                                            }
                                        </div>
                                        {
                                            notesListOwnerData?.Data?.map((item) => {
                                                return (
                                                    <div class="main_class">
                                                        <div class="note_block">
                                                            <div class="note_person">
                                                                <div class="note_person_image">
                                                                    <img src={require("../../images/note_person_image.png")} />
                                                                    <div class="person_detail">
                                                                        <h5>{item?.UserName}</h5>
                                                                        <p>{t('lastUpdate')}: {item?.DateDifference}</p>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <p>{item?.Notes}</p>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }

                                        <div class="button_block">
                                            <a href="#" class="links" onClick={() => setValue('two')}>{t('back')}</a>
                                            <a href="#" onClick={() => onClickNotesNext()} class="links">{t('Next')}</a>
                                        </div>
                                    </> : null
                            }

                            {
                                value == 'four' ?
                                    <>
                                        <div class="main_class">
                                            <div class="file-drop-area">
                                                <img src={folder} />
                                                <span class="file-message">{t('dragAndDrop')}</span>
                                                <span class="choose-file-button">{t('uploadADocument')}</span>
                                                <input class="file-input" type="file" onChange={handleFileChange} />

                                            </div>
                                        </div>
                                        <div class="main_class">
                                            <div class="table_block">
                                                <div class="table-responsive">
                                                    {

                                                        documentsListOwnerData?.Data?.length != 0 ?
                                                            <table class="table table-bordered table-striped table-hover dataTable custom">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Sr. No.</th>
                                                                        <th>{t('name')}</th>
                                                                        <th>{t('contact')}</th>
                                                                        <th>{t('fileName')}</th>
                                                                        <th></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {documentsListOwnerData?.Data?.map((item) => {
                                                                        return (
                                                                            <tr>
                                                                                <td>
                                                                                    <p>{item?.RowNum}</p>
                                                                                </td>
                                                                                <td>
                                                                                    <p>{item?.Name}</p>
                                                                                </td>
                                                                                <td>
                                                                                    <p>{item?.Phone}</p>
                                                                                </td>
                                                                                <td>
                                                                                    <a onClick={() => handleDownload(item)}>
                                                                                        <p class='blue_text'>{item?.FileName}</p>
                                                                                    </a>

                                                                                </td>
                                                                                <td>
                                                                                    <div class="table_detail">
                                                                                        <a href="#" class="link red" onClick={() => handleOpenDocPoppup(item?.Id)}><img src={deleteIcon} /></a>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })}


                                                                </tbody>
                                                            </table> :
                                                            <h1 class="text-center">No Data Found</h1>

                                                    }
                                                </div>
                                                <div class="text-right">
                                                    <Pagination className="pagination" count={documentsListOwnerData?.TotalPages} page={page} onChange={pageHandleChange} />
                                                </div>
                                            </div>
                                            <div class="button_block">
                                                <a href="#" class="links" onClick={() => setValue('three')}>{t('back')}</a>
                                                <a href="#" class="links" onClick={(e) => documentScreenApi(e)}>{t('done')}</a>
                                            </div>
                                        </div>
                                    </> : null
                            }
                        </div>
                    </div>
                </div>
                <Modal
                    open={upgradePlanOpen}
                    onClose={handleUpgradePlanClose}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                >
                    <div class="modal_block upgrade">
                        <div class="modal_header_block">
                            <h2 id="child-modal-title">Boost on MakaanPro</h2>
                            <button onClick={handleUpgradePlanClose}><img src={closeModal} /></button>
                        </div>
                        <div class="modal_body_block">
                            <div class="credit_numbers">
                                <p>Select the type of boost you want to give to your listing.</p>
                                <div class="avail_credits">
                                    <p class="blue_text"><img src={require("../../images/logo_icon.png")} />Available Credits: <span>{checkPublishCreditsData?.AvailableCredits}</span></p>
                                </div>
                            </div>
                            <div class="upgrade_plan_block">
                                {
                                    propertyCurrentSubscriptionPlanId == 1 && (
                                        <div class={mainSubscriptionTypeSelected == 1 ? "upgrade_plan_detail active" : "upgrade_plan_detail"} onClick={() => setMainSubscriptionTypeSelected(1)}>
                                            <h5>Featured</h5>
                                            <div class="duration_block">
                                                <p>Duration</p>
                                                <div class="form_block">
                                                    <FormControl>
                                                        <RadioGroup
                                                            aria-labelledby="demo-radio-buttons-group-label"
                                                            defaultValue="15 days"
                                                            name="radio-buttons-group"
                                                            class="radio_button_block"

                                                        >
                                                            <FormControlLabel value="15 days" control={<Radio />} label="15 days" onClick={() => setSubscriptionIdSelectedFeatured(2)} />

                                                            <FormControlLabel value="1 Month" control={<Radio />} label="1 month" onClick={() => setSubscriptionIdSelectedFeatured(3)} />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </div>
                                            </div>
                                            <div class="table_heading">
                                                <div style={{ display: 'inline-block' }} >
                                                    <FormControlLabel sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }} class='autorenew_check' control={<Checkbox checked={featuredAutoRenewCheckBox} onChange={handleFeaturedAutoRenewCheckBox} />} label="Auto-renew" />
                                                </div>

                                            </div>
                                            <div class="upgrade_credit_block">
                                                <h6>{subscriptionIdSelectedFeatured == 2 ? checkPublishCreditsData?.DeductionCreditsForFeatured_15_Days : checkPublishCreditsData?.DeductionCreditsForFeatured_1_Month} Credits</h6>

                                            </div>

                                        </div>
                                    )
                                }
                                <div class={mainSubscriptionTypeSelected == 2 ? "upgrade_plan_detail active" : "upgrade_plan_detail"} onClick={() => setMainSubscriptionTypeSelected(2)}>
                                    <h5>Premium</h5>
                                    <div class="duration_block">
                                        <p>Duration</p>
                                        <div class="form_block">
                                            <FormControl>
                                                <RadioGroup
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    defaultValue="15 days"
                                                    name="radio-buttons-group"
                                                    class="radio_button_block"
                                                >
                                                    <FormControlLabel value="15 days" control={<Radio />} label="15 days" onClick={() => setSubscriptionIdSelectedPremium(4)} />

                                                    <FormControlLabel value="1 Month" control={<Radio />} label="1 month" onClick={() => setSubscriptionIdSelectedPremium(5)} />
                                                </RadioGroup>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div class="table_heading">
                                        <div style={{ display: 'inline-block' }} >
                                            <FormControlLabel sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }} class='autorenew_check' control={<Checkbox checked={premiumAutoRenewCheckBox} onChange={handlePremiumAutoRenewCheckBox} />} label="Auto-renew" />
                                        </div>

                                    </div>
                                    <div class="upgrade_credit_block">
                                        <h6>{subscriptionIdSelectedPremium == 4 ? checkPublishCreditsData?.DeductionCreditsForPremium_15_Days : checkPublishCreditsData?.DeductionCreditsForPremium_1_Month} Credits</h6>

                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="modal_footer_block">
                            <div class="button_block">
                                <a href="#" class="links" onClick={() => handleUpgradePlanClose()} style={{
                                    color: mainSubscriptionTypeSelected == "" || loading ? '#999' : "inherit",// Change color when disabled
                                    pointerEvents: mainSubscriptionTypeSelected == "" || loading ? 'none' : "auto", // Disable pointer events when disabled

                                }}>Cancel</a>
                                <a href="#" class="links" onClick={() => onClickPublishUpgrade()} style={{
                                    color: mainSubscriptionTypeSelected == "" || loading ? '#999' : "inherit",// Change color when disabled
                                    pointerEvents: mainSubscriptionTypeSelected == "" || loading ? 'none' : "auto", // Disable pointer events when disabled

                                }}>Apply</a>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal
                    open={publishOpen}
                    onClose={handlePublishClose}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                >
                    <div class="modal_block upgrade">
                        <div class="modal_header_block">
                            <h2 id="child-modal-title">Publish Properties</h2>
                            <button onClick={handlePublishClose}><img src={closeModal} /></button>
                        </div>

                        <div class="modal_body_block">
                            <div class="publish_properties_block">
                                <img style={{ height: 85, marginLeft: -10 }} src={require("../../images/logo.png")} />
                                {
                                    checkPublishCreditsData?.Status == "true" ?
                                        <>
                                            <p><span class="red_text">{checkPublishCreditsData?.DeductionCreditForStandard} credits</span> will be deducted from your balance.</p>
                                            <h6>Available Credits : {checkPublishCreditsData?.AvailableCredits}</h6>
                                        </>
                                        :
                                        <p><span class="red_text">This property cannot be published as it may have incomplete information to publish to portals.</span></p>
                                }
                            </div>
                        </div>
                        <div class="modal_footer_block">
                            <div class="button_block">
                                <a href="#" class="links" style={{
                                    color: loading ? '#999' : "inherit",// Change color when disabled
                                    pointerEvents: loading ? 'none' : "auto", // Disable pointer events when disabled

                                }} onClick={() => handlePublishClose()}>Cancel</a>
                                {
                                    checkPublishCreditsData?.Status == "true" && (
                                        <a href="#" class="links" style={{
                                            color: loading ? '#999' : "inherit",// Change color when disabled
                                            pointerEvents: loading ? 'none' : "auto", // Disable pointer events when disabled

                                        }} onClick={() => onClickPublish()}>Publish</a>
                                    )
                                }

                            </div>
                        </div>
                    </div>
                </Modal>
            </section>
            <ToastContainer />

        </div>
    );

}



