import React from 'react'
import dashboard from '../../images/dashboard.svg';
import property from '../../images/property.svg';
import leads from '../../images/leads.svg';
import owners from '../../images/owners.svg';
import credits from '../../images/credits.svg';
import setting from '../../images/setting.svg';
import company from '../../images/company.svg';
import cms from '../../images/cms.svg';
import plus from '../../images/plus.svg';
import close from '../../images/close-black.svg';
import edit from '../../images/edit.svg';
import logout from '../../images/logout.svg';
import deleteIcon from '../../images/delete.svg';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Modal, TextField, Button } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import '../../css/themes/all-themes.css';
// <!-- Google Fonts -->
import '../../css/material-icon.css';

// <!-- Bootstrap Core Css -->
import '../../plugins/bootstrap/css/bootstrap.css';

// <!-- Waves Effect Css -->
import '../../plugins/node-waves/waves.css';

// <!-- Animation Css -->
import '../../plugins/animate-css/animate.css';

// <!-- Bootstrap Select Css -->
import '../../plugins/bootstrap-select/css/bootstrap-select.css';


// <!-- JQuery DataTable Css -->
import '../../plugins/jquery-datatable/skin/bootstrap/css/dataTables.bootstrap.css';
// <!-- Bootstrap Material Datetime Picker Css -->
import '../../plugins/bootstrap-material-datetimepicker/css/bootstrap-material-datetimepicker.css';

// <!-- Bootstrap DatePicker Css -->
import '../../plugins/bootstrap-datepicker/css/bootstrap-datepicker.css';

// <!-- Wait Me Css -->
import '../../plugins/waitme/waitMe.css';

// <!-- Morris Chart Css-->
import '../../plugins/morrisjs/morris.css';

// <!-- Custom Css -->
import '../../css/style.css';
import '../../css/custom.css';
import '../../css/materialize.css';

import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
const Sidebar = (props) => {

    const userRole = useSelector((state) => state?.apiReducer?.loginData?.Data?.userRole[0])

    const navigate = useNavigate()
    return (
        <>
            {
                userRole == 'Agent' && (
                    <section>
                        {/* <!-- Left Sidebar --> */}
                        <aside id="leftsidebar" class="sidebar">
                            {/* <!-- Menu --> */}
                            <div class="menu">
                                <ul id="menu-header-menu" class="head-menu list">
                                    <li class={props.message == 'Dashboard' ? "custom-menu-item active" : "custom-menu-item"} >
                                        <a onClick={() => navigate('/Dashboard')}>
                                            <i><img src={dashboard} alt="Dashboard" /></i>
                                            <span>Dashboard</span>
                                        </a>
                                    </li>

                                    <li class={props.message == 'Properties' ? "custom-menu-item active" : "custom-menu-item"}>
                                        <a onClick={() => navigate('/PropertyList')}>
                                            <i><img src={property} alt="Property" /></i>
                                            <span>Properties</span>
                                        </a>

                                    </li>
                                    <li class={props.message == 'Leads' ? "custom-menu-item active" : "custom-menu-item"}>
                                        <a onClick={() => navigate('/LeadsList')}>
                                            <i><img src={leads} alt="Leads" /></i>
                                            <span>Leads</span>
                                        </a>

                                    </li>
                                    <li class={props.message == 'Owners' ? "custom-menu-item active" : "custom-menu-item"}>
                                        <a onClick={() => navigate('/OwnerList')}>
                                            <i><img src={owners} alt="Owners" /></i>
                                            <span>Owners</span>
                                        </a>

                                    </li>
                                    <li class={props.message == 'Credit' ? "custom-menu-item active" : "custom-menu-item"}>
                                        <a onClick={() => navigate('/CreditList')}>
                                            <i><img src={credits} alt="Credits" /></i>
                                            <span>Credits</span>
                                        </a>
                                    </li>



                                </ul>
                            </div>
                            {/* <!-- #Menu --> */}
                        </aside>
                    </section>
                )
            }
            {
                userRole == 'SuperAdministrator' && (
                    <section>
                        {/* <!-- Left Sidebar --> */}
                        <aside id="leftsidebar" class="sidebar">
                            {/* <!-- Menu --> */}
                            <div class="menu">
                                <ul id="menu-header-menu" class="head-menu list">

                                    <li class={props.message == 'Dashboard' ? "custom-menu-item active" : "custom-menu-item"} >
                                        <a onClick={() => navigate('/Dashboard')}>
                                            <i><img src={dashboard} alt="Dashboard" /></i>
                                            <span>Dashboard</span>
                                        </a>
                                    </li>
                                    <li class={props.message == 'AgentList' ? "custom-menu-item active" : "custom-menu-item"}>
                                        <a onClick={() => navigate('/AgentList')}>
                                            <i><img src={company} alt="Agent" /></i>
                                            <span>Company Details</span>
                                        </a>

                                    </li>

                                    <li class={props.message == 'CMS' ? "custom-menu-item active" : "custom-menu-item"}>
                                        <a onClick={() => navigate('/CMS')}>
                                            <i><img src={cms} alt="CMS" /></i>
                                            <span>CMS</span>
                                        </a>

                                    </li>



                                    <li class={props.message == 'SettingAdmin' ? "custom-menu-item active" : "custom-menu-item"}>
                                        <a onClick={() => navigate('/SettingsAdmin')}>
                                            <i><img src={setting} alt="Setting" /></i>
                                            <span>Settings</span>
                                        </a>
                                    </li>

                                </ul>
                            </div>
                            {/* <!-- #Menu --> */}
                        </aside>
                    </section>
                )
            }
            {
                userRole == 'AgentAdmin' && (
                    <section>
                        {/* <!-- Left Sidebar --> */}
                        <aside id="leftsidebar" class="sidebar">
                            {/* <!-- Menu --> */}
                            <div class="menu">
                                <ul id="menu-header-menu" class="head-menu list">
                                    <li class={props.message == 'Dashboard' ? "custom-menu-item active" : "custom-menu-item"} >
                                        <a onClick={() => navigate('/Dashboard')}>
                                            <i><img src={dashboard} alt="Dashboard" /></i>
                                            <span>Dashboard</span>
                                        </a>
                                    </li>

                                    <li class={props.message == 'Properties' ? "custom-menu-item active" : "custom-menu-item"}>
                                        <a onClick={() => navigate('/PropertyList')}>
                                            <i><img src={property} alt="Property" /></i>
                                            <span>Properties</span>
                                        </a>

                                    </li>
                                    <li class={props.message == 'Leads' ? "custom-menu-item active" : "custom-menu-item"}>
                                        <a onClick={() => navigate('/LeadsList')}>
                                            <i><img src={leads} alt="Leads" /></i>
                                            <span>Leads</span>
                                        </a>

                                    </li>
                                    <li class={props.message == 'Owners' ? "custom-menu-item active" : "custom-menu-item"}>
                                        <a onClick={() => navigate('/OwnerList')}>
                                            <i><img src={owners} alt="Owners" /></i>
                                            <span>Owners</span>
                                        </a>

                                    </li>
                                    <li class={props.message == 'Credit' ? "custom-menu-item active" : "custom-menu-item"}>
                                        <a onClick={() => navigate('/CreditList')}>
                                            <i><img src={credits} alt="Credits" /></i>
                                            <span>Credits</span>
                                        </a>
                                    </li>
                                    <li class={props.message == 'User' ? "custom-menu-item active" : "custom-menu-item"}>
                                        <a onClick={() => navigate('/UserList')}>
                                            <i><img src={owners} alt="User" /></i>
                                            <span>Agent</span>
                                        </a>

                                    </li>

                                    <li class={props.message == 'Settings' ? "custom-menu-item active" : "custom-menu-item"}>
                                        <a onClick={() => navigate('/Settings')}>
                                            <i><img src={setting} alt="Setting" /></i>
                                            <span>Settings</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>

                        </aside>
                    </section>
                )


            }
        </>
    )
}

export default Sidebar