import React from 'react';
import PropTypes from 'prop-types';

const CustomBadge = ({ content, color, fontSize, size, style }) => {
    const badgeSize = size || '2rem'; // Default size is 2rem
    const badgeStyle = {
        backgroundColor: color || '#ff0000', // Default color is red
        color: '#fff',
        borderRadius: '50%',
        padding: '0',
        fontSize: fontSize || '1rem', // Default font size is 1rem
        width: badgeSize,
        height: badgeSize,
        textAlign: 'center',
        lineHeight: '1.8rem',
        ...style // Additional custom styles
    };

    return (
        <span style={badgeStyle}>
            {content}
        </span>
    );
};

CustomBadge.propTypes = {
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    color: PropTypes.string,
    fontSize: PropTypes.string,
    size: PropTypes.string,
    style: PropTypes.object
};

export default CustomBadge;
