import React, { useState } from 'react';
import '../../css/custom.css';
import config from '../../redux/config';

const Carousel = ({ images }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const goToPrevSlide = () => {
        const newIndex = (currentIndex - 1 + images.length) % images.length;
        setCurrentIndex(newIndex);
    };

    const goToNextSlide = () => {
        const newIndex = (currentIndex + 1) % images.length;
        setCurrentIndex(newIndex);
    };

    return (
        <div className="carousel">
            <button onClick={goToPrevSlide} className="carousel-button">
                &lt;
            </button>
            <img src={`${config.baseURL}${images[currentIndex]}`} alt="carousel-slide" className="carousel-image" />
            <button onClick={goToNextSlide} className="carousel-button">
                &gt;
            </button>
        </div>
    );
};

export default Carousel;
