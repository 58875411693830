import React, { useEffect, useState } from "react";
import plus from '../../images/plus.svg';
import edit from '../../images/edit.svg';
import deleteIcon from '../../images/delete.svg';
import closeModal from '../../images/close.svg';
import { Button, CircularProgress, Backdrop } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';

// <!-- Google Fonts -->
import '../../css/material-icon.css';

// <!-- Bootstrap Core Css -->
import '../../plugins/bootstrap/css/bootstrap.css';

// <!-- Waves Effect Css -->
import '../../plugins/node-waves/waves.css';

// <!-- Animation Css -->
import '../../plugins/animate-css/animate.css';

// <!-- Bootstrap Select Css -->
import '../../plugins/bootstrap-select/css/bootstrap-select.css';


// <!-- JQuery DataTable Css -->
import '../../plugins/jquery-datatable/skin/bootstrap/css/dataTables.bootstrap.css';
// <!-- Bootstrap Material Datetime Picker Css -->
import '../../plugins/bootstrap-material-datetimepicker/css/bootstrap-material-datetimepicker.css';

// <!-- Bootstrap DatePicker Css -->
import '../../plugins/bootstrap-datepicker/css/bootstrap-datepicker.css';

// <!-- Wait Me Css -->
import '../../plugins/waitme/waitMe.css';

// <!-- Morris Chart Css-->
import '../../plugins/morrisjs/morris.css';

// <!-- Custom Css -->
import '../../css/style.css';
import '../../css/custom.css';
import '../../css/materialize.css';


// <!-- AdminBSB Themes. You can choose a theme from css/themes instead of get all themes -->
import '../../css/themes/all-themes.css';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import Topbar from "../../component/Topbar";
import Sidebar from "../../component/Sidebar";
import { countryApi, getByIdUserApi, logoutApi, userDetailDataApi, userListApi, userListDeleteApi, userModeApi } from "../../redux/ApiActionCreator";
import { useDispatch, useSelector } from "react-redux";
import CustomBadge from "../../component/CustomBadge";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
export default function UserList() {
    const token = useSelector((state) => state?.apiReducer?.loginData?.Data?.accessToken)

    const userListData = useSelector((state) => state?.apiReducer?.userListData)

    const userListLoad = useSelector((state) => state?.apiReducer?.userListLoad)
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false);
    const [page, setPage] = React.useState(1);
    const [name, setName] = useState('')
    const [contactNo, setContactNo] = useState('')
    const [register, setRegister] = useState('')
    const [selectedUserId, setselectedUserId] = useState('')


    const handleKeyPress = (event) => {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);

        // Allow digits, decimal point, and backspace
        if (!/^\d$/.test(keyValue) && keyCode !== 8) {
            event.preventDefault();
        }

        // Prevent typing a minus sign
        if (keyCode === 45) {
            event.preventDefault();
        }
    };

    const handleKeyPressPhno = (event) => {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);

        // Allow digits, plus sign, and backspace
        if (!/^\d$/.test(keyValue) && keyValue !== "+") {
            event.preventDefault();
        }

        // Prevent typing a minus sign
        if (keyCode === 45) {
            event.preventDefault();
        }
    };






    //USERLIST API CALL
    useEffect(() => {
        const params = {
            pagenumber: page,
            Name: name == '' ? null : name,
            ContactNo: contactNo == '' ? null : contactNo,
            CommertialRegistration: register == '' ? null : register,
        }
        dispatch(userListApi(token, params))
            .then((response) => {

            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {

                    dispatch(logoutApi())
                    navigate('/')

                }

            });
    }, [page])


    //USER FILTER API CALL
    const onClickFilter = () => {
        setPage(1)
        setLoading(true)
        const params = {
            Name: name == '' ? null : name,
            ContactNo: contactNo == '' ? null : contactNo,
            CommertialRegistration: register == '' ? null : register,
            pagenumber: 1,
        }
        dispatch(userListApi(token, params))
            .then(() => {
                setLoading(false)
            })
            .catch(error => {
                setLoading(false)
            })
    }

    //RESET FILTER API CALL
    const onClickReset = () => {
        setLoading(true)
        setName('')
        setContactNo('')
        setRegister('')
        const params = {
            pagenumber: 1
        }
        dispatch(userListApi(token, params))
            .then(() => {
                setPage(1)
                setLoading(false)

            })
            .catch(error => {
                setPage(1)
                setLoading(false)
            })
    }

    const pageHandleChange = (event, value) => {
        setPage(value);
    };




    //CREATE USER NAVIGATE

    const onClickCreateUser = () => {
        dispatch(userModeApi(0))
        dispatch(userDetailDataApi('create', ''))
        navigate('/UserDetail')
    }

    //EDIT USER NAVIGATE
    const onClickEditUser = (id) => {
        setLoading(true)
        dispatch(userModeApi(1))
        dispatch(userDetailDataApi('edit', id))
        dispatch(getByIdUserApi(token, id))
            .then((response) => {
                setLoading(false)
                navigate('/UserDetail')
            })
            .catch((error) => {
                setLoading(false)

            });

    }

    const handleClickOpen = (id) => {
        setselectedUserId(id)
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);

    };

    //DELETE USER API CALL
    const onClickDeleteUser = () => {
        setLoading(true)
        dispatch(userListDeleteApi(token, selectedUserId))
            .then((response) => {
                const params = {
                    pagenumber: 1,
                    Name: name == '' ? null : name,
                    ContactNo: contactNo == '' ? null : contactNo,
                    CommertialRegistration: register == '' ? null : register,
                }
                dispatch(userListApi(token, params))
                    .then((response) => {
                        setPage(1)
                        setOpen(false);
                        setLoading(false)
                    })


            })
            .catch((error) => {

            });
    }
    return (
        <div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}

            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={userListLoad}

            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                className='dialog_box_popup'
            >

                <DialogContent class="dialog_box_block">
                    <DialogContentText id="alert-dialog-slide-description" class="dialog_box_title">
                        <h6>Delete</h6>
                        <Button onClick={handleClose}><img src={closeModal} /></Button>
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-slide-description" class="dialog_box_detail">
                        Are you sure you want to delete this User ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions class="dialog_box_button">
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={() => onClickDeleteUser()}>Delete</Button>
                </DialogActions>
            </Dialog>


            <div class="overlay"></div>

            <Topbar />

            <Sidebar message={'User'} />
            <section class="content">
                <div class="block-header">
                    <h2>Agent</h2>

                </div>

                <div class="page_heading">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <h3>Agent list</h3>

                    </div>
                    <a onClick={() => onClickCreateUser()}><img src={plus} /></a>
                </div>

                <div class="main_class">
                    <div class="form_block">
                        <div class="row clearfix">
                            <div class="col-md-3 col-sm-4 col-xs-6">
                                <div class="form-group">
                                    <div class="form-line">
                                        <input type="text" class="form-control" title={t('name')} placeholder={t('name')} value={name} onChange={(e) => setName(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-4 col-xs-6">
                                <div class="form-group">
                                    <div class="form-line">
                                        <input type="text" class="form-control" title={t('contact')} placeholder={t('contact')} maxLength={15} value={contactNo} onChange={(e) => setContactNo(e.target.value)} onKeyPress={handleKeyPressPhno}
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="button_block">
                            <a href="#" class="links" onClick={() => onClickReset()}>Reset</a>
                            <a href="#" class="links" onClick={() => onClickFilter()}>{t('search')}</a>
                        </div>
                    </div>
                </div>

                <div class="main_class">
                    <div class="table_block">
                        <div class="card">
                            <div class="table-responsive">
                                {
                                    userListData?.Data?.length != 0 ?
                                        <table class="table table-bordered table-striped table-hover dataTable">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>
                                                        <div class="table_heading text-center">
                                                            <h5>{t('Agent Id')}</h5>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div class="table_heading text-center">
                                                            <h5>{t('name')}</h5>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div class="table_heading text-center">
                                                            <h5>{t('contact')}</h5>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div class="table_heading text-center">
                                                            <h5>{t('email')}</h5>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div class="table_heading text-center">
                                                            <h5>{t('role')}</h5>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div class="table_heading text-center">
                                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                                                                <h5>No Of Approval Requests</h5>
                                                                <CustomBadge content={userListData?.ApprovalRequestsCount} color="#5EAC18" fontSize="1rem" size="1.8rem" style={{ marginLeft: '0.5rem', marginTop: -3 }} />
                                                            </div>
                                                        </div>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody class="text-center">
                                                {
                                                    userListData?.Data?.map((item) => {
                                                        return (
                                                            <tr>
                                                                <td>
                                                                    <div class="table_detail text-center">
                                                                        <a onClick={() => onClickEditUser(item?.Id)} class="link green"><img src={edit} /></a>
                                                                        <a href="#" class="link red" onClick={() => handleClickOpen(item?.Id)}><img src={deleteIcon} /></a>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="table_detail text-center">
                                                                        <h6>{item?.Id}</h6>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="table_detail text-center">
                                                                        <h6>{item?.Name}</h6>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="form-group">
                                                                        <div class="table_detail text-center">
                                                                            <h6>{item?.ContactNo}</h6>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="table_detail text-center">
                                                                        <h6>{item?.Email}</h6>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="table_detail text-center">
                                                                        <h6>{item?.Role}</h6>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="table_detail text-center">
                                                                        <h6>{item?.NoOfApprovalRequests}</h6>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }


                                            </tbody>
                                        </table> : <h1 class="text-center">No Data Found</h1>
                                }

                            </div>
                            <div class="text-right">
                                <Pagination className="pagination" count={userListData?.TotalPages} page={page} onChange={pageHandleChange} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
