import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import 'react-toastify/dist/ReactToastify.css';
import folder from '../../images/folder.svg';
import deleteIcon from '../../images/delete.svg';
import closeModal from '../../images/close.svg';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Button, Tooltip } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Pagination from '@mui/material/Pagination';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import check from '../../images/check.svg'
import close from '../../images/close-red.svg'

// <!-- Google Fonts -->
import '../../css/material-icon.css';

// <!-- Bootstrap Core Css -->
import '../../plugins/bootstrap/css/bootstrap.css';

// <!-- Waves Effect Css -->
import '../../plugins/node-waves/waves.css';

// <!-- Animation Css -->
import '../../plugins/animate-css/animate.css';

// <!-- Bootstrap Select Css -->
import '../../plugins/bootstrap-select/css/bootstrap-select.css';


// <!-- JQuery DataTable Css -->
import '../../plugins/jquery-datatable/skin/bootstrap/css/dataTables.bootstrap.css';
// <!-- Bootstrap Material Datetime Picker Css -->
import '../../plugins/bootstrap-material-datetimepicker/css/bootstrap-material-datetimepicker.css';

// <!-- Bootstrap DatePicker Css -->
import '../../plugins/bootstrap-datepicker/css/bootstrap-datepicker.css';

// <!-- Wait Me Css -->
import '../../plugins/waitme/waitMe.css';

// <!-- Morris Chart Css-->
import '../../plugins/morrisjs/morris.css';

// <!-- Custom Css -->
import '../../css/style.css';
import '../../css/custom.css';
import '../../css/materialize.css';
import '../../css/uicons-bold-rounded.css';

// <!-- AdminBSB Themes. You can choose a theme from css/themes instead of get all themes -->
import '../../css/themes/all-themes.css';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Topbar from '../../component/Topbar';
import Sidebar from '../../component/Sidebar';
import { addSubAgentCreditApi, agentCreditListApi, countryApi, createUserApi, deleteUserDocumentsApi, getByIdUserApi, logoutApi, publishRequestApproveRejectApi, updateUserApi, userDetailDataApi, userDocumentListApi, userDropdownApi, userModeApi, withdrawSubAgentCreditApi } from '../../redux/ApiActionCreator';
import axios from 'axios';
import config from '../../redux/config';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import { ToastContainer, toast } from 'react-toastify';
import { scroller } from 'react-scroll';
import CustomBadge from '../../component/CustomBadge';
import PhoneInput from 'react-phone-input-2';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;


function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
export default function UserDetail() {

    const field1Ref = useRef()
    const field2Ref = useRef()
    const field3Ref = useRef()
    const field4Ref = useRef()
    const field5Ref = useRef()
    const field6Ref = useRef()
    const field7Ref = useRef()
    const field8Ref = useRef()
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const theme = useTheme();
    const navigate = useNavigate()
    const [personName, setPersonName] = useState([]);
    const [getCreditBalance, setGetCreditBalance] = useState("")
    const [getCompanyCreditBalance, setGetCompanyCreditBalance] = useState("")

    const [open, setOpen] = useState(false);
    const [useraccordingList, setUserAccordingList] = useState(true)
    const [page, setPage] = React.useState(1);
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [contact, setContact] = useState('')
    const [loading, setLoading] = useState(false)
    const [landline, setLandline] = useState('')
    const [whatsAppNo, setwhatsAppNo] = useState('')
    const [expSince, setExpSince] = useState('')
    const [nationality, setNationality] = useState('')
    const [role, setRole] = useState('')
    const [selectLanguage, setSelectLanguage] = useState('')
    const [position, setPosition] = useState('')
    const [pfExp, setpfExp] = useState('')
    const [linkedinUrl, setLinkedinUrl] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [spokenLanguage, setSpokenLanguage] = useState('')
    const [description, setDescription] = useState('')
    const [descriptionCredits, setDescriptionCredits] = useState('')

    const [document, setDocument] = useState('')
    const [value, setValue] = React.useState('one');
    const [firstNameError, setFirstNameError] = useState('')
    const [emailError, setEmailError] = useState('')
    const [contactError, setContactError] = useState('')
    const [landlineError, setLandlineError] = useState('')
    const [roleError, setRoleError] = useState('')
    const [selectLanguageError, setselectLanguageError] = useState('')
    const [passwordError, setPasswordError] = useState('')
    const [confirmPasswordError, setconfirmPasswordError] = useState('')
    const [selectedDocumentId, setSelectedDocumentId] = useState("")
    const [passwordShow, setpasswordShow] = useState(false)
    const [confirmPasswordShow, setconfirmPasswordShow] = useState(false)
    const token = useSelector((state) => state?.apiReducer?.loginData?.Data?.accessToken)
    const userDropdownData = useSelector((state) => state?.apiReducer?.userDropdownData)
    const userDocumentListData = useSelector((state) => state?.apiReducer?.userDocumentListData)
    const getByIdUserData = useSelector((state) => state?.apiReducer?.getByIdUserData)
    const userOfflineData = useSelector((state) => state?.apiReducer?.userOfflineData)
    const userMode = useSelector((state) => state?.apiReducer?.userMode)
    const createUserData = useSelector((state) => state?.apiReducer?.createUserData)
    const agentCreditList = useSelector((state) => state?.apiReducer?.agentCreditListData)
    const [agentCreditListPage, setAgentCreditListPage] = useState(1)
    const [pendingRequestsList, setPendingRequestsList] = useState([])

    const maxLength = 7;
    const [credit, setCredit] = useState('')
    const [creditWithdraw, setCreditWithdraw] = useState('')

    const [creditError, setcreditError] = useState('')
    const [creditWithdrawError, setCreditWithdrawError] = useState('')
    const [descriptionCreditsError, setDescriptionCreditsError] = useState('')

    const [withdrawModalPoppup, setWithdrawModalPoppup] = useState(false)
    const [addCreditsModalPoppup, setAddCreditsModalPoppup] = useState(false)



    const handleKeyPress = (event) => {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);

        // Allow digits, decimal point, and backspace
        if (!/^\d$/.test(keyValue) && keyCode !== 8) {
            event.preventDefault();
        }

        // Prevent typing a minus sign
        if (keyCode === 45) {
            event.preventDefault();
        }
    };
    const handleKeyPressPhno = (event) => {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);

        // Allow digits, plus sign, and backspace
        if (!/^\d$/.test(keyValue) && keyValue !== "+") {
            event.preventDefault();
        }

        // Prevent typing a minus sign
        if (keyCode === 45) {
            event.preventDefault();
        }
    };


    // Get the current year
    const currentYear = new Date().getFullYear();

    // Create an array to store the objects
    const yearsArray = [];

    // Loop from the current year to 1953 and create an object for each year
    for (let year = currentYear; year >= 1953; year--) {
        const yearObject = {
            Id: year,
            Name: year
        };
        yearsArray.push(yearObject);
    }

    // Output the array of objects
    const fieldDefaultRefTop = useRef();
    useEffect(() => {
        scroller.scrollTo(fieldDefaultRefTop.current.id, {
            smooth: false,
            offset: -100, // Adjust as needed
        });
    }, [])

    useEffect(() => {
        dispatch(countryApi(token))
            .then((response) => {

            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {

                    dispatch(logoutApi())
                    navigate('/')

                }

            });

    }, [])

    useEffect(() => {
        if (userOfflineData?.userType != 'create' || !userMode?.mode == 0) {

            dispatch(getByIdUserApi(token, userOfflineData?.userId));
        }

    }, [])

    useEffect(() => {
        if (userOfflineData?.userType != 'create' || !userMode?.mode == 0) {
            setFirstName(getByIdUserData?.FirstName)
            setLastName(getByIdUserData?.Lastname)
            setEmail(getByIdUserData?.Email)
            setContact(getByIdUserData?.ContactNo)
            setLandline(getByIdUserData?.LandlineNo)
            setwhatsAppNo(getByIdUserData?.WhatsappNo)
            setExpSince(getByIdUserData?.ExperinceSince == null ? '' : getByIdUserData?.ExperinceSince)
            setNationality(getByIdUserData?.Nationality == null ? '' : getByIdUserData?.Nationality)
            setRole(getByIdUserData?.RoleId == null ? '' : getByIdUserData?.RoleId)
            setSelectLanguage(getByIdUserData?.Language == null ? '' : getByIdUserData?.Language)
            setPosition(getByIdUserData?.Position)
            setpfExp(getByIdUserData?.GreenEmerlandLanguage == null ? '' : getByIdUserData?.GreenEmerlandLanguage)
            setLinkedinUrl(getByIdUserData?.LinkedInUrl)
            setPassword(getByIdUserData?.Password)
            setConfirmPassword(getByIdUserData?.Password)
            setSpokenLanguage(getByIdUserData?.SpokenLanguages)
            setDescription(getByIdUserData?.Description)
        }


    }, [])

    //USER DOCUMENT LIST API CALL
    useEffect(() => {
        const params = {
            "pageNumber": page,
            "UserId": userOfflineData?.userType == 'create' ? createUserData?.UserId : getByIdUserData?.Id
        }
        dispatch(userDocumentListApi(token, params))
    }, [page, value])


    //USER DROPDOWN API CALL
    useEffect(() => {
        dispatch(userDropdownApi(token))
    }, [])

    const validateFirstName = (value) => {
        if (value.length === 0) {
            setFirstNameError(t('firstNameError'));
        } else {
            setFirstNameError('');
        }
    }


    const pageHandleChange = (event, value) => {
        setPage(value);
    };

    const pageHandleChangeAgentCreditList = (event, value) => {
        setAgentCreditListPage(value);
    };

    const validateDec = (value) => {
        if (value.length === 0) {
            setDescriptionCreditsError(t('pleaseEnterDecription'));
        } else {
            setDescriptionCreditsError('');
        }
    }
    const validateEmail = (value) => {
        if (value.length === 0) {
            setEmailError(t('emailError'));
        } else if (!(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value))) {

            setEmailError('Enter valid email');

        }
        else {
            setEmailError('');
        }
    }
    const validateContact = (value) => {
        if (value.length <= 4) {
            setContactError(t('contactNoError'));
        } else {
            setContactError('');
        }
    }




    const validateRole = (value) => {
        if (value.length === 0) {
            setRoleError(t('roleError'));
        } else {
            setRoleError('');
        }

    }




    const validatePassword = (value) => {
        if (value.length === 0) {
            setPasswordError(t('passwordError'));
        } else if (value.length < 8) {
            setPasswordError('password must be at least 8 characters');
        } else {
            setPasswordError('');
        }

    }

    const validateConfirmPassword = (value) => {
        if (value.length === 0) {
            setconfirmPasswordError(t('confirmPasswordError'));
        } else {
            setconfirmPasswordError('');
        }

    }


    //USER DOCUMENT API CALL
    const handleFileChange = async (e) => {
        setLoading(true)
        const file = e.target.files[0];
        const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/plain'];

        if (!allowedTypes.includes(file?.type)) {
            setLoading(false)

            alert('Please upload a JPG, JPEG, PNG, DOC, PDF,TXT or XLSX file.')

            e.target.value = null;
            // Trigger an additional event to force recognition of the change

            e.target.dispatchEvent(new Event('input'));

            return; // Exit the function if file type is not allowed

        }
        try {
            // Create a FormData object
            const formData = new FormData();

            // Add form fields to the FormData object
            formData.append('file', file);
            formData.append('UserId', userOfflineData?.userType == 'create' ? createUserData?.UserId : getByIdUserData?.Id);

            const response = await axios.post(
                `${config.baseURL}/User/UploadFile`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data', // Set the content type to FormData
                        Authorization: `Bearer ${token}`,
                        // Add any other headers if needed
                    },
                }
            );

            // Handle the response here (e.g., update state or perform other actions)
            if (response.data.Status == 'OK') {
                e.target.value = null;

                e.target.dispatchEvent(new Event('input'));
                const params = {
                    "pageNumber": page,
                    "UserId": userOfflineData?.userType == 'create' ? createUserData?.UserId : getByIdUserData?.Id
                }
                dispatch(userDocumentListApi(token, params))
                    .then(() => {
                        setLoading(false)
                    })
                    .catch(error => {
                        setLoading(false)
                    })
                toast.success("File Uploaded Successfully", {
                    position: 'top-right',
                    autoClose: 1000, // Adjust the duration as needed
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });

            } else {

            }

        } catch (error) {
            // Handle any errors here
            console.error('Error:', error);
        }

    };



    const getCompanyCreditBalanceApiCall = async (params) => {
        try {
            const response = await axios.get(`${config.baseURL}/Credit/GetCreditBalance`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response.status == 200) {

                if (response.data.Status == 'OK') {
                    setGetCompanyCreditBalance(response?.data?.TotalCredits)

                } else if (response.data.Status == 'ERROR') {


                }

            } else {

            }
        } catch (error) {

        }
    };

    const getCreditBalanceApiCall = async (params) => {
        try {
            const response = await axios.get(`${config.baseURL}/Credit/AgentCredits?AgentId=${userOfflineData?.userType == 'create' ? createUserData?.UserId : getByIdUserData?.Id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            console.log('hello', response)
            if (response.status == 200) {

                if (response.data.Status == 'OK') {
                    setGetCreditBalance(response?.data?.TotalCredits)

                } else if (response.data.Status == 'ERROR') {


                }

            } else {

            }
        } catch (error) {

        }
    };


    const getPendingRequestsListApiCall = async (params) => {
        setLoading(true)
        try {
            const response = await axios.get(`${config.baseURL}/Property/PropApprovalRequestList?AgentId=${userOfflineData?.userType == 'create' ? createUserData?.UserId : getByIdUserData?.Id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response.status == 200) {

                if (response.data.Status == 'OK') {
                    setLoading(false)

                    setPendingRequestsList(response.data?.Data?.ApprovalRequests)
                    console.log('requests', pendingRequestsList)
                } else if (response.data.Status == 'ERROR') {


                }

            } else {

            }
        }
        catch (error) {

        }
    };

    useEffect(() => {
        if (userOfflineData?.userType != 'create' || !userMode?.mode == 0) {
            getCreditBalanceApiCall()
            getCompanyCreditBalanceApiCall()
            getPendingRequestsListApiCall()
        }

    }, [value])

    //ONCLICK NEXT BUTTON USER CREATE AND UPDATE
    const userDetailNextAPI = () => {
        let validate = false
        if (firstName == '') {
            validate = true
            setFirstNameError(t('firstNameError'));
        } else {
            setFirstNameError('');
        }


        if (email == '') {
            validate = true
            setEmailError(t('emailError'));
        } else if (!(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email))) {
            validate = true
            setEmailError('Enter valid email');

        }
        else {
            setEmailError('');
        }

        if (contact.length <= 4) {
            validate = true
            setContactError(t('contactNoError'));
        } else {
            setContactError('');
        }




        if (role == '') {
            validate = true
            setRoleError(t('roleError'));
        } else {
            setRoleError('');
        }



        if (userOfflineData?.userType == 'create') {
            if (password == '') {
                validate = true
                setPasswordError(t('passwordError'));
            } else if (password.length < 8) {
                setPasswordError('password must be at least 8 characters');
            } else {
                setPasswordError('');
            }
        }

        if (userOfflineData?.userType == 'create') {
            if (confirmPassword == '') {
                validate = true
                setconfirmPasswordError(t('confirmPasswordError'));
            } else if (confirmPassword != password) {
                validate = true
                setconfirmPasswordError('Password and confirm password does not match');
            }
            else {
                setconfirmPasswordError('');
            }
        }

        if (validate == true) {
            // Find the topmost mandatory field that is not filled
            let topmostFieldRef;

            if (!field1Ref.current.value) {
                topmostFieldRef = field1Ref;
            }
            else if (!field2Ref.current.value) {
                topmostFieldRef = field2Ref;
            }
            else if (!(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(field2Ref.current.value))) {

                topmostFieldRef = field2Ref;

            }
          

            else if (!role) {
                topmostFieldRef = field5Ref;
            }
            else if (!selectLanguage) {
                topmostFieldRef = field6Ref;
            }
            else if (!field7Ref.current.value) {
                topmostFieldRef = field7Ref;
            }
            else if (field7Ref.current.value < 8) {
                topmostFieldRef = field7Ref;
            }
            else if (!field8Ref.current.value) {
                topmostFieldRef = field8Ref;
            }
            else if (field7Ref.current.value != field8Ref.current.value) {
                topmostFieldRef = field8Ref;

            }

            if (topmostFieldRef && topmostFieldRef.current) {
                scroller.scrollTo(topmostFieldRef.current.id, {
                    smooth: true,
                    offset: -100, // Adjust as needed
                });
            }
            if (field7Ref.current.value < 8) {
                scroller.scrollTo(topmostFieldRef.current.id, {
                    smooth: true,
                    offset: -100, // Adjust as needed
                });
            }
            if (field7Ref.current.value != field8Ref.current.value) {
                scroller.scrollTo(topmostFieldRef.current.id, {
                    smooth: true,
                    offset: -100, // Adjust as needed
                });
            }

            return;
        }

        if (validate == false) {

            setLoading(true)
            if (userOfflineData?.userType == 'create' && userMode?.mode == 0) {

                const params =
                {

                    "FirstName": firstName == '' ? null : firstName,
                    "Lastname": lastName == '' ? null : lastName,
                    "Email": email == '' ? null : email,
                    "ContactNo": contact === "" ? null : (contact.startsWith("+") ? contact : `+${contact}`),                    
                    "LandlineNo": landline == '' ? null : landline,
                    "WhatsappNo": whatsAppNo == '' ? null : whatsAppNo,
                    "ExperinceSince": expSince == '' ? null : expSince,
                    "Nationality": nationality == '' ? null : nationality,
                    "Language": selectLanguage == '' ? null : selectLanguage,
                    "Position": position == '' ? null : position,
                    "GreenEmerlandLanguage": pfExp == '' ? null : pfExp,
                    "LinkedInUrl": linkedinUrl == '' ? null : linkedinUrl,
                    "Password": password == '' ? null : password,
                    "SpokenLanguages": spokenLanguage == '' ? null : spokenLanguage,
                    "Description": description == '' ? null : description,
                    "RoleId": role == '' ? null : role

                }

                dispatch(createUserApi(token, params))
                    .then(() => {
                        dispatch(userModeApi(1))
                        dispatch(userDetailDataApi('create', userOfflineData?.userType == 'create' ? createUserData?.UserId : getByIdUserData?.Id))
                        const params = {
                            "pageNumber": 1,
                            "UserId": userOfflineData?.userType == 'create' ? createUserData?.UserId : getByIdUserData?.Id
                        }
                        dispatch(userDocumentListApi(token, params))
                            .then((response) => {

                                const params = {
                                    pagenumber: agentCreditListPage,
                                    agentId: userOfflineData?.userType == 'create' ? createUserData?.UserId : getByIdUserData?.Id
                                }
                                dispatch(agentCreditListApi(token, params))
                                toast.success("Record Saved Successfully", {
                                    position: 'top-right',
                                    autoClose: 1000, // Adjust the duration as needed
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                });
                                setLoading(false)
                                setValue('two')

                            })
                            .catch(error => {
                                setLoading(false)
                            })

                    })
                    .catch(error => {
                        toast.error(error, {
                            position: 'top-right',
                            autoClose: 1000, // Adjust the duration as needed
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                        setLoading(false)
                    })
            } else {

                const params =

                {
                    "Id": userOfflineData?.userType == 'create' ? createUserData?.UserId : getByIdUserData?.Id,
                    "FirstName": firstName == '' ? null : firstName,
                    "Lastname": lastName == '' ? null : lastName,
                    "Email": email == '' ? null : email,
                    "ContactNo": contact === "" ? null : (contact.startsWith("+") ? contact : `+${contact}`),
                    "LandlineNo": landline == '' ? null : landline,
                    "WhatsappNo": whatsAppNo == '' ? null : whatsAppNo,
                    "ExperinceSince": expSince == '' ? null : expSince,
                    "Nationality": nationality == '' ? null : nationality,
                    "Language": selectLanguage == '' ? null : selectLanguage,
                    "Position": position == '' ? null : position,
                    "GreenEmerlandLanguage": pfExp == '' ? null : pfExp,
                    "LinkedInUrl": linkedinUrl == '' ? null : linkedinUrl,
                    "SpokenLanguages": spokenLanguage == '' ? null : spokenLanguage,
                    "Description": description == '' ? null : description,
                    "RoleId": role == '' ? null : role

                }

                dispatch(updateUserApi(token, params))
                    .then(() => {
                        const params = {
                            "pageNumber": 1,
                            "UserId": userOfflineData?.userType == 'create' ? createUserData?.UserId : getByIdUserData?.Id
                        }
                        dispatch(userDocumentListApi(token, params))
                            .then((response) => {
                                toast.success("Record Saved Successfully", {
                                    position: 'top-right',
                                    autoClose: 1000, // Adjust the duration as needed
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                });
                                setLoading(false)
                                setValue('two')

                            })

                            .catch(error => {
                                setLoading(false)
                            })

                    })
                    .catch(error => {
                        setLoading(false)
                    })
            }


        }

    }

    //ONCLICK SAVE BUTTON USER CREATE AND UPDATE
    const userDetailAPI = () => {
        let validate = false
        if (firstName == '') {
            validate = true
            setFirstNameError(t('firstNameError'));
        } else {
            setFirstNameError('');
        }


        if (email == '') {
            validate = true
            setEmailError(t('emailError'));
        } else if (!(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email))) {
            validate = true
            setEmailError('Enter valid email');

        }
        else {
            setEmailError('');
        }

        if (contact.length <= 4) {
            validate = true
            setContactError(t('contactNoError'));
        } else {
            setContactError('');
        }




        if (role == '') {
            validate = true
            setRoleError(t('roleError'));
        } else {
            setRoleError('');
        }


        if (userOfflineData?.userType == 'create') {
            if (password == '') {
                validate = true
                setPasswordError(t('passwordError'));
            } else if (password.length < 8) {
                setPasswordError('password must be at least 8 characters');
            } else {
                setPasswordError('');
            }
        }

        if (userOfflineData?.userType == 'create') {
            if (confirmPassword == '') {
                validate = true
                setconfirmPasswordError(t('confirmPasswordError'));
            } else if (confirmPassword != password) {
                validate = true
                setconfirmPasswordError('Password and confirm password does not match');
            }
            else {
                setconfirmPasswordError('');
            }
        }


        if (validate == true) {
            // Find the topmost mandatory field that is not filled
            let topmostFieldRef;

            if (!field1Ref.current.value) {
                topmostFieldRef = field1Ref;
            }
            else if (!field2Ref.current.value) {
                topmostFieldRef = field2Ref;
            }
            else if (!(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(field2Ref.current.value))) {

                topmostFieldRef = field2Ref;

            }

            else if (!role) {
                topmostFieldRef = field5Ref;
            }
            else if (!selectLanguage) {
                topmostFieldRef = field6Ref;
            }
            else if (!field7Ref.current.value) {
                topmostFieldRef = field7Ref;
            }
            else if (field7Ref.current.value < 8) {
                topmostFieldRef = field7Ref;
            }
            else if (!field8Ref.current.value) {
                topmostFieldRef = field8Ref;
            }
            else if (field7Ref.current.value != field8Ref.current.value) {
                topmostFieldRef = field8Ref;

            }

            if (topmostFieldRef && topmostFieldRef.current) {
                scroller.scrollTo(topmostFieldRef.current.id, {
                    smooth: true,
                    offset: -100,
                });
            }
            if (field7Ref.current.value < 8) {
                scroller.scrollTo(topmostFieldRef.current.id, {
                    smooth: true,
                    offset: -100,
                });
            }
            if (field7Ref.current.value != field8Ref.current.value) {
                scroller.scrollTo(topmostFieldRef.current.id, {
                    smooth: true,
                    offset: -100,
                });
            }

            return;
        }

        if (validate == false) {

            if (userOfflineData?.userType == 'create' && userMode?.mode == 0) {

                const params =
                {

                    "FirstName": firstName == '' ? null : firstName,
                    "Lastname": lastName == '' ? null : lastName,
                    "Email": email == '' ? null : email,
                    "ContactNo": contact === "" ? null : (contact.startsWith("+") ? contact : `+${contact}`),
                    "LandlineNo": landline == '' ? null : landline,
                    "WhatsappNo": whatsAppNo == '' ? null : whatsAppNo,
                    "ExperinceSince": expSince == '' ? null : expSince,
                    "Nationality": nationality == '' ? null : nationality,
                    "Language": selectLanguage == '' ? null : selectLanguage,
                    "Position": position == '' ? null : position,
                    "GreenEmerlandLanguage": pfExp == '' ? null : pfExp,
                    "LinkedInUrl": linkedinUrl == '' ? null : linkedinUrl,
                    "Password": password == '' ? null : password,
                    "SpokenLanguages": spokenLanguage == '' ? null : spokenLanguage,
                    "Description": description == '' ? null : description,
                    "RoleId": role == '' ? null : role

                }

                dispatch(createUserApi(token, params))
                    .then((response) => {
                        toast.success("Record Saved Successfully", {
                            position: 'top-right',
                            autoClose: 1000, // Adjust the duration as needed
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                        setTimeout(() => {
                            setLoading(false)
                            navigate('/UserList')
                        }, 1000);

                    })
                    .catch(error => {
                        toast.error(error, {
                            position: 'top-right',
                            autoClose: 1000, // Adjust the duration as needed
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                        setLoading(false)
                    })

            } else {

                const params =

                {
                    "Id": userOfflineData?.userType == 'create' ? createUserData?.UserId : getByIdUserData?.Id,
                    "FirstName": firstName == '' ? null : firstName,
                    "Lastname": lastName == '' ? null : lastName,
                    "Email": email == '' ? null : email,
                    "ContactNo": contact === "" ? null : (contact.startsWith("+") ? contact : `+${contact}`),
                    "LandlineNo": landline == '' ? null : landline,
                    "WhatsappNo": whatsAppNo == '' ? null : whatsAppNo,
                    "ExperinceSince": expSince == '' ? null : expSince,
                    "Nationality": nationality == '' ? null : nationality,
                    "Language": selectLanguage == '' ? null : selectLanguage,
                    "Position": position == '' ? null : position,
                    "GreenEmerlandLanguage": pfExp == '' ? null : pfExp,
                    "LinkedInUrl": linkedinUrl == '' ? null : linkedinUrl,
                    "SpokenLanguages": spokenLanguage == '' ? null : spokenLanguage,
                    "Description": description == '' ? null : description,
                    "RoleId": role == '' ? null : role

                }

                dispatch(updateUserApi(token, params))
                    .then((response) => {
                        toast.success("Record Saved Successfully", {
                            position: 'top-right',
                            autoClose: 1000, // Adjust the duration as needed
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                        setTimeout(() => {
                            setLoading(false)
                            navigate('/UserList')
                        }, 1000);

                    })
                    .catch(error => {
                        setLoading(false)
                    })
            }


        }

    }

    const openCloseUserAccordingList = () => {
        setUserAccordingList(!useraccordingList)
    }


    const handleChanges = (event, newValue) => { setValue(newValue); };


    const handleClickOpen = (id) => {
        setSelectedDocumentId(id)
        setOpen(true);
    };

    //DELETE USER DOCUMENT
    const deleteUserDocument = () => {


        dispatch(deleteUserDocumentsApi(token, selectedDocumentId))
            .then(() => {
                setPage(1)
                const params = {
                    "pageNumber": 1,
                    "UserId": userOfflineData?.userType == 'create' ? createUserData?.UserId : getByIdUserData?.Id
                }
                dispatch(userDocumentListApi(token, params))
                    .then(() => {
                        setOpen(false)

                    })
                    .catch(error => {
                        setOpen(false)
                    })
            })
            .catch(error => {

            })

    }
    const handleClose = () => {
        setOpen(false);

    };

    const onClickBack = () => {
        setLoading(true)
        dispatch(userDetailDataApi('edit', ''))

        dispatch(getByIdUserApi(token, userOfflineData?.userType == 'create' ? createUserData?.UserId : getByIdUserData?.Id))
            .then((response) => {
                setLoading(false)
                setValue('one')
            })
            .catch((error) => {
                setLoading(false)

            });

    }

    //FILE DOWNLOAD FUNCTION
    const handleDownload = (item) => {
        // Specify the URL of the file you want to download
        const fileUrl = item?.FileUrl;

        // Specify the desired filename
        const fileName = item?.FileName;

        // Construct the full URL with the filename
        const urlWithFileName = `${config.baseURL}${fileUrl}?filename=${fileName}`;

        // Open a new tab and set its location to the constructed URL
        window.open(urlWithFileName, '_blank');

    };

    const handleWithdeawClose = () => {
        setWithdrawModalPoppup(false)
    }

    const handleWithdeawOpen = () => {
        setWithdrawModalPoppup(true)
    }

    const handleAddCreditsClose = () => {
        setAddCreditsModalPoppup(false)
    }

    const handleAddCreditsOpen = () => {
        setAddCreditsModalPoppup(true)
    }

    const onClickPublishRequestApprove = (id) => {
        setLoading(true)
        let isApproved = true
        dispatch(publishRequestApproveRejectApi(token, id, isApproved))
            .then((response) => {
                setLoading(false)
                getPendingRequestsListApiCall()
            })
            .catch((error) => {


            });
    }
    const onClickPublishRequestReject = (id) => {
        let isApproved = false
        setLoading(true)
        dispatch(publishRequestApproveRejectApi(token, id, isApproved))
            .then((response) => {
                setLoading(false)

                getPendingRequestsListApiCall()
            })
            .catch((error) => {


            });
    }
    //ADD CREDIT API CALL
    const onClickCredit = () => {
        let validate = false
        if (credit == '') {
            validate = true
            setcreditError(t('pleaseEnterAddNoredits'));
        } else {
            setcreditError('');
        }

        if (descriptionCredits.trim().length == 0) {
            validate = true
            setDescriptionCreditsError(t('pleaseEnterDecription'));
        } else {
            setDescriptionCreditsError('');
        }

        if (validate == false) {
            setLoading(true)
            const params = {
                AgentId: userOfflineData?.userType == 'create' ? createUserData?.UserId : getByIdUserData?.Id,
                Credits: credit,
                Description: descriptionCredits,
            }

            dispatch(addSubAgentCreditApi(token, params))
                .then((response) => {
                    handleAddCreditsClose()
                    getCreditBalanceApiCall()
                    getCompanyCreditBalanceApiCall()
                    setCredit('')
                    setDescriptionCredits('')
                    setLoading(false)

                    if (response.Data?.message == "Credit Added Successfully")
                        toast.success(response?.Data?.message, {
                            position: 'top-right',
                            autoClose: 1000, // Adjust the duration as needed
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                    else if (response.Data?.message == "Compmay Admin has Insufficient Credits") {
                        toast.error(response?.Data?.message, {
                            position: 'top-right',
                            autoClose: 1000, // Adjust the duration as needed
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                    }

                    const params = {
                        pagenumber: agentCreditListPage,
                        agentId: userOfflineData?.userType == 'create' ? createUserData?.UserId : getByIdUserData?.Id
                    }
                    dispatch(agentCreditListApi(token, params))
                   


                })
                .catch((error) => {
                    setLoading(false)
                });
        }
    }

    const onClickWithdrawCredit = () => {
        let validate = false
        if (creditWithdraw == '') {
            validate = true
            setCreditWithdrawError(t('pleaseEnterAddNoredits'));
        } else {
            setCreditWithdrawError('');
        }



        if (validate == false) {
            setLoading(true)
            const params = {
                AgentId: userOfflineData?.userType == 'create' ? createUserData?.UserId : getByIdUserData?.Id,
                Credits: creditWithdraw,
            }

            dispatch(withdrawSubAgentCreditApi(token, params))
                .then((response) => {
                    getCreditBalanceApiCall()
                    getCompanyCreditBalanceApiCall()

                    setCreditWithdraw('')
                    setLoading(false)
                    handleWithdeawClose()
                    if (response.Data?.message == "Credit Revoked Successfully")
                        toast.success(response?.Data?.message, {
                            position: 'top-right',
                            autoClose: 1000, // Adjust the duration as needed
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                    else if (response.Data?.message == "Company Agent has Insufficient Credits") {
                        toast.error(response?.Data?.message, {
                            position: 'top-right',
                            autoClose: 1000, // Adjust the duration as needed
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                    }
                    


                })
                .catch((error) => {
                    setLoading(false)
                });
        }
    }

    // AGENT CREDIT LIST API CALL
    useEffect(() => {

        const params = {
            pagenumber: agentCreditListPage,
            agentId: userOfflineData?.userType == 'create' ? createUserData?.UserId : getByIdUserData?.Id
        }
        dispatch(agentCreditListApi(token, params))
    }, [agentCreditListPage, value])


    return (
        <div className="App">
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}

            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                className='dialog_box_popup'
            >

                <DialogContent class="dialog_box_block">
                    <DialogContentText id="alert-dialog-slide-description" class="dialog_box_title">
                        <h6>Delete</h6>
                        <Button onClick={handleClose}><img src={closeModal} /></Button>
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-slide-description" class="dialog_box_detail">
                        Are you sure you want to delete this document ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions class="dialog_box_button">
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={() => deleteUserDocument()}>Delete</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={withdrawModalPoppup}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleWithdeawClose}
                aria-describedby="alert-dialog-slide-description"
                className='dialog_box_popup'
            >

                <DialogContent class="dialog_box_block">
                    <DialogContentText id="alert-dialog-slide-description" class="dialog_box_title">
                        <h6>Revoke</h6>
                        <Button onClick={handleWithdeawClose}><img src={closeModal} /></Button>
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-slide-description" class="dialog_box_detail">

                        <div class="form-group" >
                            <div class="form-line" >
                                <input type="text" style={{ width: 400, border: '1px solid #ccc', borderBottom: 'none', padding: '16px 20px', fontSize: '14px', borderRadius: '7px', color: '#555', height: 50 }} class="form-control" placeholder={t('totalNoofCredits')} maxLength={maxLength} value={creditWithdraw} onChange={(e) => setCreditWithdraw(e.target.value)} onKeyPress={handleKeyPress} />
                                {
                                    creditWithdrawError && (
                                        <span class="validation_notes">{creditWithdrawError}</span>
                                    )
                                }
                            </div>
                        </div>

                    </DialogContentText>
                </DialogContent>
                <DialogActions class="dialog_box_button">
                    <Button onClick={handleWithdeawClose}>Cancel</Button>
                    <Button onClick={() => onClickWithdrawCredit()}>Revoke</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={addCreditsModalPoppup}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleAddCreditsClose}
                aria-describedby="alert-dialog-slide-description"
                className='dialog_box_popup'
            >

                <DialogContent class="dialog_box_block">
                    <DialogContentText id="alert-dialog-slide-description" class="dialog_box_title">
                        <h6>Add</h6>
                        <Button onClick={handleAddCreditsClose}><img src={closeModal} /></Button>
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-slide-description" class="dialog_box_detail">

                        <div class="form-group" >
                            <div class="form-line" >
                                <input type="text" style={{ width: 400, border: '1px solid #ccc', borderBottom: 'none', padding: '16px 20px', fontSize: '14px', borderRadius: '7px', color: '#555', height: 50 }} class="form-control" placeholder={t('totalNoofCredits')} maxLength={maxLength} value={credit} onChange={(e) => setCredit(e.target.value)} onKeyPress={handleKeyPress} />
                                {
                                    creditError && (
                                        <span class="validation_notes">{creditError}</span>
                                    )
                                }
                            </div>

                        </div>
                        <div class="form-group" >

                            <div class="form-line" >
                                <input type="text" style={{ width: 400, border: '1px solid #ccc', borderBottom: 'none', padding: '16px 20px', fontSize: '14px', borderRadius: '7px', color: '#555', height: 50 }} class="form-control" placeholder={t('description')} value={descriptionCredits} onChange={(e) => { setDescriptionCredits(e.target.value); validateDec(e.target.value) }} />
                                {
                                    descriptionCreditsError && (
                                        <span class="validation_notes">{descriptionCreditsError}</span>
                                    )
                                }
                            </div>
                        </div>

                    </DialogContentText>
                </DialogContent>
                <DialogActions class="dialog_box_button">
                    <Button onClick={handleAddCreditsClose}>Cancel</Button>
                    <Button onClick={() => onClickCredit()}>Add</Button>
                </DialogActions>
            </Dialog>

            <div class="overlay"></div>

            <Topbar />

            <Sidebar message={'User'} />

            <section class="content">
                <div id="fieldDefaultTop" ref={fieldDefaultRefTop} class="block-header">
                    <h2>Agent Details</h2>

                </div>

                <div class="row clearfix">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="tab_block">
                            <Tabs
                                value={value}
                                onChange={handleChanges}
                                className='tab-nav-right'
                                aria-label="wrapped label tabs example"
                            >
                                <Tab className='tabs_title' value="one" label={'Agent Details'} wrapped />
                                <Tab className='tabs_title' value="two" label={t('documents')} disabled={userMode?.mode == 0 ? true : false} />
                                <Tab className='tabs_title' value="three" label={t('noCredits')} disabled={userMode?.mode == 0 ? true : false} />
                                <Tab className='tabs_title' value="four" label={<div style={{ display: 'flex', alignItems: 'center' }}>
                                    <span>Pending Publish Approval Requests</span>
                                    <CustomBadge content={(userMode?.mode == 0 || pendingRequestsList?.length == null || pendingRequestsList?.length == undefined || pendingRequestsList?.length == 0) ? 0 : pendingRequestsList?.length} color="#5EAC18" fontSize="1rem" size="1.8rem" style={{ marginLeft: '0.5rem' }} />
                                </div>} disabled={userMode?.mode == 0 ? true : false} />

                            </Tabs>
                        </div>

                        <div class="tab_content_block">

                            {
                                value == 'one' ?
                                    <>
                                        <div class="panel-group full-body" id="accordion_3" role="tablist" aria-multiselectable="true">
                                            <div class="panel">
                                                <div class="panel-heading" role="tab" id="headingOne_3">
                                                    <h4 class="panel-title">
                                                        <a href="javascript:void(0)" role="button" onClick={() => openCloseUserAccordingList()} data-toggle="collapse" aria-expanded="true" aria-controls="collapseOne_3">
                                                            <span>Agent Information</span>
                                                            <i className={useraccordingList ? 'openIcon' : 'closeIcon'}></i>
                                                        </a>
                                                    </h4>
                                                </div>
                                                {useraccordingList ?
                                                    <div id="collapseOne_3" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne_3">
                                                        <div class="panel-body">
                                                            <div class="card">
                                                                <div className='form_block'>
                                                                    <div class="row clearfix">
                                                                        <div class="col-sm-6">
                                                                            <div class="form-group">
                                                                                <div class="form-line">
                                                                                    <input type="text" class="form-control" title={t('firstName')} placeholder={t('firstName') + '*'} value={firstName} ref={field1Ref} id="field1" onChange={(e) => { setFirstName(e.target.value); validateFirstName(e.target.value) }} />
                                                                                    {
                                                                                        firstNameError && (
                                                                                            <span class="validation_notes">{firstNameError}</span>
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-6">
                                                                            <div class="form-group">
                                                                                <div class="form-line">
                                                                                    <input type="text" class="form-control" title={t('lastName')} placeholder={t('lastName')} value={lastName} onChange={(e) => { setLastName(e.target.value); }} />

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row clearfix">
                                                                        <div class="col-sm-6">
                                                                            <div class="form-group">
                                                                                <div class="form-line">
                                                                                    <input type="text" disabled={userOfflineData?.userType == 'create' && userMode?.mode == 0 ? false : true} class="form-control" title={t('email')} placeholder={t('email') + '*'} value={email} ref={field2Ref} id="field2" onChange={(e) => { setEmail(e.target.value); validateEmail(e.target.value) }} />
                                                                                    {
                                                                                        emailError && (
                                                                                            <span class="validation_notes">{emailError}</span>
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-6">
                                                                            <div class="form-group phone-input">
                                                                                <Tooltip title={'Contact No.'} arrow className="tooltip_notes">
                                                                                    <PhoneInput
                                                                                        placeholder='Contact No.'
                                                                                        country={"bh"}
                                                                                        enableSearch={true}
                                                                                        value={contact}
                                                                                        onChange={(e) => { setContact(e); validateContact(e) }}
                                                                                    />
                                                                                    {
                                                                                        contactError && (
                                                                                            <span class="validation_notes">{contactError}</span>
                                                                                        )
                                                                                    }
                                                                                </Tooltip>
                                                                            </div>
                                                                           
                                                                        </div>
                                                                    </div>
                                                                    <div class="row clearfix">
                                                                        <div class="col-sm-6">
                                                                            <div class="form-group">
                                                                                <div class="form-line">
                                                                                    <input type="text" class="form-control" title={t('landlineNo')} placeholder={t('landlineNo')} value={landline} maxLength={15} onKeyPress={handleKeyPress} ref={field4Ref} id="field4" onChange={(e) => { setLandline(e.target.value); }} />
                                                                                    {
                                                                                        landlineError && (
                                                                                            <span class="validation_notes">{landlineError}</span>
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-6">
                                                                            <div class="form-group">
                                                                                <div class="form-line">
                                                                                    <input type="text" class="form-control" title={t('whatsappNo')} placeholder={t('whatsappNo')} value={whatsAppNo} maxLength={15} onKeyPress={handleKeyPressPhno} onChange={(e) => setwhatsAppNo(e.target.value)} />

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row clearfix">
                                                                        <div class="col-sm-6">
                                                                            <div>
                                                                                <FormControl className='form-group' sx={{}}>
                                                                                    <Select
                                                                                        className='form-control border_temp'
                                                                                        sx={{ borderColor: 'transparent' }}
                                                                                        displayEmpty
                                                                                        value={expSince}
                                                                                        onChange={(e) => { setExpSince(e.target.value); }}
                                                                                        input={<OutlinedInput />}
                                                                                        title={t('expSincePlaceHolder')}
                                                                                        MenuProps={{
                                                                                            PaperProps: {
                                                                                                style: {
                                                                                                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                                                    width: 250,
                                                                                                },
                                                                                            },
                                                                                            disableScrollLock: true,
                                                                                        }}
                                                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                                                    >
                                                                                        <MenuItem className='drop_menus' value="">
                                                                                            <em className='select_text'>{t('expSincePlaceHolder')}</em>
                                                                                        </MenuItem>
                                                                                        {yearsArray?.map((item) => (
                                                                                            <MenuItem
                                                                                                className='drop_menus'
                                                                                                key={item?.Id}
                                                                                                value={item?.Id}
                                                                                                style={getStyles(item?.Name, personName, theme)}
                                                                                            >
                                                                                                {item?.Name}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </Select>

                                                                                </FormControl>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-6">
                                                                            <div>
                                                                                <FormControl className='form-group' sx={{}}>
                                                                                    <Select
                                                                                        className='form-control border_temp'
                                                                                        sx={{ borderColor: 'transparent' }}
                                                                                        title={t('nationality')}
                                                                                        displayEmpty
                                                                                        value={nationality}
                                                                                        onChange={(e) => { setNationality(e.target.value); }}
                                                                                        input={<OutlinedInput />}

                                                                                        MenuProps={{
                                                                                            PaperProps: {
                                                                                                style: {
                                                                                                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                                                    width: 250,
                                                                                                },
                                                                                            },
                                                                                            disableScrollLock: true,
                                                                                        }}
                                                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                                                    >
                                                                                        <MenuItem className='drop_menus' value="">
                                                                                            <em className='select_text'>{t('nationality')}</em>
                                                                                        </MenuItem>
                                                                                        {userDropdownData?.Countries?.map((item) => (
                                                                                            <MenuItem
                                                                                                className='drop_menus'
                                                                                                key={item?.Id}
                                                                                                value={item?.Id}
                                                                                                style={getStyles(item?.Name, personName, theme)}
                                                                                            >
                                                                                                {item?.Name}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </Select>

                                                                                </FormControl>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row clearfix">
                                                                        <div class="col-sm-6" ref={field5Ref} id="field5">
                                                                            <div>
                                                                                <FormControl className='form-group' sx={{}}>
                                                                                    <Select
                                                                                        className='form-control border_temp'
                                                                                        sx={{ borderColor: 'transparent' }}
                                                                                        displayEmpty
                                                                                        title={t('role')}
                                                                                        value={role}
                                                                                        onChange={(e) => { setRole(e.target.value); validateRole(e.target.value) }}
                                                                                        input={<OutlinedInput />}
                                                                                        MenuProps={{
                                                                                            PaperProps: {
                                                                                                style: {
                                                                                                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                                                    width: 250,
                                                                                                },
                                                                                            },
                                                                                            disableScrollLock: true,
                                                                                        }}
                                                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                                                    >
                                                                                        <MenuItem className='drop_menus' value="">
                                                                                            <em className='select_text'>{t('role') + '*'}</em>
                                                                                        </MenuItem>
                                                                                        {userDropdownData?.Roles?.map((item) => (
                                                                                            <MenuItem
                                                                                                className='drop_menus'
                                                                                                key={item?.Id}
                                                                                                value={item?.Id}
                                                                                                style={getStyles(item?.Name, personName, theme)}
                                                                                            >
                                                                                                {item?.Name}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </Select>
                                                                                    {
                                                                                        roleError && (
                                                                                            <span class="validation_notes">{roleError}</span>
                                                                                        )
                                                                                    }
                                                                                </FormControl>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-6" ref={field6Ref} id="field6">
                                                                            <div>
                                                                                <FormControl className='form-group' sx={{}}>
                                                                                    <Select
                                                                                        className='form-control border_temp'
                                                                                        sx={{ borderColor: 'transparent' }}
                                                                                        title={t('languagePlaceHolder')}
                                                                                        displayEmpty
                                                                                        value={selectLanguage}
                                                                                        onChange={(e) => { setSelectLanguage(e.target.value); }}
                                                                                        input={<OutlinedInput />}

                                                                                        MenuProps={{
                                                                                            PaperProps: {
                                                                                                style: {
                                                                                                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                                                    width: 250,
                                                                                                },
                                                                                            },
                                                                                            disableScrollLock: true,
                                                                                        }}
                                                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                                                    >
                                                                                        <MenuItem className='drop_menus' value="">
                                                                                            <em className='select_text'>{t('languagePlaceHolder')}</em>
                                                                                        </MenuItem>
                                                                                        {userDropdownData?.Languages?.map((item) => (
                                                                                            <MenuItem
                                                                                                className='drop_menus'
                                                                                                key={item?.Id}
                                                                                                value={item?.Id}
                                                                                                style={getStyles(item?.Name, personName, theme)}
                                                                                            >
                                                                                                {item?.Name}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </Select>
                                                                                    {
                                                                                        selectLanguageError && (
                                                                                            <span class="validation_notes">{selectLanguageError}</span>
                                                                                        )
                                                                                    }
                                                                                </FormControl>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row clearfix">
                                                                        <div class="col-sm-6">
                                                                            <div class="form-group">
                                                                                <div class="form-line">
                                                                                    <input type="text" class="form-control" title={t('position')} placeholder={t('position')} value={position} onChange={(e) => { setPosition(e.target.value); }} />

                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div class="col-sm-6">
                                                                            <div>
                                                                                <FormControl className='form-group' sx={{}}>
                                                                                    <Select
                                                                                        className='form-control border_temp'
                                                                                        sx={{ borderColor: 'transparent' }}
                                                                                        displayEmpty
                                                                                        value={pfExp}
                                                                                        title={t('pfExpertLanguage')}
                                                                                        onChange={(e) => { setpfExp(e.target.value); }}
                                                                                        input={<OutlinedInput />}
                                                                                        MenuProps={{
                                                                                            PaperProps: {
                                                                                                style: {
                                                                                                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                                                    width: 250,
                                                                                                },
                                                                                            },
                                                                                            disableScrollLock: true,
                                                                                        }}
                                                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                                                    >
                                                                                        <MenuItem className='drop_menus' value="">
                                                                                            <em className='select_text'>{t('pfExpertLanguage')}</em>
                                                                                        </MenuItem>
                                                                                        {userDropdownData?.Languages?.map((item) => (
                                                                                            <MenuItem
                                                                                                className='drop_menus'
                                                                                                key={item?.Id}
                                                                                                value={item?.Id}
                                                                                                style={getStyles(item?.Name, personName, theme)}
                                                                                            >
                                                                                                {item?.Name}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </Select>

                                                                                </FormControl>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row clearfix">
                                                                        <div class="col-sm-6">
                                                                            <div class="form-group">
                                                                                <div class="form-line">
                                                                                    <input type="text" class="form-control" title={t('linkedinUrl')} placeholder={t('linkedinUrl')} value={linkedinUrl} onChange={(e) => { setLinkedinUrl(e.target.value); }} />

                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div class="col-sm-6">
                                                                            <div class="form-group">
                                                                                <div class="form-line">
                                                                                    <input type="text" class="form-control" title={t('spokenLanguages')} placeholder={t('spokenLanguages')} value={spokenLanguage} onChange={(e) => { setSpokenLanguage(e.target.value); }} />

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        userOfflineData?.userType == 'create' && userMode?.mode == 0 && (
                                                                            <div class="row clearfix">
                                                                                <div class="col-sm-6">
                                                                                    <div class="form-group icon_field">
                                                                                        <div class="form-line">
                                                                                            <input type={passwordShow ? 'text' : 'password'} ref={field7Ref} id="field7" class="form-control" disabled={userOfflineData?.userType == 'create' ? false : true} placeholder={t('password') + '*'} title={t('password')} value={password} onChange={(e) => { setPassword(e.target.value); validatePassword(e.target.value) }} />
                                                                                            {
                                                                                                passwordError && (
                                                                                                    <span class="validation_notes">{passwordError}</span>
                                                                                                )
                                                                                            }
                                                                                            <span class="eye_icon">
                                                                                                <a onClick={() => setpasswordShow(!passwordShow)}>
                                                                                                    {
                                                                                                        passwordShow ?
                                                                                                            <span class="material-icons">visibility</span>
                                                                                                            :
                                                                                                            <span class="material-icons">visibility_off</span>
                                                                                                    }
                                                                                                </a>
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-6">
                                                                                    <div class="form-group icon_field">
                                                                                        <div class="form-line">
                                                                                            <input type={confirmPasswordShow ? 'text' : 'password'} ref={field8Ref} id="field8" class="form-control" disabled={userOfflineData?.userType == 'create' ? false : true} title={t('confirmPassword')} placeholder={t('confirmPassword') + '*'} value={confirmPassword} onChange={(e) => { setConfirmPassword(e.target.value); validateConfirmPassword(e.target.value) }} />
                                                                                            {
                                                                                                confirmPasswordError && (
                                                                                                    <span class="validation_notes">{confirmPasswordError}</span>
                                                                                                )
                                                                                            }
                                                                                            <span class="eye_icon">
                                                                                                <a onClick={() => setconfirmPasswordShow(!confirmPasswordShow)}>
                                                                                                    {
                                                                                                        confirmPasswordShow ?
                                                                                                            <span class="material-icons">visibility</span>
                                                                                                            :
                                                                                                            <span class="material-icons">visibility_off</span>
                                                                                                    }
                                                                                                </a>
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }

                                                                    <div class="row clearfix">

                                                                        <div class="col-sm-12">
                                                                            <div class="form-group">
                                                                                <div class="form-line">
                                                                                    <textarea rows="4" class="form-control no-resize" title={t('description')} placeholder={t('description')} value={description} onChange={(e) => { setDescription(e.target.value); }}></textarea>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="button_block">
                                                                        <a href="#" class="links" onClick={() => userDetailAPI()}>{t('save')}</a>
                                                                        <a href="#" class="links" onClick={() => userDetailNextAPI()}>{t('saveAndNext')}</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> : null
                                                }
                                            </div>
                                        </div>
                                    </> : null
                            }
                            {
                                value == 'two' ?
                                    <>
                                        <div class="main_class">
                                            <div class="file-drop-area">
                                                <img src={folder} />
                                                <span class="file-message">{t('dragAndDrop')}</span>
                                                <span class="choose-file-button">{t('uploadADocument')}</span>
                                                <input class="file-input" type="file" value={document} onChange={handleFileChange} />

                                            </div>
                                        </div>
                                        <div class="main_class">
                                            <div class="table_block">
                                                <div class="table-responsive">
                                                    {
                                                        userDocumentListData?.Data?.length != 0 ?
                                                            <table class="table table-bordered table-striped table-hover dataTable custom">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Sr. No.</th>
                                                                        <th>{t('name')}</th>
                                                                        <th>{t('contact')}</th>
                                                                        <th>{t('fileName')}</th>
                                                                        <th></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        userDocumentListData?.Data?.map((item) => {
                                                                            return (
                                                                                <tr>
                                                                                    <td>
                                                                                        <p>{item?.RowNum}</p>
                                                                                    </td>
                                                                                    <td>
                                                                                        <p>{item?.Name}</p>
                                                                                    </td>
                                                                                    <td>
                                                                                        <p>{item?.Phone}</p>
                                                                                    </td>
                                                                                    <td>
                                                                                        <a onClick={() => handleDownload(item)}>
                                                                                            <p class='blue_text'>{item?.FileName}</p>
                                                                                        </a>

                                                                                    </td>
                                                                                    <td>
                                                                                        <div class="table_detail">
                                                                                            <a href="#" class="link red" onClick={() => handleClickOpen(item?.Id)}><img src={deleteIcon} /></a>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }


                                                                </tbody>
                                                            </table>
                                                            : <h1 class="text-center">No Data Found</h1>
                                                    }

                                                </div>
                                                <div class="text-right">
                                                    <Pagination className="pagination" count={userDocumentListData?.TotalPages} page={page} onChange={pageHandleChange} />
                                                </div>
                                            </div>
                                            <div class="button_block">
                                                <a href="#" class="links" onClick={() => onClickBack()} >{t('back')}</a>
                                                <a href="#" class="links" onClick={(e) => setValue('three')}>{'Next'}</a>
                                            </div>
                                        </div>
                                    </> : null
                            }
                            {
                                value == 'three' ?
                                    <>

                                        <div className='main_class'>
                                            <div class="credit_balance_block" style={{ marginBottom: 0, display: 'flex', justifyContent: 'space-between', gap: 'rem' }}>
                                                <div>
                                                    <div class="balance_block" style={{ marginRight: 10, borderColor: '#0071C2', borderWidth: '2px' }}>
                                                        <p class="balanced">{'Available Credit Balance [Company] '}:&nbsp;&nbsp;<span style={{ fontWeight: '600', }}>{getCompanyCreditBalance}</span></p>
                                                    </div>
                                                    <div class="balance_block" style={{ borderColor: '#5EAC18', borderWidth: '2px' }}>
                                                        <p class="balanced">{'Available Credit Balance [Agent] '}:&nbsp;&nbsp;<span style={{ fontWeight: '600', }}>{getCreditBalance}</span></p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <a href='#' class="links export" onClick={() => handleWithdeawOpen()}>{"Revoke Credits From Agent A/C"}</a>
                                                    <a href='#' class="links export" onClick={() => handleAddCreditsOpen()}>{"Add Credits To Agent A/C"}</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="main_class">
                                            <div class="table_block">
                                                <div class="table-responsive">
                                                    {
                                                        agentCreditList?.Data?.length != 0 ?
                                                            <table class="table table-bordered table-striped table-hover dataTable custom">
                                                                <thead>
                                                                    <tr>
                                                                        <th>{t('id')}</th>
                                                                        <th>{t('date')}</th>
                                                                        <th>{t('description')}</th>
                                                                        <th>{t('noCredits')}</th>
                                                                    </tr>
                                                                </thead>
                                                                <tfoot>
                                                                    <tr>
                                                                        <th></th>
                                                                        <th></th>
                                                                        <th>{t('totalNoofCredits')}</th>
                                                                        <th >{agentCreditList?.TotalCredits}</th>
                                                                    </tr>
                                                                </tfoot>
                                                                <tbody>
                                                                    {
                                                                        agentCreditList?.Data?.map((item) => {
                                                                            return (
                                                                                <tr>
                                                                                    <td>
                                                                                        <p>{item?.Id}</p>
                                                                                    </td>
                                                                                    <td>
                                                                                        <p>{item?.Date}</p>
                                                                                    </td>
                                                                                    <td>
                                                                                        <p>{item?.Description}</p>
                                                                                    </td>
                                                                                    <td>
                                                                                        <p >{item?.Credits}</p>
                                                                                    </td>

                                                                                </tr>

                                                                            )
                                                                        })
                                                                    }
                                                                </tbody>
                                                            </table> :
                                                            <h1 class="text-center">No Data Found</h1>
                                                    }

                                                </div>
                                                <div class="text-right">
                                                    <Pagination className="pagination" count={agentCreditList?.TotalPages} page={agentCreditListPage} onChange={pageHandleChangeAgentCreditList} />
                                                </div>
                                            </div>
                                            <div class="button_block">
                                                <a href="#" class="links" onClick={() => setValue('two')}>{t('back')}</a>
                                                <a href="#" class="links" onClick={() => setValue('four')}>Next</a>
                                            </div>
                                        </div>
                                    </> : null
                            }
                            {
                                value == 'four' ?

                                    <div class="main_class">


                                        <div class="table_block">
                                            <div class="card">
                                                <div class="table-responsive">
                                                    {
                                                        pendingRequestsList?.length != 0 ?
                                                            <table class="table table-bordered table-striped table-hover dataTable">
                                                                <thead>
                                                                    <tr>
                                                                        <th>
                                                                            <div class="table_heading text-center">
                                                                                <h5>{t('Request')}</h5>
                                                                            </div>
                                                                        </th>
                                                                        <th>
                                                                            <div class="table_heading text-center">
                                                                                <h5>ID</h5>
                                                                            </div>
                                                                        </th>

                                                                        <th>
                                                                            <div class="table_heading text-center">
                                                                                <h5>{t('Request Date')}</h5>
                                                                            </div>
                                                                        </th>
                                                                        <th>
                                                                            <div class="table_heading text-center">
                                                                                <h5>Property Id</h5>
                                                                            </div>
                                                                        </th>
                                                                        <th>
                                                                            <div class="table_heading text-center">
                                                                                <h5>Property Name</h5>
                                                                            </div>
                                                                        </th>
                                                                        <th>
                                                                            <div class="table_heading text-center">
                                                                                <h5>{t('credits')}</h5>
                                                                            </div>
                                                                        </th>

                                                                        <th>
                                                                            <div class="table_heading text-center">
                                                                                <h5>{'Publish Type'}</h5>
                                                                            </div>
                                                                        </th>

                                                                        <th>
                                                                            <div class="table_heading text-center">
                                                                                <h5>{t('reference')}</h5>
                                                                            </div>
                                                                        </th>

                                                                        <th>
                                                                            <div class="table_heading text-center">
                                                                                <h5>{t('propertyType')}</h5>
                                                                            </div>
                                                                        </th>
                                                                        <th>
                                                                            <div class="table_heading text-center">
                                                                                <h5>{t('category')}</h5>
                                                                            </div>
                                                                        </th>
                                                                        <th>
                                                                            <div class="table_heading text-center">
                                                                                <h5>{t('location')}</h5>
                                                                            </div>
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody class="text-center">
                                                                    {pendingRequestsList?.map((item) => {
                                                                        return (
                                                                            <tr>
                                                                                <td >

                                                                                    <div class="table_detail" style={{ flexDirection: 'column' }}>

                                                                                        <a class="link green" onClick={() => onClickPublishRequestApprove(item?.ID)}><img src={check} /></a>


                                                                                        <a href="#" onClick={() => onClickPublishRequestReject(item?.ID)} class="link red"  ><img style={{ color: 'red' }} src={close} /></a>
                                                                                    </div>
                                                                                </td>

                                                                                <td>
                                                                                    <div class="table_detail text-center">
                                                                                        <h6>{item?.ID}</h6>
                                                                                    </div>
                                                                                </td>

                                                                                <td>
                                                                                    <div class="table_detail text-center">
                                                                                        <h6>{moment.utc(item?.RequestDate).local().format('DD-MM-YYYY')}</h6>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div class="table_detail text-center">
                                                                                        <h6>{item?.PropertyId}</h6>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div class="table_detail text-center">
                                                                                        <h6>{item?.Title}</h6>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div class="table_detail text-center">
                                                                                        <h6>{item?.Credits}</h6>
                                                                                    </div>
                                                                                </td>

                                                                                <td>
                                                                                    <div class="table_detail text-center">
                                                                                        <h6>{item?.PublishType}</h6>
                                                                                    </div>
                                                                                </td>

                                                                                <td>
                                                                                    <div class="table_detail text-center">
                                                                                        <h6>{item?.Reference}</h6>
                                                                                    </div>
                                                                                </td>

                                                                                <td>
                                                                                    <div class="table_detail text-center">
                                                                                        <h6>{item?.PropertyType}</h6>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div class="table_detail text-center">
                                                                                        <h6>{item?.Category}</h6>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div class="table_detail text-center">
                                                                                        <h6>{item?.Location}</h6>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        )

                                                                    })}





                                                                </tbody>
                                                            </table>
                                                            : <h1 class="text-center">No Data Found</h1>
                                                    }




                                                </div>
                                             
                                            </div>
                                        </div>
                                        <div class="button_block">
                                            <a href="#" class="links" onClick={() => setValue('three')}>{t('back')}</a>
                                            <a href="#" class="links" onClick={() => navigate('/UserList')}>Done</a>
                                        </div>
                                    </div>
                                    : null
                            }
                        </div>
                    </div>
                </div>
            </section>
            <ToastContainer />
        </div>
    );

}



