import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import plus from '../../images/plus.svg';
import edit from '../../images/edit.svg';
import deleteIcon from '../../images/delete.svg';
import closeModal from '../../images/close.svg';
import star from '../../images/star.svg';
import heart from '../../images/heart.svg';
import fileIcon from '../../images/file.svg';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { TextField, Button, Backdrop, CircularProgress, Typography, Autocomplete } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import Tooltip from '@mui/material/Tooltip';




// <!-- Google Fonts -->
import '../../css/material-icon.css';

// <!-- Bootstrap Core Css -->
import '../../plugins/bootstrap/css/bootstrap.css';

// <!-- Waves Effect Css -->
import '../../plugins/node-waves/waves.css';

// <!-- Animation Css -->
import '../../plugins/animate-css/animate.css';

// <!-- Bootstrap Select Css -->
import '../../plugins/bootstrap-select/css/bootstrap-select.css';


// <!-- JQuery DataTable Css -->
import '../../plugins/jquery-datatable/skin/bootstrap/css/dataTables.bootstrap.css';
// <!-- Bootstrap Material Datetime Picker Css -->
import '../../plugins/bootstrap-material-datetimepicker/css/bootstrap-material-datetimepicker.css';

// <!-- Bootstrap DatePicker Css -->
import '../../plugins/bootstrap-datepicker/css/bootstrap-datepicker.css';

// <!-- Wait Me Css -->
import '../../plugins/waitme/waitMe.css';

// <!-- Morris Chart Css-->
import '../../plugins/morrisjs/morris.css';

// <!-- Custom Css -->
import '../../css/style.css';
import '../../css/custom.css';
import '../../css/materialize.css';




// <!-- Style -->
// <!--<link rel="stylesheet" href="css/style.css">-->

// <!-- AdminBSB Themes. You can choose a theme from css/themes instead of get all themes -->
import '../../css/themes/all-themes.css';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import Topbar from "../../component/Topbar";
import Sidebar from "../../component/Sidebar";
import { LeadsMasterDropdownApi, deleteLeadApi, getByIdLeadRequirementsApi, getLeadsDetailApi, leadModeApi, leadsListDataAPi, logoutApi } from "../../redux/ApiActionCreator";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';


import { leadsDetailDataApi } from "../../redux/ApiActionCreator";
import axios from "axios";
import config from "../../redux/config";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;


function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
export default function LeadsList() {
    const dispatch = useDispatch()
    const { t } = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [personName, setPersonName] = useState([]);
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [page, setPage] = React.useState(1);
    const [name, setName] = useState('')
    const [contactNo, setContactNo] = useState('')
    const [created, setCreated] = useState('')
    const [date, setDate] = useState('')
    const [offeringType, setOfferingType] = useState('')
    const [user, setUser] = useState('')
    const today = new Date().toISOString().split('T')[0];
    const [status, setStatus] = useState([])
    const [id, setId] = useState('')
    const [location, setLocation] = useState(null)
    const token = useSelector((state) => state?.apiReducer?.loginData?.Data?.accessToken)
    const leadListData = useSelector((state) => state?.apiReducer?.leadListData)
    const leadListLoad = useSelector((state) => state?.apiReducer?.leadListLoad)
    const [selectedLeadsId, setselectedLeadsId] = useState('')
    const [inputTypeCreateddate, setiInputTypeCreateddate] = useState('text');
    const userRole = useSelector((state) => state?.apiReducer?.loginData?.Data?.userRole[0])
    const leadsDropdownData = useSelector((state) => state?.apiReducer?.leadsDropdownData)
    const [stateIdSelected, setStateIdSelected] = useState(null)
    const [cityIdSelected, setCityIdSelected] = useState(null)
    const [options, setOptions] = useState([]);


    const handleFocusDate = (e) => {
        setiInputTypeCreateddate('date');
        e.target.blur()
    };
    const pageHandleChange = (event, value) => {
        setPage(value);
    };

    const handleKeyPress = (event) => {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);

        // Allow digits, decimal point, and backspace
        if (!/^\d$/.test(keyValue) && keyCode !== 8) {
            event.preventDefault();
        }

        // Prevent typing a minus sign
        if (keyCode === 45) {
            event.preventDefault();
        }
    };

    const handleKeyPressPhno = (event) => {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);

        // Allow digits, plus sign, and backspace
        if (!/^\d$/.test(keyValue) && keyValue !== "+") {
            event.preventDefault();
        }

        // Prevent typing a minus sign
        if (keyCode === 45) {
            event.preventDefault();
        }
    };

    useEffect(() => {
        dispatch(LeadsMasterDropdownApi(token))
            .then((response) => {

            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {

                    dispatch(logoutApi())
                    navigate('/')

                }

            });
    }, [])

    // LEADS LIST API CALL
    useEffect(() => {
        const params = {
            pagenumber: page,
            Id: id == '' ? null : id,
            Name: name == '' ? null : name,
            ContactNo: contactNo == '' ? null : contactNo,
            CreatedDatestring: created == '' ? null : created,
            CreatedDate: date == '' ? null : date,
            OfferingTypeId: offeringType == '' ? null : offeringType,
            UserId: user == '' ? null : user,
            StatusId: status == '' ? null : status,
            CityId: cityIdSelected == "" ? null : cityIdSelected,
            StateId: stateIdSelected == "" ? null : stateIdSelected
        }
        dispatch(leadsListDataAPi(token, params))
    }, [page])



    const handleClose = () => {
        setOpen(false);
    };



    //LEADS LIST FILTER SEARCH API CALL
    const onClickFilter = () => {
        setLoading(true)
        setPage(1)
        const params = {
            Id: id == '' ? null : id,
            pagenumber: 1,
            Name: name == '' ? null : name,
            ContactNo: contactNo == '' ? null : contactNo,
            CreatedDatestring: created == '' ? null : created,
            CreatedDate: date == '' ? null : date,
            OfferingTypeId: offeringType == '' ? null : offeringType,
            UserId: user == '' ? null : user,
            StatusId: status == '' ? null : status,
            CityId: cityIdSelected == "" ? null : cityIdSelected,
            StateId: stateIdSelected == "" ? null : stateIdSelected
        }
        dispatch(leadsListDataAPi(token, params))
            .then(() => {
                setLoading(false)

            })
            .catch(error => {
                setLoading(false)


            })
    }

    // LEADS LIST RESET FILTER API CALL
    const resetFilter = () => {
        setLoading(true)
        setId('')
        setName('')
        setContactNo('')
        setCreated('')
        setDate('')
        setOfferingType('')
        setUser('')
        setStatus([])
        setLocation('')
        setiInputTypeCreateddate('text')
        setOptions([])
        setLocation(null)
        setStateIdSelected('')
        setCityIdSelected('')
        const params = {
            pagenumber: 1
        }
        dispatch(leadsListDataAPi(token, params))
            .then(() => {
                setLoading(false)
                setPage(1)
            })
            .catch(error => {
                setLoading(false)
                setPage(1)

            })
    }


    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setStatus(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    // ON CLICK CREATE LEAD FUNCTION
    const onClickAdd = () => {
        dispatch(leadModeApi(0))
        dispatch(leadsDetailDataApi('create', ''))
        navigate('/LeadsDetail')
    }

    // ON CLICK EDIT LEAD FUNCTION
    const onClickEdit = (id) => {
        dispatch(leadModeApi(1))
        setLoading(true)
        dispatch(leadsDetailDataApi('edit', id))
        dispatch(getByIdLeadRequirementsApi(token, id))
        dispatch(getLeadsDetailApi(token, id))
            .then((response) => {
                setLoading(false)
                navigate('/LeadsDetail')

            })
            .catch((error) => {
                setLoading(false)

            });

    }

    const handleClickOpen = (id) => {
        setselectedLeadsId(id)
        setOpen(true);
    };

    // DELETE LEAD API CALL
    const onClickdeleteLeadApi = () => {
        setLoading(true)

        dispatch(deleteLeadApi(token, selectedLeadsId))
            .then((response) => {
                const params = {
                    pagenumber: 1,
                    Id: id == '' ? null : id,
                    Name: name == '' ? null : name,
                    ContactNo: contactNo == '' ? null : contactNo,
                    CreatedDatestring: created == '' ? null : created,
                    CreatedDate: date == '' ? null : date,
                    OfferingTypeId: offeringType == '' ? null : offeringType,
                    UserId: user == '' ? null : user,
                    StatusId: status == '' ? null : status,
                    CityId: cityIdSelected == "" ? null : cityIdSelected,
                    StateId: stateIdSelected == "" ? null : stateIdSelected
                }
                dispatch(leadsListDataAPi(token, params))
                    .then(() => {
                        setOpen(false)
                        setLoading(false)
                        setPage(1)

                    })
                    .catch(error => {
                        setOpen(false)
                        setLoading(false)
                        setPage(1)

                    })




            })
            .catch((error) => {
                setLoading(false)

            });
    }


    // GET LOCATION AUTOCOMPLETE DROPDOWN API CALL
    const handleLocationSearch = async (inputValue) => {
        if (inputValue.length == 0) {
            setOptions([]); // Clear the options when the input is empty
        }
        else if (inputValue.length > 0) {
            try {
                const response = await axios.post(`${config.baseURL}/Location/BahrainSearch?input=${inputValue}`, {}, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                const data = response?.data?.Data; // Adjust this based on your API response structure

                setOptions(data); // Update the options state with the response data
            } catch (error) {
                console.error('Error fetching location data', error);
            }
        }
    };

    const handleSelect = async (event, value) => {
        setLocation(value)
        setStateIdSelected(value?.StateId)
        setCityIdSelected(value?.CityId)
        // You can perform any further actions with the selected value here
    };

    return (
        <div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}

            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={leadListLoad}

            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                className='dialog_box_popup'
            >

                <DialogContent class="dialog_box_block">
                    <DialogContentText id="alert-dialog-slide-description" class="dialog_box_title">
                        <h6>Delete</h6>
                        <Button onClick={handleClose}><img src={closeModal} /></Button>
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-slide-description" class="dialog_box_detail">
                        Are you sure you want to delete this lead ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions class="dialog_box_button">
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={() => onClickdeleteLeadApi()}>Delete</Button>
                </DialogActions>
            </Dialog>
            <div class="overlay"></div>
            <Topbar />
            <Sidebar message={'Leads'} />

            <section class="content">
                <div class="block-header">
                    <h2>{t('leads')}</h2>

                </div>

                <div class="page_heading">
                    <h3>{t('leadsList')}</h3>
                    <a onClick={() => onClickAdd()}><img src={plus} /></a>
                </div>

                <div class="main_class">
                    <div class="form_block">
                        <div class="row clearfix">
                            <div class="col-md-3 col-sm-4 col-xs-6">
                                <div class="form-group">
                                    <div class="form-line">
                                        <input type="text" class="form-control" title={t('name')} placeholder={t('name')} value={name} onChange={(e) => setName(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-4 col-xs-6">
                                <div class="form-group">
                                    <div class="form-line">
                                        <input type="text" class="form-control" title={t('contact')} placeholder={t('contact')} value={contactNo} onKeyPress={handleKeyPressPhno} maxLength={15} onChange={(e) => setContactNo(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-4 col-xs-6">
                                <div>
                                    <FormControl className='form-group' sx={{}}>
                                        <Select
                                            className='form-control border_temp'
                                            sx={{ borderColor: 'transparent' }}
                                            displayEmpty
                                            value={created}
                                            onChange={(e) => { setCreated(e.target.value); setDate(''); setiInputTypeCreateddate('text') }}
                                            input={<OutlinedInput />}
                                            title={t('created')}
                                            MenuProps={{
                                                PaperProps: {
                                                    style: {
                                                        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                        width: 250,
                                                    },
                                                },
                                                disableScrollLock: true,
                                            }}
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem className='drop_menus' value="">
                                                <em className='select_text'>{t('created')}</em>
                                            </MenuItem>
                                            {leadsDropdownData?.Created?.map((item) => (
                                                <MenuItem
                                                    className='drop_menus'
                                                    key={item?.Id}
                                                    value={item?.Name}
                                                    style={getStyles(item?.Name, personName, theme)}
                                                >
                                                    {item?.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-4 col-xs-6">
                                <div class="form-group">
                                    <div class="form-line">
                                        <input type={inputTypeCreateddate}
                                            title={t('createdDate')}
                                            max={today} onFocus={(e) => handleFocusDate(e)} class="form-control"
                                            onKeyDown={(e) => e.preventDefault()} // Prevent typing
                                            onKeyPress={(e) => e.preventDefault()} // Prevent typing
                                            onKeyUp={(e) => e.preventDefault()} // Prevent typing
                                            onPaste={(e) => e.preventDefault()} // Prevent pasting
                                            placeholder={t('createdDate')} value={date} onChange={(e) => { setDate(e.target.value); setCreated('') }} />
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-4 col-xs-6">
                                <div>
                                    <FormControl className='form-group' sx={{}}>
                                        <Select
                                            className='form-control border_temp'
                                            displayEmpty
                                            title={t('offeringType')}
                                            value={offeringType}
                                            onChange={(e) => setOfferingType(e.target.value)}
                                            input={<OutlinedInput />}
                                            MenuProps={{
                                                PaperProps: {
                                                    style: {
                                                        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                        width: 250,
                                                    },
                                                },
                                                disableScrollLock: true,
                                            }}
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem className='drop_menus' value="">
                                                <em className='select_text'>{t('offeringType')}</em>
                                            </MenuItem>
                                            {leadsDropdownData?.OfferingTypes?.map((item) => (
                                                <MenuItem
                                                    className='drop_menus'
                                                    key={item?.Id}
                                                    value={item?.Id}
                                                    style={getStyles(item?.Name, personName, theme)}
                                                >
                                                    {item?.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            {
                                userRole == 'SuperAdministrator' ?
                                    <div class="col-md-3 col-sm-4 col-xs-6">
                                        <div>
                                            <FormControl className='form-group' sx={{}}>
                                                <Select
                                                    className='form-control border_temp'

                                                    displayEmpty
                                                    value={user}
                                                    title={t('user')}
                                                    onChange={(e) => setUser(e.target.value)}
                                                    input={<OutlinedInput />}
                                                    MenuProps={{
                                                        PaperProps: {
                                                            style: {
                                                                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                width: 250,
                                                            },
                                                        },
                                                        disableScrollLock: true,
                                                    }}
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                >
                                                    <MenuItem className='drop_menus' value="">
                                                        <em className='select_text'>{t('user')}</em>
                                                    </MenuItem>
                                                    {leadsDropdownData?.Users?.map((item) => (
                                                        <MenuItem
                                                            className='drop_menus'
                                                            key={item?.Id}
                                                            value={item?.Id}
                                                            style={getStyles(name, item?.Name, theme)}
                                                        >
                                                            {item?.Name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div> : null
                            }

                            <div class="col-md-3 col-sm-4 col-xs-6">
                                <div>
                                    <FormControl className='form-group' sx={{}}>
                                        <Select
                                            className='form-control border_temp'
                                            sx={{ borderColor: 'transparent' }}
                                            multiple
                                            displayEmpty
                                            value={status}
                                            title="Status"
                                            onChange={handleChange}
                                            input={<OutlinedInput />}
                                            renderValue={(selected) => {
                                                if (selected.length === 0) {
                                                    return <em className='select_text'>Status</em>;
                                                }

                                                const selectedStatus = selected.map(id => {
                                                    const statuss = leadsDropdownData?.LeadStatuses?.find(item => item?.Id === id);
                                                    return statuss ? statuss.Name : ''; // Return the name if found, otherwise an empty string
                                                });

                                                return selectedStatus.join(', ');
                                            }}
                                            MenuProps={{
                                                PaperProps: {
                                                    style: {
                                                        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                        width: 250,
                                                    },
                                                },
                                                disableScrollLock: true,
                                            }}
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem className='drop_menus' disabled value="">
                                                <em className='placeholder'>Status</em>
                                            </MenuItem>
                                            {leadsDropdownData?.LeadStatuses?.map((item) => (
                                                <MenuItem
                                                    className='drop_menus'
                                                    key={item?.Id}
                                                    value={item?.Id}
                                                    style={getStyles(item?.Name, personName, theme)}
                                                >
                                                    {item?.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-4 col-xs-6">
                                <div class="form-group">
                                    <div class="form-line">
                                        <input type="text" class="form-control" title={t('id')} placeholder={t('id')} value={id} onKeyPress={handleKeyPress} onChange={(e) => setId(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-4 col-xs-6">
                                <div>
                                    <FormControl className='form-group' sx={{}}>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            sx={{ width: 300, fontSize: 14, }}
                                            className='form-control auto_complete'
                                            onChange={handleSelect}
                                            options={options}
                                            title="Location"
                                            value={location}
                                            getOptionLabel={(option) => option?.Name} // Adjust this based on your API response structure
                                            onInputChange={(event, newInputValue) => handleLocationSearch(newInputValue)}
                                            renderInput={(params) => <TextField {...params} placeholder={"Location"}

                                            />}
                                        />
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                        <div class="button_block">
                            <a href="#" class="links" onClick={() => resetFilter()}>Reset</a>
                            <a href="#" class="links" onClick={() => onClickFilter()}>{t('search')}</a>
                        </div>
                    </div>
                </div>

                <div class="main_class">
                    <div class="table_block">
                        <div class="card">
                            <div class="table-responsive">
                                {
                                    leadListData?.Data?.length != 0 ?
                                        <table class="table table-bordered table-striped table-hover dataTable">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>
                                                        <div class="table_heading">
                                                            <h5>{t('Lead Id')}</h5>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div class="table_heading">
                                                            <h5>{t('status')}</h5>
                                                            <p>{t('updated')}</p>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div class="table_heading">
                                                            <h5>{t('name')}</h5>
                                                            <p>{t('mobile')}</p>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div class="table_heading">
                                                            <h5>{t('type')}</h5>
                                                            <p>{t('offering')}</p>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div class="table_heading">
                                                            <h5>{t('budget')}</h5>
                                                            <p>{t('location')}</p>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div class="table_heading">
                                                            <h5>{t('timeFrame')}</h5>
                                                            <p>{t('bedrooms')}</p>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div class="table_heading">
                                                            <h5>{t('assignedTo')}</h5>
                                                            <p>{t('source')}</p>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div class="table_heading">
                                                            <h5>{t('quality')}</h5>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div class="table_heading">
                                                            <h5>{t('shortlisted')}</h5>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div class="table_heading">
                                                            <h5>{t('notes')}</h5>
                                                        </div>
                                                    </th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    leadListData?.Data?.map((item) => {

                                                        return (
                                                            <tr>
                                                                <td>
                                                                    <div class="table_detail">
                                                                        <a onClick={() => onClickEdit(item?.Id)} class="link green"><img src={edit} /></a>
                                                                        <a href="#" class="link red" onClick={() => handleClickOpen(item?.Id)}><img src={deleteIcon} /></a>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="table_detail">
                                                                        <h6>{item?.Id}</h6>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="table_detail">
                                                                        <h6>{item?.LeadStatusName}</h6>
                                                                        <Tooltip title={<Typography component="div">
                                                                            Enquiry date :
                                                                            <br />
                                                                            {item?.EnquiryDate}
                                                                        </Typography>}
                                                                            arrow className="tooltip_notes">
                                                                            <p>{item?.Updated}</p>
                                                                        </Tooltip>

                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="form-group">
                                                                        <div class="table_detail">
                                                                            <h6>{item?.LeadName}</h6>
                                                                            <p>{item?.LeadContactNo}</p>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="table_detail">
                                                                        <h6>{item?.PropertyTypeName}</h6>
                                                                        <p>{item?.OfferingTypeName}</p>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="table_detail">
                                                                        <h6>Up to {item?.MaxBudget} BHD</h6>
                                                                        <p>{item?.Location}</p>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="table_detail">
                                                                        <h6>{item?.TimeframeName}</h6>
                                                                        <p>From {item?.MinBedrooms == -1 ? "Studio" : item?.MinBedrooms} to {item?.MaxBedroons == -1 ? "Studio" : item?.MaxBedroons} BR</p>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="table_detail text-center">
                                                                        <h6>{item?.AssignetoUserName}</h6>
                                                                        <Tooltip title={item?.LeadSources} arrow className="tooltip_notes">
                                                                            <p class="text-center"><img style={{ width: '25px', height: '25px' }} src={require("../../images/logo_icon.png")} /></p>
                                                                        </Tooltip>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="table_detail">
                                                                        <h6 class="text-center">{item?.LeadQuality == null ? 0 : item?.LeadQuality}<img src={star} /></h6>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="table_detail">
                                                                        <h6 class="text-center">{item?.ShortListed}<img src={heart} /></h6>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="table_detail text-center">
                                                                        <Tooltip
                                                                            title={
                                                                                <Typography component="div">
                                                                                    {item?.NoteCreatedDate}
                                                                                    &nbsp; - &nbsp;
                                                                                    {item?.NoteCreatedBy}
                                                                                    <br />
                                                                                    {item?.LeadNote}
                                                                                </Typography>
                                                                            } arrow className="tooltip_notes">
                                                                            <h6 class="text-center"><img src={fileIcon} /></h6>
                                                                        </Tooltip>
                                                                    </div>
                                                                </td>

                                                            </tr>
                                                        )
                                                    })
                                                }


                                            </tbody>
                                        </table> :
                                        <h1 class="text-center">No Data Found</h1>
                                }

                            </div>
                            <div class="text-right">
                                <Pagination className="pagination" count={leadListData?.TotalPages} page={page} onChange={pageHandleChange} />
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </div >
    )
}
