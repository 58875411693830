import React, { useEffect, useState } from "react";
import plus from '../../images/plus.svg';
import edit from '../../images/edit.svg';
import deleteIcon from '../../images/delete.svg';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Button } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import { Backdrop, CircularProgress, } from '@mui/material';
import closeModal from '../../images/close.svg';
import Slide from '@mui/material/Slide';

// <!-- Google Fonts -->
import '../../css/material-icon.css';

// <!-- Bootstrap Core Css -->
import '../../plugins/bootstrap/css/bootstrap.css';

// <!-- Waves Effect Css -->
import '../../plugins/node-waves/waves.css';

// <!-- Animation Css -->
import '../../plugins/animate-css/animate.css';

// <!-- Bootstrap Select Css -->
import '../../plugins/bootstrap-select/css/bootstrap-select.css';


// <!-- JQuery DataTable Css -->
import '../../plugins/jquery-datatable/skin/bootstrap/css/dataTables.bootstrap.css';
// <!-- Bootstrap Material Datetime Picker Css -->
import '../../plugins/bootstrap-material-datetimepicker/css/bootstrap-material-datetimepicker.css';

// <!-- Bootstrap DatePicker Css -->
import '../../plugins/bootstrap-datepicker/css/bootstrap-datepicker.css';

// <!-- Wait Me Css -->
import '../../plugins/waitme/waitMe.css';

// <!-- Morris Chart Css-->
import '../../plugins/morrisjs/morris.css';

// <!-- Custom Css -->
import '../../css/style.css';
import '../../css/custom.css';
import '../../css/materialize.css';



// <!-- AdminBSB Themes. You can choose a theme from css/themes instead of get all themes -->
import '../../css/themes/all-themes.css';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import Topbar from "../../component/Topbar";
import Sidebar from "../../component/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { deleteOwnerApi, getByIdOwnerApi, logoutApi, ownerDetailDataApi, ownerDropdownApi, ownerListApi, ownerModeApi, ownerPropertyModeApi } from "../../redux/ApiActionCreator";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function OwnerList() {
    const { t } = useTranslation();
    const theme = useTheme();
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [personName, setPersonName] = useState([])
    const [selectedOwnerId, setselectedOwnerId] = useState('')
    const [page, setPage] = React.useState(1);
    const [name, setName] = useState('')
    const [contactNo, setContactNo] = useState('')
    const [email, setEmail] = useState('')
    const [user, setuser] = useState('')
    const [loading, setLoading] = useState(false)
    const [ownerPopup, setownerPopup] = useState(false)
    const userRole = useSelector((state) => state?.apiReducer?.loginData?.Data?.userRole[0])
    const token = useSelector((state) => state?.apiReducer?.loginData?.Data?.accessToken)
    const ownerDropdownData = useSelector((state) => state?.apiReducer?.ownerDropdownData)
    const ownerListData = useSelector((state) => state?.apiReducer?.ownerListData)
    const ownerListLoad = useSelector((state) => state?.apiReducer?.ownerListLoad)




    const handleKeyPressPhno = (event) => {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);

        // Allow digits, plus sign, and backspace
        if (!/^\d$/.test(keyValue) && keyValue !== "+") {
            event.preventDefault();
        }

        // Prevent typing a minus sign
        if (keyCode === 45) {
            event.preventDefault();
        }
    };

    // GET OWNER MASTER DROPDOWN API CALL
    useEffect(() => {
        dispatch(ownerDropdownApi(token))
    }, [])

    // GET OWNER LIST API CALL
    useEffect(() => {
        const params = {
            pagenumber: page,
            "UserId": user == '' ? null : user,
            "Name": name == '' ? null : name,
            "Phone": contactNo == '' ? null : contactNo,
            "Email": email == '' ? null : email,
        }
        dispatch(ownerListApi(token, params))
            .then((response) => {

            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {

                    dispatch(logoutApi())
                    navigate('/')

                }


            });
    }, [page])

    // OWNER LIST SEARCH FILTER API CALL
    const onClickFilter = () => {
        setLoading(true)
        setPage(1)
        const params = {
            "UserId": user == '' ? null : user,
            "Name": name == '' ? null : name,
            "Phone": contactNo == '' ? null : contactNo,
            "Email": email == '' ? null : email,
            pagenumber: 1,

        }

        dispatch(ownerListApi(token, params))
            .then((response) => {

                setLoading(false)

            })
            .catch((error) => {

                setLoading(false)


            });

    }

    // OWNER LIST RESET FILTER API CALL
    const onClickReset = () => {
        setName('')
        setuser('')
        setContactNo('')
        setEmail('')
        const params = {
            pagenumber: 1
        }
        dispatch(ownerListApi(token, params))
            .then((response) => {
                setPage(1);


            })
            .catch((error) => {
                setPage(1);



            });
    }

    // DELETE OWNER API CALL
    const deleteOwner = () => {
        dispatch(deleteOwnerApi(token, selectedOwnerId))
            .then((response) => {
                const params = {
                    pagenumber: 1,
                    "UserId": user == '' ? null : user,
                    "Name": name == '' ? null : name,
                    "Phone": contactNo == '' ? null : contactNo,
                    "Email": email == '' ? null : email,
                }
                dispatch(ownerListApi(token, params))
                    .then((response) => {
                        setownerPopup(false);
                        setLoading(false)
                        setPage(1)

                    })
                    .catch((error) => {
                        setLoading(false)
                        setPage(1)


                    });

            })
            .catch((error) => {
                setLoading(false)


            });
    }


    const pageHandleChange = (event, value) => {
        setPage(value);
    };

    // ON CLICK CREATE OWNER FUNCTION
    const onClickCreateOwner = () => {
        dispatch(ownerPropertyModeApi(0))
        dispatch(ownerModeApi(0))
        dispatch(ownerDetailDataApi('create', ''))
        navigate('/OwnerDetail')
    }

    // ON CLICK EDIT OWNER FUNCTION
    const onClickEditOwner = (id) => {
        dispatch(ownerPropertyModeApi(0))
        dispatch(ownerModeApi(1))
        dispatch(ownerDetailDataApi('edit', id))
        dispatch(getByIdOwnerApi(token, id))
            .then((response) => {
                navigate('/OwnerDetail')
                setLoading(false)

            })
            .catch((error) => {
                setLoading(false)


            });


    }
    const handleClickOpen = (id) => {

        setselectedOwnerId(id)
        setownerPopup(true);
    };
    const handleClosepopup = () => {

        setownerPopup(false);
    };
    return (
        <div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={ownerListLoad}

            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}

            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Dialog
                open={ownerPopup}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClosepopup}
                aria-describedby="alert-dialog-slide-description"
                className='dialog_box_popup'
            >

                <DialogContent class="dialog_box_block">
                    <DialogContentText id="alert-dialog-slide-description" class="dialog_box_title">
                        <h6>Delete</h6>
                        <Button onClick={handleClosepopup}><img src={closeModal} /></Button>
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-slide-description" class="dialog_box_detail">
                        <p>Are you sure you want to delete this owner ?</p>
                    </DialogContentText>
                </DialogContent>
                <DialogActions class="dialog_box_button">
                    <Button onClick={handleClosepopup}>Cancel</Button>
                    <Button onClick={() => deleteOwner()}>Delete</Button>
                </DialogActions>
            </Dialog>


            <div class="overlay"></div>

            <Topbar />

            <Sidebar message={'Owners'} />

            <section class="content">
                <div class="block-header">
                    <h2>{t('owner')}</h2>

                </div>

                <div class="page_heading">
                    <h3>{t('ownerList')}</h3>
                    <a onClick={() => onClickCreateOwner()}><img src={plus} /></a>
                </div>

                <div class="main_class">
                    <div class="form_block">
                        <div class="row clearfix">
                            <div class="col-md-3 col-sm-4 col-xs-6">
                                <div class="form-group">
                                    <div class="form-line">
                                        <input type="text" class="form-control" title={t('name')} placeholder={t('name')} value={name} onChange={(e) => setName(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-4 col-xs-6">
                                <div class="form-group">
                                    <div class="form-line">
                                        <input type="text" class="form-control" title={t('contact')} placeholder={t('contact')} value={contactNo} onKeyPress={handleKeyPressPhno} maxLength={15} onChange={(e) => setContactNo(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-4 col-xs-6">
                                <div class="form-group">
                                    <div class="form-line">
                                        <input type="text" class="form-control" title={t('email')} placeholder={t('email')} value={email} onChange={(e) => setEmail(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            {
                                userRole == 'AgentAdmin' && (
                                    <div class="col-md-3 col-sm-4 col-xs-6">
                                        <div>
                                            <FormControl className='form-group' sx={{}}>
                                                <Select
                                                    className='form-control border_temp'
                                                    title={t('assignedTo')}
                                                    displayEmpty
                                                    value={user}
                                                    onChange={(e) => setuser(e.target.value)}
                                                    input={<OutlinedInput />}

                                                    MenuProps={{
                                                        PaperProps: {
                                                            style: {
                                                                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                width: 250,
                                                            },
                                                        },
                                                        disableScrollLock: true,
                                                    }}
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                >
                                                    <MenuItem className='drop_menus' value="">
                                                        <em className='select_text'>{t('assignedTo')}</em>
                                                    </MenuItem>
                                                    {ownerDropdownData?.Users?.map((item) => (
                                                        <MenuItem
                                                            className='drop_menus'
                                                            key={item?.Id}
                                                            value={item?.Id}
                                                            style={getStyles(item?.Name, personName, theme)}
                                                        >
                                                            {item?.Name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                        <div class="button_block">
                            <a href="#" class="links" onClick={() => onClickReset()}>Reset</a>
                            <a href="#" class="links" onClick={() => onClickFilter()}>{t('search')}</a>
                        </div>
                    </div>
                </div>

                <div class="main_class">
                    <div class="table_block">
                        <div class="card">
                            <div class="table-responsive">
                                {
                                    ownerListData?.Data?.length != 0 ?
                                        <table class="table table-bordered table-striped table-hover dataTable">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>
                                                        <div class="table_heading text-center">
                                                            <h5>{t('Owner Id')}</h5>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div class="table_heading text-center">
                                                            <h5>{t('name')}</h5>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div class="table_heading text-center">
                                                            <h5>{t('contact')}</h5>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div class="table_heading text-center">
                                                            <h5>{t('email')}</h5>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div class="table_heading text-center">
                                                            <h5>{t('assignedTo')}</h5>
                                                        </div>
                                                    </th>

                                                </tr>
                                            </thead>
                                            <tbody class="text-center">

                                                {
                                                    ownerListData?.Data?.map((item) => {
                                                        return (
                                                            <tr>
                                                                <td>
                                                                    <div class="table_detail text-center">
                                                                        <a onClick={() => onClickEditOwner(item?.Id)} class="link green"><img src={edit} /></a>
                                                                        <a href="#" class="link red" onClick={() => handleClickOpen(item?.Id)}><img src={deleteIcon} /></a>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="table_detail text-center">
                                                                        <h6>{item?.Id}</h6>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="table_detail text-center">
                                                                        <h6>{item?.Name}</h6>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="form-group">
                                                                        <div class="table_detail text-center">
                                                                            <h6>{item?.Phone}</h6>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="table_detail text-center">
                                                                        <h6>{item?.Email}</h6>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="table_detail text-center">
                                                                        <h6>{item?.AssignedToName}</h6>
                                                                    </div>
                                                                </td>


                                                            </tr>
                                                        )
                                                    })
                                                }



                                            </tbody>
                                        </table> : <h1 class="text-center">No Data Found</h1>

                                }

                            </div>
                            <div class="text-right">
                                <Pagination className="pagination" count={ownerListData?.TotalPages} page={page} onChange={pageHandleChange} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
