import React, { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { Backdrop, CircularProgress } from '@mui/material';

// Importing CSS files
import '../../css/material-icon.css';
import '../../plugins/bootstrap/css/bootstrap.css';
import '../../plugins/node-waves/waves.css';
import '../../plugins/animate-css/animate.css';
import '../../plugins/bootstrap-select/css/bootstrap-select.css';
import '../../plugins/jquery-datatable/skin/bootstrap/css/dataTables.bootstrap.css';
import '../../plugins/bootstrap-material-datetimepicker/css/bootstrap-material-datetimepicker.css';
import '../../plugins/bootstrap-datepicker/css/bootstrap-datepicker.css';
import '../../plugins/waitme/waitMe.css';
import '../../plugins/morrisjs/morris.css';
import '../../css/style.css';
import '../../css/custom.css';
import '../../css/materialize.css';
import '../../css/uicons-bold-rounded.css';
import '../../css/themes/all-themes.css';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Topbar from '../../component/Topbar';
import Sidebar from '../../component/Sidebar';
import { countryApi, logoutApi, settingsAdminAddApi } from '../../redux/ApiActionCreator';
import { useDispatch, useSelector } from 'react-redux';
import config from '../../redux/config';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

const SettingsAdmin = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    // State variables for form fields and errors
    const token = useSelector((state) => state?.apiReducer?.loginData?.Data?.accessToken);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [server, setServer] = useState("");
    const [port, setPort] = useState("");

    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [serverError, setServerError] = useState("");
    const [portError, setPortError] = useState("");
    const [loading, setLoading] = useState(false);

    // Handle key press for port input (allow digits only)
    const handleKeyPress = (event) => {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);

        // Allow digits, decimal point, and backspace
        if (!/^\d$/.test(keyValue) && keyCode !== 8) {
            event.preventDefault();
        }

        // Prevent typing a minus sign
        if (keyCode === 45) {
            event.preventDefault();
        }
    };

    // Validation functions for form fields
    const validateEmail = (value) => {
        if (value.length == 0) {
            setEmailError(t('emailisrequired'));
        } else if (!(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value))) {
            setEmailError('Enter valid email');
        } else {
            setEmailError('');
        }
    };

    const validatePassword = (value) => {
        if (value.length == 0) {
            setPasswordError('Password is Required');
        } else {
            setPasswordError('');
        }
    };

    const validateServer = (value) => {
        if (value.length == 0) {
            setServerError('Server is Required');
        } else {
            setServerError('');
        }
    };

    const validatePort = (value) => {
        if (value.length == 0) {
            setPortError('Port is Required');
        } else {
            setPortError('');
        }
    };



    // Fetch settings admin data on component mount
    useEffect(() => {
        getByIdSettingsAdminApi();
    }, []);

    // Function to handle form submission
    const onClickSettingsAdminAddApi = () => {
        let validate = false;

        validateEmail(email);
        validatePassword(password);
        validateServer(server);
        validatePort(port);

        if (emailError || passwordError || serverError || portError) {
            validate = true;
        }

        if (!validate) {
            setLoading(true);

            const params = {
                "Email": email || null,
                "Password": password || null,
                "Server": server || null,
                "Port": port || null,
            };

            dispatch(settingsAdminAddApi(token, params))
                .then(() => {
                    getByIdSettingsAdminApi();
                    toast.success("Record Saved Successfully", {
                        position: 'top-right',
                        autoClose: 1000, // Adjust the duration as needed
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    setLoading(false);
                })
                .catch(error => {
                    setLoading(false);
                    toast.error(error.message, {
                        position: 'top-right',
                        autoClose: 1000, // Adjust the duration as needed
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                });
        }
    };

    // Fetch settings admin data by ID
    const getByIdSettingsAdminApi = async () => {
        setLoading(true);

        try {
            const response = await axios.post(`${config.baseURL}/SuperAdminSetting/Get`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.status === 200 && response.data.Status === 'OK') {
                const masterData = response?.data?.Data;
                setEmail(masterData?.Email);
                setPassword(masterData?.Password);
                setServer(masterData?.Server);
                setPort(masterData?.Port);
            } else if (response.data.Status === 'ERROR') {
                // Handle error if needed
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            if (error.response && error.response.status === 401) {
                dispatch(logoutApi());
                navigate('/');
            }
        }
    };

    return (
        <div className="App">
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className="overlay"></div>
            <Topbar />
            <Sidebar message={'SettingAdmin'} />
            <section className="content">
                <div className="block-header">
                    <h2>{t('settings')}</h2>
                </div>
                <div className="tab_content_block setting">
                    <div>
                        <div className="main_class setting">
                            <h3 className="title_heading">SMTP Setup</h3>
                            <div className="form_block outline_form">
                                <div className="row clearfix">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <div className="form-line">
                                                <input
                                                    type="text"
                                                    title={t('email')}
                                                    value={email}
                                                    onChange={(e) => { setEmail(e.target.value); validateEmail(e.target.value); }}
                                                    className="form-control"
                                                    placeholder={t('email')}
                                                />
                                                {emailError && <span className="validation_notes">{emailError}</span>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <div className="form-line">
                                                <input
                                                    type="text"
                                                    title={t('password')}
                                                    value={password}
                                                    onChange={(e) => { setPassword(e.target.value); validatePassword(e.target.value); }}
                                                    className="form-control"
                                                    placeholder={t('password')}
                                                />
                                                {passwordError && <span className="validation_notes">{passwordError}</span>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row clearfix">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <div className="form-line">
                                                <input
                                                    type="text"
                                                    title={t('Server')}
                                                    value={server}
                                                    onChange={(e) => { setServer(e.target.value); validateServer(e.target.value); }}
                                                    className="form-control"
                                                    placeholder={t('Server')}
                                                />
                                                {serverError && <span className="validation_notes">{serverError}</span>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <div className="form-line">
                                                <input
                                                    type="text"
                                                    title={t('Port')}
                                                    value={port}
                                                    maxLength={6}
                                                    onKeyPress={handleKeyPress}
                                                    onChange={(e) => { setPort(e.target.value); validatePort(e.target.value); }}
                                                    className="form-control"
                                                    placeholder={t('Port')}
                                                />
                                                {portError && <span className="validation_notes">{portError}</span>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="button_block">
                                <button onClick={onClickSettingsAdminAddApi} className="links">{t('save')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ToastContainer />
        </div>
    );
}

export default SettingsAdmin;
