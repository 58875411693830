import React from 'react'
import { Card, CardContent, Grid, Typography, Box } from '@mui/material';

// <!-- Custom Css -->
import '../../css/style.css';
import '../../css/custom.css';

const CardComponent = (props) => {
    const { backgroundColor, totalValue, avgPercentage, title, icon: IconComponent, svgIcon: SvgIconComponent } = props
    return (

        <div class="col-sm-3">

            <Card
                sx={{

                    background: backgroundColor,
                    color: 'white',
                    borderRadius: '25px',
                }}
            >
                <CardContent>
                    <Grid >
                        <Grid item>
                            <Grid direction="column" spacing={1} marginTop={'1px'} >
                                <Grid item display="flex" justifyContent="space-between">
                                    {SvgIconComponent && React.cloneElement(SvgIconComponent, { sx: { fontSize: '50px' } })}
                                    <Box display="flex" alignItems="center" marginTop={'15px'}>

                                        {/* <Typography fontSize={'18px'} fontWeight={700} fontFamily={'Atak Bold'} >
                                            {avgPercentage}
                                        </Typography> */}
                                        {/* {IconComponent && React.cloneElement(IconComponent, { sx: { fontSize: 25, marginRight: 1 } })} */}

                                    </Box>
                                </Grid>
                                <Grid item>
                                    <Typography marginTop={1} fontSize={'45px'} fontWeight={700} fontFamily={'Atak Bold'}>{totalValue}</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography fontWeight={500} fontSize={'21px'} fontFamily={'Atak Medium'}>
                                        {title}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* <Grid item>
                            <Box display="flex" alignItems="center" marginTop={'15px'}>

                                <Typography fontSize={'18px'} fontWeight={700} fontFamily={'Atak Bold'} >
                                    {avgPercentage}
                                </Typography>
                                {IconComponent && React.cloneElement(IconComponent, { sx: { fontSize: 25, marginRight: 1 } })}

                            </Box>
                        </Grid> */}
                    </Grid>
                </CardContent>
            </Card>
        </div>

    )
}

export default CardComponent