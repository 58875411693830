import React, { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { Backdrop, CircularProgress } from '@mui/material';

// Importing CSS files
import '../../css/material-icon.css';
import '../../plugins/bootstrap/css/bootstrap.css';
import '../../plugins/node-waves/waves.css';
import '../../plugins/animate-css/animate.css';
import '../../plugins/bootstrap-select/css/bootstrap-select.css';
import '../../plugins/jquery-datatable/skin/bootstrap/css/dataTables.bootstrap.css';
import '../../plugins/bootstrap-material-datetimepicker/css/bootstrap-material-datetimepicker.css';
import '../../plugins/bootstrap-datepicker/css/bootstrap-datepicker.css';
import '../../plugins/waitme/waitMe.css';
import '../../plugins/morrisjs/morris.css';
import '../../css/style.css';
import '../../css/custom.css';
import '../../css/materialize.css';
import '../../css/uicons-bold-rounded.css';
import '../../css/themes/all-themes.css';

import { useTranslation } from 'react-i18next';
import Topbar from '../../component/Topbar';
import Sidebar from '../../component/Sidebar';

import { ToastContainer, toast } from 'react-toastify';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;


function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}
const EditProfile = () => {

    const { t } = useTranslation();
    const [expSince, setExpSince] = useState('')
    const [personName, setPersonName] = useState([]);
    const theme = useTheme();
    const navigate = useNavigate()
    const [nationality, setNationality] = useState('')
    const userDropdownData = useSelector((state) => state?.apiReducer?.userDropdownData)

    // Get the current year
    const currentYear = new Date().getFullYear();

    const yearsArray = [];

    // Loop from the current year to 1953 and create an object for each year
    for (let year = currentYear; year >= 1953; year--) {
        const yearObject = {
            Id: year,
            Name: year
        };
        yearsArray.push(yearObject);
    }





    return (
        <div className="App">
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className="overlay"></div>
            <Topbar />
            <Sidebar message={'SettingAdmin'} />
            <section className="content">
                <div className="block-header">
                    <h2>{t('Edit Profile')}</h2>
                </div>
                <div className="tab_content_block setting">
                    {/* <div>
                        <div className="main_class setting">
                            <h3 className="title_heading">Edit Profile</h3>
                            <div className="form_block outline_form">
                                <div className="row clearfix">
                                    <div className="col-sm-6">
                                        <div class="form-group">
                                            <div class="form-line">
                                                <div class="custom-file">
                                                    <input type="file" title="Select Your Company Image" class="custom-file-input" id="customFile" />

                                                    <label class="custom-file-label" for="customFile">{"Select Your Profile Image"}</label>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <div className="form-line">
                                                <input
                                                    type="text"
                                                    title={t('First Name')}

                                                    className="form-control"
                                                    placeholder={t('First Name')}
                                                />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row clearfix">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <div className="form-line">
                                                <input
                                                    type="text"
                                                    title={t('Last Name')}

                                                    className="form-control"
                                                    placeholder={t('Last Name')}
                                                />

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <div className="form-line">
                                                <input
                                                    type="text"
                                                    title={t('Mobile No')}

                                                    maxLength={6}


                                                    className="form-control"
                                                    placeholder={t('Mobile No')}
                                                />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="button_block">
                                <button className="links">{t('save')}</button>
                            </div>
                        </div>
                    </div> */}

                    <div class="panel-group full-body" id="accordion_3" role="tablist" aria-multiselectable="true">
                        <div class="panel">


                            <div id="collapseOne_3" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne_3">
                                <div class="panel-body">
                                    <div class="card">
                                        <div className='form_block'>
                                            <div class="row clearfix">
                                                <div class="col-sm-6">
                                                    <div class="form-group">
                                                        <div class="form-line">
                                                            <input type="text" class="form-control" title={t('firstName')} placeholder={t('firstName')} />

                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <div class="form-group">
                                                        <div class="form-line">
                                                            <input type="text" class="form-control" title={t('lastName')} placeholder={t('lastName')} />

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row clearfix">
                                                <div class="col-sm-6">
                                                    <div class="form-group">
                                                        <div class="form-line">
                                                            <input type="text" class="form-control" title={t('email')} placeholder={t('email')} />

                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <div class="form-group">
                                                        <div class="form-line">
                                                            <input type="text" class="form-control" title={t('contact')} placeholder={t('contact')} />

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row clearfix">
                                                <div class="col-sm-6">
                                                    <div class="form-group">
                                                        <div class="form-line">
                                                            <input type="text" class="form-control" title={t('landlineNo')} placeholder={t('landlineNo')} id="field4" />

                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <div class="form-group">
                                                        <div class="form-line">
                                                            <input type="text" class="form-control" title={t('whatsappNo')} placeholder={t('whatsappNo')} maxLength={15} />

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row clearfix">
                                                <div class="col-sm-6">
                                                    <div>
                                                        <FormControl className='form-group' sx={{}}>
                                                            <Select
                                                                className='form-control border_temp'
                                                                sx={{ borderColor: 'transparent' }}
                                                                displayEmpty
                                                                value={expSince}
                                                                onChange={(e) => { setExpSince(e.target.value); }}
                                                                input={<OutlinedInput />}
                                                                title={t('expSincePlaceHolder')}
                                                                MenuProps={{
                                                                    PaperProps: {
                                                                        style: {
                                                                            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                            width: 250,
                                                                        },
                                                                    },
                                                                    disableScrollLock: true,
                                                                }}
                                                                inputProps={{ 'aria-label': 'Without label' }}
                                                            >
                                                                <MenuItem className='drop_menus' value="">
                                                                    <em className='select_text'>{t('expSincePlaceHolder')}</em>
                                                                </MenuItem>
                                                                {yearsArray?.map((item) => (
                                                                    <MenuItem
                                                                        className='drop_menus'
                                                                        key={item?.Id}
                                                                        value={item?.Id}
                                                                        style={getStyles(item?.Name, personName, theme)}
                                                                    >
                                                                        {item?.Name}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>

                                                        </FormControl>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <div>
                                                        <FormControl className='form-group' sx={{}}>
                                                            <Select
                                                                className='form-control border_temp'
                                                                sx={{ borderColor: 'transparent' }}
                                                                title={t('nationality')}
                                                                displayEmpty
                                                                value={nationality}
                                                                onChange={(e) => { setNationality(e.target.value); }}
                                                                input={<OutlinedInput />}

                                                                MenuProps={{
                                                                    PaperProps: {
                                                                        style: {
                                                                            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                            width: 250,
                                                                        },
                                                                    },
                                                                    disableScrollLock: true,
                                                                }}
                                                                inputProps={{ 'aria-label': 'Without label' }}
                                                            >
                                                                <MenuItem className='drop_menus' value="">
                                                                    <em className='select_text'>{t('nationality')}</em>
                                                                </MenuItem>
                                                                {userDropdownData?.Countries?.map((item) => (
                                                                    <MenuItem
                                                                        className='drop_menus'
                                                                        key={item?.Id}
                                                                        value={item?.Id}
                                                                        style={getStyles(item?.Name, personName, theme)}
                                                                    >
                                                                        {item?.Name}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>

                                                        </FormControl>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row clearfix">
                                                <div class="col-sm-6" id="field5">
                                                    <div class="form-group">
                                                        <div class="form-line">
                                                            <div class="custom-file custom_file_group" >
                                                                <input type="file" title="Profile Image" class="custom-file-input" id="customFile" />

                                                                <label class="custom-file-label" for="customFile">{"Profile Image"}</label>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6" id="field6">
                                                    <div>

                                                        <FormControl className='form-group' sx={{}}>
                                                            <Select
                                                                className='form-control border_temp'
                                                                sx={{ borderColor: 'transparent' }}
                                                                title={t('nationality')}
                                                                displayEmpty
                                                                value={nationality}
                                                                onChange={(e) => { setNationality(e.target.value); }}
                                                                input={<OutlinedInput />}

                                                                MenuProps={{
                                                                    PaperProps: {
                                                                        style: {
                                                                            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                            width: 250,
                                                                        },
                                                                    },
                                                                    disableScrollLock: true,
                                                                }}
                                                                inputProps={{ 'aria-label': 'Without label' }}
                                                            >
                                                                <MenuItem className='drop_menus' value="">
                                                                    <em className='select_text'>{t('languagePlaceHolder')}</em>
                                                                </MenuItem>
                                                                {userDropdownData?.Countries?.map((item) => (
                                                                    <MenuItem
                                                                        className='drop_menus'
                                                                        key={item?.Id}
                                                                        value={item?.Id}
                                                                        style={getStyles(item?.Name, personName, theme)}
                                                                    >
                                                                        {item?.Name}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>

                                                        </FormControl>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row clearfix">
                                                <div class="col-sm-6">
                                                    <div class="form-group">
                                                        <div class="form-line">
                                                            <input type="text" class="form-control" title={t('position')} placeholder={t('position')} />

                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-sm-6">
                                                    <div>


                                                        <FormControl className='form-group' sx={{}}>
                                                            <Select
                                                                className='form-control border_temp'
                                                                sx={{ borderColor: 'transparent' }}
                                                                title={t('nationality')}
                                                                displayEmpty
                                                                value={nationality}
                                                                onChange={(e) => { setNationality(e.target.value); }}
                                                                input={<OutlinedInput />}

                                                                MenuProps={{
                                                                    PaperProps: {
                                                                        style: {
                                                                            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                            width: 250,
                                                                        },
                                                                    },
                                                                    disableScrollLock: true,
                                                                }}
                                                                inputProps={{ 'aria-label': 'Without label' }}
                                                            >
                                                                <MenuItem className='drop_menus' value="">
                                                                    <em className='select_text'>{t('pfExpertLanguage')}</em>
                                                                </MenuItem>
                                                                {userDropdownData?.Countries?.map((item) => (
                                                                    <MenuItem
                                                                        className='drop_menus'
                                                                        key={item?.Id}
                                                                        value={item?.Id}
                                                                        style={getStyles(item?.Name, personName, theme)}
                                                                    >
                                                                        {item?.Name}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>

                                                        </FormControl>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row clearfix">
                                                <div class="col-sm-6">
                                                    <div class="form-group">
                                                        <div class="form-line">
                                                            <input type="text" class="form-control" title={t('linkedinUrl')} placeholder={t('linkedinUrl')} />

                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-sm-6">
                                                    <div class="form-group">
                                                        <div class="form-line">
                                                            <input type="text" class="form-control" title={t('spokenLanguages')} placeholder={t('spokenLanguages')} />

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="button_block" onClick={() => navigate('/DashBoard')}>
                                                <a href="#" class="links">{t('save')}</a>

                                            </div>



                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <ToastContainer />
        </div>
    );
}

export default EditProfile;
