// import React, { useState } from 'react';
import axios from 'axios';
import Sidebar from '../../component/Sidebar';
import Topbar from '../../component/Topbar';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Slider from '@mui/material/Slider';
import './style.css'; // Import your CSS file for component styling

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Backdrop, CircularProgress } from '@mui/material';

import { useDispatch } from 'react-redux';
import config from '../../redux/config';
import { useNavigate } from 'react-router-dom';
import { logoutApi } from '../../redux/ApiActionCreator';
import { ToastContainer, toast } from 'react-toastify';


const Settings = () => {
  // State to manage watermark type and settings
  const dispatch = useDispatch()

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const [watermarkType, setWatermarkType] = useState(''); // 'none', 'image', 'text'
  const [opacity, setOpacity] = useState(); // Initial opacity value
  const [proportional, setProportional] = useState(); // Initial proportional value
  const [text, setText] = useState(""); // Text input for watermark
  const [textError, setTextError] = useState("");
  const [selectedPosition, setSelectedPosition] = useState(''); // Selected position for watermark
  const [watermarkUrl, setWatermarkUrl] = useState('');
  const token = useSelector((state) => state?.apiReducer?.loginData?.Data?.accessToken)
  const [selectedPositionNumber, setSelectedPositionNumber] = useState(3)
  const getWatermarkSettingsDataApiCall = async () => {
    setLoading(true)
    try {
      const response = await axios.get(`${config.baseURL}/Company/WaterMarkSettings`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log("wset", response)
      if (response.status === 200) {
        setLoading(false)
        setWatermarkType(response?.data?.Data?.WatermarkPreferenceId == null ? "image" : response?.data?.Data?.WatermarkPreferenceId == 1 ? "image" : response?.data?.Data?.WatermarkPreferenceId == 2 ? "text" : "none")

        setSelectedPositionNumber(response?.data?.Data?.Position == null ? 3 : response?.data?.Data?.Position)
        setSelectedPosition((response?.data?.Data?.Position == 3 || response?.data?.Data?.Position == null) ? 'top-right' : response?.data?.Data?.Position == 1 ? 'top-left' : response?.data?.Data?.Position == 2 ? 'top-center' : response?.data?.Data?.Position == 4 ? 'center-left' : response?.data?.Data?.Position == 5 ? 'center-center' : response?.data?.Data?.Position == 6 ? 'center-right' : response?.data?.Data?.Position == 7 ? 'bottom-left' : response?.data?.Data?.Position == 8 ? 'bottom-center' : "bottom-right")
        setText(response?.data?.Data?.WatermarkText == null ? "" : response?.data?.Data?.WatermarkText)
        setOpacity(response?.data?.Data?.Opecity == null ? 0.60 : response?.data?.Data?.Opecity)
        setProportional(response?.data?.Data?.Proportional == null ? 70 : response?.data?.Data?.Proportional)
        setWatermarkUrl(response?.data?.Data?.LogoUrl)
      } else if (response.data.Status === 'ERROR') {
        // Handle error if needed
      }
    } catch (error) {
      setLoading(false)
      if (error.response && error.response.status === 401) {
        dispatch(logoutApi());
        navigate('/');
      }
    }
  };

  const validateText = (value) => {
    if (value.length === 0) {
      setTextError('Text cannot be Blank');
    } else {
      setTextError('');
    }
  };

  const addWatermarkSettingsDataApiCall = async () => {
    let validate = false
    if (text == '' && watermarkType == 'text') {
      validate = true
      setTextError("Text cannot be blank");
    } else {
      setTextError('');
    }
    if (validate == false) {
      setLoading(true)
      const params = {
        "Position": parseInt(selectedPositionNumber),
        "Opecity": opacity,
        "WatermarkText": text,
        "WatermarkPreferenceId": watermarkType == 'image' ? parseInt(1) : watermarkType == 'text' ? parseInt(2) : parseInt(3),
        "Proportional": parseInt(proportional)
      }
      console.log('dummy', params)
      try {
        const response = await axios.post(`${config.baseURL}/Company/WaterMarkSettings`, params, {
          headers: { Authorization: `Bearer ${token}` },
        });
        console.log("addwatermarksettings", response)
        if (response.status === 200 && response.data.Status === 'OK') {
          setLoading(false)

          getWatermarkSettingsDataApiCall()
          toast.success("Watermark Settings Updated Successfully", {
            position: 'top-right',
            autoClose: 1000, // Adjust the duration as needed
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } else if (response.data.Status === 'ERROR') {
          // Handle error if needed
        }
      } catch (error) {
        setLoading(false)
        if (error.response && error.response.status === 401) {
          dispatch(logoutApi());
          navigate('/');
        }
      }
    }
  };

  useEffect(() => {
    getWatermarkSettingsDataApiCall()
  }, [])
  // Handle watermark type change
  const handleWatermarkTypeChange = (event) => {
    setWatermarkType(event.target.value);
  };

  // Handle opacity change
  const handleOpacityChange = (e) => {
    setOpacity(parseFloat(e.target.value));
  };

  // Handle proportional change
  const handleProportionalChange = (e) => {
    setProportional(parseInt(e.target.value));
  };

  // Handle text input change
  const handleTextChange = (e) => {
    setText(e.target.value);
    validateText(e.target.value)
  };

  // Handle click on image position
  const handleImagePositionClick = (position, number) => {
    setSelectedPosition(position);
    setSelectedPositionNumber(number)
    // Here you can send `position` to backend through API
  };


  // Function to render watermark preview based on type
  const renderWatermarkPreview = () => {
    if (watermarkType === 'image') {
      // Render image watermark preview
      return (
        <img
          src={`${config.baseURL}${watermarkUrl}`}
          alt="Watermark Preview"
          className="watermark-preview"
          style={{
            opacity: opacity,
            width: `${proportional * 6}%`, // Adjust width based on proportional value
          }}
        />
      );
    } else if (watermarkType === 'text') {
      // Render text watermark preview
      return (
        <div className="watermark-preview" style={{ opacity: opacity, color: 'black' }}>
          {text}
        </div>
      );
    } else {
      return null; // No watermark selected
    }
  };

  return (
    <div className='App'>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}

      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Topbar />
      <Sidebar message={'Settings'} />
      <section className="content">
        <div className="watermark-settings-page d-flex">

          {/* Left section */}
          <div className="left-section align-self-center">
            <h2>Watermark Settings</h2>
            {/* Watermark type options */}
            <div className="watermark-type-options">
           
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                class="setting_radio_button_block"
                value={watermarkType}
                onChange={handleWatermarkTypeChange}

              >
                <FormControlLabel value="image" control={<Radio />} label="Image as Watermark" />
                <FormControlLabel value="text" control={<Radio />} label="Text as Watermark" />
                <FormControlLabel value="none" control={<Radio />} label="Disabled" />
              </RadioGroup>
            
              <br />
            
            </div>

            {/* Conditional options based on watermark type */}
            {watermarkType === 'image' && (
              <div className="image-watermark-options">
                <label>Opacity: {opacity}</label>
            
                <Slider
                  min={0}
                  max={1}
                  step={0.01}
                  value={opacity}
                  onChange={handleOpacityChange}
                  aria-label="Opacity"
                />
                <br />
                <label>Proportional: {proportional}</label>
                <Slider
                  min={0}
                  max={100}
                  step={1}
                  value={proportional}
                  onChange={handleProportionalChange}
                  aria-label="Proportional"
                />
              
              </div>

            )}

            {watermarkType === 'text' && (
              <div className="text-watermark-options">
                <label style={{ marginRight: '10px', display: 'block' }}>Text : </label>
                <input
                  className='custom_text'
                  maxLength={17}
                  type="text"
                  value={text}
                  onChange={handleTextChange}
                  placeholder="Enter text for watermark"
                />
                {
                  textError && (
                    <span class="validation_notes">{textError}</span>

                  )
                }
              </div>
            )}
            <button type="submit" onClick={() => addWatermarkSettingsDataApiCall()} className="btn btn-primary links waves-effect">
              Submit
            </button>
          </div>

          {/* Right section */}
          <div className="right-section">
            {/* Static image container */}
            <div className="image-container">
              <img src={require('../../images/background_watermark.png')} alt="Preview Image" className="preview-image" />
              {/* Touchpoints for watermark positioning */}
              {watermarkType != "none" && (
                <div className="touchpoints">
                  {/* Assuming touchpoints as clickable areas */}
                  <div className="touchpoint top-left" style={{ backgroundColor: selectedPosition === 'top-left' && 'transparent', border: selectedPosition === 'top-left' && '0px' }} onClick={() => handleImagePositionClick('top-left', 1)} >
                    {selectedPosition === 'top-left' && renderWatermarkPreview()}
                  </div>
                  <div className="touchpoint top-center" style={{ backgroundColor: selectedPosition === 'top-center' && 'transparent', border: selectedPosition === 'top-center' && '0px' }} onClick={() => handleImagePositionClick('top-center', 2)}>
                    {selectedPosition === 'top-center' && renderWatermarkPreview()}
                  </div>
                  <div className="touchpoint top-right" style={{ backgroundColor: selectedPosition === 'top-right' && 'transparent', border: selectedPosition === 'top-right' && '0px' }} onClick={() => handleImagePositionClick('top-right', 3)}>
                    {selectedPosition === 'top-right' && renderWatermarkPreview()}
                  </div>
                  <div className="touchpoint center-left" style={{ backgroundColor: selectedPosition === 'center-left' && 'transparent', border: selectedPosition === 'center-left' && '0px' }} onClick={() => handleImagePositionClick('center-left', 4)}>
                    {selectedPosition === 'center-left' && renderWatermarkPreview()}
                  </div>
                  <div className="touchpoint center-center" style={{ backgroundColor: selectedPosition === 'center-center' && 'transparent', border: selectedPosition === 'center-center' && '0px' }} onClick={() => handleImagePositionClick('center-center', 5)}>
                    {selectedPosition === 'center-center' && renderWatermarkPreview()}
                  </div>
                  <div className="touchpoint center-right" style={{ backgroundColor: selectedPosition === 'center-right' && 'transparent', border: selectedPosition === 'center-right' && '0px' }} onClick={() => handleImagePositionClick('center-right', 6)}>
                    {selectedPosition === 'center-right' && renderWatermarkPreview()}
                  </div>
                  <div className="touchpoint bottom-left" style={{ backgroundColor: selectedPosition === 'bottom-left' && 'transparent', border: selectedPosition === 'bottom-left' && '0px' }} onClick={() => handleImagePositionClick('bottom-left', 7)}>
                    {selectedPosition === 'bottom-left' && renderWatermarkPreview()}
                  </div>
                  <div className="touchpoint bottom-center" style={{ backgroundColor: selectedPosition === 'bottom-center' && 'transparent', border: selectedPosition === 'bottom-center' && '0px' }} onClick={() => handleImagePositionClick('bottom-center', 8)}>
                    {selectedPosition === 'bottom-center' && renderWatermarkPreview()}
                  </div>
                  <div className="touchpoint bottom-right" style={{ backgroundColor: selectedPosition === 'bottom-right' && 'transparent', border: selectedPosition === 'bottom-right' && '0px' }} onClick={() => handleImagePositionClick('bottom-right', 9)}>
                    {selectedPosition === 'bottom-right' && renderWatermarkPreview()}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />

    </div>
  );
};

export default Settings;
