import ACTION_TYPES from './ActionTypes';

const initialState = {
    loginLoad: false,
    loginData: [],
    loginError: null,
    agentLoad: false,
    agentData: [],
    agentError: null,
    companyNameLoad: false,
    companyNameList: [],
    companyError: null,
    agentNameLoad: false,
    agentNameData: [],
    agentNameError: false,
    countryLoad: false,
    countryData: [],
    countryError: null,
    stateLoad: false,
    stateData: [],
    stateError: null,
    cityLoad: false,
    cityData: [],
    cityError: null,
    getAgentDetailsLoad: false,
    getAgentDetailsData: [],
    getAgentDetailsError: null,
    createAgentLoad: false,
    createAgentData: [],
    createAgentError: null,
    editAgentLoad: false,
    editAgentData: [],
    editAgentError: null,
    propertyTypeLoad: false,
    propertyTypeData: [],
    propertyTypeError: null,
    furnishingTypeLoad: false,
    furnishingTypeData: [],
    furnishingTypeError: null,
    occupancyLoad: false,
    occupancyData: [],
    occupancyError: null,
    renovationLoad: false,
    renovationData: [],
    renovationError: null,
    deleteAgentLoad: false,
    deleteAgentData: [],
    deleteAgentError: null,
    agentOffData: '',
    tenureLoad: false,
    tenureData: [],
    tenureError: null,
    propertyCategoryLoad: false,
    propertyCategoryData: [],
    propertyCategoryError: null,
    maritialStatusLoad: false,
    maritialStatusData: [],
    maritialStatusError: null,
    agentUploadDocumentsListLoad: false,
    agentUploadDocumentsListData: [],
    agentUploadDocumentsListError: null,
    agentCreditListLoad: false,
    agentCreditListData: [],
    agentCreditListError: null,
    deleteAgentCreditListLoad: false,
    deleteAgentCreditListData: [],
    deleteAgentCreditListError: null,
    addAgentCreditListLoad: false,
    addAgentCreditListData: [],
    addAgentCreditListError: null,
    addPropertyLoad: false,
    addPropertyData: [],
    addPropertyError: null,
    propertyOfflineData: [],
    propertyNotesLoad: false,
    propertyNotesData: [],
    propertyNotesError: null,
    propertyFilterLoad: false,
    propertyFilterData: [],
    propertyFilterError: null,
    propertyMasterDataLoad: false,
    propertyMasterData: [],
    propertyMasterDataError: null,
    updatePropertyLoad: false,
    updatePropertyData: [],
    updatePropertyError: null,
    imageMediaLoad: false,
    imageMediaListData: [],
    imageMediaError: null,
    propertyDocumentsListLoad: false,
    propertyDocumentsListData: [],
    propertyDocumentsListError: null,
    uploadImageUrlLoad: false,
    uploadImageUrlData: [],
    uploadVideoUrlError: null,
    deletePropertyDocumentsListLoad: false,
    deletePropertyDocumentsListData: [],
    deletePropertyDocumentsListError: null,
    getPropertyLoad: false,
    getPropertyData: [],
    getPropertyError: null,
    deletePropertyLoad: false,
    deletePropertyData: [],
    deletePropertyError: null,
    propertyNoteListLoad: false,
    propertyNotesListData: [],
    propertyNotesListError: null,
    leadsDropdownLoad: false,
    leadsDropdownData: [],
    leadsDropdownError: null,
    leadOfflineData: [],
    leadCreateload: false,
    leadCreateData: [],
    leadCreateError: null,
    leadUpdateLoad: false,
    leadUpdateData: [],
    leadUpdateError: null,
    leadListLoad: false,
    leadListData: [],
    leadListError: null,
    getLeadDetailsLoad: false,
    getLeadDetailsData: [],
    getLeadDetailsError: null,
    addNotesLeadsLoad: false,
    addNotesLeadsData: [],
    addNotesLeadsError: null,
    deleteLeadLoad: false,
    deleteLeadData: [],
    deleteLeadError: null,
    notesListLeadLoad: false,
    notesListLeadData: [],
    notesListLeadError: null,
    documentsListLeadLoad: false,
    documentsListLeadData: [],
    documentsListLeadError: null,
    deleteLeadDocumentsLoad: false,
    deleteLeadDocumentsData: [],
    deleteLeadDocumentsError: null,
    addLeadRequirementsLoad: false,
    addLeadRequirementsData: [],
    addLeadRequirementsError: null,
    editLeadRequirementsLoad: false,
    editLeadRequirementsData: [],
    editLeadRequirementsError: null,
    getByIdLeadRequirementsLoad: false,
    getByIdLeadRequirementsData: [],
    getByIdLeadRequirementsError: null,
    shortlistedPropertiesLoad: false,
    shortlistedPropertiesData: [],
    shortlistedPropertiesError: null,
    matchingListingsLoad: false,
    matchingListingsData: [],
    matchingListingsError: null,
    addShortlistedPropertiesLoad: false,
    addShortlistedPropertiesData: [],
    addShortlistedPropertiesError: null,
    createUserLoad: false,
    createUserData: [],
    createUserError: null,
    userDropdownLoad: false,
    userDropdownData: [],
    userDropdownErr: null,
    userDocumentListLoad: false,
    userDocumentListData: [],
    userDocumentListError: null,
    userDeleteDocumentLoad: false,
    userDeleteDocumentData: [],
    userDeleteDocumentError: null,
    userOfflineData: [],
    getByIdUserLoad: false,
    getByIdUserData: [],
    getByIdUserError: null,
    userListLoad: false,
    userListData: [],
    userListError: null,
    userDeleteLoad: false,
    userDeleteData: [],
    userDeleteError: null,
    updateUserLoad: false,
    updateUserData: [],
    updateUserError: null,
    companyProfileSettingsUpdateLoad: false,
    companyProfileSettingsUpdateData: [],
    companyProfileSettingsUpdateError: null,
    getCompanyProfileSettingsDetailsLoad: false,
    getCompanyProfileSettingsDetailsData: [],
    getCompanyProfileSettingsDetailsError: null,
    locationLoad: false,
    locationData: [],
    locationError: null,
    addLocationLoad: false,
    addLocationData: [],
    addLocationError: null,
    createOwnerLoad: false,
    createOwnerData: [],
    createOwnerError: null,
    ownerListLoad: false,
    ownerListData: [],
    ownerListError: null,
    editOwnerLoad: false,
    editOwnerData: [],
    editOwnerError: null,
    ownerDropdownLoad: false,
    ownerDropdownData: [],
    ownerDropdownError: null,
    ownerOfflineData: [],
    getByIdOwnerLoad: false,
    getByIdOwnerData: [],
    getByIdOwnerError: null,
    deleteOwnerLoad: false,
    deleteOwnerData: [],
    deleteOwnerError: null,
    addNotesOwnerLoad: false,
    addNotesOwnerData: [],
    addNotesOwnerError: null,
    notesListOwnerLoad: false,
    notesListOwnerData: [],
    notesListOwnerError: null,
    ownerDocumentsLoad: false,
    ownerDocumentsData: [],
    ownerDocumentsError: null,
    deleteOwnerDocumentsLoad: false,
    deleteOwnerDocumentsData: [],
    deleteOwnerDocumentsError: null,
    calculateFlexCostLoad: false,
    calculateFlexCostData: [],
    calculateFlexCostError: null,
    ownerPropertiesLoad: false,
    ownerPropertiesData: [],
    ownerPropertiesError: null,
    checkPublishCreditsLoad: false,
    checkPublishCreditsData: [],
    checkPublishCreditsError: null,
    floorPlanDocListLoad: false,
    floorPlanDocListData: [],
    floorPlanDocListError: null,
    ownerPropertyDeleteLoad: false,
    ownerPropertyDeleteData: [],
    ownerPropertyDeleteError: null,
    testimonialsListLoad: false,
    testimonialsListData: [],
    testimonialsListError: null,
    testimonialDeleteLoad: false,
    testimonialDeleteData: [],
    testimonialDeleteError: null,
    testimonialApproveLoad: false,
    testimonialApproveData: [],
    testimonialApproveError: null,
    blogCreateLoad: false,
    blogCreateData: [],
    blogCreateError: null,
    blogUpdateLoad: false,
    blogUpdateData: [],
    blogUpdateError: null,
    blogListLoad: false,
    blogListData: [],
    blogListError: null,
    blogGetByIdLoad: false,
    blogGetByIdData: [],
    blogGetByIdError: null,
    blogDeleteLoad: false,
    blogDeleteData: [],
    blogDeleteError: null,
    advertisementImageListLoad: false,
    advertisementImageListData: [],
    advertisementImageListError: null,
    advertisementImageListVerticalLoad: false,
    advertisementImageListVerticalData: [],
    advertisementImageListVerticalError: null,
    howItWorksCounterGetByIdLoad: false,
    howItWorksCounterGetByIdData: [],
    howItWorksCounterGetByIdError: null,
    howItWorksCounterUpdateLoad: false,
    howItWorksCounterUpdateData: [],
    howItWorksCounterUpdateError: null,
    deleteAdvertisementImageLoad: false,
    deleteAdvertisementImageData: [],
    deleteAdvertisementImageError: null,
    userMode: [],
    ownerMode: [],
    leadMode: [],
    propertyMode: [],
    companyMode: [],
    creditManageLoad: false,
    creditManageData: [],
    creditManageError: null,
    unpublishedPropertiesCreditManageLoad: false,
    unpublishedPropertiesCreditManageData: [],
    unpublishedPropertiesCreditManageError: null,
    ownerPropertyEdit: [],
    deleteImageLoad: false,
    deleteImageData: [],
    deleteImageError: null,
    amenitiesMediaLoad: false,
    amenitiesMediaData: [],
    amenitiesMediaError: null,
    exportToCsvLoad: false,
    exportToCsvData: [],
    exportToCsvError: null,
    settingsAdminAddLoad: false,
    settingsAdminAddData: [],
    settingsAdminAddError: null,
    addSubAgentCreditLoad: false,
    addSubAgentCreditData: [],
    addSubAgentCreditError: null,
    withdrawSubAgentCreditLoad: false,
    withdrawSubAgentCreditData: [],
    withdrawSubAgentCreditError: null,
    publishRequestApproveRejectLoad: false,
    publishRequestApproveRejectData: [],
    publishRequestApproveRejectError: null,
    blogFlagData: [],
    mimicFlag: false,
    mimicData: []



};

const apiReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.LOGIN_PENDING:
            return {
                ...state,
                loginLoad: true,
            };
        case ACTION_TYPES.LOGIN_SUCCESS:
            return {
                ...state,
                loginData: action.payload,
                loginLoad: false,
            };
        case ACTION_TYPES.LOGIN_ERROR:
            return {
                ...state,
                loginError: action.payload,
                loginLoad: false,
            };
        case ACTION_TYPES.MIMIC_PENDING:
            return {
                ...state,
                loginLoad: true,
                mimicFlag: false
            };
        case ACTION_TYPES.MIMIC_SUCCESS:
            return {
                ...state,
                loginData: action.payload,
                loginLoad: false,
                mimicFlag: true
            };
        case ACTION_TYPES.MIMIC_ERROR:
            return {
                ...state,
                loginError: action.payload,
                loginLoad: false,
                mimicFlag: false
            };
        case ACTION_TYPES.MIMIC_BACK:
            return {
                ...state,
                mimicData: action.payload,

            };
        case ACTION_TYPES.AGENTLIST_PENDING:
            return {
                ...state,
                agentLoad: true,
            };
        case ACTION_TYPES.AGENTLIST_SUCCESS:
            return {
                ...state,
                agentData: action.payload,
                agentLoad: false,
            };
        case ACTION_TYPES.AGENTLIST_ERROR:
            return {
                ...state,
                agentData: action.payload,
                agentLoad: false,
            };
        case ACTION_TYPES.COMPNAME_LIST_PENDING:
            return {
                ...state,
                companyNameLoad: true,
            };
        case ACTION_TYPES.COMPNAME_LIST_SUCCESS:
            return {
                ...state,
                companyNameList: action.payload,
                companyNameLoad: false,
            };
        case ACTION_TYPES.COMPNAME_LIST_ERROR:
            return {
                ...state,
                companyError: action.payload,
                companyNameLoad: false,
            };
        case ACTION_TYPES.AGENTNAME_PENDING:
            return {
                ...state,
                agentNameLoad: true,
            };
        case ACTION_TYPES.AGENTNAME_SUCCESS:
            return {
                ...state,
                agentNameData: action.payload,
                agentNameLoad: false,
            };
        case ACTION_TYPES.AGENTNAME_ERROR:
            return {
                ...state,
                agentNameError: action.payload,
                agentNameLoad: false,
            };
        case ACTION_TYPES.COUNTRY_PENDING:
            return {
                ...state,
                countryLoad: true,
            };
        case ACTION_TYPES.COUNTRY_SUCCESS:
            return {
                ...state,
                countryData: action.payload,
                countryLoad: false,
            };
        case ACTION_TYPES.COUNTRY_ERROR:
            return {
                ...state,
                countryError: action.payload,
                countryLoad: false,
            };

        case ACTION_TYPES.STATE_PENDING:
            return {
                ...state,
                stateLoad: true,
            };
        case ACTION_TYPES.STATE_SUCCESS:
            return {
                ...state,
                stateData: action.payload,
                stateLoad: false,
            };
        case ACTION_TYPES.STATE_ERROR:
            return {
                ...state,
                stateError: action.payload,
                stateLoad: false,
            };

        case ACTION_TYPES.CITY_PENDING:
            return {
                ...state,
                cityLoad: true,
            };
        case ACTION_TYPES.CITY_SUCCESS:
            return {
                ...state,
                cityData: action.payload,
                cityLoad: false,
            };
        case ACTION_TYPES.CITY_ERROR:
            return {
                ...state,
                cityError: action.payload,
                cityLoad: false,
            };

        case ACTION_TYPES.GET_AGENT_DETAILS_PENDING:
            return {
                ...state,
                getAgentDetailsLoad: true,
            };
        case ACTION_TYPES.GET_AGENT_DETAILS_SUCCESS:
            return {
                ...state,
                getAgentDetailsData: action.payload,
                getAgentDetailsLoad: false,
            };
        case ACTION_TYPES.GET_AGENT_DETAILS_ERROR:
            return {
                ...state,
                getAgentDetailsError: action.payload,
                getAgentDetailsLoad: false,
            };

        case ACTION_TYPES.CREATE_AGENT_PENDING:
            return {
                ...state,
                createAgentLoad: true,
            };
        case ACTION_TYPES.CREATE_AGENT_SUCCESS:
            return {
                ...state,
                createAgentData: action.payload,
                createAgentLoad: false,
            };
        case ACTION_TYPES.CREATE_AGENT_ERROR:
            return {
                ...state,
                createAgentError: action.payload,
                createAgentLoad: false,
            };

        case ACTION_TYPES.EDIT_AGENT_PENDING:
            return {
                ...state,
                editAgentLoad: true,
            };
        case ACTION_TYPES.EDIT_AGENT_SUCCESS:
            return {
                ...state,
                editAgentData: action.payload,
                editAgentLoad: false,
            };
        case ACTION_TYPES.EDIT_AGENT_ERROR:
            return {
                ...state,
                editAgentError: action.payload,
                editAgentLoad: false,
            };

        case ACTION_TYPES.PROPERTY_TYPE_PENDING:
            return {
                ...state,
                propertyTypeLoad: true,
            };
        case ACTION_TYPES.PROPERTY_TYPE_SUCCESS:
            return {
                ...state,
                propertyTypeData: action.payload,
                propertyTypeLoad: false,
            };
        case ACTION_TYPES.PROPERTY_TYPE_ERROR:
            return {
                ...state,
                propertyTypeError: action.payload,
                propertyTypeLoad: false,
            };
        case ACTION_TYPES.FURNISHING_TYPE_PENDING:
            return {
                ...state,
                furnishingTypeLoad: true,
            };
        case ACTION_TYPES.FURNISHING_TYPE_SUCCESS:
            return {
                ...state,
                furnishingTypeData: action.payload,
                furnishingTypeLoad: false,
            };
        case ACTION_TYPES.FURNISHING_TYPE_ERROR:
            return {
                ...state,
                furnishingTypeError: action.payload,
                furnishingTypeLoad: false,
            };
        case ACTION_TYPES.OCCUPANCY_TYPE_PENDING:
            return {
                ...state,
                occupancyLoad: true,
            };
        case ACTION_TYPES.OCCUPANCY_TYPE_SUCCESS:
            return {
                ...state,
                occupancyData: action.payload,
                occupancyLoad: false,
            };
        case ACTION_TYPES.OCCUPANCY_TYPE_ERROR:
            return {
                ...state,
                occupancyError: action.payload,
                occupancyLoad: false,
            };
        case ACTION_TYPES.RENOVATION_TYPE_PENDING:
            return {
                ...state,
                renovationLoad: true,
            };
        case ACTION_TYPES.RENOVATION_TYPE_SUCCESS:
            return {
                ...state,
                renovationData: action.payload,
                renovationLoad: false,
            };
        case ACTION_TYPES.RENOVATION_TYPE_ERROR:
            return {
                ...state,
                renovationError: action.payload,
                renovationLoad: false,
            };

        case ACTION_TYPES.DELETE_AGENT_PENDING:
            return {
                ...state,
                deleteAgentLoad: true,
            };
        case ACTION_TYPES.DELETE_AGENT_SUCCESS:
            return {
                ...state,
                deleteAgentData: action.payload,
                deleteAgentLoad: false,
            };
        case ACTION_TYPES.DELETE_AGENT_ERROR:
            return {
                ...state,
                deleteAgentError: action.payload,
                deleteAgentLoad: false,
            };
        case ACTION_TYPES.AGENTDETAIL:
            return {
                ...state,
                agentOffData: action.payload,
            };
        case ACTION_TYPES.TENURE_PENDING:
            return {
                ...state,
                tenureLoad: true,
            };
        case ACTION_TYPES.TENURE_SUCCESS:
            return {
                ...state,
                tenureData: action.payload,
                tenureLoad: false,
            };
        case ACTION_TYPES.TENURE_ERROR:
            return {
                ...state,
                tenureError: action.payload,
                tenureLoad: false,
            };

        case ACTION_TYPES.PROPERTY_CATEGORY_PENDING:
            return {
                ...state,
                propertyCategoryLoad: true,
            };
        case ACTION_TYPES.PROPERTY_CATEGORY_SUCCESS:
            return {
                ...state,
                propertyCategoryData: action.payload,
                propertyCategoryLoad: false,
            };
        case ACTION_TYPES.PROPERTY_CATEGORY_ERROR:
            return {
                ...state,
                propertyCategoryError: action.payload,
                propertyCategoryLoad: false,
            };

        case ACTION_TYPES.MARITIAL_STATUS_PENDING:
            return {
                ...state,
                maritialStatusLoad: true,
            };
        case ACTION_TYPES.MARITIAL_STATUS_SUCCESS:
            return {
                ...state,
                maritialStatusData: action.payload,
                maritialStatusLoad: false,
            };
        case ACTION_TYPES.MARITIAL_STATUS_ERROR:
            return {
                ...state,
                maritialStatusError: action.payload,
                maritialStatusLoad: false,
            };

        case ACTION_TYPES.AGENT_UPLOAD_DOCUMENTS_LIST_PENDING:
            return {
                ...state,
                agentUploadDocumentsListLoad: true,
            };
        case ACTION_TYPES.AGENT_UPLOAD_DOCUMENTS_LIST_SUCCESS:
            return {
                ...state,
                agentUploadDocumentsListData: action.payload,
                agentUploadDocumentsListLoad: false,
            };
        case ACTION_TYPES.AGENT_UPLOAD_DOCUMENTS_LIST_ERROR:
            return {
                ...state,
                agentUploadDocumentsListError: action.payload,
                agentUploadDocumentsListLoad: false,
            };

        case ACTION_TYPES.AGENT_CREDIT_LIST_PENDING:
            return {
                ...state,
                agentCreditListLoad: true,
            };
        case ACTION_TYPES.AGENT_CREDIT_LIST_SUCCESS:
            return {
                ...state,
                agentCreditListData: action.payload,
                agentCreditListLoad: false,
            };
        case ACTION_TYPES.AGENT_CREDIT_LIST_ERROR:
            return {
                ...state,
                agentCreditListError: action.payload,
                agentCreditListLoad: false,
            };

        case ACTION_TYPES.DELETE_AGENT_CREDIT_LIST_PENDING:
            return {
                ...state,
                deleteAgentCreditListLoad: true,
            };
        case ACTION_TYPES.DELETE_AGENT_CREDIT_LIST_SUCCESS:
            return {
                ...state,
                deleteAgentCreditListData: action.payload,
                deleteAgentCreditListLoad: false,
            };
        case ACTION_TYPES.DELETE_AGENT_CREDIT_LIST_ERROR:
            return {
                ...state,
                deleteAgentCreditListError: action.payload,
                deleteAgentCreditListLoad: false,
            };

        case ACTION_TYPES.ADD_AGENT_CREDIT_LIST_PENDING:
            return {
                ...state,
                addAgentCreditListLoad: true,
            };
        case ACTION_TYPES.ADD_AGENT_CREDIT_LIST_SUCCESS:
            return {
                ...state,
                addAgentCreditListData: action.payload,
                addAgentCreditListLoad: false,
            };
        case ACTION_TYPES.ADD_AGENT_CREDIT_LIST_ERROR:
            return {
                ...state,
                addAgentCreditListError: action.payload,
                addAgentCreditListLoad: false,
            };

        case ACTION_TYPES.ADD_PROPERTY_PENDING:
            return {
                ...state,
                addPropertyLoad: true,
            };
        case ACTION_TYPES.ADD_PROPERTY_SUCCESS:
            return {
                ...state,
                addPropertyData: action.payload,
                addPropertyLoad: false,
            };
        case ACTION_TYPES.ADD_PROPERTY_ERROR:
            return {
                ...state,
                addPropertyError: action.payload,
                addPropertyLoad: false,
            };
        case ACTION_TYPES.ADD_NOTES_PENDING:
            return {
                ...state,
                propertyNotesLoad: true,
            };
        case ACTION_TYPES.ADD_NOTES_SUCCESS:
            return {
                ...state,
                propertyNotesData: action.payload,
                propertyNotesLoad: false,
            };
        case ACTION_TYPES.ADD_NOTES_ERROR:
            return {
                ...state,
                propertyNotesError: action.payload,
                propertyNotesLoad: false,
            };
        case ACTION_TYPES.PROPERTYFILTER_PENDING:
            return {
                ...state,
                propertyFilterLoad: true,
            };
        case ACTION_TYPES.PROPERTYFILTER_SUCCESS:
            return {
                ...state,
                propertyFilterData: action.payload,
                propertyFilterLoad: false,
            };
        case ACTION_TYPES.PROPERTYFILTER_ERROR:
            return {
                ...state,
                propertyFilterData: action.payload,
                propertyFilterLoad: false,
            };
        case ACTION_TYPES.PROPERTY_DROPDOWN_MASTERDATA_PENDING:
            return {
                ...state,
                propertyMasterDataLoad: true,
            };
        case ACTION_TYPES.PROPERTY_DROPDOWN_MASTERDATA_SUCCESS:
            return {
                ...state,
                propertyMasterData: action.payload,
                propertyMasterDataLoad: false,
            };
        case ACTION_TYPES.PROPERTY_DROPDOWN_MASTERDATA_ERROR:
            return {
                ...state,
                propertyMasterDataError: action.payload,
                propertyMasterDataLoad: false,
            };
        case ACTION_TYPES.UPDATE_PROPERTY_PENDING:
            return {
                ...state,
                updatePropertyLoad: true,
            };
        case ACTION_TYPES.UPDATE_PROPERTY_SUCCESS:
            return {
                ...state,
                updatePropertyData: action.payload,
                updatePropertyLoad: false,
            };
        case ACTION_TYPES.UPDATE_PROPERTY_ERROR:
            return {
                ...state,
                updatePropertyError: action.payload,
                updatePropertyLoad: false,
            };

        case ACTION_TYPES.IMAGEMEDIA_LIST_PENDING:
            return {
                ...state,
                imageMediaLoad: true,
            };
        case ACTION_TYPES.IMAGEMEDIA_LIST_SUCCESS:
            return {
                ...state,
                imageMediaListData: action.payload,
                imageMediaLoad: false,
            };
        case ACTION_TYPES.IMAGEMEDIA_LIST_ERROR:
            return {
                ...state,
                imageMediaError: action.payload,
                imageMediaLoad: false,
            };
        case ACTION_TYPES.PROPERTYDETAIL:
            return {
                ...state,
                propertyOfflineData: action.payload,
            };

        case ACTION_TYPES.PROPERTY_DOCUMENTS_LIST_PENDING:
            return {
                ...state,
                propertyDocumentsListLoad: true,
            };
        case ACTION_TYPES.PROPERTY_DOCUMENTS_LIST_SUCCESS:
            return {
                ...state,
                propertyDocumentsListData: action.payload,
                propertyDocumentsListLoad: false,
            };
        case ACTION_TYPES.PROPERTY_DOCUMENTS_LIST_ERROR:
            return {
                ...state,
                propertyDocumentsListError: action.payload,
                propertyDocumentsListLoad: false,
            };
        case ACTION_TYPES.UPLOAD_VIDEO_URL_PENDING:
            return {
                ...state,
                uploadImageUrlLoad: true,
            };
        case ACTION_TYPES.UPLOAD_VIDEO_URL_SUCCESS:
            return {
                ...state,
                uploadImageUrlData: action.payload,
                uploadImageUrlLoad: false,
            };
        case ACTION_TYPES.UPLOAD_VIDEO_URL_ERROR:
            return {
                ...state,
                uploadVideoUrlError: action.payload,
                uploadImageUrlLoad: false,
            };


        case ACTION_TYPES.DELETE_PROPERTY_DOCUMENTS_LIST_PENDING:
            return {
                ...state,
                deletePropertyDocumentsListLoad: true,
            };
        case ACTION_TYPES.DELETE_PROPERTY_DOCUMENTS_LIST_SUCCESS:
            return {
                ...state,
                deletePropertyDocumentsListData: action.payload,
                deletePropertyDocumentsListLoad: false,
            };
        case ACTION_TYPES.DELETE_PROPERTY_DOCUMENTS_LIST_ERROR:
            return {
                ...state,
                deletePropertyDocumentsListError: action.payload,
                deletePropertyDocumentsListLoad: false,
            };
        case ACTION_TYPES.GET_PROPERTY_DETAIL_PENDING:
            return {
                ...state,
                getPropertyLoad: true,
            };
        case ACTION_TYPES.GET_PROPERTY_DETAIL_SUCCESS:
            return {
                ...state,
                getPropertyData: action.payload,
                getPropertyLoad: false,
            };
        case ACTION_TYPES.GET_PROPERTY_DETAIL_ERROR:
            return {
                ...state,
                getPropertyError: action.payload,
                getPropertyLoad: false,
            };

        case ACTION_TYPES.DELETE_PROPERTY_PENDING:
            return {
                ...state,
                deletePropertyLoad: true,
            };
        case ACTION_TYPES.DELETE_PROPERTY_SUCCESS:
            return {
                ...state,
                deletePropertyData: action.payload,
                deletePropertyLoad: false,
            };
        case ACTION_TYPES.DELETE_PROPERTY_ERROR:
            return {
                ...state,
                deletePropertyError: action.payload,
                deletePropertyLoad: false,
            };
        case ACTION_TYPES.NOTESLIST_PENDING:
            return {
                ...state,
                propertyNoteListLoad: true,
            };
        case ACTION_TYPES.NOTESLIST_SUCCESS:
            return {
                ...state,
                propertyNotesListData: action.payload,
                propertyNoteListLoad: false,
            };
        case ACTION_TYPES.NOTESLIST_ERROR:
            return {
                ...state,
                propertyNotesListError: action.payload,
                propertyNoteListLoad: false,
            };

        case ACTION_TYPES.LEADS_DROPDOWN_PENDING:
            return {
                ...state,
                leadsDropdownLoad: true,
            };
        case ACTION_TYPES.LEADS_DROPDOWN_SUCCESS:
            return {
                ...state,
                leadsDropdownData: action.payload,
                leadsDropdownLoad: false,
            };
        case ACTION_TYPES.LEADS_DROPDOWN_ERROR:
            return {
                ...state,
                leadsDropdownError: action.payload,
                leadsDropdownLoad: false,
            };

        case ACTION_TYPES.LEADSDETAIL:
            return {
                ...state,
                leadOfflineData: action.payload,
            };
        case ACTION_TYPES.LEADS_CREATE_PENDING:
            return {
                ...state,
                leadCreateload: true,
            };
        case ACTION_TYPES.LEADS_CREATE_SUCCESS:
            return {
                ...state,
                leadCreateData: action.payload,
                leadCreateload: false,
            };
        case ACTION_TYPES.LEADS_CREATE_ERROR:
            return {
                ...state,
                leadCreateError: action.payload,
                leadCreateload: false,
            };
        case ACTION_TYPES.LEADS_UPDATE_PENDING:
            return {
                ...state,
                leadUpdateLoad: true,
            };
        case ACTION_TYPES.LEADS_UPDATE_SUCCESS:
            return {
                ...state,
                leadUpdateData: action.payload,
                leadUpdateLoad: false,
            };
        case ACTION_TYPES.LEADS_UPDATE_ERROR:
            return {
                ...state,
                leadUpdateError: action.payload,
                leadUpdateLoad: false,
            };
        case ACTION_TYPES.LEADS_LIST_PENDING:
            return {
                ...state,
                leadListLoad: true,
            };
        case ACTION_TYPES.LEADS_LIST_SUCCESS:
            return {
                ...state,
                leadListData: action.payload,
                leadListLoad: false,
            };
        case ACTION_TYPES.LEADS_LIST_ERROR:
            return {
                ...state,
                leadListData: action.payload,
                leadListLoad: false,
            };


        case ACTION_TYPES.GET_LEADS_DETAIL_PENDING:
            return {
                ...state,
                getLeadDetailsLoad: true,
            };
        case ACTION_TYPES.GET_LEADS_DETAIL_SUCCESS:
            return {
                ...state,
                getLeadDetailsData: action.payload,
                getLeadDetailsLoad: false,
            };
        case ACTION_TYPES.GET_LEADS_DETAIL_ERROR:
            return {
                ...state,
                getLeadDetailsError: action.payload,
                getLeadDetailsLoad: false,
            };

        case ACTION_TYPES.ADD_NOTES_LEADS_PENDING:
            return {
                ...state,
                addNotesLeadsLoad: true,
            };
        case ACTION_TYPES.ADD_NOTES_LEADS_SUCCESS:
            return {
                ...state,
                addNotesLeadsData: action.payload,
                addNotesLeadsLoad: false,
            };
        case ACTION_TYPES.ADD_NOTES_LEADS_ERROR:
            return {
                ...state,
                addNotesLeadsError: action.payload,
                addNotesLeadsLoad: false,
            };

        case ACTION_TYPES.DELETE_LEAD_PENDING:
            return {
                ...state,
                deleteLeadLoad: true,
            };
        case ACTION_TYPES.DELETE_LEAD_SUCCESS:
            return {
                ...state,
                deleteLeadData: action.payload,
                deleteLeadLoad: false,
            };
        case ACTION_TYPES.DELETE_LEAD_ERROR:
            return {
                ...state,
                deleteLeadError: action.payload,
                deleteLeadLoad: false,
            };

        case ACTION_TYPES.NOTESLIST_LEAD_PENDING:
            return {
                ...state,
                notesListLeadLoad: true,
            };
        case ACTION_TYPES.NOTESLIST_LEAD_SUCCESS:
            return {
                ...state,
                notesListLeadData: action.payload,
                notesListLeadLoad: false,
            };
        case ACTION_TYPES.NOTESLIST_LEAD_ERROR:
            return {
                ...state,
                notesListLeadError: action.payload,
                notesListLeadLoad: false,
            };

        case ACTION_TYPES.DOCUMENTSLIST_LEAD_PENDING:
            return {
                ...state,
                documentsListLeadLoad: true,
            };
        case ACTION_TYPES.DOCUMENTSLIST_LEAD_SUCCESS:
            return {
                ...state,
                documentsListLeadData: action.payload,
                documentsListLeadLoad: false,
            };
        case ACTION_TYPES.DOCUMENTSLIST_LEAD_ERROR:
            return {
                ...state,
                documentsListLeadError: action.payload,
                documentsListLeadLoad: false,
            };

        case ACTION_TYPES.DELETE_DOCUMENT_LEAD_PENDING:
            return {
                ...state,
                deleteLeadDocumentsLoad: true,
            };
        case ACTION_TYPES.DELETE_DOCUMENT_LEAD_SUCCESS:
            return {
                ...state,
                deleteLeadDocumentsData: action.payload,
                deleteLeadDocumentsLoad: false,
            };
        case ACTION_TYPES.DELETE_DOCUMENT_LEAD_ERROR:
            return {
                ...state,
                deleteLeadDocumentsError: action.payload,
                deleteLeadDocumentsLoad: false,
            };


        case ACTION_TYPES.ADD_LEAD_REQUIREMENTS_PENDING:
            return {
                ...state,
                addLeadRequirementsLoad: true,
            };
        case ACTION_TYPES.ADD_LEAD_REQUIREMENTS_SUCCESS:
            return {
                ...state,
                addLeadRequirementsData: action.payload,
                addLeadRequirementsLoad: false,
            };
        case ACTION_TYPES.ADD_LEAD_REQUIREMENTS_ERROR:
            return {
                ...state,
                addLeadRequirementsError: action.payload,
                addLeadRequirementsLoad: false,
            };


        case ACTION_TYPES.EDIT_LEAD_REQUIREMENTS_PENDING:
            return {
                ...state,
                editLeadRequirementsLoad: true,
            };
        case ACTION_TYPES.EDIT_LEAD_REQUIREMENTS_SUCCESS:
            return {
                ...state,
                editLeadRequirementsData: action.payload,
                editLeadRequirementsLoad: false,
            };
        case ACTION_TYPES.EDIT_LEAD_REQUIREMENTS_ERROR:
            return {
                ...state,
                editLeadRequirementsError: action.payload,
                editLeadRequirementsLoad: false,
            };

        case ACTION_TYPES.GET_BY_ID_LEAD_REQUIREMENTS_PENDING:
            return {
                ...state,
                getByIdLeadRequirementsLoad: true,
            };
        case ACTION_TYPES.GET_BY_ID_LEAD_REQUIREMENTS_SUCCESS:
            return {
                ...state,
                getByIdLeadRequirementsData: action.payload,
                getByIdLeadRequirementsLoad: false,
            };
        case ACTION_TYPES.GET_BY_ID_LEAD_REQUIREMENTS_ERROR:
            return {
                ...state,
                getByIdLeadRequirementsError: action.payload,
                getByIdLeadRequirementsLoad: false,
            };


        case ACTION_TYPES.SHORTLISTED_PROPERTIES_PENDING:
            return {
                ...state,
                shortlistedPropertiesLoad: true,
            };
        case ACTION_TYPES.SHORTLISTED_PROPERTIES_SUCCESS:
            return {
                ...state,
                shortlistedPropertiesData: action.payload,
                shortlistedPropertiesLoad: false,
            };
        case ACTION_TYPES.SHORTLISTED_PROPERTIES_ERROR:
            return {
                ...state,
                shortlistedPropertiesError: action.payload,
                shortlistedPropertiesLoad: false,
            };


        case ACTION_TYPES.MATCHING_LISTINGS_PENDING:
            return {
                ...state,
                matchingListingsLoad: true,
            };
        case ACTION_TYPES.MATCHING_LISTINGS_SUCCESS:
            return {
                ...state,
                matchingListingsData: action.payload,
                matchingListingsLoad: false,
            };
        case ACTION_TYPES.MATCHING_LISTINGS_ERROR:
            return {
                ...state,
                matchingListingsError: action.payload,
                matchingListingsLoad: false,
            };

        case ACTION_TYPES.ADD_SHORTLISTED_PROPERTIES_PENDING:
            return {
                ...state,
                addShortlistedPropertiesLoad: true,
            };
        case ACTION_TYPES.ADD_SHORTLISTED_PROPERTIES_SUCCESS:
            return {
                ...state,
                addShortlistedPropertiesData: action.payload,
                addShortlistedPropertiesLoad: false,
            };
        case ACTION_TYPES.ADD_SHORTLISTED_PROPERTIES_ERROR:
            return {
                ...state,
                addShortlistedPropertiesError: action.payload,
                addShortlistedPropertiesLoad: false,
            };

        case ACTION_TYPES.CREATE_USER_PENDING:
            return {
                ...state,
                createUserLoad: true,
            };
        case ACTION_TYPES.CREATE_USER_SUCCESS:
            return {
                ...state,
                createUserData: action.payload,
                createUserLoad: false,
            };
        case ACTION_TYPES.CREATE_USER_ERROR:
            return {
                ...state,
                createUserError: action.payload,
                createUserLoad: false,
            };

        case ACTION_TYPES.USER_MASTER_DROPDOWN_PENDING:
            return {
                ...state,
                userDropdownLoad: true,
            };
        case ACTION_TYPES.USER_MASTER_DROPDOWN_SUCCESS:
            return {
                ...state,
                userDropdownData: action.payload,
                userDropdownLoad: false,
            };
        case ACTION_TYPES.USER_MASTER_DROPDOWN_ERROR:
            return {
                ...state,
                userDropdownErr: action.payload,
                userDropdownLoad: false,
            };
        case ACTION_TYPES.DOCUMENTLIST_PENDING:
            return {
                ...state,
                userDocumentListLoad: true,
            };
        case ACTION_TYPES.DOCUMENTLIST_SUCCESS:
            return {
                ...state,
                userDocumentListData: action.payload,
                userDocumentListLoad: false,
            };
        case ACTION_TYPES.DOCUMENTLIST_ERROR:
            return {
                ...state,
                userDocumentListError: action.payload,
                userDocumentListLoad: false,
            };

        case ACTION_TYPES.USER_DELETE_DOCUMENT_PENDING:
            return {
                ...state,
                userDeleteDocumentLoad: true,
            };
        case ACTION_TYPES.USER_DELETE_DOCUMENT_SUCCESS:
            return {
                ...state,
                userDeleteDocumentData: action.payload,
                userDeleteDocumentLoad: false,
            };
        case ACTION_TYPES.USER_DELETE_DOCUMENT_ERROR:
            return {
                ...state,
                userDeleteDocumentError: action.payload,
                userDeleteDocumentLoad: false,
            };
        case ACTION_TYPES.USERDETAIL:
            return {
                ...state,
                userOfflineData: action.payload,
            };
        case ACTION_TYPES.OWNERDETAIL:
            return {
                ...state,
                ownerOfflineData: action.payload,
            };
        case ACTION_TYPES.BLOG_FLAG:
            return {
                ...state,
                blogFlagData: action.payload,
            };
        case ACTION_TYPES.CLEAR_STATE:
            return initialState


        case ACTION_TYPES.GET_BY_ID_USER_PENDING:
            return {
                ...state,
                getByIdUserLoad: true,
            };
        case ACTION_TYPES.GET_BY_ID_USER_SUCCESS:
            return {
                ...state,
                getByIdUserData: action.payload,
                getByIdUserLoad: false,
            };
        case ACTION_TYPES.GET_BY_ID_USER_ERROR:
            return {
                ...state,
                getByIdUserError: action.payload,
                getByIdUserLoad: false,
            };


        case ACTION_TYPES.USER_LIST_PENDING:
            return {
                ...state,
                userListLoad: true,
            };
        case ACTION_TYPES.USER_LIST_SUCCESS:
            return {
                ...state,
                userListData: action.payload,
                userListLoad: false,
            };
        case ACTION_TYPES.USER_LIST_ERROR:
            return {
                ...state,
                userListData: action.payload,
                userListLoad: false,
            };
        case ACTION_TYPES.USER_DELETE_PENDING:
            return {
                ...state,
                userDeleteLoad: true,
            };
        case ACTION_TYPES.USER_DELETE_SUCCESS:
            return {
                ...state,
                userDeleteData: action.payload,
                userDeleteLoad: false,
            };
        case ACTION_TYPES.USER_DELETE_ERROR:
            return {
                ...state,
                userDeleteError: action.payload,
                userDeleteLoad: false,
            };
        case ACTION_TYPES.UPDATE_USER_PENDING:
            return {
                ...state,
                updateUserLoad: true,
            };
        case ACTION_TYPES.UPDATE_USER_SUCCESS:
            return {
                ...state,
                updateUserData: action.payload,
                updateUserLoad: false,
            };
        case ACTION_TYPES.UPDATE_USER_ERROR:
            return {
                ...state,
                updateUserError: action.payload,
                updateUserLoad: false,
            };

        case ACTION_TYPES.COMPANY_PROFILE_SETTINGS_UPDATE_PENDING:
            return {
                ...state,
                companyProfileSettingsUpdateLoad: true,
            };
        case ACTION_TYPES.COMPANY_PROFILE_SETTINGS_UPDATE_SUCCESS:
            return {
                ...state,
                companyProfileSettingsUpdateData: action.payload,
                companyProfileSettingsUpdateLoad: false,
            };
        case ACTION_TYPES.COMPANY_PROFILE_SETTINGS_UPDATE_ERROR:
            return {
                ...state,
                companyProfileSettingsUpdateError: action.payload,
                companyProfileSettingsUpdateLoad: false,
            };


        case ACTION_TYPES.GET_COMPANY_PROFILE_SETTINGS_DETAILS_PENDING:
            return {
                ...state,
                getCompanyProfileSettingsDetailsLoad: true,
            };
        case ACTION_TYPES.GET_COMPANY_PROFILE_SETTINGS_DETAILS_SUCCESS:
            return {
                ...state,
                getCompanyProfileSettingsDetailsData: action.payload,
                getCompanyProfileSettingsDetailsLoad: false,
            };
        case ACTION_TYPES.GET_COMPANY_PROFILE_SETTINGS_DETAILS_ERROR:
            return {
                ...state,
                getCompanyProfileSettingsDetailsError: action.payload,
                getCompanyProfileSettingsDetailsLoad: false,
            };

        case ACTION_TYPES.LOCATION_MASTERDATA_PENDING:
            return {
                ...state,
                locationLoad: true,
            };
        case ACTION_TYPES.LOCATION_MASTERDATA_SUCCESS:
            return {
                ...state,
                locationData: action.payload,
                locationLoad: false,
            };
        case ACTION_TYPES.LOCATION_MASTERDATA_ERROR:
            return {
                ...state,
                locationError: action.payload,
                locationLoad: false,
            };
        case ACTION_TYPES.ADD_LOCATION_PENDING:
            return {
                ...state,
                addLocationLoad: true,
            };
        case ACTION_TYPES.ADD_LOCATION_SUCCESS:
            return {
                ...state,
                addLocationData: action.payload,
                addLocationLoad: false,
            };
        case ACTION_TYPES.ADD_LOCATION_ERROR:
            return {
                ...state,
                addLocationError: action.payload,
                addLocationLoad: false,
            };

        case ACTION_TYPES.CREATE_OWNER_PENDING:
            return {
                ...state,
                createOwnerLoad: true,
            };
        case ACTION_TYPES.CREATE_OWNER_SUCCESS:
            return {
                ...state,
                createOwnerData: action.payload,
                createOwnerLoad: false,
            };
        case ACTION_TYPES.CREATE_OWNER_ERROR:
            return {
                ...state,
                createOwnerError: action.payload,
                createOwnerLoad: false,
            };

        case ACTION_TYPES.OWNER_LIST_PENDING:
            return {
                ...state,
                ownerListLoad: true,
            };
        case ACTION_TYPES.OWNER_LIST_SUCCESS:
            return {
                ...state,
                ownerListData: action.payload,
                ownerListLoad: false,
            };
        case ACTION_TYPES.OWNER_LIST_ERROR:
            return {
                ...state,
                ownerListData: action.payload,
                ownerListLoad: false,
            };


        case ACTION_TYPES.EDIT_OWNER_PENDING:
            return {
                ...state,
                editOwnerLoad: true,
            };
        case ACTION_TYPES.EDIT_OWNER_SUCCESS:
            return {
                ...state,
                editOwnerData: action.payload,
                editOwnerLoad: false,
            };
        case ACTION_TYPES.EDIT_OWNER_ERROR:
            return {
                ...state,
                editOwnerError: action.payload,
                editOwnerLoad: false,
            };


        case ACTION_TYPES.OWNER_MASTER_DROPDOWN_PENDING:
            return {
                ...state,
                ownerDropdownLoad: true,
            };
        case ACTION_TYPES.OWNER_MASTER_DROPDOWN_SUCCESS:
            return {
                ...state,
                ownerDropdownData: action.payload,
                ownerDropdownLoad: false,
            };
        case ACTION_TYPES.OWNER_MASTER_DROPDOWN_ERROR:
            return {
                ...state,
                ownerDropdownError: action.payload,
                ownerDropdownLoad: false,
            };


        case ACTION_TYPES.GETBYID_OWNER_PENDING:
            return {
                ...state,
                getByIdOwnerLoad: true,
            };
        case ACTION_TYPES.GETBYID_OWNER_SUCCESS:
            return {
                ...state,
                getByIdOwnerData: action.payload,
                getByIdOwnerLoad: false,
            };
        case ACTION_TYPES.GETBYID_OWNER_ERROR:
            return {
                ...state,
                getByIdOwnerError: action.payload,
                getByIdOwnerLoad: false,
            };


        case ACTION_TYPES.DELETE_OWNER_PENDING:
            return {
                ...state,
                deleteOwnerLoad: true,
            };
        case ACTION_TYPES.DELETE_OWNER_SUCCESS:
            return {
                ...state,
                deleteOwnerData: action.payload,
                deleteOwnerLoad: false,
            };
        case ACTION_TYPES.DELETE_OWNER_ERROR:
            return {
                ...state,
                deleteOwnerError: action.payload,
                deleteOwnerLoad: false,
            };


        case ACTION_TYPES.ADD_NOTES_OWNER_PENDING:
            return {
                ...state,
                addNotesOwnerLoad: true,
            };
        case ACTION_TYPES.ADD_NOTES_OWNER_SUCCESS:
            return {
                ...state,
                addNotesOwnerData: action.payload,
                addNotesOwnerLoad: false,
            };
        case ACTION_TYPES.ADD_NOTES_OWNER_ERROR:
            return {
                ...state,
                addNotesOwnerError: action.payload,
                addNotesOwnerLoad: false,
            };


        case ACTION_TYPES.NOTES_LIST_OWNER_PENDING:
            return {
                ...state,
                notesListOwnerLoad: true,
            };
        case ACTION_TYPES.NOTES_LIST_OWNER_SUCCESS:
            return {
                ...state,
                notesListOwnerData: action.payload,
                notesListOwnerLoad: false,
            };
        case ACTION_TYPES.NOTES_LIST_OWNER_ERROR:
            return {
                ...state,
                notesListOwnerError: action.payload,
                notesListOwnerLoad: false,
            };

        case ACTION_TYPES.OWNER_DOCUMENTS_LIST_PENDING:
            return {
                ...state,
                ownerDocumentsLoad: true,
            };
        case ACTION_TYPES.OWNER_DOCUMENTS_LIST_SUCCESS:
            return {
                ...state,
                ownerDocumentsData: action.payload,
                ownerDocumentsLoad: false,
            };
        case ACTION_TYPES.OWNER_DOCUMENTS_LIST_ERROR:
            return {
                ...state,
                ownerDocumentsError: action.payload,
                ownerDocumentsLoad: false,
            };

        case ACTION_TYPES.DELETE_OWNER_DOCUMENT_PENDING:
            return {
                ...state,
                deleteOwnerDocumentsLoad: true,
            };
        case ACTION_TYPES.DELETE_OWNER_DOCUMENT_SUCCESS:
            return {
                ...state,
                deleteOwnerDocumentsData: action.payload,
                deleteOwnerDocumentsLoad: false,
            };
        case ACTION_TYPES.DELETE_OWNER_DOCUMENT_ERROR:
            return {
                ...state,
                deleteOwnerDocumentsError: action.payload,
                deleteOwnerDocumentsLoad: false,
            };

        case ACTION_TYPES.CALCULATE_FLEX_COST_PENDING:
            return {
                ...state,
                calculateFlexCostLoad: true,
            };
        case ACTION_TYPES.CALCULATE_FLEX_COST_SUCCESS:
            return {
                ...state,
                calculateFlexCostData: action.payload,
                calculateFlexCostLoad: false,
            };
        case ACTION_TYPES.CALCULATE_FLEX_COST_ERROR:
            return {
                ...state,
                calculateFlexCostError: action.payload,
                calculateFlexCostLoad: false,
            };

        case ACTION_TYPES.OWNER_PROPERTIES_PENDING:
            return {
                ...state,
                ownerPropertiesLoad: true,
            };
        case ACTION_TYPES.OWNER_PROPERTIES_SUCCESS:
            return {
                ...state,
                ownerPropertiesData: action.payload,
                ownerPropertiesLoad: false,
            };
        case ACTION_TYPES.OWNER_PROPERTIES_ERROR:
            return {
                ...state,
                ownerPropertiesError: action.payload,
                ownerPropertiesLoad: false,
            };

        case ACTION_TYPES.CHECK_PUBLISH_CREDITS_PENDING:
            return {
                ...state,
                checkPublishCreditsLoad: true,
            };
        case ACTION_TYPES.CHECK_PUBLISH_CREDITS_SUCCESS:
            return {
                ...state,
                checkPublishCreditsData: action.payload,
                checkPublishCreditsLoad: false,
            };
        case ACTION_TYPES.CHECK_PUBLISH_CREDITS_ERROR:
            return {
                ...state,
                checkPublishCreditsError: action.payload,
                checkPublishCreditsLoad: false,
            };
        case ACTION_TYPES.FLOOR_PLAN_DOCLIST_PENDING:
            return {
                ...state,
                floorPlanDocListLoad: true,
            };
        case ACTION_TYPES.FLOOR_PLAN_DOCLIST_SUCCESS:
            return {
                ...state,
                floorPlanDocListData: action.payload,
                floorPlanDocListLoad: false,
            };
        case ACTION_TYPES.FLOOR_PLAN_DOCLIST_ERROR:
            return {
                ...state,
                floorPlanDocListError: action.payload,
                floorPlanDocListLoad: false,
            };
        case ACTION_TYPES.CREDIT_MANAGEMENT_PENDING:
            return {
                ...state,
                creditManageLoad: true,
            };
        case ACTION_TYPES.CREDIT_MANAGEMENT_SUCCESS:
            return {
                ...state,
                creditManageData: action.payload,
                creditManageLoad: false,
            };
        case ACTION_TYPES.CREDIT_MANAGEMENT_ERROR:
            return {
                ...state,
                creditManageError: action.payload,
                creditManageLoad: false,
            };

        case ACTION_TYPES.UNPUBLISHED_PROPERTIES_CREDIT_MANAGEMENT_PENDING:
            return {
                ...state,
                unpublishedPropertiesCreditManageLoad: true,
            };
        case ACTION_TYPES.UNPUBLISHED_PROPERTIES_CREDIT_MANAGEMENT_SUCCESS:
            return {
                ...state,
                unpublishedPropertiesCreditManageData: action.payload,
                unpublishedPropertiesCreditManageLoad: false,
            };
        case ACTION_TYPES.UNPUBLISHED_PROPERTIES_CREDIT_MANAGEMENT_ERROR:
            return {
                ...state,
                unpublishedPropertiesCreditManageError: action.payload,
                unpublishedPropertiesCreditManageLoad: false,
            };

        case ACTION_TYPES.OWNER_PROPERTY_DELETE_PENDING:
            return {
                ...state,
                ownerPropertyDeleteLoad: true,
            };
        case ACTION_TYPES.OWNER_PROPERTY_DELETE_SUCCESS:
            return {
                ...state,
                ownerPropertyDeleteData: action.payload,
                ownerPropertyDeleteLoad: false,
            };
        case ACTION_TYPES.OWNER_PROPERTY_DELETE_ERROR:
            return {
                ...state,
                ownerPropertyDeleteError: action.payload,
                ownerPropertyDeleteLoad: false,
            };
        case ACTION_TYPES.MEDIA_IMAGE_DELETE_PENDING:
            return {
                ...state,
                deleteImageLoad: true,
            };
        case ACTION_TYPES.MEDIA_IMAGE_DELETE_SUCCESS:
            return {
                ...state,
                deleteImageData: action.payload,
                deleteImageLoad: false,
            };
        case ACTION_TYPES.MEDIA_IMAGE_DELETE_ERROR:
            return {
                ...state,
                deleteImageError: action.payload,
                deleteImageLoad: false,
            };
        case ACTION_TYPES.AMENITIESMEDIA_LIST_PENDING:
            return {
                ...state,
                amenitiesMediaLoad: true,
            };
        case ACTION_TYPES.AMENITIESMEDIA_LIST_SUCCESS:
            return {
                ...state,
                amenitiesMediaData: action.payload,
                amenitiesMediaLoad: false,
            };
        case ACTION_TYPES.AMENITIESMEDIA_LIST_ERROR:
            return {
                ...state,
                amenitiesMediaError: action.payload,
                amenitiesMediaLoad: false,
            };
        case ACTION_TYPES.EXPORT_TO_CSV_PENDING:
            return {
                ...state,
                exportToCsvLoad: true,
            };
        case ACTION_TYPES.EXPORT_TO_CSV_SUCCESS:
            return {
                ...state,
                exportToCsvData: action.payload,
                exportToCsvLoad: false,
            };
        case ACTION_TYPES.EXPORT_TO_CSV_ERROR:
            return {
                ...state,
                exportToCsvError: action.payload,
                exportToCsvLoad: false,
            };

        case ACTION_TYPES.TESTIMONIALS_DELETE_PENDING:
            return {
                ...state,
                testimonialDeleteLoad: true,
            };
        case ACTION_TYPES.TESTIMONIALS_DELETE_SUCCESS:
            return {
                ...state,
                testimonialDeleteData: action.payload,
                testimonialDeleteLoad: false,
            };
        case ACTION_TYPES.TESTIMONIALS_DELETE_ERROR:
            return {
                ...state,
                testimonialDeleteError: action.payload,
                testimonialDeleteLoad: false,
            };

        case ACTION_TYPES.TESTIMONIALS_LIST_PENDING:
            return {
                ...state,
                testimonialsListLoad: true,
            };
        case ACTION_TYPES.TESTIMONIALS_LIST_SUCCESS:
            return {
                ...state,
                testimonialsListData: action.payload,
                testimonialsListLoad: false,
            };
        case ACTION_TYPES.TESTIMONIALS_LIST_ERROR:
            return {
                ...state,
                testimonialsListError: action.payload,
                testimonialsListLoad: false,
            };

        case ACTION_TYPES.TESTIMONIAL_APPROVE_PENDING:
            return {
                ...state,
                testimonialApproveLoad: true,
            };
        case ACTION_TYPES.TESTIMONIAL_APPROVE_SUCCESS:
            return {
                ...state,
                testimonialApproveData: action.payload,
                testimonialApproveLoad: false,
            };
        case ACTION_TYPES.TESTIMONIAL_APPROVE_ERROR:
            return {
                ...state,
                testimonialApproveError: action.payload,
                testimonialApproveLoad: false,
            };

        case ACTION_TYPES.BLOG_CREATE_PENDING:
            return {
                ...state,
                blogCreateLoad: true,
            };
        case ACTION_TYPES.BLOG_CREATE_SUCCESS:
            return {
                ...state,
                blogCreateData: action.payload,
                blogCreateLoad: false,
            };
        case ACTION_TYPES.BLOG_CREATE_ERROR:
            return {
                ...state,
                blogCreateError: action.payload,
                blogCreateLoad: false,
            };

        case ACTION_TYPES.BLOG_UPATE_PENDING:
            return {
                ...state,
                blogUpdateLoad: true,
            };
        case ACTION_TYPES.BLOG_UPATE_SUCCESS:
            return {
                ...state,
                blogUpdateData: action.payload,
                blogUpdateLoad: false,
            };
        case ACTION_TYPES.BLOG_UPATE_ERROR:
            return {
                ...state,
                blogUpdateError: action.payload,
                blogUpdateLoad: false,
            };

        case ACTION_TYPES.BLOG_LIST_PENDING:
            return {
                ...state,
                blogListLoad: true,
            };
        case ACTION_TYPES.BLOG_LIST_SUCCESS:
            return {
                ...state,
                blogListData: action.payload,
                blogListLoad: false,
            };
        case ACTION_TYPES.BLOG_LIST_ERROR:
            return {
                ...state,
                blogListError: action.payload,
                blogListLoad: false,
            };

        case ACTION_TYPES.BLOG_GETBYID_PENDING:
            return {
                ...state,
                blogGetByIdLoad: true,
            };
        case ACTION_TYPES.BLOG_GETBYID_SUCCESS:
            return {
                ...state,
                blogGetByIdData: action.payload,
                blogGetByIdLoad: false,
            };
        case ACTION_TYPES.BLOG_GETBYID_ERROR:
            return {
                ...state,
                blogGetByIdError: action.payload,
                blogGetByIdLoad: false,
            };


        case ACTION_TYPES.BLOG_DELETE_PENDING:
            return {
                ...state,
                blogDeleteLoad: true,
            };
        case ACTION_TYPES.BLOG_DELETE_SUCCESS:
            return {
                ...state,
                blogDeleteData: action.payload,
                blogDeleteLoad: false,
            };
        case ACTION_TYPES.BLOG_DELETE_ERROR:
            return {
                ...state,
                blogDeleteError: action.payload,
                blogDeleteLoad: false,
            };

        case ACTION_TYPES.ADVERTISEMENT_IMAGELIST_PENDING:
            return {
                ...state,
                advertisementImageListLoad: true,
            };
        case ACTION_TYPES.ADVERTISEMENT_IMAGELIST_SUCCESS:
            return {
                ...state,
                advertisementImageListData: action.payload,
                advertisementImageListLoad: false,
            };
        case ACTION_TYPES.ADVERTISEMENT_IMAGELIST_ERROR:
            return {
                ...state,
                advertisementImageListError: action.payload,
                advertisementImageListLoad: false,
            };

        case ACTION_TYPES.ADVERTISEMENT_IMAGELIST_VERTICAL_PENDING:
            return {
                ...state,
                advertisementImageListVerticalLoad: true,
            };
        case ACTION_TYPES.ADVERTISEMENT_IMAGELIST_VERTICAL_SUCCESS:
            return {
                ...state,
                advertisementImageListVerticalData: action.payload,
                advertisementImageListVerticalLoad: false,
            };
        case ACTION_TYPES.ADVERTISEMENT_IMAGELIST_VERTICAL_ERROR:
            return {
                ...state,
                advertisementImageListVerticalError: action.payload,
                advertisementImageListVerticalLoad: false,
            };

        case ACTION_TYPES.HOWITWORKS_COUNTER_GETBYID_PENDING:
            return {
                ...state,
                howItWorksCounterGetByIdLoad: true,
            };
        case ACTION_TYPES.HOWITWORKS_COUNTER_GETBYID_SUCCESS:
            return {
                ...state,
                howItWorksCounterGetByIdData: action.payload,
                howItWorksCounterGetByIdLoad: false,
            };
        case ACTION_TYPES.HOWITWORKS_COUNTER_GETBYID_ERROR:
            return {
                ...state,
                howItWorksCounterGetByIdError: action.payload,
                howItWorksCounterGetByIdLoad: false,
            };


        case ACTION_TYPES.HOWITWORKS_COUNTER_UPDATE_PENDING:
            return {
                ...state,
                howItWorksCounterUpdateLoad: true,
            };
        case ACTION_TYPES.HOWITWORKS_COUNTER_UPDATE_SUCCESS:
            return {
                ...state,
                howItWorksCounterUpdateData: action.payload,
                howItWorksCounterUpdateLoad: false,
            };
        case ACTION_TYPES.HOWITWORKS_COUNTER_UPDATE_ERROR:
            return {
                ...state,
                howItWorksCounterUpdateError: action.payload,
                howItWorksCounterUpdateLoad: false,
            };
        case ACTION_TYPES.DELETE_ADVERTISEMENT_IMAGE_PENDING:
            return {
                ...state,
                deleteAdvertisementImageLoad: true,
            };
        case ACTION_TYPES.DELETE_ADVERTISEMENT_IMAGE_SUCCESS:
            return {
                ...state,
                deleteAdvertisementImageData: action.payload,
                deleteAdvertisementImageLoad: false,
            };
        case ACTION_TYPES.DELETE_ADVERTISEMENT_IMAGE_ERROR:
            return {
                ...state,
                deleteAdvertisementImageError: action.payload,
                deleteAdvertisementImageLoad: false,
            };

        case ACTION_TYPES.SETTINGS_ADMIN_ADD_PENDING:
            return {
                ...state,
                settingsAdminAddLoad: true,
            };
        case ACTION_TYPES.SETTINGS_ADMIN_ADD_SUCCESS:
            return {
                ...state,
                settingsAdminAddData: action.payload,
                settingsAdminAddLoad: false,
            };
        case ACTION_TYPES.SETTINGS_ADMIN_ADD_ERROR:
            return {
                ...state,
                settingsAdminAddError: action.payload,
                settingsAdminAddLoad: false,
            };


        case ACTION_TYPES.ADD_SUB_AGENT_CREDIT_PENDING:
            return {
                ...state,
                addSubAgentCreditLoad: true,
            };
        case ACTION_TYPES.ADD_SUB_AGENT_CREDIT_SUCCESS:
            return {
                ...state,
                addSubAgentCreditData: action.payload,
                addSubAgentCreditLoad: false,
            };
        case ACTION_TYPES.ADD_SUB_AGENT_CREDIT_ERROR:
            return {
                ...state,
                addSubAgentCreditError: action.payload,
                addSubAgentCreditLoad: false,
            };

        case ACTION_TYPES.WITHDRAW_SUB_AGENT_CREDIT_PENDING:
            return {
                ...state,
                withdrawSubAgentCreditLoad: true,
            };
        case ACTION_TYPES.WITHDRAW_SUB_AGENT_CREDIT_SUCCESS:
            return {
                ...state,
                withdrawSubAgentCreditData: action.payload,
                withdrawSubAgentCreditLoad: false,
            };
        case ACTION_TYPES.WITHDRAW_SUB_AGENT_CREDIT_ERROR:
            return {
                ...state,
                withdrawSubAgentCreditError: action.payload,
                withdrawSubAgentCreditLoad: false,
            };

        case ACTION_TYPES.PUBLISH_REQUEST_APPROVE_REJECT_PENDING:
            return {
                ...state,
                publishRequestApproveRejectLoad: true,
            };
        case ACTION_TYPES.PUBLISH_REQUEST_APPROVE_REJECT_SUCCESS:
            return {
                ...state,
                publishRequestApproveRejectData: action.payload,
                publishRequestApproveRejectLoad: false,
            };
        case ACTION_TYPES.PUBLISH_REQUEST_APPROVE_REJECT_ERROR:
            return {
                ...state,
                publishRequestApproveRejectError: action.payload,
                publishRequestApproveRejectLoad: false,
            };




        case ACTION_TYPES.USER_ENABLE:
            return {
                ...state,
                userMode: action.payload,
            };
        case ACTION_TYPES.OWNER_ENABLE:
            return {
                ...state,
                ownerMode: action.payload,
            };
        case ACTION_TYPES.LEAD_ENABLE:
            return {
                ...state,
                leadMode: action.payload,
            };
        case ACTION_TYPES.PROPERTY_ENABLE:
            return {
                ...state,
                propertyMode: action.payload,
            };
        case ACTION_TYPES.COMPANY_ENABLE:
            return {
                ...state,
                companyMode: action.payload,
            };
        case ACTION_TYPES.OWNER_PROPERTY:
            return {
                ...state,
                ownerPropertyEdit: action.payload,
            };
        default:
            return state;
    }
};

export default apiReducer;