import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Link, Navigate, } from 'react-router-dom';
import PropertyDetail from '../screen/PropertyDetail';
import PropertyList from '../screen/PropertyList';
import LeadsList from '../screen/LeadsList';
import OwnerList from '../screen/OwnerList';
import CreditList from '../screen/CreditList';
import UserList from '../screen/UserList';
import LeadsDetail from '../screen/LeadsDetail';
import OwnerDetail from '../screen/OwnerDetail';
import UserDetail from '../screen/UserDetail';
import SettingsAdmin from '../screen/SettingsAdmin';
import Login from '../screen/Login';
import AgentList from '../screen/AgentList';
import AgentDetail from '../screen/AgentDetail';
import { I18nextProvider } from 'react-i18next';
import i18n from '../i18n';
import { useSelector } from 'react-redux';
import Dashboard from '../screen/DashBoard';
import CMS from '../screen/CMS';
import AddBlog from '../screen/AddBlog';
import ForgotPassword from '../screen/ForgotPassword';
import EditProfile from '../screen/EditProfile';
import WatermarkSettingsPage from '../screen/Settings';
import Settings from '../screen/Settings';


function Navigation() {
    const isAuthenticated = useSelector((state) => state?.apiReducer?.loginData?.Data?.accessToken);
    const userRoles = useSelector((state) => state?.apiReducer?.loginData?.Data?.userRole[0]);
    const [totalRoutes, setTotalRoutes] = useState([])

    useEffect(() => {
        const routes = [
            {
                id: 1,
                name: "Login",
                path: "/Login",
                component: <Login />,
                isPrivate: false
            },

            {
                id: 3,
                name: "Dashboard",
                path: "/Dashboard",
                component: <Dashboard />,
                isPrivate: true
            },
            {
                id: 20,
                name: "ForgotPassword",
                path: "/ForgotPassword",
                component: <ForgotPassword />,
                isPrivate: false
            },
            {
                id: 21,
                name: "EditProfile",
                path: "/EditProfile",
                component: <EditProfile />,
                isPrivate: true
            }


        ]

        const roles = {
            SuperAdministrator: [

                {
                    id: 3,
                    name: "Dashboard",
                    path: "/Dashboard",
                    component: <Dashboard />,
                    isPrivate: true
                },
                {
                    id: 5,
                    name: "AgentList",
                    path: "/AgentList",
                    component: <AgentList />,
                    isPrivate: true
                },
                {
                    id: 6,
                    name: "AgentDetail",
                    path: "/AgentDetail",
                    component: <AgentDetail />,
                    isPrivate: true
                },
                {
                    id: 7,
                    name: "SettingsAdmin",
                    path: "/SettingsAdmin",
                    component: <SettingsAdmin />,
                    isPrivate: true
                },
                {
                    id: 18,
                    name: "CMS",
                    path: "/CMS",
                    component: <CMS />,
                    isPrivate: true
                },
                {
                    id: 19,
                    name: "AddBlog",
                    path: "/AddBlog",
                    component: <AddBlog />,
                    isPrivate: true
                },
            ], AgentAdmin: [

                {
                    id: 3,
                    name: "Dashboard",
                    path: "/Dashboard",
                    component: <Dashboard />,
                    isPrivate: true
                },
                {
                    id: 8,
                    name: "PropertyList",
                    path: "/PropertyList",
                    component: <PropertyList />,
                    isPrivate: true
                },
                {
                    id: 9,
                    name: "PropertyDetail",
                    path: "/PropertyDetail",
                    component: <PropertyDetail />,
                    isPrivate: true
                },
                {
                    id: 10,
                    name: "CreditList",
                    path: "/CreditList",
                    component: <CreditList />,
                    isPrivate: true
                },
                {
                    id: 11,
                    name: "LeadsList",
                    path: "/LeadsList",
                    component: <LeadsList />,
                    isPrivate: true
                },
                {
                    id: 12,
                    name: "LeadsDetail",
                    path: "/LeadsDetail",
                    component: <LeadsDetail />,
                    isPrivate: true
                },
                {
                    id: 13,
                    name: "OwnerList",
                    path: "/OwnerList",
                    component: <OwnerList />,
                    isPrivate: true
                },
                {
                    id: 14,
                    name: "OwnerDetail",
                    path: "/OwnerDetail",
                    component: <OwnerDetail />,
                    isPrivate: true
                },
                {
                    id: 15,
                    name: "UserList",
                    path: "/UserList",
                    component: <UserList />,
                    isPrivate: true
                },
                {
                    id: 16,
                    name: "UserDetail",
                    path: "/UserDetail",
                    component: <UserDetail />,
                    isPrivate: true
                },
                {
                    id: 17,
                    name: "Settings",
                    path: "/Settings",
                    component: <Settings />,
                    isPrivate: true
                },

            ], Agent: [

                {
                    id: 3,
                    name: "Dashboard",
                    path: "/Dashboard",
                    component: <Dashboard />,
                    isPrivate: true
                },
                {
                    id: 8,
                    name: "PropertyList",
                    path: "/PropertyList",
                    component: <PropertyList />,
                    isPrivate: true
                },
                {
                    id: 9,
                    name: "PropertyDetail",
                    path: "/PropertyDetail",
                    component: <PropertyDetail />,
                    isPrivate: true
                },
                {
                    id: 11,
                    name: "LeadsList",
                    path: "/LeadsList",
                    component: <LeadsList />,
                    isPrivate: true
                },
                {
                    id: 12,
                    name: "LeadsDetail",
                    path: "/LeadsDetail",
                    component: <LeadsDetail />,
                    isPrivate: true
                },
                {
                    id: 13,
                    name: "OwnerList",
                    path: "/OwnerList",
                    component: <OwnerList />,
                    isPrivate: true
                },
                {
                    id: 14,
                    name: "OwnerDetail",
                    path: "/OwnerDetail",
                    component: <OwnerDetail />,
                    isPrivate: true
                },
                {
                    id: 10,
                    name: "CreditList",
                    path: "/CreditList",
                    component: <CreditList />,
                    isPrivate: true
                },

            ],
        }

        const userRoleRoutes = roles[userRoles] || [];
        const mergedRoutes = [...routes, ...userRoleRoutes];
        setTotalRoutes(mergedRoutes)
    }, [isAuthenticated, userRoles])





    return (
        <I18nextProvider i18n={i18n}>
            <BrowserRouter>
                <Routes>
                    {totalRoutes.map((ele) => {

                        return isAuthenticated && !ele.isPrivate ? (
                            <Route key={ele.id} path="*" element={<Navigate to="/Dashboard" />} />
                        ) : !isAuthenticated && ele.isPrivate ? (
                            <Route key={ele.id} path="*" element={<Navigate to="/Login" />} />
                        ) : (
                            <Route key={ele.id} path={ele.path} element={ele.component} />
                        );
                    })}
                </Routes>
            </BrowserRouter>
        </I18nextProvider>
    );
}
export default Navigation