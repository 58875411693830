const ACTION_TYPES = {

    // ***************************************LOGIN*********************************************

    LOGIN_PENDING: 'LOGIN_PENDING',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_ERROR: 'LOGIN_ERROR',


    // ***************************************AGENTLIST*********************************************

    AGENTLIST_PENDING: 'AGENTLIST_PENDING',
    AGENTLIST_SUCCESS: 'AGENTLIST_SUCCESS',
    AGENTLIST_ERROR: 'AGENTLIST_ERROR',


    // ***************************************COMPANYLIST*********************************************

    COMPNAME_LIST_PENDING: 'COMPNAME_LIST_PENDING',
    COMPNAME_LIST_SUCCESS: 'COMPNAME_LIST_SUCCESS',
    COMPNAME_LIST_ERROR: 'COMPNAME_LIST_ERROR',

    // ***************************************AGENTLIST*********************************************

    AGENTNAME_PENDING: 'AGENTNAME_PENDING',
    AGENTNAME_SUCCESS: 'AGENTNAME_SUCCESS',
    AGENTNAME_ERROR: 'AGENTNAME_ERROR',

    // ***************************************COUNTRYLIST*********************************************

    COUNTRY_PENDING: 'COUNTRY_PENDING',
    COUNTRY_SUCCESS: 'COUNTRY_SUCCESS',
    COUNTRY_ERROR: 'COUNTRY_ERROR',

    // ***************************************STATELIST*********************************************

    STATE_PENDING: 'STATE_PENDING',
    STATE_SUCCESS: 'STATE_SUCCESS',
    STATE_ERROR: 'STATE_ERROR',

    // ***************************************CITYLIST*********************************************

    CITY_PENDING: 'CITY_PENDING',
    CITY_SUCCESS: 'CITY_SUCCESS',
    CITY_ERROR: 'CITY_ERROR',

    // ***************************************GET_AGENT_DETAILS*********************************************

    GET_AGENT_DETAILS_PENDING: 'GET_AGENT_DETAILS_PENDING',
    GET_AGENT_DETAILS_SUCCESS: 'GET_AGENT_DETAILS_SUCCESS',
    GET_AGENT_DETAILS_ERROR: 'GET_AGENT_DETAILS_ERROR',

    // ***************************************CREATE_AGENT*********************************************

    CREATE_AGENT_PENDING: 'CREATE_AGENT_PENDING',
    CREATE_AGENT_SUCCESS: 'CREATE_AGENT_SUCCESS',
    CREATE_AGENT_ERROR: 'CREATE_AGENT_ERROR',

    // ***************************************EDIT_AGENT*********************************************

    EDIT_AGENT_PENDING: 'EDIT_AGENT_PENDING',
    EDIT_AGENT_SUCCESS: 'EDIT_AGENT_SUCCESS',
    EDIT_AGENT_ERROR: 'EDIT_AGENT_ERROR',

    // ***************************************DELETE_AGENT*********************************************

    DELETE_AGENT_PENDING: 'DELETE_AGENT_PENDING',
    DELETE_AGENT_SUCCESS: 'DELETE_AGENT_SUCCESS',
    DELETE_AGENT_ERROR: 'DELETE_AGENT_ERROR',

    // *************************************PROPERTY_TYPE*********************************************

    PROPERTY_TYPE_PENDING: 'PROPERTY_TYPE_PENDING',
    PROPERTY_TYPE_SUCCESS: 'PROPERTY_TYPE_SUCCESS',
    PROPERTY_TYPE_ERROR: 'PROPERTY_TYPE_ERROR',

    // *************************************FurnishingType*********************************************

    FURNISHING_TYPE_PENDING: 'FURNISHING_TYPE_PENDING',
    FURNISHING_TYPE_SUCCESS: 'FURNISHING_TYPE_SUCCESS',
    FURNISHING_TYPE_ERROR: 'FURNISHING_TYPE_ERROR',

    // *************************************OCCUPANYCY*********************************************

    OCCUPANCY_TYPE_PENDING: 'OCCUPANCY_TYPE_PENDING',
    OCCUPANCY_TYPE_SUCCESS: 'OCCUPANCY_TYPE_SUCCESS',
    OCCUPANCY_TYPE_ERROR: 'OCCUPANCY_TYPE_ERROR',

    // *************************************RENOVATION_TYPE*********************************************

    RENOVATION_TYPE_PENDING: 'RENOVATION_TYPE_PENDING',
    RENOVATION_TYPE_SUCCESS: 'RENOVATION_TYPE_SUCCESS',
    RENOVATION_TYPE_ERROR: 'RENOVATION_TYPE_ERROR',

    // *************************************AGENTDETAIL*********************************************

    AGENTDETAIL: 'AGENTDETAIL',

    // ************************************TENURE********************************************

    TENURE_PENDING: 'TENURE_PENDING',
    TENURE_SUCCESS: 'TENURE_SUCCESS',
    TENURE_ERROR: 'TENURE_ERROR',

    // ************************************PROPERTY CATEGORY********************************************

    PROPERTY_CATEGORY_PENDING: 'PROPERTY_CATEGORY_PENDING',
    PROPERTY_CATEGORY_SUCCESS: 'PROPERTY_CATEGORY_SUCCESS',
    PROPERTY_CATEGORY_ERROR: 'PROPERTY_CATEGORY_ERROR',

    // ************************************MARITIAL STATUS********************************************

    MARITIAL_STATUS_PENDING: 'MARITIAL_STATUS_PENDING',
    MARITIAL_STATUS_SUCCESS: 'MARITIAL_STATUS_SUCCESS',
    MARITIAL_STATUS_ERROR: 'MARITIAL_STATUS_ERROR',


    // ************************************AGENT UPLOAD DOCUMENTS LIST********************************************

    AGENT_UPLOAD_DOCUMENTS_LIST_PENDING: 'AGENT_UPLOAD_DOCUMENTS_LIST_PENDING',
    AGENT_UPLOAD_DOCUMENTS_LIST_SUCCESS: 'AGENT_UPLOAD_DOCUMENTS_LIST_SUCCESS',
    AGENT_UPLOAD_DOCUMENTS_LIST_ERROR: 'AGENT_UPLOAD_DOCUMENTS_LIST_ERROR',

    // ************************************AGENT CREDIT LIST********************************************

    AGENT_CREDIT_LIST_PENDING: 'AGENT_CREDIT_LIST_PENDING',
    AGENT_CREDIT_LIST_SUCCESS: 'AGENT_CREDIT_LIST_SUCCESS',
    AGENT_CREDIT_LIST_ERROR: 'AGENT_CREDIT_LIST_ERROR',

    // ************************************DELETE AGENT CREDIT LIST********************************************

    DELETE_AGENT_CREDIT_LIST_PENDING: 'DELETE_AGENT_CREDIT_LIST_PENDING',
    DELETE_AGENT_CREDIT_LIST_SUCCESS: 'DELETE_AGENT_CREDIT_LIST_SUCCESS',
    DELETE_AGENT_CREDIT_LIST_ERROR: 'DELETE_AGENT_CREDIT_LIST_ERROR',


    // ************************************ADD AGENT CREDIT LIST********************************************

    ADD_AGENT_CREDIT_LIST_PENDING: 'ADD_AGENT_CREDIT_LIST_PENDING',
    ADD_AGENT_CREDIT_LIST_SUCCESS: 'ADD_AGENT_CREDIT_LIST_SUCCESS',
    ADD_AGENT_CREDIT_LIST_ERROR: 'ADD_AGENT_CREDIT_LIST_ERROR',

    // ************************************ADD PROPERTY********************************************

    ADD_PROPERTY_PENDING: 'ADD_PROPERTY_PENDING',
    ADD_PROPERTY_SUCCESS: 'ADD_PROPERTY_SUCCESS',
    ADD_PROPERTY_ERROR: 'ADD_PROPERTY_ERROR',

    // ************************************UPDATE PROPERTY********************************************

    UPDATE_PROPERTY_PENDING: 'UPDATE_PROPERTY_PENDING',
    UPDATE_PROPERTY_SUCCESS: 'UPDATE_PROPERTY_SUCCESS',
    UPDATE_PROPERTY_ERROR: 'UPDATE_PROPERTY_ERROR',


    // *************************************PROPERTYDETAIL*********************************************

    PROPERTYDETAIL: 'PROPERTYDETAIL',

    // ************************************ADD NOTES********************************************

    ADD_NOTES_PENDING: 'ADD_NOTES_PENDING',
    ADD_NOTES_SUCCESS: 'ADD_NOTES_SUCCESS',
    ADD_NOTES_ERROR: 'ADD_NOTES_ERROR',

    // ************************************PROPERTY FILTER********************************************

    PROPERTYFILTER_PENDING: 'PROPERTYFILTER_PENDING',
    PROPERTYFILTER_SUCCESS: 'PROPERTYFILTER_SUCCESS',
    PROPERTYFILTER_ERROR: 'PROPERTYFILTER_ERROR',

    // ************************************DROPDOWN PROPERTY MASTERDATA********************************************

    PROPERTY_DROPDOWN_MASTERDATA_PENDING: 'PROPERTY_DROPDOWN_MASTERDATA_PENDING',
    PROPERTY_DROPDOWN_MASTERDATA_SUCCESS: 'PROPERTY_DROPDOWN_MASTERDATA_SUCCESS',
    PROPERTY_DROPDOWN_MASTERDATA_ERROR: 'PROPERTY_DROPDOWN_MASTERDATA_ERROR',

    // ************************************IMAGE MEDIA LIST********************************************

    IMAGEMEDIA_LIST_PENDING: 'IMAGEMEDIA_LIST_PENDING',
    IMAGEMEDIA_LIST_SUCCESS: 'IMAGEMEDIA_LIST_SUCCESS',
    IMAGEMEDIA_LIST_ERROR: 'IMAGEMEDIA_LIST_ERROR',

    // ************************************Amenities media LIST********************************************

    AMENITIESMEDIA_LIST_PENDING: 'AMENITIESMEDIA_LIST_PENDING',
    AMENITIESMEDIA_LIST_SUCCESS: 'AMENITIESMEDIA_LIST_SUCCESS',
    AMENITIESMEDIA_LIST_ERROR: 'AMENITIESMEDIA_LIST_ERROR',
    // ************************************PROPERTY DOCUMENTS LIST********************************************

    PROPERTY_DOCUMENTS_LIST_PENDING: 'PROPERTY_DOCUMENTS_LIST_PENDING',
    PROPERTY_DOCUMENTS_LIST_SUCCESS: 'PROPERTY_DOCUMENTS_LIST_SUCCESS',
    PROPERTY_DOCUMENTS_LIST_ERROR: 'PROPERTY_DOCUMENTS_LIST_ERROR',

    // ************************************PROPERTY DOCUMENTS LIST********************************************

    UPLOAD_VIDEO_URL_PENDING: 'UPLOAD_VIDEO_URL_PENDING',
    UPLOAD_VIDEO_URL_SUCCESS: 'UPLOAD_VIDEO_URL_SUCCESS',
    UPLOAD_VIDEO_URL_ERROR: 'UPLOAD_VIDEO_URL_ERROR',

    // ************************************DELETE PROPERTY DOCUMENTS LIST********************************************

    DELETE_PROPERTY_DOCUMENTS_LIST_PENDING: 'DELETE_DOCUMENTS_LIST_PENDING',
    DELETE_PROPERTY_DOCUMENTS_LIST_SUCCESS: 'DELETE_DOCUMENTS_LIST_SUCCESS',
    DELETE_PROPERTY_DOCUMENTS_LIST_ERROR: 'DELETE_DOCUMENTS_LIST_ERROR',


    // ************************************GET BY PROPERTY LIST********************************************

    GET_PROPERTY_DETAIL_PENDING: 'GET_PROPERTY_DETAIL_PENDING',
    GET_PROPERTY_DETAIL_SUCCESS: 'GET_PROPERTY_DETAIL_SUCCESS',
    GET_PROPERTY_DETAIL_ERROR: 'GET_PROPERTY_DETAIL_ERROR',

    // ************************************DELETE PROPERTY********************************************

    DELETE_PROPERTY_PENDING: 'DELETE_PROPERTY_PENDING',
    DELETE_PROPERTY_SUCCESS: 'DELETE_PROPERTY_PENDING',
    DELETE_PROPERTY_ERROR: 'DELETE_PROPERTY_ERROR',


    // ************************************NOTES LIST********************************************

    NOTESLIST_PENDING: 'NOTESLIST_PENDING',
    NOTESLIST_SUCCESS: 'NOTESLIST_SUCCESS',
    NOTESLIST_ERROR: 'NOTESLIST_ERROR',

    // ************************************LEADS DROPDOWN********************************************

    LEADS_DROPDOWN_PENDING: 'LEADS_DROPDOWN_PENDING',
    LEADS_DROPDOWN_SUCCESS: 'LEADS_DROPDOWN_SUCCESS',
    LEADS_DROPDOWN_ERROR: 'LEADS_DROPDOWN_ERROR',
    // *************************************PROPERTYDETAIL*********************************************

    LEADSDETAIL: 'LEADSDETAIL',

    // ************************************LEADS CREATE********************************************

    LEADS_CREATE_PENDING: 'LEADS_CREATE_PENDING',
    LEADS_CREATE_SUCCESS: 'LEADS_CREATE_SUCCESS',
    LEADS_CREATE_ERROR: 'LEADS_CREATE_ERROR',

    // ************************************LEADS UPDATE********************************************

    LEADS_UPDATE_PENDING: 'LEADS_UPDATE_PENDING',
    LEADS_UPDATE_SUCCESS: 'LEADS_UPDATE_SUCCESS',
    LEADS_UPDATE_ERROR: 'LEADS_UPDATE_ERROR',

    // ************************************LEADS LIST********************************************

    LEADS_LIST_PENDING: 'LEADS_LIST_PENDING',
    LEADS_LIST_SUCCESS: 'LEADS_LIST_SUCCESS',
    LEADS_LIST_ERROR: 'LEADS_LIST_ERROR',


    // ************************************GET BY LEADS LIST********************************************

    GET_LEADS_DETAIL_PENDING: 'GET_LEADS_DETAIL_PENDING',
    GET_LEADS_DETAIL_SUCCESS: 'GET_LEADS_DETAIL_SUCCESS',
    GET_LEADS_DETAIL_ERROR: 'GET_LEADS_DETAIL_ERROR',

    // ************************************ADD NOTES LEADS********************************************

    ADD_NOTES_LEADS_PENDING: 'ADD_NOTES_LEADS_PENDING',
    ADD_NOTES_LEADS_SUCCESS: 'ADD_NOTES_LEADS_SUCCESS',
    ADD_NOTES_LEADS_ERROR: 'ADD_NOTES_LEADS_ERROR',


    // ***************************************DELETE_LEAD*********************************************

    DELETE_LEAD_PENDING: 'DELETE_LEAD_PENDING',
    DELETE_LEAD_SUCCESS: 'DELETE_LEAD_SUCCESS',
    DELETE_LEAD_ERROR: 'DELETE_LEAD_ERROR',



    // ************************************NOTES LIST********************************************

    NOTESLIST_LEAD_PENDING: 'NOTESLIST_LEAD_PENDING',
    NOTESLIST_LEAD_SUCCESS: 'NOTESLIST_LEAD_SUCCESS',
    NOTESLIST_LEAD_ERROR: 'NOTESLIST_LEAD_ERROR',


    // ************************************DOCUMENTS LIST LEAD********************************************

    DOCUMENTSLIST_LEAD_PENDING: 'DOCUMENTSLIST_LEAD_PENDING',
    DOCUMENTSLIST_LEAD_SUCCESS: 'DOCUMENTSLIST_LEAD_SUCCESS',
    DOCUMENTSLIST_LEAD_ERROR: 'DOCUMENTSLIST_LEAD_ERROR',




    // ************************************DELETE DOCUMENTS  LEAD********************************************

    DELETE_DOCUMENT_LEAD_PENDING: 'DELETE_DOCUMENT_LEAD_PENDING',
    DELETE_DOCUMENT_LEAD_SUCCESS: 'DELETE_DOCUMENT_LEAD_SUCCESS',
    DELETE_DOCUMENT_LEAD_ERROR: 'DELETE_DOCUMENT_LEAD_ERROR',


    // ************************************ADD LEAD REQUIREMENTS ********************************************

    ADD_LEAD_REQUIREMENTS_PENDING: 'ADD_LEAD_REQUIREMENTS_PENDING',
    ADD_LEAD_REQUIREMENTS_SUCCESS: 'ADD_LEAD_REQUIREMENTS_SUCCESS',
    ADD_LEAD_REQUIREMENTS_ERROR: 'ADD_LEAD_REQUIREMENTS_ERROR',


    // ************************************EDIT LEAD REQUIREMENTS ********************************************

    EDIT_LEAD_REQUIREMENTS_PENDING: 'EDIT_LEAD_REQUIREMENTS_PENDING',
    EDIT_LEAD_REQUIREMENTS_SUCCESS: 'EDIT_LEAD_REQUIREMENTS_SUCCESS',
    EDIT_LEAD_REQUIREMENTS_ERROR: 'EDIT_LEAD_REQUIREMENTS_ERROR',

    // ************************************GET BY ID LEAD REQUIREMENTS ********************************************

    GET_BY_ID_LEAD_REQUIREMENTS_PENDING: 'GET_BY_ID_LEAD_REQUIREMENTS_PENDING',
    GET_BY_ID_LEAD_REQUIREMENTS_SUCCESS: 'GET_BY_ID_LEAD_REQUIREMENTS_SUCCESS',
    GET_BY_ID_LEAD_REQUIREMENTS_ERROR: 'GET_BY_ID_LEAD_REQUIREMENTS_ERROR',

    // ************************************SHORTLISTED PROPERTIES ********************************************

    SHORTLISTED_PROPERTIES_PENDING: 'SHORTLISTED_PROPERTIES_PENDING',
    SHORTLISTED_PROPERTIES_SUCCESS: 'SHORTLISTED_PROPERTIES_SUCCESS',
    SHORTLISTED_PROPERTIES_ERROR: 'SHORTLISTED_PROPERTIES_ERROR',

    // ************************************MATCHING LISTINGS ********************************************

    MATCHING_LISTINGS_PENDING: 'MATCHING_LISTINGS_PENDING',
    MATCHING_LISTINGS_SUCCESS: 'MATCHING_LISTINGS_SUCCESS',
    MATCHING_LISTINGS_ERROR: 'MATCHING_LISTINGS_ERROR',

    // ************************************ADD SHORTLISTED PROPERTIES ********************************************

    ADD_SHORTLISTED_PROPERTIES_PENDING: 'ADD_SHORTLISTED_PROPERTIES_PENDING',
    ADD_SHORTLISTED_PROPERTIES_SUCCESS: 'ADD_SHORTLISTED_PROPERTIES_SUCCESS',
    ADD_SHORTLISTED_PROPERTIES_ERROR: 'ADD_SHORTLISTED_PROPERTIES_ERROR',

    // ************************************USER_MASTER_DROPDOWN_PENDING ********************************************

    USER_MASTER_DROPDOWN_PENDING: 'USER_MASTER_DROPDOWN_PENDING',
    USER_MASTER_DROPDOWN_SUCCESS: 'USER_MASTER_DROPDOWN_SUCCESS',
    USER_MASTER_DROPDOWN_ERROR: 'USER_MASTER_DROPDOWN_ERROR',

    // ************************************CREATE USER ********************************************

    CREATE_USER_PENDING: 'CREATE_USER_PENDING',
    CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
    CREATE_USER_ERROR: 'CREATE_USER_ERROR',

    // ************************************ USER DOCUMENT LIST ********************************************

    DOCUMENTLIST_PENDING: 'DOCUMENTLIST_PENDING',
    DOCUMENTLIST_SUCCESS: 'DOCUMENTLIST_SUCCESS',
    DOCUMENTLIST_ERROR: 'DOCUMENTLIST_ERROR',

    // ************************************ USER DELETE DOCUMENT ********************************************

    USER_DELETE_DOCUMENT_PENDING: 'USER_DELETE_DOCUMENT_PENDING',
    USER_DELETE_DOCUMENT_SUCCESS: 'USER_DELETE_DOCUMENT_SUCCESS',
    USER_DELETE_DOCUMENT_ERROR: 'USER_DELETE_DOCUMENT_ERROR',

    // *************************************USERDETAIL*********************************************

    USERDETAIL: 'USERDETAIL',

    // ************************************ GET BY ID USER ********************************************

    GET_BY_ID_USER_PENDING: 'GET_BY_ID_USER_PENDING',
    GET_BY_ID_USER_SUCCESS: 'GET_BY_ID_USER_SUCCESS',
    GET_BY_ID_USER_ERROR: 'GET_BY_ID_USER_ERROR',

    // ************************************ USER LIST ********************************************

    USER_LIST_PENDING: 'USER_LIST_PENDING',
    USER_LIST_SUCCESS: 'USER_LIST_SUCCESS',
    USER_LIST_ERROR: 'USER_LIST_ERROR',

    // ************************************ USER DELETE ********************************************

    USER_DELETE_PENDING: 'USER_DELETE_PENDING',
    USER_DELETE_SUCCESS: 'USER_DELETE_SUCCESS',
    USER_DELETE_ERROR: 'USER_DELETE_ERROR',

    // ************************************ USER DELETE ********************************************

    UPDATE_USER_PENDING: 'UPDATE_USER_PENDING',
    UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
    UPDATE_USER_ERROR: 'UPDATE_USER_ERROR',

    // ************************************ COMPANY PROFILE SETTINGS UPDATE ********************************************

    COMPANY_PROFILE_SETTINGS_UPDATE_PENDING: 'COMPANY_PROFILE_SETTINGS_UPDATE_PENDING',
    COMPANY_PROFILE_SETTINGS_UPDATE_SUCCESS: 'COMPANY_PROFILE_SETTINGS_UPDATE_SUCCESS',
    COMPANY_PROFILE_SETTINGS_UPDATE_ERROR: 'COMPANY_PROFILE_SETTINGS_UPDATE_ERROR',

    // ************************************ GET COMPANY PROFILE SETTINGS DETAILS ********************************************

    GET_COMPANY_PROFILE_SETTINGS_DETAILS_PENDING: 'GET_COMPANY_PROFILE_SETTINGS_DETAILS_PENDING',
    GET_COMPANY_PROFILE_SETTINGS_DETAILS_SUCCESS: 'GET_COMPANY_PROFILE_SETTINGS_DETAILS_SUCCESS',
    GET_COMPANY_PROFILE_SETTINGS_DETAILS_ERROR: 'GET_COMPANY_PROFILE_SETTINGS_DETAILS_ERROR',


    // ************************************ LOCATION MASTERDATA ********************************************

    LOCATION_MASTERDATA_PENDING: 'LOCATION_MASTERDATA_PENDING',
    LOCATION_MASTERDATA_SUCCESS: 'LOCATION_MASTERDATA_SUCCESS',
    LOCATION_MASTERDATA_ERROR: 'LOCATION_MASTERDATA_ERROR',

    // ************************************ LOCATION MASTERDATA ********************************************

    ADD_LOCATION_PENDING: 'ADD_LOCATION_PENDING',
    ADD_LOCATION_SUCCESS: 'ADD_LOCATION_SUCCESS',
    ADD_LOCATION_ERROR: 'ADD_LOCATION_ERROR',


    // ************************************CREATE OWNER ********************************************

    CREATE_OWNER_PENDING: 'CREATE_OWNER_PENDING',
    CREATE_OWNER_SUCCESS: 'CREATE_OWNER_SUCCESS',
    CREATE_OWNER_ERROR: 'CREATE_OWNER_ERROR',

    // *************************************OWNERDETAIL*********************************************

    OWNERDETAIL: 'OWNERDETAIL',


    // ************************************ OWNER LIST ********************************************

    OWNER_LIST_PENDING: 'OWNER_LIST_PENDING',
    OWNER_LIST_SUCCESS: 'OWNER_LIST_SUCCESS',
    OWNER_LIST_ERROR: 'OWNER_LIST_ERROR',



    // ************************************ EDIT OWNER ********************************************

    EDIT_OWNER_PENDING: 'EDIT_OWNER_PENDING',
    EDIT_OWNER_SUCCESS: 'EDIT_OWNER_SUCCESS',
    EDIT_OWNER_ERROR: 'EDIT_OWNER_ERROR',



    // ************************************OWNER_MASTER_DROPDOWN ********************************************

    OWNER_MASTER_DROPDOWN_PENDING: 'OWNER_MASTER_DROPDOWN_PENDING',
    OWNER_MASTER_DROPDOWN_SUCCESS: 'OWNER_MASTER_DROPDOWN_SUCCESS',
    OWNER_MASTER_DROPDOWN_ERROR: 'OWNER_MASTER_DROPDOWN_ERROR',

    // ************************************GET BY ID OWNER ********************************************

    GETBYID_OWNER_PENDING: 'GETBYID_OWNER_PENDING',
    GETBYID_OWNER_SUCCESS: 'GETBYID_OWNER_SUCCESS',
    GETBYID_OWNER_ERROR: 'GETBYID_OWNER_ERROR',


    // ************************************DELETE OWNER ********************************************

    DELETE_OWNER_PENDING: 'DELETE_OWNER_PENDING',
    DELETE_OWNER_SUCCESS: 'DELETE_OWNER_SUCCESS',
    DELETE_OWNER_ERROR: 'DELETE_OWNER_ERROR',


    // ************************************ADD NOTES OWNER********************************************

    ADD_NOTES_OWNER_PENDING: 'ADD_NOTES_OWNER_PENDING',
    ADD_NOTES_OWNER_SUCCESS: 'ADD_NOTES_OWNER_SUCCESS',
    ADD_NOTES_OWNER_ERROR: 'ADD_NOTES_OWNER_ERROR',


    // ************************************DELETE NOTES OWNER********************************************

    NOTES_LIST_OWNER_PENDING: 'NOTES_LIST_OWNER_PENDING',
    NOTES_LIST_OWNER_SUCCESS: 'NOTES_LIST_OWNER_SUCCESS',
    NOTES_LIST_OWNER_ERROR: 'NOTES_LIST_OWNER_ERROR',


    // ************************************OWNER DOCUMENTS LIST********************************************

    OWNER_DOCUMENTS_LIST_PENDING: 'OWNER_DOCUMENTS_LIST_PENDING',
    OWNER_DOCUMENTS_LIST_SUCCESS: 'OWNER_DOCUMENTS_LIST_SUCCESS',
    OWNER_DOCUMENTS_LIST_ERROR: 'OWNER_DOCUMENTS_LIST_ERROR',

    // ************************************DELETE OWNER DOCUMENT ********************************************

    DELETE_OWNER_DOCUMENT_PENDING: 'DELETE_OWNER_DOCUMENT_PENDING',
    DELETE_OWNER_DOCUMENT_SUCCESS: 'DELETE_OWNER_DOCUMENT_SUCCESS',
    DELETE_OWNER_DOCUMENT_ERROR: 'DELETE_OWNER_DOCUMENT_ERROR',


    // ************************************CALCULATE CREDIT SCORE ********************************************

    CALCULATE_FLEX_COST_PENDING: 'CALCULATE_FLEX_COST_PENDING',
    CALCULATE_FLEX_COST_SUCCESS: 'CALCULATE_FLEX_COST_SUCCESS',
    CALCULATE_FLEX_COST_ERROR: 'CALCULATE_FLEX_COST_ERROR',


    // ************************************OWNER PROPERTIES  ********************************************

    OWNER_PROPERTIES_PENDING: 'OWNER_PROPERTIES_PENDING',
    OWNER_PROPERTIES_SUCCESS: 'OWNER_PROPERTIES_SUCCESS',
    OWNER_PROPERTIES_ERROR: 'OWNER_PROPERTIES_ERROR',



    // ************************************CHECK PUBLISH CREDITS  ********************************************

    CHECK_PUBLISH_CREDITS_PENDING: 'CHECK_PUBLISH_CREDITS_PENDING',
    CHECK_PUBLISH_CREDITS_SUCCESS: 'CHECK_PUBLISH_CREDITS_SUCCESS',
    CHECK_PUBLISH_CREDITS_ERROR: 'CHECK_PUBLISH_CREDITS_ERROR',

    // ************************************FLOOR PLAN DOCUMENT ********************************************

    FLOOR_PLAN_DOCLIST_PENDING: 'FLOOR_PLAN_DOCLIST_PENDING',
    FLOOR_PLAN_DOCLIST_SUCCESS: 'FLOOR_PLAN_DOCLIST_SUCCESS',
    FLOOR_PLAN_DOCLIST_ERROR: 'FLOOR_PLAN_DOCLIST_ERROR',
    // ************************************CREDIT MANAGEMENT ********************************************

    CREDIT_MANAGEMENT_PENDING: 'CREDIT_MANAGEMENT_PENDING',
    CREDIT_MANAGEMENT_SUCCESS: 'CREDIT_MANAGEMENT_SUCCESS',
    CREDIT_MANAGEMENT_ERROR: 'CREDIT_MANAGEMENT_ERROR',

    // ************************************OWNER PROPERTY DELETE ********************************************

    OWNER_PROPERTY_DELETE_PENDING: 'OWNER_PROPERTY_DELETE_PENDING',
    OWNER_PROPERTY_DELETE_SUCCESS: 'OWNER_PROPERTY_DELETE_SUCCESS',
    OWNER_PROPERTY_DELETE_ERROR: 'OWNER_PROPERTY_DELETE_ERROR',

    // ************************************Media image DELETE ********************************************

    MEDIA_IMAGE_DELETE_PENDING: 'MEDIA_IMAGE_DELETE_PENDING',
    MEDIA_IMAGE_DELETE_SUCCESS: 'MEDIA_IMAGE_DELETE_SUCCESS',
    MEDIA_IMAGE_DELETE_ERROR: 'MEDIA_IMAGE_DELETE_ERROR',

    // ************************************MIMIC********************************************

    MIMIC_PENDING: 'MIMIC_PENDING',
    MIMIC_SUCCESS: 'MIMIC_SUCCESS',
    MIMIC_ERROR: 'MIMIC_ERROR',

    // ************************************TESTIMONIALS_DELETE********************************************

    TESTIMONIALS_DELETE_PENDING: 'TESTIMONIALS_DELETE_PENDING',
    TESTIMONIALS_DELETE_SUCCESS: 'TESTIMONIALS_DELETE_SUCCESS',
    TESTIMONIALS_DELETE_ERROR: 'TESTIMONIALS_DELETE_ERROR',


    // ************************************TESTIMONIALS_LIST********************************************

    TESTIMONIALS_LIST_PENDING: 'TESTIMONIALS_LIST_PENDING',
    TESTIMONIALS_LIST_SUCCESS: 'TESTIMONIALS_LIST_SUCCESS',
    TESTIMONIALS_LIST_ERROR: 'TESTIMONIALS_LIST_ERROR',



    // ************************************TESTIMONIAL_APPROVE********************************************

    TESTIMONIAL_APPROVE_PENDING: 'TESTIMONIAL_APPROVE_PENDING',
    TESTIMONIAL_APPROVE_SUCCESS: 'TESTIMONIAL_APPROVE_SUCCESS',
    TESTIMONIAL_APPROVE_ERROR: 'TESTIMONIAL_APPROVE_ERROR',


    // ************************************BLOG_CREATE********************************************

    BLOG_CREATE_PENDING: 'BLOG_CREATE_PENDING',
    BLOG_CREATE_SUCCESS: 'BLOG_CREATE_SUCCESS',
    BLOG_CREATE_ERROR: 'BLOG_CREATE_ERROR',



    // ************************************BLOG_UPATE********************************************

    BLOG_UPATE_PENDING: 'BLOG_UPATE_PENDING',
    BLOG_UPATE_SUCCESS: 'BLOG_UPATE_SUCCESS',
    BLOG_UPATE_ERROR: 'BLOG_UPATE_ERROR',

    // ************************************BLOG_LIST********************************************

    BLOG_LIST_PENDING: 'BLOG_LIST_PENDING',
    BLOG_LIST_SUCCESS: 'BLOG_LIST_SUCCESS',
    BLOG_LIST_ERROR: 'BLOG_LIST_ERROR',

    // ************************************BLOG_GETbYID********************************************

    BLOG_GETBYID_PENDING: 'BLOG_GETBYID_PENDING',
    BLOG_GETBYID_SUCCESS: 'BLOG_GETBYID_SUCCESS',
    BLOG_GETBYID_ERROR: 'BLOG_GETBYID_ERROR',

    // ************************************BLOG_DELETE********************************************

    BLOG_DELETE_PENDING: 'BLOG_DELETE_PENDING',
    BLOG_DELETE_SUCCESS: 'BLOG_DELETE_SUCCESS',
    BLOG_DELETE_ERROR: 'BLOG_DELETE_ERROR',

    // ************************************ADVERTISEMENT_IMAGELIST********************************************

    ADVERTISEMENT_IMAGELIST_PENDING: 'ADVERTISEMENT_IMAGELIST_PENDING',
    ADVERTISEMENT_IMAGELIST_SUCCESS: 'ADVERTISEMENT_IMAGELIST_SUCCESS',
    ADVERTISEMENT_IMAGELIST_ERROR: 'ADVERTISEMENT_IMAGELIST_ERROR',


    // ************************************ADVERTISEMENT_IMAGELIST_VERTICAL********************************************

    ADVERTISEMENT_IMAGELIST_VERTICAL_PENDING: 'ADVERTISEMENT_IMAGELIST_VERTICAL_PENDING',
    ADVERTISEMENT_IMAGELIST_VERTICAL_SUCCESS: 'ADVERTISEMENT_IMAGELIST_VERTICAL_SUCCESS',
    ADVERTISEMENT_IMAGELIST_VERTICAL_ERROR: 'ADVERTISEMENT_IMAGELIST_VERTICAL_ERROR',


    // ************************************HOWITWORKS_COUNTER_GETBYID********************************************

    HOWITWORKS_COUNTER_GETBYID_PENDING: 'HOWITWORKS_COUNTER_GETBYID_PENDING',
    HOWITWORKS_COUNTER_GETBYID_SUCCESS: 'HOWITWORKS_COUNTER_GETBYID_SUCCESS',
    HOWITWORKS_COUNTER_GETBYID_ERROR: 'HOWITWORKS_COUNTER_GETBYID_ERROR',


    // ************************************HOWITWORKS_COUNTER_UPDATE********************************************

    HOWITWORKS_COUNTER_UPDATE_PENDING: 'HOWITWORKS_COUNTER_UPDATE_PENDING',
    HOWITWORKS_COUNTER_UPDATE_SUCCESS: 'HOWITWORKS_COUNTER_UPDATE_SUCCESS',
    HOWITWORKS_COUNTER_UPDATE_ERROR: 'HOWITWORKS_COUNTER_UPDATE_ERROR',


    // ************************************DELETE_ADVERTISEMENT_IMAGE********************************************

    DELETE_ADVERTISEMENT_IMAGE_PENDING: 'DELETE_ADVERTISEMENT_IMAGE_PENDING',
    DELETE_ADVERTISEMENT_IMAGE_SUCCESS: 'DELETE_ADVERTISEMENT_IMAGE_SUCCESS',
    DELETE_ADVERTISEMENT_IMAGE_ERROR: 'DELETE_ADVERTISEMENT_IMAGE_ERROR',


    // ************************************EXPORT TO CSV ********************************************

    EXPORT_TO_CSV_PENDING: 'EXPORT_TO_CSV_PENDING',
    EXPORT_TO_CSV_SUCCESS: 'EXPORT_TO_CSV_SUCCESS',
    EXPORT_TO_CSV_ERROR: 'EXPORT_TO_CSV_ERROR',


    // ************************************SETTINGS ADMIN ADD********************************************

    SETTINGS_ADMIN_ADD_PENDING: 'SETTINGS_ADMIN_ADD_PENDING',
    SETTINGS_ADMIN_ADD_SUCCESS: 'SETTINGS_ADMIN_ADD_SUCCESS',
    SETTINGS_ADMIN_ADD_ERROR: 'SETTINGS_ADMIN_ADD_ERROR',


    // ************************************UNPUBLISHED PROPERTIES CREDIT MANAGEMENT ********************************************

    UNPUBLISHED_PROPERTIES_CREDIT_MANAGEMENT_PENDING: 'UNPUBLISHED_PROPERTIES_CREDIT_MANAGEMENT_PENDING',
    UNPUBLISHED_PROPERTIES_CREDIT_MANAGEMENT_SUCCESS: 'UNPUBLISHED_PROPERTIES_CREDIT_MANAGEMENT_SUCCESS',
    UNPUBLISHED_PROPERTIES_CREDIT_MANAGEMENT_ERROR: 'UNPUBLISHED_PROPERTIES_CREDIT_MANAGEMENT_ERROR',


    // ************************************ADD CREDIT TO SUB AGENT ********************************************

    ADD_SUB_AGENT_CREDIT_PENDING: 'ADD_SUB_AGENT_CREDIT_PENDING',
    ADD_SUB_AGENT_CREDIT_SUCCESS: 'ADD_SUB_AGENT_CREDIT_SUCCESS',
    ADD_SUB_AGENT_CREDIT_ERROR: 'ADD_SUB_AGENT_CREDIT_ERROR',

    // ************************************WITHDRAW CREDIT FROM SUB AGENT ********************************************

    WITHDRAW_SUB_AGENT_CREDIT_PENDING: 'WITHDRAW_SUB_AGENT_CREDIT_PENDING',
    WITHDRAW_SUB_AGENT_CREDIT_SUCCESS: 'WITHDRAW_SUB_AGENT_CREDIT_SUCCESS',
    WITHDRAW_SUB_AGENT_CREDIT_ERROR: 'WITHDRAW_SUB_AGENT_CREDIT_ERROR',


    // ************************************PUBLISH_REQUEST_APPROVE_REJECT********************************************

    PUBLISH_REQUEST_APPROVE_REJECT_PENDING: 'PUBLISH_REQUEST_APPROVE_REJECT_PENDING',
    PUBLISH_REQUEST_APPROVE_REJECT_SUCCESS: 'PUBLISH_REQUEST_APPROVE_REJECT_SUCCESS',
    PUBLISH_REQUEST_APPROVE_REJECT_ERROR: 'PUBLISH_REQUEST_APPROVE_REJECT_ERROR',


    // *************************************MIMIC_BACK*********************************************

    MIMIC_BACK: 'MIMIC_BACK',

    // *************************************USER_ENABLE*********************************************

    USER_ENABLE: 'USER_ENABLE',

    // ******************************************OWNER_ENABLE************************************
    OWNER_ENABLE: 'OWNER_ENABLE',
    // ******************************************LEAD_ENABLE************************************
    LEAD_ENABLE: 'LEAD_ENABLE',
    // ******************************************PROPERTY_ENABLE************************************
    PROPERTY_ENABLE: 'PROPERTY_ENABLE',
    // ******************************************COMPANY_ENABLE************************************
    COMPANY_ENABLE: 'COMPANY_ENABLE',

    // ******************************************OWNER_PROPERTY************************************
    OWNER_PROPERTY: 'OWNER_PROPERTY',


    // ******************************************BLOG_FLAG************************************
    BLOG_FLAG: 'BLOG_FLAG',
    // ******************************************CLEAR_STATE************************************

    CLEAR_STATE: 'CLEAR_STATE',

};
export default ACTION_TYPES;