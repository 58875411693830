import ACTION_TYPES from './ActionTypes.js';

export const loginData = () => ({
    type: ACTION_TYPES.LOGIN_PENDING
});

export const loginSuccess = (data) => ({
    type: ACTION_TYPES.LOGIN_SUCCESS,
    payload: data
});
export const loginError = (error) => ({
    type: ACTION_TYPES.LOGIN_ERROR,
    payload: error
})

export const agentData = () => ({
    type: ACTION_TYPES.AGENTLIST_PENDING
});

export const agentSuccess = (data) => ({
    type: ACTION_TYPES.AGENTLIST_SUCCESS,
    payload: data
});
export const agentError = (error) => ({
    type: ACTION_TYPES.AGENTLIST_ERROR,
    payload: error
})

export const companyNameData = () => ({
    type: ACTION_TYPES.COMPNAME_LIST_PENDING
});

export const companyNameSuccess = (data) => ({
    type: ACTION_TYPES.COMPNAME_LIST_SUCCESS,
    payload: data
});
export const companyNameError = (error) => ({
    type: ACTION_TYPES.COMPNAME_LIST_ERROR,
    payload: error
})

export const agentNameData = () => ({
    type: ACTION_TYPES.AGENTNAME_PENDING
});

export const agentNameSuccess = (data) => ({
    type: ACTION_TYPES.AGENTNAME_SUCCESS,
    payload: data
});
export const agentNameError = (error) => ({
    type: ACTION_TYPES.AGENTNAME_ERROR,
    payload: error
})

export const countryData = () => ({
    type: ACTION_TYPES.COUNTRY_PENDING
});

export const countrySuccess = (data) => ({
    type: ACTION_TYPES.COUNTRY_SUCCESS,
    payload: data
});
export const countryError = (error) => ({
    type: ACTION_TYPES.COUNTRY_ERROR,
    payload: error
})



export const stateData = () => ({
    type: ACTION_TYPES.STATE_PENDING
});

export const stateSuccess = (data) => ({
    type: ACTION_TYPES.STATE_SUCCESS,
    payload: data
});
export const stateError = (error) => ({
    type: ACTION_TYPES.STATE_ERROR,
    payload: error
})



export const cityData = () => ({
    type: ACTION_TYPES.CITY_PENDING
});

export const citySuccess = (data) => ({
    type: ACTION_TYPES.CITY_SUCCESS,
    payload: data
});
export const cityError = (error) => ({
    type: ACTION_TYPES.CITY_ERROR,
    payload: error
})



export const getAgentDetailsData = () => ({
    type: ACTION_TYPES.GET_AGENT_DETAILS_PENDING
});

export const getAgentDetailsSuccess = (data) => ({
    type: ACTION_TYPES.GET_AGENT_DETAILS_SUCCESS,
    payload: data
});
export const getAgentDetailsError = (error) => ({
    type: ACTION_TYPES.GET_AGENT_DETAILS_ERROR,
    payload: error
})

export const createAgentData = () => ({
    type: ACTION_TYPES.CREATE_AGENT_PENDING
});

export const createAgentSuccess = (data) => ({
    type: ACTION_TYPES.CREATE_AGENT_SUCCESS,
    payload: data
});
export const createAgentError = (error) => ({
    type: ACTION_TYPES.CREATE_AGENT_ERROR,
    payload: error
})

export const editAgentData = () => ({
    type: ACTION_TYPES.EDIT_AGENT_PENDING
});

export const editAgentSuccess = (data) => ({
    type: ACTION_TYPES.EDIT_AGENT_SUCCESS,
    payload: data
});
export const editAgentError = (error) => ({
    type: ACTION_TYPES.EDIT_AGENT_ERROR,
    payload: error
})

export const deleteAgentData = () => ({
    type: ACTION_TYPES.DELETE_AGENT_PENDING
});

export const deleteAgentSuccess = (data) => ({
    type: ACTION_TYPES.DELETE_AGENT_SUCCESS,
    payload: data
});
export const deleteAgentError = (error) => ({
    type: ACTION_TYPES.DELETE_AGENT_ERROR,
    payload: error
})


export const propertyTypeData = () => ({
    type: ACTION_TYPES.PROPERTY_TYPE_PENDING
});

export const propertyTypeSuccess = (data) => ({
    type: ACTION_TYPES.PROPERTY_TYPE_SUCCESS,
    payload: data
});
export const propertyTypeError = (error) => ({
    type: ACTION_TYPES.PROPERTY_TYPE_ERROR,
    payload: error
})

export const FurnishingTypeData = () => ({
    type: ACTION_TYPES.FURNISHING_TYPE_PENDING
});

export const FurnishingTypeSuccess = (data) => ({
    type: ACTION_TYPES.FURNISHING_TYPE_SUCCESS,
    payload: data
});
export const FurnishingTypeError = (error) => ({
    type: ACTION_TYPES.FURNISHING_TYPE_ERROR,
    payload: error
})

export const occupancyTypeData = () => ({
    type: ACTION_TYPES.OCCUPANCY_TYPE_PENDING
});

export const occupancyTypeSuccess = (data) => ({
    type: ACTION_TYPES.OCCUPANCY_TYPE_SUCCESS,
    payload: data
});
export const occupancyTypeError = (error) => ({
    type: ACTION_TYPES.OCCUPANCY_TYPE_ERROR,
    payload: error
})

export const renovationTypeData = () => ({
    type: ACTION_TYPES.RENOVATION_TYPE_PENDING
});

export const renovationTypeSuccess = (data) => ({
    type: ACTION_TYPES.RENOVATION_TYPE_SUCCESS,
    payload: data
});
export const renovationTypeError = (error) => ({
    type: ACTION_TYPES.RENOVATION_TYPE_ERROR,
    payload: error
})

export const agentDetailData = (agentType, agentId,) => {
    return {
        type: ACTION_TYPES.AGENTDETAIL,
        payload: { agentType, agentId },
    };
};


export const tenureData = () => ({
    type: ACTION_TYPES.TENURE_PENDING
});

export const tenureSuccess = (data) => ({
    type: ACTION_TYPES.TENURE_SUCCESS,
    payload: data
});
export const tenureError = (error) => ({
    type: ACTION_TYPES.TENURE_ERROR,
    payload: error
})

export const propertyCategoryData = () => ({
    type: ACTION_TYPES.PROPERTY_CATEGORY_PENDING
});

export const propertyCategorySuccess = (data) => ({
    type: ACTION_TYPES.PROPERTY_CATEGORY_SUCCESS,
    payload: data
});
export const propertyCategoryError = (error) => ({
    type: ACTION_TYPES.PROPERTY_CATEGORY_ERROR,
    payload: error
})


export const maritialStatusData = () => ({
    type: ACTION_TYPES.MARITIAL_STATUS_PENDING
});

export const maritialStatusSuccess = (data) => ({
    type: ACTION_TYPES.MARITIAL_STATUS_SUCCESS,
    payload: data
});
export const maritialStatusError = (error) => ({
    type: ACTION_TYPES.MARITIAL_STATUS_ERROR,
    payload: error
})


export const agentUploadDocumentsListData = () => ({
    type: ACTION_TYPES.AGENT_UPLOAD_DOCUMENTS_LIST_PENDING
});

export const agentUploadDocumentsListSuccess = (data) => ({
    type: ACTION_TYPES.AGENT_UPLOAD_DOCUMENTS_LIST_SUCCESS,
    payload: data
});
export const agentUploadDocumentsListError = (error) => ({
    type: ACTION_TYPES.AGENT_UPLOAD_DOCUMENTS_LIST_ERROR,
    payload: error
})


export const agentCreditListData = () => ({
    type: ACTION_TYPES.AGENT_CREDIT_LIST_PENDING
});

export const agentCreditListSuccess = (data) => ({
    type: ACTION_TYPES.AGENT_CREDIT_LIST_SUCCESS,
    payload: data
});
export const agentCreditListError = (error) => ({
    type: ACTION_TYPES.AGENT_CREDIT_LIST_ERROR,
    payload: error
})


export const deleteAgentCreditListData = () => ({
    type: ACTION_TYPES.DELETE_AGENT_CREDIT_LIST_PENDING
});

export const deleteAgentCreditListSuccess = (data) => ({
    type: ACTION_TYPES.DELETE_AGENT_CREDIT_LIST_SUCCESS,
    payload: data
});
export const deleteAgentCreditListError = (error) => ({
    type: ACTION_TYPES.DELETE_AGENT_CREDIT_LIST_ERROR,
    payload: error
})




export const addAgentCreditListData = () => ({
    type: ACTION_TYPES.ADD_AGENT_CREDIT_LIST_PENDING
});

export const addAgentCreditListSuccess = (data) => ({
    type: ACTION_TYPES.ADD_AGENT_CREDIT_LIST_SUCCESS,
    payload: data
});
export const addAgentCreditListError = (error) => ({
    type: ACTION_TYPES.ADD_AGENT_CREDIT_LIST_ERROR,
    payload: error
})


export const addPropertyData = () => ({
    type: ACTION_TYPES.ADD_PROPERTY_PENDING
});

export const addPropertySuccess = (data) => ({
    type: ACTION_TYPES.ADD_PROPERTY_SUCCESS,
    payload: data
});
export const addPropertyError = (error) => ({
    type: ACTION_TYPES.ADD_PROPERTY_ERROR,
    payload: error
})

export const updatePropertyData = () => ({
    type: ACTION_TYPES.UPDATE_PROPERTY_PENDING
});

export const updatePropertySuccess = (data) => ({
    type: ACTION_TYPES.UPDATE_PROPERTY_SUCCESS,
    payload: data
});
export const updatePropertyError = (error) => ({
    type: ACTION_TYPES.UPDATE_PROPERTY_ERROR,
    payload: error
})

export const imageMediaData = () => ({
    type: ACTION_TYPES.IMAGEMEDIA_LIST_PENDING
});

export const imageMediaSuccess = (data) => ({
    type: ACTION_TYPES.IMAGEMEDIA_LIST_SUCCESS,
    payload: data
});
export const imageMediaError = (error) => ({
    type: ACTION_TYPES.IMAGEMEDIA_LIST_ERROR,
    payload: error
})

export const amenitiesImageMediaData = () => ({
    type: ACTION_TYPES.AMENITIESMEDIA_LIST_PENDING
});

export const amenitiesImageMediaSuccess = (data) => ({
    type: ACTION_TYPES.AMENITIESMEDIA_LIST_SUCCESS,
    payload: data
});
export const amenitiesImageMediaError = (error) => ({
    type: ACTION_TYPES.AMENITIESMEDIA_LIST_ERROR,
    payload: error
})
export const addNotesData = () => ({
    type: ACTION_TYPES.ADD_NOTES_PENDING
});

export const addNotesSuccess = (data) => ({
    type: ACTION_TYPES.ADD_NOTES_SUCCESS,
    payload: data
});
export const addNotesError = (error) => ({
    type: ACTION_TYPES.ADD_NOTES_ERROR,
    payload: error
})

export const propertyListData = () => ({
    type: ACTION_TYPES.PROPERTYFILTER_PENDING
});

export const propertyListSuccess = (data) => ({
    type: ACTION_TYPES.PROPERTYFILTER_SUCCESS,
    payload: data
});
export const propertyListError = (error) => ({
    type: ACTION_TYPES.PROPERTYFILTER_ERROR,
    payload: error
})

export const propertyDropdownMasterData = () => ({
    type: ACTION_TYPES.PROPERTY_DROPDOWN_MASTERDATA_PENDING
});

export const propertyDropdownMasterSuccess = (data) => ({
    type: ACTION_TYPES.PROPERTY_DROPDOWN_MASTERDATA_SUCCESS,
    payload: data
});
export const propertyDropdownMasterError = (error) => ({
    type: ACTION_TYPES.PROPERTY_DROPDOWN_MASTERDATA_ERROR,
    payload: error
})
export const propertyDetailData = (propertyType, propertyId) => {
    return {
        type: ACTION_TYPES.PROPERTYDETAIL,
        payload: { propertyType, propertyId },
    };
};


export const propertyDocumentsListData = () => ({
    type: ACTION_TYPES.PROPERTY_DOCUMENTS_LIST_PENDING
});

export const propertyDocumentsListSuccess = (data) => ({
    type: ACTION_TYPES.PROPERTY_DOCUMENTS_LIST_SUCCESS,
    payload: data
});
export const propertyDocumentsListError = (error) => ({
    type: ACTION_TYPES.PROPERTY_DOCUMENTS_LIST_ERROR,
    payload: error
})

export const uploadVideoUrlData = () => ({
    type: ACTION_TYPES.UPLOAD_VIDEO_URL_PENDING
});

export const uploadVideoUrlSuccess = (data) => ({
    type: ACTION_TYPES.UPLOAD_VIDEO_URL_SUCCESS,
    payload: data
});
export const uploadVideoUrlError = (error) => ({
    type: ACTION_TYPES.UPLOAD_VIDEO_URL_ERROR,
    payload: error
})




export const deletePropertyDocumentsListData = () => ({
    type: ACTION_TYPES.DELETE_PROPERTY_DOCUMENTS_LIST_PENDING
});

export const deletePropertyDocumentsListSuccess = (data) => ({
    type: ACTION_TYPES.DELETE_PROPERTY_DOCUMENTS_LIST_SUCCESS,
    payload: data
});
export const deletePropertyDocumentsListError = (error) => ({
    type: ACTION_TYPES.DELETE_PROPERTY_DOCUMENTS_LIST_ERROR,
    payload: error
})

export const getPropertyDetailData = () => ({
    type: ACTION_TYPES.GET_PROPERTY_DETAIL_PENDING
});

export const getPropertyDetailSuccess = (data) => ({
    type: ACTION_TYPES.GET_PROPERTY_DETAIL_SUCCESS,
    payload: data
});
export const getPropertyDetailError = (error) => ({
    type: ACTION_TYPES.GET_PROPERTY_DETAIL_ERROR,
    payload: error
})


export const deletePropertyData = () => ({
    type: ACTION_TYPES.DELETE_PROPERTY_PENDING
});

export const deletePropertySuccess = (data) => ({
    type: ACTION_TYPES.DELETE_PROPERTY_SUCCESS,
    payload: data
});
export const deletePropertyError = (error) => ({
    type: ACTION_TYPES.DELETE_PROPERTY_ERROR,
    payload: error
})

export const notesPropertyListData = () => ({
    type: ACTION_TYPES.NOTESLIST_PENDING
});

export const notesPropertyListSuccess = (data) => ({
    type: ACTION_TYPES.NOTESLIST_SUCCESS,
    payload: data
});
export const notesPropertyListError = (error) => ({
    type: ACTION_TYPES.NOTESLIST_ERROR,
    payload: error
})


export const leadsDropDownData = () => ({
    type: ACTION_TYPES.LEADS_DROPDOWN_PENDING
});

export const leadsDropDownSuccess = (data) => ({
    type: ACTION_TYPES.LEADS_DROPDOWN_SUCCESS,
    payload: data
});
export const leadsDropDownError = (error) => ({
    type: ACTION_TYPES.LEADS_DROPDOWN_ERROR,
    payload: error
})

export const leadsDetailData = (leadType, leadId) => {
    return {
        type: ACTION_TYPES.LEADSDETAIL,
        payload: { leadType, leadId },
    };
};

export const leadsCreateData = () => ({
    type: ACTION_TYPES.LEADS_CREATE_PENDING
});

export const leadsCreateSuccess = (data) => ({
    type: ACTION_TYPES.LEADS_CREATE_SUCCESS,
    payload: data
});
export const leadsCreateError = (error) => ({
    type: ACTION_TYPES.LEADS_CREATE_ERROR,
    payload: error
})

export const leadsUpdateData = () => ({
    type: ACTION_TYPES.LEADS_UPDATE_PENDING
});

export const leadsUpdateSuccess = (data) => ({
    type: ACTION_TYPES.LEADS_UPDATE_SUCCESS,
    payload: data
});
export const leadsUpdateError = (error) => ({
    type: ACTION_TYPES.LEADS_UPDATE_ERROR,
    payload: error
})

export const leadsListData = () => ({
    type: ACTION_TYPES.LEADS_LIST_PENDING
});

export const leadsListSuccess = (data) => ({
    type: ACTION_TYPES.LEADS_LIST_SUCCESS,
    payload: data
});
export const leadsListError = (error) => ({
    type: ACTION_TYPES.LEADS_LIST_ERROR,
    payload: error
})


export const getLeadsDetailData = () => ({
    type: ACTION_TYPES.GET_LEADS_DETAIL_PENDING
});

export const getLeadsDetailSuccess = (data) => ({
    type: ACTION_TYPES.GET_LEADS_DETAIL_SUCCESS,
    payload: data
});
export const getLeadsDetailError = (error) => ({
    type: ACTION_TYPES.GET_LEADS_DETAIL_ERROR,
    payload: error
})


export const addNotesLeadsData = () => ({
    type: ACTION_TYPES.ADD_NOTES_LEADS_PENDING
});

export const addNotesLeadsSuccess = (data) => ({
    type: ACTION_TYPES.ADD_NOTES_LEADS_SUCCESS,
    payload: data
});
export const addNotesLeadsError = (error) => ({
    type: ACTION_TYPES.ADD_NOTES_LEADS_ERROR,
    payload: error
})


export const deleteLeadData = () => ({
    type: ACTION_TYPES.DELETE_LEAD_PENDING
});

export const deleteLeadSuccess = (data) => ({
    type: ACTION_TYPES.DELETE_LEAD_SUCCESS,
    payload: data
});
export const deleteLeadError = (error) => ({
    type: ACTION_TYPES.DELETE_LEAD_ERROR,
    payload: error
})


export const notesListLeadData = () => ({
    type: ACTION_TYPES.NOTESLIST_LEAD_PENDING
});

export const notesListLeadSuccess = (data) => ({
    type: ACTION_TYPES.NOTESLIST_LEAD_SUCCESS,
    payload: data
});
export const notesListLeadError = (error) => ({
    type: ACTION_TYPES.NOTESLIST_LEAD_ERROR,
    payload: error
})


export const documentsListLeadData = () => ({
    type: ACTION_TYPES.DOCUMENTSLIST_LEAD_PENDING
});

export const documentsListLeadSuccess = (data) => ({
    type: ACTION_TYPES.DOCUMENTSLIST_LEAD_SUCCESS,
    payload: data
});
export const documentsListLeadError = (error) => ({
    type: ACTION_TYPES.DOCUMENTSLIST_LEAD_ERROR,
    payload: error
})


export const deleteDocumentsLeadData = () => ({
    type: ACTION_TYPES.DELETE_DOCUMENT_LEAD_PENDING
});

export const deleteDocumentsLeadSuccess = (data) => ({
    type: ACTION_TYPES.DELETE_DOCUMENT_LEAD_SUCCESS,
    payload: data
});
export const deleteDocumentsLeadError = (error) => ({
    type: ACTION_TYPES.DELETE_DOCUMENT_LEAD_ERROR,
    payload: error
})


export const addLeadRequirementsData = () => ({
    type: ACTION_TYPES.ADD_LEAD_REQUIREMENTS_PENDING
});

export const addLeadRequirementsSuccess = (data) => ({
    type: ACTION_TYPES.ADD_LEAD_REQUIREMENTS_SUCCESS,
    payload: data
});
export const addLeadRequirementsError = (error) => ({
    type: ACTION_TYPES.ADD_LEAD_REQUIREMENTS_ERROR,
    payload: error
})


export const editLeadRequirementsData = () => ({
    type: ACTION_TYPES.EDIT_LEAD_REQUIREMENTS_PENDING
});

export const editLeadRequirementsSuccess = (data) => ({
    type: ACTION_TYPES.EDIT_LEAD_REQUIREMENTS_SUCCESS,
    payload: data
});
export const editLeadRequirementsError = (error) => ({
    type: ACTION_TYPES.EDIT_LEAD_REQUIREMENTS_ERROR,
    payload: error
})



export const getByIdLeadRequirementsData = () => ({
    type: ACTION_TYPES.GET_BY_ID_LEAD_REQUIREMENTS_PENDING
});

export const getByIdLeadRequirementsSuccess = (data) => ({
    type: ACTION_TYPES.GET_BY_ID_LEAD_REQUIREMENTS_SUCCESS,
    payload: data
});
export const getByIdLeadRequirementsError = (error) => ({
    type: ACTION_TYPES.GET_BY_ID_LEAD_REQUIREMENTS_ERROR,
    payload: error
})


export const shortlistedPropertiesData = () => ({
    type: ACTION_TYPES.SHORTLISTED_PROPERTIES_PENDING
});

export const shortlistedPropertiesSuccess = (data) => ({
    type: ACTION_TYPES.SHORTLISTED_PROPERTIES_SUCCESS,
    payload: data
});
export const shortlistedPropertiesError = (error) => ({
    type: ACTION_TYPES.SHORTLISTED_PROPERTIES_ERROR,
    payload: error
})



export const matchingListingsData = () => ({
    type: ACTION_TYPES.MATCHING_LISTINGS_PENDING
});

export const matchingListingsSuccess = (data) => ({
    type: ACTION_TYPES.MATCHING_LISTINGS_SUCCESS,
    payload: data
});
export const matchingListingsError = (error) => ({
    type: ACTION_TYPES.MATCHING_LISTINGS_ERROR,
    payload: error
})



export const addShortlistedPropertiesData = () => ({
    type: ACTION_TYPES.ADD_SHORTLISTED_PROPERTIES_PENDING
});

export const addShortlistedPropertiesSuccess = (data) => ({
    type: ACTION_TYPES.ADD_SHORTLISTED_PROPERTIES_SUCCESS,
    payload: data
});
export const addShortlistedPropertiesError = (error) => ({
    type: ACTION_TYPES.ADD_SHORTLISTED_PROPERTIES_ERROR,
    payload: error
})


export const createUserData = () => ({
    type: ACTION_TYPES.CREATE_USER_PENDING
});

export const createUserSuccess = (data) => ({
    type: ACTION_TYPES.CREATE_USER_SUCCESS,
    payload: data
});
export const createUserError = (error) => ({
    type: ACTION_TYPES.CREATE_USER_ERROR,
    payload: error
})

export const userDropDownData = () => ({
    type: ACTION_TYPES.USER_MASTER_DROPDOWN_PENDING
});
export const userDropdownSuccess = (data) => ({
    type: ACTION_TYPES.USER_MASTER_DROPDOWN_SUCCESS,
    payload: data
});
export const userDropdownError = (error) => ({
    type: ACTION_TYPES.USER_MASTER_DROPDOWN_ERROR,
    payload: error
})

export const userDocumentData = () => ({
    type: ACTION_TYPES.DOCUMENTLIST_PENDING
});

export const userDocumentSuccess = (data) => ({
    type: ACTION_TYPES.DOCUMENTLIST_SUCCESS,
    payload: data
});
export const userDocumentError = (error) => ({
    type: ACTION_TYPES.DOCUMENTLIST_ERROR,
    payload: error
})


export const userDeleteDocumentData = () => ({
    type: ACTION_TYPES.USER_DELETE_DOCUMENT_PENDING
});

export const userDeleteDocumentSuccess = (data) => ({
    type: ACTION_TYPES.USER_DELETE_DOCUMENT_SUCCESS,
    payload: data
});
export const userDeleteDocumentError = (error) => ({
    type: ACTION_TYPES.USER_DELETE_DOCUMENT_ERROR,
    payload: error
})

export const userDeleteData = () => ({
    type: ACTION_TYPES.USER_DELETE_PENDING
});

export const userDeleteDataSuccess = (data) => ({
    type: ACTION_TYPES.USER_DELETE_SUCCESS,
    payload: data
});
export const userDeleteDataError = (error) => ({
    type: ACTION_TYPES.USER_DELETE_ERROR,
    payload: error
})

export const updateUserData = () => ({
    type: ACTION_TYPES.UPDATE_USER_PENDING
});

export const updateUserSuccess = (data) => ({
    type: ACTION_TYPES.UPDATE_USER_SUCCESS,
    payload: data
});
export const updateUserError = (error) => ({
    type: ACTION_TYPES.UPDATE_USER_ERROR,
    payload: error
})
export const userDetailData = (userType, userId) => {
    return {
        type: ACTION_TYPES.USERDETAIL,
        payload: { userType, userId },
    };
};

export const ownerDetailData = (ownerType, ownerId) => {
    return {
        type: ACTION_TYPES.OWNERDETAIL,
        payload: { ownerType, ownerId },
    };
};



export const getByIdUserData = () => ({
    type: ACTION_TYPES.GET_BY_ID_USER_PENDING
});

export const getByIdUserSuccess = (data) => ({
    type: ACTION_TYPES.GET_BY_ID_USER_SUCCESS,
    payload: data
});
export const getByIdUserError = (error) => ({
    type: ACTION_TYPES.GET_BY_ID_USER_ERROR,
    payload: error
})


export const userListData = () => ({
    type: ACTION_TYPES.USER_LIST_PENDING
});

export const userListSuccess = (data) => ({
    type: ACTION_TYPES.USER_LIST_SUCCESS,
    payload: data
});
export const userListError = (error) => ({
    type: ACTION_TYPES.USER_LIST_ERROR,
    payload: error
})



export const companyProfileSettingsUpdateData = () => ({
    type: ACTION_TYPES.COMPANY_PROFILE_SETTINGS_UPDATE_PENDING
});

export const companyProfileSettingsUpdateSuccess = (data) => ({
    type: ACTION_TYPES.COMPANY_PROFILE_SETTINGS_UPDATE_SUCCESS,
    payload: data
});
export const companyProfileSettingsUpdateError = (error) => ({
    type: ACTION_TYPES.COMPANY_PROFILE_SETTINGS_UPDATE_ERROR,
    payload: error
})



export const getCompanyProfileSettingsDetailsData = () => ({
    type: ACTION_TYPES.GET_COMPANY_PROFILE_SETTINGS_DETAILS_PENDING
});

export const getCompanyProfileSettingsDetailsSuccess = (data) => ({
    type: ACTION_TYPES.GET_COMPANY_PROFILE_SETTINGS_DETAILS_SUCCESS,
    payload: data
});
export const getCompanyProfileSettingsDetailsError = (error) => ({
    type: ACTION_TYPES.GET_COMPANY_PROFILE_SETTINGS_DETAILS_ERROR,
    payload: error
})

export const getLocationData = () => ({
    type: ACTION_TYPES.LOCATION_MASTERDATA_PENDING
});

export const getLocationSuccess = (data) => ({
    type: ACTION_TYPES.LOCATION_MASTERDATA_SUCCESS,
    payload: data
});
export const getLocationError = (error) => ({
    type: ACTION_TYPES.LOCATION_MASTERDATA_ERROR,
    payload: error
})
export const addLocationData = () => ({
    type: ACTION_TYPES.ADD_LOCATION_PENDING
});

export const addLocationSuccess = (data) => ({
    type: ACTION_TYPES.ADD_LOCATION_SUCCESS,
    payload: data
});
export const addLocationError = (error) => ({
    type: ACTION_TYPES.ADD_LOCATION_ERROR,
    payload: error
})



export const createOwnerData = () => ({
    type: ACTION_TYPES.CREATE_OWNER_PENDING
});

export const createOwnerSuccess = (data) => ({
    type: ACTION_TYPES.CREATE_OWNER_SUCCESS,
    payload: data
});
export const createOwnerError = (error) => ({
    type: ACTION_TYPES.CREATE_OWNER_ERROR,
    payload: error
})


export const ownerListData = () => ({
    type: ACTION_TYPES.OWNER_LIST_PENDING
});

export const ownerListSuccess = (data) => ({
    type: ACTION_TYPES.OWNER_LIST_SUCCESS,
    payload: data
});
export const ownerListError = (error) => ({
    type: ACTION_TYPES.OWNER_LIST_ERROR,
    payload: error
})


export const editOwnerData = () => ({
    type: ACTION_TYPES.EDIT_OWNER_PENDING
});

export const editOwnerSuccess = (data) => ({
    type: ACTION_TYPES.EDIT_OWNER_SUCCESS,
    payload: data
});
export const editOwnerError = (error) => ({
    type: ACTION_TYPES.EDIT_OWNER_ERROR,
    payload: error
})


export const ownerDropDownData = () => ({
    type: ACTION_TYPES.OWNER_MASTER_DROPDOWN_PENDING
});
export const ownerDropdownSuccess = (data) => ({
    type: ACTION_TYPES.OWNER_MASTER_DROPDOWN_SUCCESS,
    payload: data
});
export const ownerDropdownError = (error) => ({
    type: ACTION_TYPES.OWNER_MASTER_DROPDOWN_ERROR,
    payload: error
})


export const getByIdOwnerData = () => ({
    type: ACTION_TYPES.GETBYID_OWNER_PENDING
});
export const getByIdOwnerSuccess = (data) => ({
    type: ACTION_TYPES.GETBYID_OWNER_SUCCESS,
    payload: data
});
export const getByIdOwnerError = (error) => ({
    type: ACTION_TYPES.GETBYID_OWNER_ERROR,
    payload: error
})


export const deleteOwnerData = () => ({
    type: ACTION_TYPES.DELETE_OWNER_PENDING
});
export const deleteOwnerSuccess = (data) => ({
    type: ACTION_TYPES.DELETE_OWNER_SUCCESS,
    payload: data
});
export const deleteOwnerError = (error) => ({
    type: ACTION_TYPES.DELETE_OWNER_ERROR,
    payload: error
})


export const addNotesOwnerData = () => ({
    type: ACTION_TYPES.ADD_NOTES_OWNER_PENDING
});
export const addNotesOwnerSuccess = (data) => ({
    type: ACTION_TYPES.ADD_NOTES_OWNER_SUCCESS,
    payload: data
});
export const addNotesOwnerError = (error) => ({
    type: ACTION_TYPES.ADD_NOTES_OWNER_ERROR,
    payload: error
})



export const notesListOwnerData = () => ({
    type: ACTION_TYPES.NOTES_LIST_OWNER_PENDING
});
export const notesListOwnerSuccess = (data) => ({
    type: ACTION_TYPES.NOTES_LIST_OWNER_SUCCESS,
    payload: data
});
export const notesListOwnerError = (error) => ({
    type: ACTION_TYPES.NOTES_LIST_OWNER_ERROR,
    payload: error
})



export const ownerDocumentsListData = () => ({
    type: ACTION_TYPES.OWNER_DOCUMENTS_LIST_PENDING
});
export const ownerDocumentsListSuccess = (data) => ({
    type: ACTION_TYPES.OWNER_DOCUMENTS_LIST_SUCCESS,
    payload: data
});
export const ownerDocumentsListError = (error) => ({
    type: ACTION_TYPES.OWNER_DOCUMENTS_LIST_ERROR,
    payload: error
})


export const deleteOwnerDocumentData = () => ({
    type: ACTION_TYPES.DELETE_OWNER_DOCUMENT_PENDING
});
export const deleteOwnerDocumentSuccess = (data) => ({
    type: ACTION_TYPES.DELETE_OWNER_DOCUMENT_SUCCESS,
    payload: data
});
export const deleteOwnerDocumentError = (error) => ({
    type: ACTION_TYPES.DELETE_OWNER_DOCUMENT_ERROR,
    payload: error
})



export const calculateFlexCostData = () => ({
    type: ACTION_TYPES.CALCULATE_FLEX_COST_PENDING
});
export const calculateFlexCostSuccess = (data) => ({
    type: ACTION_TYPES.CALCULATE_FLEX_COST_SUCCESS,
    payload: data
});
export const calculateFlexCostError = (error) => ({
    type: ACTION_TYPES.CALCULATE_FLEX_COST_ERROR,
    payload: error
})


export const ownerPropertiesData = () => ({
    type: ACTION_TYPES.OWNER_PROPERTIES_PENDING
});
export const ownerPropertiesSuccess = (data) => ({
    type: ACTION_TYPES.OWNER_PROPERTIES_SUCCESS,
    payload: data
});
export const ownerPropertiesError = (error) => ({
    type: ACTION_TYPES.OWNER_PROPERTIES_ERROR,
    payload: error
})


export const checkPublishCreditsData = () => ({
    type: ACTION_TYPES.CHECK_PUBLISH_CREDITS_PENDING
});
export const checkPublishCreditsSuccess = (data) => ({
    type: ACTION_TYPES.CHECK_PUBLISH_CREDITS_SUCCESS,
    payload: data
});
export const checkPublishCreditsError = (error) => ({
    type: ACTION_TYPES.CHECK_PUBLISH_CREDITS_ERROR,
    payload: error
})

export const FloorPlanDocListData = () => ({
    type: ACTION_TYPES.FLOOR_PLAN_DOCLIST_PENDING
});
export const FloorPlanDocListSuccess = (data) => ({
    type: ACTION_TYPES.FLOOR_PLAN_DOCLIST_SUCCESS,
    payload: data
});
export const FloorPlanDocListError = (error) => ({
    type: ACTION_TYPES.FLOOR_PLAN_DOCLIST_ERROR,
    payload: error
})

export const creditManageData = () => ({
    type: ACTION_TYPES.CREDIT_MANAGEMENT_PENDING
});
export const creditManageSuccess = (data) => ({
    type: ACTION_TYPES.CREDIT_MANAGEMENT_SUCCESS,
    payload: data
});
export const creditManageError = (error) => ({
    type: ACTION_TYPES.CREDIT_MANAGEMENT_ERROR,
    payload: error
})


export const unpublishedPropertiesCreditManagementData = () => ({
    type: ACTION_TYPES.UNPUBLISHED_PROPERTIES_CREDIT_MANAGEMENT_PENDING
});
export const unpublishedPropertiesCreditManagementSuccess = (data) => ({
    type: ACTION_TYPES.UNPUBLISHED_PROPERTIES_CREDIT_MANAGEMENT_SUCCESS,
    payload: data
});
export const unpublishedPropertiesCreditManagementError = (error) => ({
    type: ACTION_TYPES.UNPUBLISHED_PROPERTIES_CREDIT_MANAGEMENT_ERROR,
    payload: error
})

export const deleteOwnerPropertyData = () => ({
    type: ACTION_TYPES.OWNER_PROPERTY_DELETE_PENDING
});
export const deleteOwnerPropertySuccess = (data) => ({
    type: ACTION_TYPES.OWNER_PROPERTY_DELETE_SUCCESS,
    payload: data
});
export const deleteOwnerPropertyError = (error) => ({
    type: ACTION_TYPES.OWNER_PROPERTY_DELETE_ERROR,
    payload: error
})


export const deleteMediaImageData = () => ({
    type: ACTION_TYPES.MEDIA_IMAGE_DELETE_PENDING
});
export const deleteMediaImageSuccess = (data) => ({
    type: ACTION_TYPES.MEDIA_IMAGE_DELETE_SUCCESS,
    payload: data
});
export const deleteMediaImageError = (error) => ({
    type: ACTION_TYPES.MEDIA_IMAGE_DELETE_ERROR,
    payload: error
})


export const exportToCsvData = () => ({
    type: ACTION_TYPES.EXPORT_TO_CSV_PENDING
});
export const exportToCsvSuccess = (data) => ({
    type: ACTION_TYPES.EXPORT_TO_CSV_SUCCESS,
    payload: data
});
export const exportToCsvError = (error) => ({
    type: ACTION_TYPES.EXPORT_TO_CSV_ERROR,
    payload: error
})

export const mimicData = () => ({
    type: ACTION_TYPES.MIMIC_PENDING
});
export const mimicSuccess = (data) => ({
    type: ACTION_TYPES.MIMIC_SUCCESS,
    payload: data
});
export const mimicError = (error) => ({
    type: ACTION_TYPES.MIMIC_ERROR,
    payload: error
})

export const mimicBack = (data) => ({
    type: ACTION_TYPES.MIMIC_BACK,
    payload: data
});

export const testimonialsListData = () => ({
    type: ACTION_TYPES.TESTIMONIALS_LIST_PENDING
});
export const testimonialsListSuccess = (data) => ({
    type: ACTION_TYPES.TESTIMONIALS_LIST_SUCCESS,
    payload: data
});
export const testimonialsListError = (error) => ({
    type: ACTION_TYPES.TESTIMONIALS_LIST_ERROR,
    payload: error
})



export const testimonialsDeleteData = () => ({
    type: ACTION_TYPES.TESTIMONIALS_DELETE_PENDING
});
export const testimonialsDeleteSuccess = (data) => ({
    type: ACTION_TYPES.TESTIMONIALS_DELETE_SUCCESS,
    payload: data
});
export const testimonialsDeleteError = (error) => ({
    type: ACTION_TYPES.TESTIMONIALS_DELETE_ERROR,
    payload: error
})



export const testimonialApproveData = () => ({
    type: ACTION_TYPES.TESTIMONIAL_APPROVE_PENDING
});
export const testimonialApproveSuccess = (data) => ({
    type: ACTION_TYPES.TESTIMONIAL_APPROVE_SUCCESS,
    payload: data
});
export const testimonialApproveError = (error) => ({
    type: ACTION_TYPES.TESTIMONIAL_APPROVE_ERROR,
    payload: error
})



export const blogCreateData = () => ({
    type: ACTION_TYPES.BLOG_CREATE_PENDING
});
export const blogCreateSuccess = (data) => ({
    type: ACTION_TYPES.BLOG_CREATE_SUCCESS,
    payload: data
});
export const blogCreateError = (error) => ({
    type: ACTION_TYPES.BLOG_CREATE_ERROR,
    payload: error
})


export const blogUpdateData = () => ({
    type: ACTION_TYPES.BLOG_UPATE_PENDING
});
export const blogUpdateSuccess = (data) => ({
    type: ACTION_TYPES.BLOG_UPATE_SUCCESS,
    payload: data
});
export const blogUpdateError = (error) => ({
    type: ACTION_TYPES.BLOG_UPATE_ERROR,
    payload: error
})



export const blogListData = () => ({
    type: ACTION_TYPES.BLOG_LIST_PENDING
});
export const blogListSuccess = (data) => ({
    type: ACTION_TYPES.BLOG_LIST_SUCCESS,
    payload: data
});
export const blogListError = (error) => ({
    type: ACTION_TYPES.BLOG_LIST_ERROR,
    payload: error
})


export const blogGetByIdData = () => ({
    type: ACTION_TYPES.BLOG_GETBYID_PENDING
});
export const blogGetByIdSuccess = (data) => ({
    type: ACTION_TYPES.BLOG_GETBYID_SUCCESS,
    payload: data
});
export const blogGetByIdError = (error) => ({
    type: ACTION_TYPES.BLOG_GETBYID_ERROR,
    payload: error
})



export const blogDeleteData = () => ({
    type: ACTION_TYPES.BLOG_DELETE_PENDING
});
export const blogDeleteSuccess = (data) => ({
    type: ACTION_TYPES.BLOG_DELETE_SUCCESS,
    payload: data
});
export const blogDeleteError = (error) => ({
    type: ACTION_TYPES.BLOG_DELETE_ERROR,
    payload: error
})



export const advertisementImageListData = () => ({
    type: ACTION_TYPES.ADVERTISEMENT_IMAGELIST_PENDING
});
export const advertisementImageListSuccess = (data) => ({
    type: ACTION_TYPES.ADVERTISEMENT_IMAGELIST_SUCCESS,
    payload: data
});
export const advertisementImageListError = (error) => ({
    type: ACTION_TYPES.ADVERTISEMENT_IMAGELIST_ERROR,
    payload: error
})



export const advertisementImageListVerticalData = () => ({
    type: ACTION_TYPES.ADVERTISEMENT_IMAGELIST_VERTICAL_PENDING
});
export const advertisementImageListVerticalSuccess = (data) => ({
    type: ACTION_TYPES.ADVERTISEMENT_IMAGELIST_VERTICAL_SUCCESS,
    payload: data
});
export const advertisementImageListVerticalError = (error) => ({
    type: ACTION_TYPES.ADVERTISEMENT_IMAGELIST_VERTICAL_ERROR,
    payload: error
})



export const howItWorksCounterGetByIdData = () => ({
    type: ACTION_TYPES.HOWITWORKS_COUNTER_GETBYID_PENDING
});
export const howItWorksCounterGetByIdSuccess = (data) => ({
    type: ACTION_TYPES.HOWITWORKS_COUNTER_GETBYID_SUCCESS,
    payload: data
});
export const howItWorksCounterGetByIdError = (error) => ({
    type: ACTION_TYPES.HOWITWORKS_COUNTER_GETBYID_ERROR,
    payload: error
})



export const howItWorksCounterUpdateData = () => ({
    type: ACTION_TYPES.HOWITWORKS_COUNTER_UPDATE_PENDING
});
export const howItWorksCounterUpdateSuccess = (data) => ({
    type: ACTION_TYPES.HOWITWORKS_COUNTER_UPDATE_SUCCESS,
    payload: data
});
export const howItWorksCounterUpdateError = (error) => ({
    type: ACTION_TYPES.HOWITWORKS_COUNTER_UPDATE_ERROR,
    payload: error
})




export const deleteAdvertisementImageData = () => ({
    type: ACTION_TYPES.DELETE_ADVERTISEMENT_IMAGE_PENDING
});
export const deleteAdvertisementImageSuccess = (data) => ({
    type: ACTION_TYPES.DELETE_ADVERTISEMENT_IMAGE_SUCCESS,
    payload: data
});
export const deleteAdvertisementImageError = (error) => ({
    type: ACTION_TYPES.DELETE_ADVERTISEMENT_IMAGE_ERROR,
    payload: error
})


export const settingsAdminAddData = () => ({
    type: ACTION_TYPES.SETTINGS_ADMIN_ADD_PENDING
});
export const settingsAdminAddSuccess = (data) => ({
    type: ACTION_TYPES.SETTINGS_ADMIN_ADD_SUCCESS,
    payload: data
});
export const settingsAdminAddError = (error) => ({
    type: ACTION_TYPES.SETTINGS_ADMIN_ADD_ERROR,
    payload: error
})


export const addSubAgentCreditData = () => ({
    type: ACTION_TYPES.ADD_SUB_AGENT_CREDIT_PENDING
});

export const addSubAgentCreditSuccess = (data) => ({
    type: ACTION_TYPES.ADD_SUB_AGENT_CREDIT_SUCCESS,
    payload: data
});
export const addSubAgentCreditError = (error) => ({
    type: ACTION_TYPES.ADD_SUB_AGENT_CREDIT_ERROR,
    payload: error
})



export const withdrawSubAgentCreditData = () => ({
    type: ACTION_TYPES.WITHDRAW_SUB_AGENT_CREDIT_PENDING
});

export const withdrawSubAgentCreditSuccess = (data) => ({
    type: ACTION_TYPES.WITHDRAW_SUB_AGENT_CREDIT_SUCCESS,
    payload: data
});
export const withdrawSubAgentCreditError = (error) => ({
    type: ACTION_TYPES.WITHDRAW_SUB_AGENT_CREDIT_ERROR,
    payload: error
})


export const publishRequestApproveRejectData = () => ({
    type: ACTION_TYPES.PUBLISH_REQUEST_APPROVE_REJECT_PENDING
});
export const publishRequestApproveRejectSuccess = (data) => ({
    type: ACTION_TYPES.PUBLISH_REQUEST_APPROVE_REJECT_SUCCESS,
    payload: data
});
export const publishRequestApproveRejectError = (error) => ({
    type: ACTION_TYPES.PUBLISH_REQUEST_APPROVE_REJECT_ERROR,
    payload: error
})

export const userModeEnable = (mode) => {
    return {
        type: ACTION_TYPES.USER_ENABLE,
        payload: { mode },
    };
};

export const ownerModeEnable = (mode) => {
    return {
        type: ACTION_TYPES.OWNER_ENABLE,
        payload: { mode },
    };
};
export const leadModeEnable = (mode) => {
    return {
        type: ACTION_TYPES.LEAD_ENABLE,
        payload: { mode },
    };
};
export const propertyModeEnable = (mode) => {
    return {
        type: ACTION_TYPES.PROPERTY_ENABLE,
        payload: { mode },
    };
};

export const companyModeEnable = (mode) => {
    return {
        type: ACTION_TYPES.COMPANY_ENABLE,
        payload: { mode },
    };
};

export const ownerPropertyEdit = (mode) => {
    return {
        type: ACTION_TYPES.OWNER_PROPERTY,
        payload: { mode },
    };
};

export const blogFlag = (blogType, blogId) => {
    return {
        type: ACTION_TYPES.BLOG_FLAG,
        payload: { blogType, blogId },
    };
};
export const clearData = (data) => ({
    type: ACTION_TYPES.CLEAR_STATE,

})