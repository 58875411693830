import React, { useEffect, useState } from 'react';
import refresh from '../../images/refresh.svg';
import calculator from '../../images/calculator.svg';
import closeModal from '../../images/close.svg';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Modal, } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

// <!-- Google Fonts -->
import '../../css/material-icon.css';

// <!-- Bootstrap Core Css -->
import '../../plugins/bootstrap/css/bootstrap.css';

// <!-- Waves Effect Css -->
import '../../plugins/node-waves/waves.css';

// <!-- Animation Css -->
import '../../plugins/animate-css/animate.css';

// <!-- Bootstrap Select Css -->
import '../../plugins/bootstrap-select/css/bootstrap-select.css';


// <!-- JQuery DataTable Css -->
import '../../plugins/jquery-datatable/skin/bootstrap/css/dataTables.bootstrap.css';
// <!-- Bootstrap Material Datetime Picker Css -->
import '../../plugins/bootstrap-material-datetimepicker/css/bootstrap-material-datetimepicker.css';

// <!-- Bootstrap DatePicker Css -->
import '../../plugins/bootstrap-datepicker/css/bootstrap-datepicker.css';

// <!-- Wait Me Css -->
import '../../plugins/waitme/waitMe.css';

// <!-- Morris Chart Css-->
import '../../plugins/morrisjs/morris.css';

// <!-- Custom Css -->
import '../../css/style.css';
import '../../css/custom.css';
import '../../css/materialize.css';


// <!-- AdminBSB Themes. You can choose a theme from css/themes instead of get all themes -->
import '../../css/themes/all-themes.css';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Topbar from '../../component/Topbar';
import Sidebar from '../../component/Sidebar';
import { PropertyMasterDropdownApi, calculateFlexCostApi, countryApi, creditManagementApi, exportToCsv, logoutApi, unpublishedPropertiesCreditManagementApi } from '../../redux/ApiActionCreator';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import config from '../../redux/config';
import axios from 'axios';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;


function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

function CreditList() {
    const theme = useTheme();
    const dispatch = useDispatch()
    const { t } = useTranslation();
    const navigate = useNavigate()
    const [personName, setPersonName] = useState([]);
    const [searchShow, setSearchShow] = useState(true)
    const [open, setOpen] = useState(false);
    const [page, setPage] = React.useState(1);



    const numbers = [
        {
            Id: 1,
            Name: "1"
        },
        {
            Id: 2,
            Name: "2"
        },
        {
            Id: 3,
            Name: "3"
        },
        {
            Id: 4,
            Name: "4"
        },
        {
            Id: 5,
            Name: "5"
        },
        {
            Id: 6,
            Name: "6"
        },
        {
            Id: 7,
            Name: "7"
        },
        {
            Id: 8,
            Name: "8"
        },
        {
            Id: 9,
            Name: "9"
        },
        {
            Id: 10,
            Name: "10"
        },
        {
            Id: 11,
            Name: "11"
        },
        {
            Id: 12,
            Name: "12"
        },
        {
            Id: 13,
            Name: "13"
        },
        {
            Id: 14,
            Name: "14"
        },
        {
            Id: 15,
            Name: "15"
        },
        {
            Id: 16,
            Name: "16"
        },
        {
            Id: 17,
            Name: "17"
        },
        {
            Id: 18,
            Name: "18"
        },
        {
            Id: 19,
            Name: "19"
        },
        {
            Id: 20,
            Name: "20"
        },
    ];
    const priceTypeOptionsFlexCostRent = [
        {
            Id: 1,
            Name: "BHD/Month"
        }
    ];

    const priceTypeOptionsFlexCostSale = [
        {
            Id: 1,
            Name: "BHD"
        }
    ];
    const [loading, setLoading] = useState(false)
    const [priceTypeSelectedFlexCostRent, setPriceTypeSelectedFlexCostRent] = useState(1)
    const [priceTypeSelectedFlexCostSale, setPriceTypeSelectedFlexCostSale] = useState(1)
    const [bedroomsFlexCalculator, setBedroomsFlexCalculator] = useState('')
    const [bathroomsroomsFlexCalculator, setBathroomsFlexCalculator] = useState('')
    const [categorySelectedFlexCalculator, setCategorySelectedFlexCalculator] = useState(1)
    const [locationSelectedFlexCalculator, setLocationSelectedFlexCalculator] = useState('')
    const [propertyTypeSelectedFlexCalculator, setPropertyTypeFlexCalculator] = useState('')
    const [priceFlexCostCalculator, setPriceFlexCostCalculator] = useState("")
    const [locationSelectedFlexCalculatorError, setLocationSelectedFlexCalculatorError] = useState('')
    const [propertyTypeSelectedFlexCalculatorError, setPropertyTypeFlexCalculatorError] = useState('')
    const [priceFlexCostCalculatorError, setPriceFlexCostCalculatorError] = useState("")
    const [refreshLoading, setRefreshLoading] = useState(false)
    const creditManageData = useSelector((state) => state?.apiReducer?.creditManageData)
    const unpublishedPropertiesCreditManageData = useSelector((state) => state?.apiReducer?.unpublishedPropertiesCreditManageData)
    const propertyMasterData = useSelector((state) => state?.apiReducer?.propertyMasterData)
    const priceOptionsDisplay = categorySelectedFlexCalculator == 1 || categorySelectedFlexCalculator == 3 ? priceTypeOptionsFlexCostRent : priceTypeOptionsFlexCostSale
    const flexCostData = useSelector((state) => state?.apiReducer?.calculateFlexCostData)
    const selectedPropertyTypeDropdownFlexCalculator = categorySelectedFlexCalculator == 1 ? propertyMasterData?.ResidentialRent : categorySelectedFlexCalculator == 2 ? propertyMasterData?.ResidentialSale : categorySelectedFlexCalculator == 3 ? propertyMasterData?.CommertialRent : propertyMasterData?.CommertialSale
    const token = useSelector((state) => state?.apiReducer?.loginData?.Data?.accessToken)
    const exportToCsvData = useSelector((state) => state?.apiReducer?.exportToCsvData)
    const [value, setValue] = React.useState('one');


    //GET EXPORT TO CSV DATA
    useEffect(() => {

        dispatch(exportToCsv(token))
            .then((response) => {

            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {

                    dispatch(logoutApi())
                    navigate('/')

                }


            });
    }, [])



    //ONCLICK EXPORT CSV FILE
    const handleExport = () => {
        const headings = Object?.keys(exportToCsvData[0]);
        const dataWithLocalDateTime = exportToCsvData?.map(item => ({
            ...item,
            "TransactionDate": moment.utc(item["TransactionDate"], "MMM DD YYYY h:mmA").local().format("MMM DD YYYY hh:mmA")
        }));

        const csvContent = "data:text/csv;charset=utf-8," +
            headings.join(",") + "\n" + // Adding headings as the first row
            dataWithLocalDateTime.map(row => headings.map(heading => row[heading]).join(",")).join("\n");
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "exported_data.csv");
        document.body.appendChild(link);
        link.click();
    };



    useEffect(() => {
        dispatch(PropertyMasterDropdownApi(token))
    }, [])

    //CREDIT MANAGE API CALL
    useEffect(() => {
        const params = {
            "pageNumber": page,
        }
        dispatch(creditManagementApi(token, params))
            .then((response) => {

            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {

                    dispatch(logoutApi())
                    navigate('/')

                }


            });
    }, [page, value])


    //UNPUBLISHED PROPERTIES CREDIT MANAGE API CALL

    useEffect(() => {

        dispatch(unpublishedPropertiesCreditManagementApi(token))
            .then((response) => {

            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {

                    dispatch(logoutApi())
                    navigate('/')

                }


            });
    }, [])

    //ONCLICK TOTAL BALANCE REFRESH
    const onClickRefresh = () => {
        setRefreshLoading(true)

        const params = {
            "pageNumber": page,
        }
        dispatch(creditManagementApi(token, params))
            .then((response) => {
                setTimeout(() => {
                    setRefreshLoading(false)
                }, 2000);


            })
            .catch((error) => {
                setTimeout(() => {
                    setRefreshLoading(false)
                }, 2000);



            });
    }



    const validatePropertyTypeFlexCostCalculator = (value) => {
        if (value.length === 0) {
            setPropertyTypeFlexCalculatorError('Please Select Property Type');
        } else {
            setPropertyTypeFlexCalculatorError('');
        }
    };

    const validateLocationFlexCostCalculator = (value) => {
        if (value.length === 0) {
            setLocationSelectedFlexCalculatorError('Please Select Location');
        } else {
            setLocationSelectedFlexCalculatorError('');
        }
    };

    const validatePriceFlexCostCalculator = (value) => {
        if (value.length === 0) {
            setPriceFlexCostCalculatorError('Please Enter Price');
        } else {
            setPriceFlexCostCalculatorError('');
        }
    };

    //RESET FLEX COST CALCULATOR
    const resetCalculateFlexCost = () => {
        setLoading(true)
        setCategorySelectedFlexCalculator(1)
        setPropertyTypeFlexCalculator("")
        setPriceFlexCostCalculator("")
        setLocationSelectedFlexCalculator("")
        setBathroomsFlexCalculator("")
        setBedroomsFlexCalculator("")
        const params = {

        }

        dispatch(calculateFlexCostApi(token, params))
            .then(() => {
                setLoading(false)

            })
            .catch(error => {
                setLoading(false)
            })


    }

    //ONCLICK FLEX COST CALCULATOR
    const onClickCalculateFlexCost = () => {
        let validate = false
        if (locationSelectedFlexCalculator == '') {
            validate = true
            setLocationSelectedFlexCalculatorError("Please Select Location");
        } else {
            setLocationSelectedFlexCalculatorError('');
        }

        if (priceFlexCostCalculator == '') {
            validate = true
            setPriceFlexCostCalculatorError("Please Enter Price");
        } else {
            setPriceFlexCostCalculatorError('');
        }

        if (propertyTypeSelectedFlexCalculator == '') {
            validate = true
            setPropertyTypeFlexCalculatorError("Please Select Property Type");
        } else {
            setPropertyTypeFlexCalculatorError('');
        }


        if (validate == false) {
            setLoading(true)
            const params = {
                Price: priceFlexCostCalculator == "" ? null : priceFlexCostCalculator,
                CityId: locationSelectedFlexCalculator == "" ? null : locationSelectedFlexCalculator,
                CategoryId: categorySelectedFlexCalculator == "" ? null : categorySelectedFlexCalculator,
                PropertyTypeId: propertyTypeSelectedFlexCalculator == "" ? null : propertyTypeSelectedFlexCalculator,
            }

            dispatch(calculateFlexCostApi(token, params))
                .then(() => {
                    setLoading(false)

                })
                .catch(error => {
                    setLoading(false)
                })
        }
    }




    const pageHandleChange = (event, value) => {
        setPage(value);
    };

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleChanges = (event, newValue) => {

        setValue(newValue);
    };

    const claimApiCall = async (id) => {
        setLoading(true)
        try {
            const response = await axios.get(`${config.baseURL}/Credit/ClaimCredits?claimId=${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.status == 200) {

                if (response.data.Status == 'OK') {
                    dispatch(exportToCsv(token))

                    dispatch(unpublishedPropertiesCreditManagementApi(token))
                        .then((response) => {
                            const params = {
                                "pageNumber": page,
                            }
                            dispatch(creditManagementApi(token, params))
                                .then((response) => {

                                })
                                .catch((error) => {
                                    if (error.response && error.response.status === 401) {

                                        dispatch(logoutApi())
                                        navigate('/')

                                    }


                                });
                            setLoading(false)
                        })
                        .catch((error) => {
                            setLoading(false)


                        });



                } else if (response.data.Status == 'ERROR') {


                }

            } else {

            }
        } catch (error) {

            setLoading(false)
        }
    };
    return (
        <div className="App">
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}

            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <div class="overlay"></div>
            <Topbar />
            <Sidebar message={'Credit'} />

            <section class="content">
                <div class="block-header">
                    <h2>{t('creditManagement')}</h2>
                </div>

                <div class="row clearfix">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="tab_block">
                            <Tabs
                                value={value}

                                onChange={handleChanges}
                                className='tab-nav-right'
                                aria-label="wrapped label tabs example"
                            >
                                <Tab className='tabs_title' value="one" label={'ELIGIBLE FOR CREDIT RETURN'} wrapped />
                                <Tab className='tabs_title' value="two" label={'CREDIT USAGE HISTORY'} />

                            </Tabs>
                        </div>

                        <div class="tab_content_block">

                            {
                                value == 'one' ?

                                    <div class="main_class">


                                        <div class="table_block">
                                            <div class="card">
                                                <div class="table-responsive">
                                                    {
                                                        unpublishedPropertiesCreditManageData?.Data?.list?.length != 0 ?
                                                            <table class="table table-bordered table-striped table-hover dataTable">
                                                                <thead>
                                                                    <tr>
                                                                        <th>
                                                                            <div class="table_heading text-center">
                                                                                <h5>{t('Request Credit Return')}</h5>
                                                                            </div>
                                                                        </th>
                                                                        <th>
                                                                            <div class="table_heading text-center">
                                                                                <h5>{t('Unpublished Date')}</h5>
                                                                            </div>
                                                                        </th>
                                                                        <th>
                                                                            <div class="table_heading text-center">
                                                                                <h5>{t('Action Before')}</h5>
                                                                            </div>
                                                                        </th>
                                                                        <th>
                                                                            <div class="table_heading text-center">
                                                                                <h5>{t('credits')}</h5>
                                                                            </div>
                                                                        </th>

                                                                        <th>
                                                                            <div class="table_heading text-center">
                                                                                <h5>{t('webId')}</h5>
                                                                            </div>
                                                                        </th>
                                                                        <th>
                                                                            <div class="table_heading text-center">
                                                                                <h5>{t('reference')}</h5>
                                                                            </div>
                                                                        </th>

                                                                        <th>
                                                                            <div class="table_heading text-center">
                                                                                <h5>{t('propertyType')}</h5>
                                                                            </div>
                                                                        </th>
                                                                        <th>
                                                                            <div class="table_heading text-center">
                                                                                <h5>{t('category')}</h5>
                                                                            </div>
                                                                        </th>
                                                                        <th>
                                                                            <div class="table_heading text-center">
                                                                                <h5>{t('location')}</h5>
                                                                            </div>
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody class="text-center">
                                                                    {
                                                                        unpublishedPropertiesCreditManageData?.Data?.list?.map((item) => {


                                                                            return (
                                                                                <tr>
                                                                                    <td >
                                                                                        {
                                                                                            item?.IsExpired == false && (
                                                                                                <div class="table_detail text-center">

                                                                                                    <a onClick={() => claimApiCall(item?.ID)} class={"status green"} style={{ marginTop: 10 }}>{"Claim"}</a>
                                                                                                </div>

                                                                                            )
                                                                                        }
                                                                                    </td>

                                                                                    <td>
                                                                                        <div class="table_detail text-center">
                                                                                            <h6>{moment.utc(item?.UnpublishedDate).local().format('DD-MM-YYYY hh:mma')}</h6>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div class="table_detail text-center">
                                                                                            <h6>{moment.utc(item?.ActionBefore).local().format('DD-MM-YYYY hh:mma')}</h6>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div class="table_detail text-right">
                                                                                            <h6>{item?.Credits}</h6>
                                                                                        </div>
                                                                                    </td>


                                                                                    <td>
                                                                                        <div class="table_detail text-center">
                                                                                            <h6></h6>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div class="table_detail text-center">
                                                                                            <h6>{item?.Reference}</h6>
                                                                                        </div>
                                                                                    </td>

                                                                                    <td>
                                                                                        <div class="table_detail text-center">
                                                                                            <h6>{item?.PropertyTypeName}</h6>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div class="table_detail text-center">
                                                                                            <h6>{item?.CategoryName}</h6>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div class="table_detail text-center">
                                                                                            <h6>{item?.CityName}</h6>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }





                                                                </tbody>
                                                            </table> : <h1 class="text-center">No Data Found</h1>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : null
                            }
                            {
                                value == 'two' ?

                                    <div class="main_class">

                                        <div class="credit_balance_block">
                                            <div class="balance_block">
                                                <p class="balanced">{t('creditBalance')}:&nbsp;&nbsp;<span>{creditManageData?.TotalCredits}</span><a href="#" onClick={() => onClickRefresh()} class={refreshLoading ? "animated_refresh" : "static_refresh"} ><img src={refresh}></img></a></p>

                                                <button onClick={handleOpen}><img src={calculator} /></button>
                                            </div>
                                            {
                                                creditManageData?.Data?.length == 0 ?
                                                    null :
                                                    <a href='#' onClick={() => handleExport()} class="links export">{t('exportToCSV')}</a>
                                            }

                                        </div>
                                        <div class="table_block">
                                            <div class="card">
                                                <div class="table-responsive">
                                                    {
                                                        creditManageData?.Data?.length != 0 ?
                                                            <table class="table table-bordered table-striped table-hover dataTable">
                                                                <thead>
                                                                    <tr>

                                                                        <th>
                                                                            <div class="table_heading text-center">
                                                                                <h5>{t('date')}</h5>
                                                                            </div>
                                                                        </th>
                                                                        <th>
                                                                            <div class="table_heading text-center">
                                                                                <h5>{t('credits')}</h5>
                                                                            </div>
                                                                        </th>
                                                                        <th>
                                                                            <div class="table_heading text-center">
                                                                                <h5>{t('creditBalance')}</h5>
                                                                            </div>
                                                                        </th>
                                                                        <th>
                                                                            <div class="table_heading text-center">
                                                                                <h5>{t('description')}</h5>
                                                                            </div>
                                                                        </th>
                                                                        <th>
                                                                            <div class="table_heading text-center">
                                                                                <h5>{t('webId')}</h5>
                                                                            </div>
                                                                        </th>
                                                                        <th>
                                                                            <div class="table_heading text-center">
                                                                                <h5>{t('reference')}</h5>
                                                                            </div>
                                                                        </th>
                                                                        <th>
                                                                            <div class="table_heading text-center">
                                                                                <h5>{t('propertyType')}</h5>
                                                                            </div>
                                                                        </th>
                                                                        <th>
                                                                            <div class="table_heading text-center">
                                                                                <h5>{t('category')}</h5>
                                                                            </div>
                                                                        </th>
                                                                        <th>
                                                                            <div class="table_heading text-center">
                                                                                <h5>{t('location')}</h5>
                                                                            </div>
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody class="text-center">
                                                                    {
                                                                        creditManageData?.Data?.map((item) => {

                                                                            return (
                                                                                <tr>

                                                                                    <td>
                                                                                        <div class="table_detail text-center">
                                                                                            <h6>{moment.utc(item?.TransactionDate).local().format('DD-MM-YYYY hh:mma')}</h6>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div class="table_detail text-right">
                                                                                            <h6>{item?.Credits}</h6>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div class="table_detail text-right">
                                                                                            <h6>{item?.CreditBalance}</h6>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div class="table_detail text-center">
                                                                                            <h6>{item?.Description}</h6>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div class="table_detail text-center">
                                                                                            <h6>{item?.WebId}</h6>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div class="table_detail text-center">
                                                                                            <h6>{item?.Reference}</h6>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div class="table_detail text-center">
                                                                                            <h6>{item?.PropertyTypeName}</h6>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div class="table_detail text-center">
                                                                                            <h6>{item?.CategoryName}</h6>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div class="table_detail text-center">
                                                                                            <h6>{item?.Location}</h6>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }
                                                                </tbody>
                                                            </table> : <h1 class="text-center">No Data Found</h1>
                                                    }

                                                </div>
                                                <div class="text-right">
                                                    <Pagination className="pagination" count={creditManageData?.TotalPages} page={page} onChange={pageHandleChange} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : null
                            }

                        </div>
                    </div>
                </div>


                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                >
                    <div class="modal_block">
                        <div class="modal_header_block">
                            <h2 id="child-modal-title">Flex Cost Calculator</h2>
                            <button onClick={handleClose}><img src={closeModal} /></button>
                        </div>
                        <div class="modal_body_block">
                            <p>Fill out your property details and calculate how many Flex credits you will need to list or upgrade it.</p>
                            <div class="form_block">
                                <div class="row clearfix">
                                    <div class="col-md-12">
                                        <div>
                                            <FormControl className='form-group' sx={{}}>
                                                <Select
                                                    className='form-control border_temp'

                                                    displayEmpty
                                                    value={locationSelectedFlexCalculator}
                                                    onChange={(e) => { setLocationSelectedFlexCalculator(e.target.value); validateLocationFlexCostCalculator(e.target.value) }}
                                                    input={<OutlinedInput />}

                                                    MenuProps={{
                                                        PaperProps: {
                                                            style: {
                                                                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                width: 250,
                                                            },
                                                        },
                                                        disableScrollLock: true,
                                                    }}
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                >
                                                    <MenuItem className='drop_menus' disabled value="">
                                                        <em className='select_text'>{t('location')}</em>
                                                    </MenuItem>
                                                    {propertyMasterData?.FlexCalLocations?.map((item) => (
                                                        <MenuItem
                                                            className='drop_menus'
                                                            key={item?.Id}
                                                            value={item?.Id}
                                                            style={getStyles(item?.Name, personName, theme)}
                                                        >
                                                            {item?.Name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                {
                                                    locationSelectedFlexCalculatorError && (
                                                        <span class="validation_notes">{locationSelectedFlexCalculatorError}</span>

                                                    )
                                                }
                                            </FormControl>
                                        </div>
                                    </div>
                                </div>
                                <div class="row clearfix">
                                    <div class="col-md-12">
                                        <div>
                                            <FormControl className='form-group' sx={{}}>
                                                <Select
                                                    className='form-control border_temp'

                                                    displayEmpty
                                                    value={categorySelectedFlexCalculator}
                                                    onChange={(e) => { setCategorySelectedFlexCalculator(e.target.value); setPropertyTypeFlexCalculator("") }}
                                                    input={<OutlinedInput />}

                                                    MenuProps={{
                                                        PaperProps: {
                                                            style: {
                                                                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                width: 250,
                                                            },
                                                        },
                                                        disableScrollLock: true,
                                                    }}
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                >
                                                    <MenuItem className='drop_menus' disabled value="">
                                                        <em className='select_text'>Category</em>
                                                    </MenuItem>
                                                    {propertyMasterData?.CategoryForOfferingTypes?.map((item) => (
                                                        <MenuItem
                                                            className='drop_menus'
                                                            key={item?.Id}
                                                            value={item?.Id}
                                                            style={getStyles(item?.Name, personName, theme)}
                                                        >
                                                            {item?.Name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                </div>
                                <div class="row clearfix">
                                    <div class="col-md-6">
                                        <div>
                                            <FormControl className='form-group' sx={{}}>
                                                <Select
                                                    className='form-control border_temp'

                                                    displayEmpty
                                                    value={propertyTypeSelectedFlexCalculator}
                                                    onChange={(e) => { setPropertyTypeFlexCalculator(e.target.value); validatePropertyTypeFlexCostCalculator(e.target.value) }}
                                                    input={<OutlinedInput />}

                                                    MenuProps={{
                                                        PaperProps: {
                                                            style: {
                                                                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                width: 250,
                                                            },
                                                        },
                                                        disableScrollLock: true,
                                                    }}
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                >
                                                    <MenuItem className='drop_menus' disabled value="">
                                                        <em className='select_text'>Property Type</em>
                                                    </MenuItem>
                                                    {selectedPropertyTypeDropdownFlexCalculator?.map((item) => (
                                                        <MenuItem
                                                            className='drop_menus'
                                                            key={item?.Id}
                                                            value={item?.Id}
                                                            style={getStyles(item?.Name, personName, theme)}
                                                        >
                                                            {item?.Name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                {
                                                    propertyTypeSelectedFlexCalculatorError && (
                                                        <span class="validation_notes">{propertyTypeSelectedFlexCalculatorError}</span>

                                                    )
                                                }
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div>
                                            <FormControl className='form-group' sx={{}}>
                                                <Select
                                                    className='form-control border_temp'
                                                    displayEmpty
                                                    disabled={categorySelectedFlexCalculator == 3 || categorySelectedFlexCalculator == 4}
                                                    value={bedroomsFlexCalculator}
                                                    onChange={(e) => setBedroomsFlexCalculator(e.target.value)}
                                                    input={<OutlinedInput />}
                                                    MenuProps={{
                                                        PaperProps: {
                                                            style: {
                                                                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                width: 250,
                                                            },
                                                        },
                                                        disableScrollLock: true,
                                                    }}
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                >
                                                    <MenuItem className='drop_menus' disabled value="">
                                                        <em className='select_text'>{t('bedrooms')}</em>
                                                    </MenuItem>
                                                    {numbers?.map((item) => (
                                                        <MenuItem
                                                            className='drop_menus'
                                                            key={item?.Id}
                                                            value={item?.Id}
                                                            style={getStyles(item?.Name, personName, theme)}
                                                        >
                                                            {item?.Name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div>
                                            <FormControl className='form-group' sx={{}}>
                                                <Select
                                                    className='form-control border_temp'
                                                    displayEmpty
                                                    value={bathroomsroomsFlexCalculator}
                                                    onChange={(e) => setBathroomsFlexCalculator(e.target.value)}
                                                    input={<OutlinedInput />}
                                                    MenuProps={{
                                                        PaperProps: {
                                                            style: {
                                                                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                width: 250,
                                                            },
                                                        },
                                                        disableScrollLock: true,
                                                    }}
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                >
                                                    <MenuItem className='drop_menus' disabled value="">
                                                        <em className='select_text'>{t('bathroom')}</em>
                                                    </MenuItem>
                                                    {numbers?.map((item) => (
                                                        <MenuItem
                                                            className='drop_menus'
                                                            key={item?.Id}
                                                            value={item?.Id}
                                                            style={getStyles(item?.Name, personName, theme)}
                                                        >
                                                            {item?.Name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                </div>
                                <div class="row clearfix">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <div class="form-line">
                                                <input type="number" value={priceFlexCostCalculator} onChange={(e) => { setPriceFlexCostCalculator(e.target.value); validatePriceFlexCostCalculator(e.target.value) }} class="form-control" placeholder="Price" />
                                                {
                                                    priceFlexCostCalculatorError && (
                                                        <span class="validation_notes">{priceFlexCostCalculatorError}</span>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <div class="form-line">
                                                <div>
                                                    <FormControl className='form-group' sx={{}}>
                                                        <Select
                                                            className='form-control border_temp'

                                                            displayEmpty
                                                            value={categorySelectedFlexCalculator == 1 || categorySelectedFlexCalculator == 3 ? priceTypeSelectedFlexCostRent : priceTypeSelectedFlexCostSale}
                                                            onChange={(e) => categorySelectedFlexCalculator == 1 || categorySelectedFlexCalculator == 3 ? setPriceTypeSelectedFlexCostRent(e.target.value) : setPriceTypeSelectedFlexCostSale(e.target.value)}
                                                            input={<OutlinedInput />}

                                                            MenuProps={{
                                                                PaperProps: {
                                                                    style: {
                                                                        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                        width: 250,
                                                                    },
                                                                },
                                                                disableScrollLock: true,
                                                            }}
                                                            inputProps={{ 'aria-label': 'Without label' }}
                                                        >
                                                            <MenuItem className='drop_menus' disabled value="">
                                                                <em className='select_text'></em>
                                                            </MenuItem>
                                                            {priceOptionsDisplay?.map((item) => (
                                                                <MenuItem
                                                                    className='drop_menus'
                                                                    key={item?.Id}
                                                                    value={item?.Id}
                                                                    style={getStyles(item?.Name, personName, theme)}
                                                                >
                                                                    {item?.Name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h4>Listing Cost (Credits)</h4>
                            <div class="plans_detail">
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="plans_block">
                                            <h6>Standard</h6>
                                            <p>1 Month:&nbsp;&nbsp;{flexCostData?.Data?.Standard == null ? "--" : flexCostData?.Data?.Standard}</p>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="plans_block">
                                            <h6>Featured</h6>
                                            <p>15 Days:&nbsp;&nbsp;{flexCostData?.Data?.Featured_for_15_Days == null ? "--" : flexCostData?.Data?.Featured_for_15_Days}</p>
                                            <p>1 Month:&nbsp;&nbsp;{flexCostData?.Data?.Featured_for_1_Month == null ? "--" : flexCostData?.Data?.Featured_for_1_Month}</p>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="plans_block">
                                            <h6>Premium</h6>
                                            <p>15 Days:&nbsp;&nbsp;{flexCostData?.Data?.Premium_for_15_Days == null ? "--" : flexCostData?.Data?.Premium_for_15_Days}</p>
                                            <p>1 Month:&nbsp;&nbsp;{flexCostData?.Data?.Premium_for_1_Month == null ? "--" : flexCostData?.Data?.Premium_for_1_Month}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal_footer_block">
                            <div class="button_block">
                                <a href="#" onClick={() => resetCalculateFlexCost()} class="links">Reset</a>
                                <a href="#" onClick={() => onClickCalculateFlexCost()} class="links">Calculate</a>
                            </div>
                        </div>
                    </div>
                </Modal>
            </section>
        </div>
    );

}


export default CreditList;
